/**
 * @file App layout getter
 * @module transform.layout
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import {RouteMeta} from 'vue-router'
import {LayoutColumn} from '/@/app/state'

export const getLayoutByRouteMeta = (routeMeta: RouteMeta) => {
  return routeMeta.layout === LayoutColumn.Center
    ? LayoutColumn.Center
    : routeMeta.layout === LayoutColumn.Full
    ? LayoutColumn.Full
    : routeMeta.layout === LayoutColumn.Normal
    ? LayoutColumn.Normal
    : routeMeta.layout === LayoutColumn.Left
    ? LayoutColumn.Left : LayoutColumn.Right
}
