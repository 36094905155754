<template>
  <div class="follow-list">
    <placeholder :data="users.length" :loading="!users.length && fetching">
      <template #loading>
        <ul class="follow-list-skeleton" key="skeleton">
          <li v-for="item in 6" :key="item" class="item">
              <div class="avatar">
                <skeleton-base />
              </div>
              <div class="info">
                <div class="nick">
                  <skeleton-line />
                </div>
                <div class="position">
                  <skeleton-line />
                </div>
              </div>
              <div class="action">
                  <skeleton-line />
              </div>
          </li>
        </ul>
      </template>
      <template #placeholder>
        <empty class="empty" :i18n-key="LanguageKey.ARTICLE_PLACEHOLDER" />
      </template>
      <template #default>
        <div key="list">
          <!-- list -->
          <transition-group name="list-fade">
            <div class="follow-item" v-for="(item, i) in users">
              <div class="item-avatar">
                <uimage :alt="item.follower.nickName" :src="item.follower.avatar" />
              </div>
              <div class="item-info">
                <div class="nick">
                  <span>{{ item.follower.nickName }}</span>
                  <level :level=item.follower.level />
                </div>
                <div class="position">
                  {{ item.follower.occupation }}
                </div>
              </div>
              <div class="item-action" :class="{ follow: isFollow(item.follower.id)}">
                <button type="button" :disabled="userStore.votes.fetching" @click="handUserFollow(item.follower.id)">{{ isFollow(item.follower.id) ? '已关注' : '关注' }}</button>
              </div>
            </div>
          </transition-group>
        </div>
      </template>
    </placeholder>
    <!-- loadmore -->
    <button class="follow-load" :disabled="fetching || !isLoadMoreEnabled" @click="handleLoadmore">
      <div class="background">
        <span class="left"></span>
        <span class="right"></span>
      </div>
      <div class="content">
        <span class="left">
          <template v-if="fetching">•••</template>
          <template v-else>{{ users.length }} / {{ pagination?.total }}</template>
        </span>
        <span class="right">
          <webfont bolder uppercase>
            <i18n v-if="fetching" :k="LanguageKey.ARTICLE_LIST_LOADING" />
            <i18n v-else-if="isLoadMoreEnabled" :k="LanguageKey.ARTICLE_LIST_LOADMORE" />
            <i18n v-else :k="LanguageKey.ARTICLE_LIST_NO_MORE" />
          </webfont>
          <i class="iconfont icon-loadmore"></i>
        </span>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, PropType } from "vue";
  import { useEnhancer } from '/@/app/enhancer'
  import { Article } from '/@/stores/article'
  import { useUserStore } from '/@/stores/user'
  import { LanguageKey } from '/@/language'
  import { User } from "/@/stores/user";
  import Level from '/@/components/widget/level.vue'
  import Toast from "/@/composables/toast";

  export enum Events {
    Loadmore = 'loadmore'
  }
  export default defineComponent({
    name: "UserFollowList",
    components: { Level },
    props: {
      users: {
        type: Array,
        required: true
      },
      pagination: Object,
      fetching: {
        type: Boolean,
        required: true
      },
    },
    emits: [Events.Loadmore],
    setup(props, context) {
      const { isDarkTheme } = useEnhancer()
      const userStore = useUserStore()
      const isLoadMoreEnabled = computed(() => {
        return props.pagination
          ? props.users.length != props.pagination.total
          : false
      })

      const handleLoadmore = () => {
        context.emit(Events.Loadmore)
      }
      const isFollow = (userId: string) => {
        return userStore.votes.follows.includes(userId)
      }

      const handUserFollow = async (userId: string) => {
        if(!userStore.isLogin){
          Toast.error("请登入！")
          return
        }
        const isFollow = await userStore.postUserFollow({byUserId: userId});
        console.log(`isFollow:${isFollow}`)
        Toast.success(`${isFollow ? "关注成功！" : "取消关注！"}`)
      }

      return {
        LanguageKey,
        isDarkTheme,
        isLoadMoreEnabled,
        handleLoadmore,
        handUserFollow,
        userStore,
        isFollow
      }
    }
  })
</script>


<style lang="scss" scoped>
  @use 'sass:math';
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $width: 40px;
  .follow-list {
    .follow-list-skeleton {
      padding: 0;
      margin: 0;
      list-style: none;
      overflow: hidden;

      .item {
        display: flex;
        height: 5rem;
        padding: $gap;
        justify-content: center;
        align-items: center;
        margin-bottom: $sm-gap;
        @include common-bg-module();
        @include radius-box($sm-radius);
        &:last-child {
          margin-bottom: 0;
        }

        .avatar{
          width: 4rem;
          height: 4rem;
          margin-right: $lg-gap;
        }
        .info{
          display: flex;
          justify-content: start;
          flex-direction: column;
          flex: 1;
          .nick{
            width: 30%;
            height: 2rem;
            padding-bottom: $sm-gap;
          }
          .position{
            width: 50%;
            height: 1.5rem;
          }
        }
        .action{
          width: 100px;
          height: 3rem;
        }
      }
    }
    .follow-load {
      width: 100%;
      height: $button-block-height;
      position: relative;
      display: block;
      @include radius-box($sm-radius);
      &[disabled] {
        opacity: 0.6;
      }
      &:not([disabled]):hover {
        .background {
          .left {
            background: $module-bg-opaque;
          }
          .right {
            background: $surmon;
          }
        }
        .content {
          .left {
            color: $primary;
          }
          .right {
            color: $white;
          }
        }
      }

      .content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left,
        .right {
          @include color-transition();
        }

        .left {
          font-weight: bold;
          color: $text-disabled;
          margin-left: 2em;
        }

        .right {
          width: 10rem;
          color: $text-secondary;
          .iconfont {
            margin-left: $gap;
          }
        }
      }

      .background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-index-normal - 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        $skew: -20deg;
        $skew-offset: $gap;

        .left,
        .right {
          height: 100%;
          border-radius: $xs-radius;
          @include background-transition();
        }

        .left {
          flex: 1;
          margin-left: -$skew-offset;
          margin-right: $skew-offset;
          background: $module-bg;
          transform: skew($skew);
        }

        .right {
          width: 12rem;
          margin-right: -$skew-offset;
          background: $module-bg-opaque;
          transform: skew($skew);
        }
      }
    }
  }
  .follow-item {
    $padding: $lg-gap;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*background-color: $module-bg-darker-1;*/
    border-radius: $xs-radius;
    /*@include common-bg-module($transition-time-fast);*/
    background-color: $module-bg;
    padding: $padding;
    overflow: hidden;
    border-bottom: 1px solid $module-bg-darker-1;
    &:hover {
      background-color: $module-bg;
    }

    &:last-child {
      border-bottom: 0px solid $module-bg-darker-1;
    }
    //padding: 0 $sm-gap;
/*    &:hover{
      background-color: $module-bg-darker-2;
    }*/
    &:last-child {
      margin: 0;
    }
    .item-avatar{

      width: $width;
      height: $width;
      margin-right: $lg-gap;
      position: relative;
      background-color: $module-bg-darker-1;
      @include radius-box(5px);
      img {
        transition: transform 0.5s ease-out;
      }
    }
    .item-info{
      display: flex;
      justify-content: start;
      justify-items: center;
      flex-direction: column;
      flex: 1;
      .nick{
        span{
          line-height: $font-size-h4;
          font-size: $font-size-h4;
          font-weight: 600;
        }
        margin-bottom: 2px;
      }
      .position{
        font-size: $font-size-h6;
      }
    }
    .item-action{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      padding: 0 $gap;
      margin-right: $gap;
      height: 2.5rem;
      align-items: center;
      border-radius: $sm-radius;
      transition: all $transition-time-fast;
      cursor: pointer;
      color: #54a5dd;
      border: 1px solid #54a5dd;
      &.follow{
        background-color: #54a5dd;
        color: $white;
      }
      &:hover {
        opacity: 0.8;
      }

    }
  }
</style>
