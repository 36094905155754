<template>
  <div class="saunter-page">
    <!--  发布圈文  -->
      <Issue
        v-model:content="boiling.content"
        v-model:previewed="boiling.previewed"
        v-model:pictures="boiling.pictures"
        v-model:boneId="boiling.boneId"
        v-model:video="boiling.video"
        :auto-focus="false"
        :hidden-stationery="false"
        :posting="false"
        :is-login="userStore.isLogin"
        :isMobile="isMobile"
        @submit="handleSubmit" />


    <!-- 圈文列表   -->
    <boiling-list
      :mammon="false"
      :fetching="boilingStore.list.fetching"
      :boilings="boilingStore.list.data"
      :pagination="boilingStore.list.pagination"
      @loadmore="loadmoreArticles"
      @like="handleLike"
      :isMobile="isMobile"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUniversalFetch, onClient } from '/@/universal'
  import { useTwitterStore } from '/@/stores/twitter'
  import { useBoilingStore } from '/@/stores/saunter'
  import { useUserStore } from '/@/stores/user'
  import { scrollToNextScreen } from '/@/utils/scroller'
  import { LanguageKey } from '/@/language'
  import { META } from '/@/config/app.config'
  import Issue from './issue.vue'
  import BoilingList from './boiling/list.vue'
  import Toast from "/@/composables/toast/index"

  export default defineComponent({
    name: 'SaunterPage',
    components: {
      Issue,
      BoilingList
    },
    props: {
      boneId: {
        type: String,
        required: false
      },
      isMobile: {
        type: Boolean,
        default: false
      }
    },

    setup(props) {
      const boilingStore = useBoilingStore()
      const userStore = useUserStore()
      const { meta, i18n } = useEnhancer()

      meta(() => ({
        title: `${i18n.t(LanguageKey.PAGE_SAUNTER)} - ${META.title}`,
        description: `${META.description}`,
        keywords: `${i18n.t(LanguageKey.PAGE_SAUNTER)},${META.keywords}`
      }))

      const loadmoreArticles = async () => {
        const targetPage = boilingStore.list.pagination?.current + 1
        await boilingStore.fetchBoilingList({ pageNum: targetPage, boneId: props.boneId })
        if (targetPage > 1) {
          onClient(scrollToNextScreen)
        }
      }
      const boiling = reactive({
        pictures: [],
        boneId: null,
        video: {
          src: null,
          poster: null,
          source: 1
        },
        content: '',
        topicId: null,
        previewed: false
      })
      useUniversalFetch(() => {
        return Promise.all([
          boilingStore.fetchBoilingList({boneId: props.boneId}),
        ])
      })

      const cleanBoiling = () => {
        boiling.content = ''
        boiling.pictures.length = 0
        boiling.pictures = []
        boiling.topicId = null
        boiling.boneId = null
        boiling.video = {
          src: null,
          poster: null,
          source: 1
        }
      }
      const handleSubmit = async (data: object) => {
        // console.log(`boiling:${JSON.stringify(boiling)}`)
        // console.log(`data:${JSON.stringify(data)}`)
        try {
          await boilingStore.saveBoiling(data).then((response) => {
            Toast.success("发布成功！")
            boilingStore.fetchBoilingList({boneId: props.boneId})
            cleanBoiling()
          }).catch((error) => {
            Toast.error(error)
          }).finally(() => {
            boilingStore.list.fetching = false
          })
        }catch (error: any){
          Toast.error(error)
        }
      }


      const handleLike = async (boilingId: string) => {
        if(!userStore.isLogin){
          Toast.error("请登入！")
          return
        }
        try {
          userStore.votes.fetching = true
          boilingStore.postBoilingLike(boilingId).then(response => {
            const flag = response.data
            boilingStore.changeVoteBoilingListById(flag, 'likeNum', boilingId)
            userStore.changesVotes(flag, 'likes', boilingId)
          }).finally(() => {
            userStore.votes.fetching = false
          })
        } catch (error) {
          alert(error)
        }
      }

      onBeforeMount(() => {
        watch(
          () => props.boneId,
          (tagId) => boilingStore.fetchBoilingList({boneId: props.boneId}),
          { flush: 'post' }
        )
      })

      return {
        boilingStore,
        loadmoreArticles,
        handleLike,
        boiling,
        userStore,
        handleSubmit
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .saunter-page {
    .issue {
      margin-bottom: $lg-gap;
    }
  }
</style>
