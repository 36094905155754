<template>
  <div class="upload-button">
    <template v-if="multiple">
      <input
        ref="input"
        type="file"
        multiple="multiple"
        :accept="acceptType"
        @change="handleChange"
      />
    </template>
    <template v-else>
      <input ref="input" type="file" :accept="acceptType" @change="handleChange" />
    </template>
  </div>
</template>

<script lang="ts">
  import {defineComponent, ref, reactive} from "vue";
  import Toast from "/@/composables/toast/index";
  import localhost from "/@/services/localhost";

  const defaultType = [
    'bmp',
    'jpg',
    'png',
    'tif',
    'gif',
    'svg',
    'psd',
    'ai',
    'raw',
    'WMF',
    'webp',
  ];
  export default defineComponent({
    name: "Upload",
    props: {
      multiple: {
        // 是否多选
        type: Boolean,
        default: false,
      },
      acceptType: {
        // 文件类型
        type: String,
        default: 'image/*',
      },
      type: {
        // 限制上传文件类型
        type: String,
        default: 'jpg,png',
      },
      bulk: {
        // 限制上传文件大小
        type: Number,
        default: 0,
      },
      moduleType: {
        // 资源类型
        type: Number,
        default: 0,
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    emits: ['data', 'update:loading'],
    setup(props, context) {
      //参数
      const { bulk, type, multiple } = reactive(props);
      const input = ref(null);
      let uploadFiles = reactive([]);
      const uploading = ref(false);//是否在上传

      //点击按钮
      const inputClick = () => {
        console.log(`acceptType:${props.acceptType}`)
        if (uploading.value) {
          Toast.warning('请等待前面的文件完成上传!');
        }
        return input.value.click();
      }

      //选择文件
      const handleChange = ({ target }) => {
        console.log(target.files)
        if (multiple) {
          uploadFiles = [...target.files];
        }else{
          uploadFiles = [...target.files];
        }
        uploadFile(uploadFiles);
        target.value = '';
      }

      //开始上传
      const uploadFile = async (files) => {
        context.emit('update:loading', true)
        console.log(files)
        // if (type) await detectorType(file);

        if (bulk) await detectorBulk(files);



        const data = new FormData();
        files = Array.from(files); // files是类数组，需要先转为数组
        files.forEach((file) => {
          data.append("files", file);
        });
        //data.append('file', files);  // 可以通过这种形式，来传递其他项目中要传递的参数。
        // 后端接受参数 ，可以接受多个参数
        data.append("source", false);
        data.append("moduleType", props.moduleType);
        try {
          localhost.post<any>(`/blog/file/uploadBatch`, data).then((response) => {
            console.log(response)
            context.emit('data', response); // 将请求到的数据 抛出去
            context.emit('update:loading', false)
          }).finally(() => {
            context.emit('update:loading', false)
          })
        }catch (e) {
          console.log( `upload-error:${e}`)
        }



      }

      //文件类型校验
      const detectorType = (file) => {
        return new Promise((resolve, reject) => {
          const sizeList = type.split(',');
          const fileSize = file.name.split('.');
          const fileExtension = fileSize[fileSize.length - 1].toLowerCase();
          if (!sizeList.includes(fileExtension)) {
            if (!defaultType.includes(fileExtension)) {
              Toast.error('文件类型不对！');
            } else {
              Toast.error('图片类型不对！');
            }
            reject(new Error());
          } else {
            resolve(true);
          }
        });
      };

      //文件大小校验
      const detectorBulk = (files) => {
        return new Promise((resolve, reject) => {
          files.forEach((file) => {
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > bulk) {
              Toast.error(`大小超出${bulk}M`);
              context.emit('update:loading', false)
              reject(new Error());
            } else {
              resolve(true);
            }
          });

        });
      }

      return {
        input,
        inputClick,
        handleChange,
      }
    }
  })
</script>

<style scoped>

</style>
