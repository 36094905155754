/**
 * @file Statistic state
 * @module store.statistic
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import localhost from "/@/services/localhost";

export interface Statistic {
  tags: number
  articleNum: number
  commentNum: number
  boilingNum: number
  userNum: number
  totalLikes: number
  averageEmotion: number
}

export const useStatisticStore = defineStore('statistic', {
  state: () => ({
    fetching: false,
    statistic: null as null | Statistic,
    statisticCalendar: {
      articleCalendar: [],
      boilingCalendar: [],
      commentCalendar: [],
      pictureCalendar: []
    }

  }),
  actions: {
    fetchStatistic() {
      this.fetching = true
      return localhost.get<any>(`/blog/web/statistics/api/site`)
        .then((response) => {
          this.statistic = response.data
        })
        .finally(() => {
          this.fetching = false
        })
    },

    fetchStatisticCalendar() {
      return localhost.get<any>(`/blog/web/statistics/api/calendar`)
        .then((response) => {
          this.statisticCalendar = response.data
        })
        .finally(() => {

        })
    },

    postFeedback(feedback: { impressionValue: number; content: string, agent: string }) {
      return localhost.post('/blog/web/feedback/api/submit', {
        ...feedback,
      })
    }
  }
})

