<template>
  <div class="article">
    <div class="condition">
      <div class="left">
        <span class="me" :class="{ active: activeTid === 0 }" @click="refresh(0)">我的</span>
        <divider type="vertical" />
        <span class="like" :class="{ active: activeTid === 1 }" @click="refresh(1)">点赞</span>
        <divider type="vertical" />
        <span class="collect" :class="{ active: activeTid === 2 }" @click="refresh(2)">收藏</span>
      </div>
      <div class="right">
        <span class="hot" :class="{ active: activeSid === 0 }" @click="refreshSort(0)">最新</span>
        <divider type="vertical" />
        <span class="new" :class="{ active: activeSid === 1 }" @click="refreshSort(1)">热门</span>
      </div>
    </div>
    <user-article-list
      :fetching="articleListStore.userArticleList.fetching"
      :articles="articleListStore.userArticleList.data"
      :pagination="articleListStore.userArticleList.pagination"
      @loadmore="loadmoreArticle"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref, toRaw } from "vue";
  import UserArticleList from "/@/pages/user/article/list.vue"
  import { useEnhancer } from '/@/app/enhancer'
  import { useUniversalFetch, onClient } from '/@/universal'
  import { useArticleListStore } from '/@/stores/article'
  import { scrollToNextScreen, scrollToAnchor, scrollToPageTop } from '/@/utils/scroller'
  import { LanguageKey } from '/@/language'
  import { META } from '/@/config/app.config'

  export default defineComponent({
    name: "UserArticle",
    components: {
      UserArticleList
    },
    props: {
      userId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const { meta, i18n } = useEnhancer()
      const articleListStore = useArticleListStore()

      meta(() => ({
        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `user article`,
        keywords: `user article keys`
      }))

      const params  = reactive({
        userId: props.userId,
        choose: 0,
        sort: 1,
        pageNum: 1
      })
      const loadmoreArticle = async () => {
        const targetPage = articleListStore.userArticleList.pagination?.current + 1
        params.pageNum = targetPage
        await articleListStore.fetchUserArticleList(params)
        if (targetPage > 1) {
          onClient(scrollToNextScreen)
        }
      }
      const activeTid = ref(0)
      const activeSid = ref(0)
      const refresh = async (choose: number) => {
        params.choose = choose
        activeTid.value = choose
        await articleListStore.fetchUserArticleList(params)
        onClient(scrollToPageTop)

      }
      const refreshSort = async (sort: number) => {
        params.sort = sort
        activeSid.value = sort
        await articleListStore.fetchUserArticleList(params)
        onClient(scrollToPageTop)

      }
      useUniversalFetch(() => {
        return Promise.all([
          articleListStore.fetchUserArticleList(params)
        ])
      })

      return {
        LanguageKey,
        articleListStore,
        loadmoreArticle,
        refresh,
        refreshSort,
        activeTid,
        activeSid
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .article{
    .condition{
      display: flex;
      justify-content: space-between;
      align-items: center;
      $conditionbar-size: 3rem;
      height: $conditionbar-size;
      line-height: $conditionbar-size;
      padding: 0 $lg-gap;
      .left{
        .me,
        .like,
        .collect {
          //margin-right: 2rem;
          //background-color: $module-bg-darker-1;
          //@include radius-box($xs-radius);
          cursor: pointer;
          padding: $xs-gap $xs-gap * 2;
          /*font-weight: bold;*/
          &:hover {
            color: $surmon;
          }

        }

      }
      .right{
        .hot,
        .new{
          cursor: pointer;
          &:hover {
            color: $surmon;
          }
        }
      }
    }
  }
  .active{
    color: $surmon;
  }
</style>
