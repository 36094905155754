/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import localhost from '/@/services/localhost'

export interface Config {
  configCode: string
  configName: string
  configInfo: object
}

export const useConfigStore = defineStore('config', {
  state: () => ({
    configList: {
      fetching: false,
      data: [] as Array<Config>
    },
  }),
  //获取配置
  getters: {
    getConfig: (state): Object => (configCode: string) =>{
      // console.log(`configCode:${configCode}`)
      let config
      state.configList.data.forEach((item) => {
          if(item.configCode == configCode){
            config = item
            return
          }
        }
      )
      console.log(`config:${JSON.stringify(config)}`)
      return config?.configInfo;
    }
  },
  actions: {
    fetchConfigList() {
      this.configList.fetching = true
      const fetch = localhost.get<any>('/blog/web/config/api/list').then((response) => {
          this.configList.data = response.data
          // console.log(response.data)
        })
        .finally(() => {
          this.configList.fetching = false
        })
    },
  }
})
