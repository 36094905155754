const _cdn = 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-39734fbc-b241-4d89-ad87-0befd655e266/'
// export const webSearchUrl = [
//   {
//     name: 'baidu',
//     icon: 'https://www.baidu.com/favicon.ico',
//     path: 'https://www.baidu.com/s?wd='
//   },
//   {
//     name: 'bing',
//     icon: 'https://cn.bing.com/sa/simg/favicon-2x.ico',
//     path: 'https://cn.bing.com/search?q='
//   },
//   {
//     name: 'google',
//     icon: 'https://images.frontendjs.com/google.png?imageView2/2/h/60',
//     path: 'https://www.google.com/search?q='
//   },
//   {
//     name: 'github',
//     icon: 'https://images.frontendjs.com/github.png?imageView2/2/h/30',
//     path: 'https://github.com/search?q='
//   },
//
//   // 教程
//   {
//     icon: 'https://static.runoob.com/images/favicon.ico',
//     name: '菜鸟教程',
//     path: 'https://www.runoob.com/'
//   },
//   {
//     icon: 'https://developer.mozilla.org/favicon-48x48.cbbd161b.png',
//     name: 'MDN',
//     path: 'https://developer.mozilla.org/zh-CN'
//   },
//   {
//     icon: 'https://www.w3school.com.cn/ui2019/logo-16-red.png',
//     name: 'w3school',
//     path: 'https://www.w3school.com.cn/'
//   },
//   {
//     icon: 'https://www.bilibili.com/favicon.ico?v=1',
//     name: '哔哩哔哩',
//     path: 'https://www.bilibili.com/'
//   },
//   {
//     icon: 'https://www.imooc.com/favicon.ico',
//     name: '慕课网',
//     path: 'https://www.imooc.com/'
//   },
//   {
//     icon: 'https://docschina.org/favicon.ico',
//     name: '印记中文',
//     path: 'https://docschina.org/'
//   },
//   {
//     icon: 'https://www.baidu.com/favicon.ico',
//     name: '百度',
//     path: 'http://buhuibaidu.me/'
//   },
//   {
//     icon: 'https://chinese.freecodecamp.org/favicon-32x32.png?v=6cba562cbd10e31af925a976f3db73f7',
//     name: 'freeCodeCamp',
//     path: 'https://chinese.freecodecamp.org/learn'
//   },
//   {
//     icon: 'https://uimgproxy.suning.cn/uimg1/sop/commodity/vVvk0AsMAZA1ATV98CVCbQ.png',
//     name: 'blender 布的',
//     path: 'http://blenderco.cn/'
//   },
//
//   //社区
//   {
//     icon: 'https://lf3-cdn-tos.bytescm.com/obj/static/xitu_juejin_web//static/favicons/favicon-32x32.png',
//     name: '掘金',
//     path: 'https://juejin.cn/'
//   },
//   {
//     icon: 'https://static2.cnodejs.org/public/images/cnode_icon_32.png',
//     name: 'cnode',
//     path: 'https://cnodejs.org/'
//   },
//   {
//     icon: _cdn + '4dc55388-e42d-4c25-b87f-b60dea208161.ico',
//     name: 'segmentfault',
//     path: 'https://segmentfault.com/'
//   },
//   {
//     icon: 'https://images.frontendjs.com/github.png?imageView2/2/h/30',
//     name: 'github',
//     path: 'http://github.com/'
//   },
//   {
//     icon: 'https://gitee.com/assets/favicon_message.ico?1581387642851',
//     name: '码云',
//     path: 'https://gitee.com/'
//   },
//   {
//     icon: 'https://static.oschina.net/new-osc/img/favicon.ico',
//     name: '开源中国',
//     path: 'https://www.oschina.net/'
//   },
//   {
//     icon: 'https://cdn.sstatic.net/Sites/stackoverflow/Img/favicon.ico?v=ec617d715196',
//     name: 'stackoverflow',
//     path: 'https://stackoverflow.com/'
//   },
//   {
//     icon: 'https://g.csdnimg.cn/static/logo/favicon32.ico',
//     name: 'CSDN',
//     path: 'https://www.csdn.net/'
//   },
//   {
//     icon: 'https://www.jianshu.com/favicon.ico',
//     name: '简书',
//     path: 'https://www.jianshu.com/'
//   },
//   {
//     icon: 'https://static.zhihu.com/heifetz/favicon.ico',
//     name: '知乎',
//     path: 'https://www.zhihu.com/'
//   },
//
//   // 文档
//   {
//     icon: 'https://cn.vuejs.org/images/logo.svg',
//     name: 'vue-Cli',
//     path: 'https://cli.vuejs.org/zh/guide/'
//   },
//   {
//     icon: 'https://cn.vuejs.org/images/logo.svg',
//     name: 'vue-Router',
//     path: 'https://router.vuejs.org/zh/'
//   },
//   {
//     icon: 'https://cn.vuejs.org/images/logo.svg',
//     name: 'vue-Vuex',
//     path: 'https://vuex.vuejs.org/zh/'
//   },
//   {
//     icon: 'https://cn.vuejs.org/images/logo.svg',
//     name: 'vueUse',
//     path: 'https://vueuse.org/'
//   },
//   {
//     icon: 'https://pinia.vuejs.org/logo.png',
//     name: 'Pinia',
//     path: 'https://pinia.vuejs.org/'
//   },
//   {
//     icon: 'https://react.docschina.org/favicon.ico',
//     name: 'react-router',
//     path: 'http://react-guide.github.io/react-router-cn/index.html'
//   },
//   {
//     icon: 'https://react.docschina.org/favicon.ico',
//     name: 'redux',
//     path: 'https://www.redux.org.cn/'
//   },
//   {
//     icon: 'https://www.mobxjs.com/img/favicon.png',
//     name: 'mobx',
//     path: 'https://cn.mobx.js.org/'
//   },
//   {
//     icon: 'https://www.html5plus.org/favicon.ico',
//     name: 'h5Api',
//     path: 'https://www.html5plus.org/doc/h5p.html'
//   },
//   {
//     icon: 'https://cssvalues.com/favicon.ico',
//     name: 'css 属性速查',
//     path: 'https://cssvalues.com/'
//   },
//   {
//     icon: 'https://static.runoob.com/images/favicon.ico',
//     name: 'css 选择器',
//     path: 'https://www.runoob.com/cssref/css-selectors.html'
//   },
//   {
//     icon: _cdn + 'd01c693b-1ebb-4209-b13e-4ed5b13b3366.png',
//     name: 'js 一行代码',
//     path: 'https://1loc.dev/'
//   },
//   {
//     icon: 'https://overapi.com/favicon.ico',
//     name: 'overapi',
//     path: 'https://overapi.com/'
//   },
//
//   // 设计
//   {
//     icon: 'https://www.uisdc.com/favicon.ico',
//     name: '优设网',
//     path: 'https://www.uisdc.com/'
//   },
//   {
//     icon: 'https://www.ui.cn/Public/img/favicon.ico',
//     name: 'UI中国',
//     path: 'https://www.ui.cn/'
//   },
//   {
//     icon: 'https://static.zcool.cn/git_z/z/site/favicon.ico?version=1644228200344',
//     name: '站酷',
//     path: 'https://www.zcool.com.cn/'
//   },
//   {
//     icon: 'https://huaban.com/favicon.ico',
//     name: '花瓣',
//     path: 'https://huaban.com/'
//   },
//   {
//     icon: 'https://icon.meiye.art/favicon.ico',
//     name: '美叶',
//     path: 'https://www.meiye.art/'
//   },
//   {
//     icon: 'https://www.51yuansu.com/favicon.ico',
//     name: '觅元素',
//     path: 'https://www.51yuansu.com/'
//   },
//   {
//     icon: 'http://www.qijishow.com/img/ico.ico',
//     name: '奇迹秀',
//     path: 'http://www.qijishow.com/'
//   },
//   {
//     icon: 'https://super-static-assets.s3.amazonaws.com/266a4be2-7da9-4794-9ed0-4c4c28f12a49/images/a482e888-17a9-4e61-8e6c-d890947d51b9.png',
//     name: 'Spline',
//     path: 'https://cn.spline.design/learning-community'
//   },
//   // svg工具
//   {
//     icon: 'https://c.runoob.com/more/svgeditor/images/favicon.svg',
//     name: 'SVG 编辑器',
//     path: 'https://c.runoob.com/more/svgeditor/'
//   },
//   {
//     icon: 'https://getwaves.io/favicon-32x32.png?v=f13a1a2e88a9720e746d5561039d3f5f',
//     name: 'SVG 波浪线',
//     path: 'https://getwaves.io/'
//   },
//   {
//     icon: 'https://undraw.co/favicon.ico',
//     name: 'unDraw',
//     path: 'https://undraw.co/illustrations'
//   },
//   {
//     icon: 'https://www.shapedivider.app/favicon.ico',
//     name: 'Shape Dividers',
//     path: 'https://www.shapedivider.app/'
//   },
//   {
//     icon: _cdn + '7071a630-33c2-4ca0-bb77-9c7a7868a7a9.png',
//     name: 'IRA Design',
//     path: 'https://iradesign.io/gallery/illustrations'
//   },
//   {
//     icon: 'https://delesign.com/public/images/favicon-ico.png',
//     name: 'Free Designs',
//     path: 'https://delesign.com/free-designs/graphics/'
//   },
//   {
//     icon: 'http://datav.aliyun.com/favicon.ico',
//     name: '地理小工具',
//     path: 'http://datav.aliyun.com/portal/school/atlas/area_selector?spm=a2crr.b71357980.0.0.15cd26c0dUO9va'
//   },
//
//   // 语言
//   {
//     icon: _cdn + '1c67ed7b-b498-467e-bba0-8908310f0f58.png',
//     name: 'node',
//     path: 'http://nodejs.cn/'
//   },
//   {
//     icon: 'https://www.tslang.cn/assets/images/icons/favicon.ico',
//     name: 'Typescript',
//     path: 'https://www.tslang.cn/'
//   },
//   {
//     icon: 'https://www.sass.hk/favicon.ico',
//     name: 'sass',
//     path: 'https://www.sass.hk/'
//   },
//   {
//     icon: 'https://less.bootcss.com/public/ico/favicon.ico',
//     name: 'less',
//     path: 'https://less.bootcss.com/'
//   },
//   {
//     icon: 'https://www.stylus-lang.cn/favicon.ico',
//     name: 'stylus',
//     path: 'https://www.stylus-lang.cn/'
//   },
//   {
//     icon: 'https://git-scm.com/favicon.ico',
//     name: 'git',
//     path: 'https://git-scm.com/'
//   },
//   {
//     icon: 'https://www.dartcn.com/assets/shared/dart/icon/64.png',
//     name: 'dart',
//     path: 'https://www.dartcn.com/guides/language/language-tour#rune'
//   },
//   // 图标
//   {
//     icon: 'https://img.alicdn.com/imgextra/i2/O1CN01ZyAlrn1MwaMhqz36G_!!6000000001499-73-tps-64-64.ico',
//     name: 'iconfont',
//     path: 'https://www.iconfont.cn/'
//   },
//   {
//     icon: _cdn + 'ecd0c0ab-5bea-4d3f-ba7b-443d451407a3.png',
//     name: 'fontawesome',
//     path: 'http://www.fontawesome.com.cn/'
//   },
//   {
//     icon: 'https://icomoon.io/favicon.ico',
//     name: 'icomoon',
//     path: 'https://icomoon.io/'
//   },
//   {
//     icon: 'https://icons.mono.company/favicon.ico',
//     name: 'icomoon',
//     path: 'https://icons.mono.company/'
//   },
//   {
//     icon: 'https://lf1-cdn2-tos.bytegoofy.com/bydesign/iconparksite/logo.svg',
//     name: 'iconPark',
//     path: 'https://iconpark.oceanengine.com/official'
//   },
//   {
//     icon: 'https://unpkg.byted-static.com/byted/arco-config/1.0.9/assets/icon-box.ico',
//     name: 'iconBox',
//     path: 'https://arco.design/iconbox/libs'
//   },
//   {
//     icon: 'https://www.fonts.net.cn/favicon.ico',
//     name: '字体天下',
//     path: 'https://www.fonts.net.cn/'
//   },
//   // 设计工具
//   {
//     icon: 'https://static.figma.com/app/icon/1/favicon.ico',
//     name: 'figma',
//     path: 'https://www.figma.com/'
//   },
//   {
//     icon: 'https://mastergo.com/favicon.ico',
//     name: 'masterGo',
//     path: 'https://mastergo.com/'
//   },
//   {
//     icon: 'https://img.js.design/assets/webImg/favicon.ico',
//     name: '即时设计',
//     path: 'https://js.design/'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-1.png',
//     name: '神器推荐专栏',
//     path: 'https://www.uisdc.com/category/hot-download/tools-download'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-2.png',
//     name: '设计师神器',
//     path: 'https://uiiiuiii.com/inspirations/featured/tool'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2020/04/nav-aliyun-202018.png',
//     name: '阿里云建站神器',
//     path: 'https://ac.aliyun.com/application/webdesign/sumei?source=5176.11533457&userCode=itzns7ko'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-3.png',
//     name: 'PPT神器 iSlide',
//     path: 'https://www.uisdc.com/ppt-artifact-islide'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2020/03/jdr-design-nav.png',
//     name: '京东JDR Design',
//     path: 'https://jdrd.jd.com/'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-6.png',
//     name: 'Adobe全家桶',
//     path: 'https://uiiiuiii.com/software/5103.html'
//   },
//   {
//     icon: 'https://image.uisdc.com/wp-content/uploads/2019/11/nav-1107-epub.png',
//     name: 'H5制作神器',
//     path: 'https://www.epub360.com/'
//   },
//   // 可视化图表库
//   {
//     icon: _cdn + 'e901f8e8-e500-4fcc-a153-ebfe6dfe77d0.png',
//     name: 'echarts',
//     path: 'https://echarts.apache.org/zh/index.html'
//   },
//   {
//     icon: 'https://www.highcharts.com/docs/img/favicon.png',
//     name: 'highcharts',
//     path: 'https://www.highcharts.com/docs/index'
//   },
//   {
//     icon: _cdn + '4bf0d9f5-65b7-43c6-9735-e3523ef9c3ef.png',
//     name: 'antv',
//     path: 'https://antv.vision/zh'
//   },
//   {
//     icon: 'https://d3js.org/favicon.png',
//     name: 'd3',
//     path: 'https://d3js.org/'
//   },
//   {
//     icon: 'https://threejs.org/files/favicon.ico',
//     name: 'threejs',
//     path: 'https://threejs.org/'
//   },
//   {
//     icon: _cdn + '2887e2e7-5ec7-4c79-aaef-8150aa86980c.png',
//     name: 'fabricjs',
//     path: 'http://fabricjs.com/',
//     title: '是一个强大而简单的 Javascript HTML5 画布库'
//   },
//   {
//     icon: 'https://apexcharts.com/wp-content/themes/apexcharts/favicon.ico',
//     name: 'ApexCharts',
//     path: 'https://apexcharts.com/'
//   },
//   {
//     icon: 'https://www.eveningwater.com/static/image/icon.jpg',
//     name: '剑指offer算法题',
//     path: 'https://eveningwater.github.io/to-offer/#/'
//   },
//   {
//     icon: 'https://leetcode.cn/favicon.ico',
//     name: 'leetcode',
//     path: 'https://books.halfrost.com/leetcode/'
//   }
// ]
export const webSearchUrl = [
  // {
  //   path: "https://juejin.cn/",
  //   icon: "https://lf3-cdn-tos.bytescm.com/obj/static/xitu_juejin_web//static/favicons/favicon-32x32.png",
  //   name: "掘金"
  // },
  // {
  //   path: "https://www.zhihu.com/",
  //   icon: "https://static.zhihu.com/heifetz/favicon.ico",
  //   name: "知乎"
  // },
  // {
  //   path: "https://1loc.dev/",
  //   icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEUAAAAYdP8YdP8ZdP8Zdf8Zdf8QcP8Ydf8adf8ZdP8Zdf8gcP8ZdP8Zc/8Zdv8Yc/8Zdf+Y92gGAAAAEHRSTlMAoCDv35AQYM/PMBBvUN9AktiDrAAAALpJREFUOMuFk9sOhCAMRHuRgqK7/f+v3ZDoDmBC563p6YgwJcgyF3XXwtnoLUnqEMvUtuSTkg3jm7+0dSYH+j1xLOYHD/v3defzEgZh1PSc77oHAHhqtTwV0QtwQT0BsKio9JNmQI2yd+IZ8Ey8BpjKGiika0DJ14CHQPiJ8JDhb8YXVXUJWHttxCE34Nx1mBDAyGD33LAgSPrAUN1uGvrekQtDG8U+XhyoBqvXTIYoJowP679ru65h/X81WygHd4mavAAAAABJRU5ErkJggg==",
  //   name: "js一行代码"
  // },
  // {
  //   path: "https://element.eleme.cn/#/zh-CN",
  //   icon: "https://element.eleme.cn/favicon.ico",
  //   name: "element"
  // },
  // {
  //   path: "https://www.bilibili.com/",
  //   icon: "https://www.bilibili.com/favicon.ico?v=1",
  //   name: "哔哩哔哩"
  // },
  // {
  //   path: "https://element-plus.gitee.io/zh-CN/",
  //   icon: "https://element-plus.gitee.io/images/element-plus-logo-small.svg",
  //   name: "element-plus"
  // },
  // {
  //   path: "https://animista.net/",
  //   icon: "https://animista.net/favicon.ico",
  //   name: "css动画"
  // },
  // {
  //   path: "https://cn.vuejs.org/",
  //   icon: "https://cn.vuejs.org/logo.svg",
  //   name: "vue3"
  // },
  // {
  //   path: "https://www.runoob.com/",
  //   icon: "https://static.runoob.com/images/favicon.ico",
  //   name: "菜鸟教程"
  // },
  // {
  //   path: "https://www.antdv.com/docs/vue/introduce-cn/",
  //   icon: "https://qn.antdv.com/favicon.ico",
  //   name: "antdvue"
  // },
  // {
  //   path: "https://echarts.apache.org/zh/index.html",
  //   icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAt1BMVEUAAACqMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2oK0iqMU2qMU2qMU2qMU2qMU2qMU3///+mJ0SlJUOpL0unKUaoLEjjusKxRFzy4OPt09jWnKjTlKGpLUr47e7u19vrztTgs7zKf4/Db4HAZHi8XXK6VmyuOlSsNVD79vbmw8rcrLbXoKrLgpHIe4vCbH+2T2apL0qfFjZpasZcAAAAGnRSTlMAlWUM5O3V6KdN2cqunINzXVUZFO/cz82ySm4ogdMAAADeSURBVBjTTZDXdoMwEEQXEBhcEpfUQRIl4O7YcXr5/+/KypKM7+M9Gs3ZoRNRFgzi+H40iehMlsBxO/buChcEndNSSlV29hFAga9NNa8VDCF39NkdmpxZfhZgejPKgMXLU255lvZpAKg2dzTQLIc0gP5d5Z6D+SChGPro0j4vWKJcn+WPWnAT3QFq7t1mX9UF+jQy7Usn93+rd+gHmgCQOyer7UfTqpAiYey37XptZb27mRKNYexxW63fzKG6vCYmgF2kLE6TpHZhth2p3zkUXvU465mFaSJEMgynZPgHXx8i16SguRAAAAAASUVORK5CYII=",
  //   name: "echarts"
  // },
  // {
  //   path: "https://www.ui.cn/",
  //   icon: "https://www.ui.cn/Public/img/favicon.ico",
  //   name: "UI中国"
  // },
  // {
  //   path: "https://developer.mozilla.org/zh-CN",
  //   icon: "https://developer.mozilla.org/favicon-48x48.cbbd161b.png",
  //   name: "MDN"
  // },
  // {
  //   path: "https://cssgradient.io/",
  //   icon: "https://cssgradient.io/images/favicon-23859487.png",
  //   name: "css渐变色"
  // },
  // {
  //   path: "https://vant-contrib.gitee.io/vant/v3/#/zh-CN",
  //   icon: "https://img.yzcdn.cn/vant/logo.png",
  //   name: "vant"
  // },
  // {
  //   path: "https://hejialianghe.gitee.io/",
  //   icon: "https://hejialianghe.gitee.io/logo.png",
  //   name: "web全栈体系"
  // },
  // {
  //   path: "https://tool.lu/",
  //   icon: "https://tool.lu/favicon.ico",
  //   name: "在线工具"
  // },
  // {
  //   path: "https://ikuuu.ltd/",
  //   icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAB+FBMVEUAAABsaXQAAAASERMODg8iISQHBwgGBgbAvs8gISaPkJze3ud4eIFtbXYcGR0NDQ7X1+JZVl9ycnsDAwPOztpvb3YICAlbWV8PDhB3d4HAwNFSUV5ISEzAvsMtLTJyc4Dk5Os0NDspKCwcGx4DAwQkJCjGxMssLDKjlKfh4eojIyjMy9Z7e4IWFhm/vciWh5lGRkwhISRkZG2CgosAAABubXV6e4UREROYmKaFgolIR01qaXNubXeXl58JCQoCAgLY2OF6doOEhIuVlZ3Fw9Db2+VOTlWNjZe4uMoZGRsJCQp9foPHx9aDg4yXl58wMDZUU1bc3OdHR04ODg+SkpptbXbX1+IqKjE+OkAAAABiYmrOztsAAABnZ24AAACAgIpJSU89PUJYWF4zMjdaWmBSUlYxMTQEAwTi4urOw9rRzdO5tbuZmaNtbXZdW2VKSlBCQkdAQEM+PkI7O0AmJSkdHSH09PTp6e/m5u3o5+jAtc2ioqSKiph9fYZxcXlvbnllZmtXV15FRkwtLjIqKS4KCgvg3+Xe3ePW1t7Rz9bDw9HBwc/MzMzFxcy4rMi/wMTBwcG6ur+foK+jn6aWl6WfnKKVlZ+VlZmRkZWCgZB6eoR8cH9yaHVWXm5bYGxkZGthYWc8SF9QUFg2QFQ8PD42NjwcHB4XFxkq2LhvAAAAYHRSTlMABBMH/qQ1HwX+/fv54YV3dGtoWFRQOy0nJBAP/vz8+/r5+Pj49vX08u/v7erq5uXl393V0s7MycC+uLOvpY6Ni4qCfn18dnNycm5paGdmYlhSUk5NSkhHQC8fHBsQDQt3c833AAABSElEQVQY02LADhh9nbxFxaNChYREJSVD/Jzd40GC1jNr5A2MeGpreXh5uSZNMYsFKRWsysxryq2rq8/NzavPzHQE65fQn93cmJeSkpSU1Fg4RyMCYihfSUF+fn4CCDS1sDJDrApXTCwqSiwuTgSCpf4gEY6wwCD15OS0gpSkorTkNDk3AddoBhFNBWPV5rkNPZWVE1LnJZfoZFQ4MPhwdywoLV3YX9ZXVVa9mLM0tZdbADAGKQ8LrVaWZdlZWZ1Z1SwsLIa2IlJAU6XZ7FXSCydnZ09NS09nZWeE+pTJpLVwRnvb9CXpy204YN7nYE3oyigvz+helGoqDQsSF+2cmmk5OVyz5qdYMUMFxfUqJjYkpSYUFCcr8cWBhZhjgi3VZGVaSjiVdc3thNmYGIF6mSLZxAIEPfn5vYTF2JnYJYCCQFFmZgZiAACAmlhoJvl29QAAAABJRU5ErkJggg==",
  //   name: "ikuuu-vpn"
  // },
  {
    path: "https://www.runoob.com/",
    icon: "https://static.runoob.com/images/favicon.ico",
    name: "菜鸟教程"
  },
  {
    path: "https://developer.mozilla.org/zh-CN",
    icon: "https://developer.mozilla.org/favicon-48x48.cbbd161b.png",
    name: "MDN"
  },
  {
    path: "https://www.w3school.com.cn/",
    icon: "https://www.w3school.com.cn/ui2019/logo-16-red.png",
    name: "w3school"
  },
  {
    path: "https://www.bilibili.com/",
    icon: "https://www.bilibili.com/favicon.ico?v=1",
    name: "哔哩哔哩"
  },
  {
    path: "https://www.imooc.com/",
    icon: "https://www.imooc.com/favicon.ico",
    name: "慕课网"
  },
  {
    path: "https://docschina.org/",
    icon: "https://docschina.org/favicon.ico",
    name: "印记中文"
  },
  {
    path: "https://www.baidu.com/",
    icon: "https://www.baidu.com/favicon.ico",
    name: "百度"
  },
  {
    path: "https://chinese.freecodecamp.org/learn",
    icon: "https://chinese.freecodecamp.org/favicon-32x32.png?v=6cba562cbd10e31af925a976f3db73f7",
    name: "freeCodeCamp"
  },
  {
    path: "http://blenderco.cn/",
    icon: "https://uimgproxy.suning.cn/uimg1/sop/commodity/vVvk0AsMAZA1ATV98CVCbQ.png",
    name: "blender布的"
  },
  {
    path: "https://juejin.cn/",
    icon: "https://lf3-cdn-tos.bytescm.com/obj/static/xitu_juejin_web//static/favicons/favicon-32x32.png",
    name: "掘金"
  },
  {
    path: "https://cnodejs.org/",
    icon: "https://static2.cnodejs.org/public/images/cnode_icon_32.png",
    name: "cnode"
  },
  {
    path: "https://segmentfault.com/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAjVBMVEUAmmEAml4AmmH///8KnWdwxqYFm2RqxKIPn2r1+/iR07uAzLB8y61Jt44oqnrj9O55yqx1yKkgp3T5/fvY7+fW7uWi2sVZvZhUu5UvrX4epnMWo2/s9/Po9vHA5tey4M+v382b18Bdv5tAs4g9soc2r4La8OjQ7OHM6t/J6d275NSp3MmJ0LVnwqAip3bktNAhAAAAAnRSTlPjSQzAlCMAAAD+SURBVDjLhdNpc4MgEAZg6yIqAVJvjbmP3u3//3l1UYYkCL5fHNlnht0dDV6C0JOhjHVPsO6PC5A88YHmDQDWJyeIQKVygT4dAXcBgdX6Em0UcN3Qe6ZYIfCN+ToH+jNnWYO3JvIHgZTyDlA2NX7owhh0EgNOoNPMgiu+7r4PayhxBh2igO4qRd+KoYecY3WT/5keVgqYPVhTtHhSnokT0BIwKcsNoA97kBWoFL/4ltkgJNluJEID8rxq2n7g+RcOZYGJfOJnNAvoscNHPQFugQgKdrnxAgCOuBYLbEEn7WbBtZrqWxHaQEWw/fu+jsfTWzyEGuDPMlgQweLv/w8WoBFSvYkFYAAAAABJRU5ErkJggg==",
    name: "segmentfault"
  },
  {
    path: "http://github.com/",
    icon: "https://images.frontendjs.com/github.png?imageView2/2/h/30",
    name: "github"
  },
  {
    path: "https://gitee.com/",
    icon: "https://gitee.com/assets/favicon_message.ico?1581387642851",
    name: "码云"
  },
  {
    path: "https://www.oschina.net/",
    icon: "https://static.oschina.net/new-osc/img/favicon.ico",
    name: "开源中国"
  },
  {
    path: "https://stackoverflow.com/",
    icon: "https://cdn.sstatic.net/Sites/stackoverflow/Img/favicon.ico?v=ec617d715196",
    name: "stackoverflow"
  },
  {
    path: "https://www.jianshu.com/",
    icon: "https://www.jianshu.com/favicon.ico",
    name: "简书"
  },
  {
    path: "https://www.zhihu.com/",
    icon: "https://static.zhihu.com/heifetz/favicon.ico",
    name: "知乎"
  },
  {
    path: "https://leetcode.cn/",
    icon: "https://leetcode.cn/favicon.ico",
    name: "力扣"
  },
  {
    path: "https://cli.vuejs.org/zh/guide/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vue-Cli"
  },
  {
    path: "https://router.vuejs.org/zh/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vue-Router"
  },
  {
    path: "https://vuex.vuejs.org/zh/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vue-Vuex"
  },
  {
    path: "https://vueuse.org/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vueUse"
  },
  {
    path: "https://kazupon.github.io/vue-i18n/zh/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vue-i18n"
  },
  {
    path: "https://pinia.vuejs.org/zh/",
    icon: "https://pinia.vuejs.org/logo.png",
    name: "Pinia"
  },
  {
    path: "http://react-guide.github.io/react-router-cn/index.html",
    icon: "https://react.docschina.org/favicon.ico",
    name: "react-router"
  },
  {
    path: "https://www.redux.org.cn/",
    icon: "https://react.docschina.org/favicon.ico",
    name: "redux"
  },
  {
    path: "https://cn.mobx.js.org/",
    icon: "https://www.mobxjs.com/img/favicon.png",
    name: "mobx"
  },
  {
    path: "https://www.html5plus.org/doc/h5p.html",
    icon: "https://www.html5plus.org/favicon.ico",
    name: "h5Api"
  },
  {
    path: "https://cssvalues.com/",
    icon: "https://cssvalues.com/favicon.ico",
    name: "css属性速查"
  },
  {
    path: "https://www.runoob.com/cssref/css-selectors.html",
    icon: "https://static.runoob.com/images/favicon.ico",
    name: "css选择器"
  },
  {
    path: "https://1loc.dev/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEUAAAAYdP8YdP8ZdP8Zdf8Zdf8QcP8Ydf8adf8ZdP8Zdf8gcP8ZdP8Zc/8Zdv8Yc/8Zdf+Y92gGAAAAEHRSTlMAoCDv35AQYM/PMBBvUN9AktiDrAAAALpJREFUOMuFk9sOhCAMRHuRgqK7/f+v3ZDoDmBC563p6YgwJcgyF3XXwtnoLUnqEMvUtuSTkg3jm7+0dSYH+j1xLOYHD/v3defzEgZh1PSc77oHAHhqtTwV0QtwQT0BsKio9JNmQI2yd+IZ8Ey8BpjKGiika0DJ14CHQPiJ8JDhb8YXVXUJWHttxCE34Nx1mBDAyGD33LAgSPrAUN1uGvrekQtDG8U+XhyoBqvXTIYoJowP679ru65h/X81WygHd4mavAAAAABJRU5ErkJggg==",
    name: "js一行代码"
  },
  {
    path: "https://overapi.com/",
    icon: "https://overapi.com/favicon.ico",
    name: "overapi"
  },
  {
    path: "https://lhammer.cn/You-need-to-know-css/#/zh-cn/",
    icon: "https://lhammer.cn/You-need-to-know-css/static/favicon.ico",
    name: "CSS诀窍"
  },
  {
    path: "https://hejialianghe.gitee.io/",
    icon: "https://hejialianghe.gitee.io/logo.png",
    name: "web全栈体系"
  },
  {
    path: "https://tc39.es/",
    icon: "https://tc39.es/favicon.ico",
    name: "TC39"
  },
  {
    path: "https://www.bootcdn.cn/",
    icon: "https://www.bootcdn.cn/assets/ico/favicon.ico?1644166305141",
    name: "BootCDN"
  },
  {
    path: "https://caniuse.com/flexbox",
    icon: "https://caniuse.com/img/favicon-128.png",
    name: "web兼容性"
  },
  {
    path: "http://zhongguose.com/",
    icon: "data:image/x-icon;base64,AAABAAEAEBAAAAAAAABoBAAAFgAAACgAAAAQAAAAIAAAAAEAIAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAACEhIQAz8/PAO3t7QCXl5cAj4+PAI+PjwDm5ua6/f39/9bW1h28vLx2wsLCAKampgCmpqYAenp6AMfHxwDDw8MAhISEAM/PzwDt7e0AtLS0AJKSkgCJiYkA//////////////////////////+vr68Ar6+vAH9/fwDHx8cAw8PDAISEhADPz88A7e3tAO7u7gDb29sA3t7eJv//////////////////////////g4ODAHp6egCAgIAAx8fHAMPDwwCEhIQA0NDQAPX19WLg4OBIwMDAAM7Ozh/+/f7///////////////7//////9jY2ADZ2NkAdnZ2AMfHxwDDw8MAfn5+AP///////////////////v+QjpQN5+Lx/+vk9v/w6vj/8uv5/8fGxprr5vDS////////////////wcHBDmhoaAD///////////////////7/7ef3/8+38v/PvfL/1cTw/9rN7//YxfH/8On2//////////////////39/f/19fXk////////////////+fX8/+LW8P+5ifX/o1Dw/4Ye6/+WP+7/0b/x/+3l9v////////////////+3t7dA//////////////////////Dq9//XxvH/hRHx/zYEvv8uALn/hhTy/8698P/q4fX//////////////////////3R0dAD///////////35/v/l2/T/yrP1/14c0f8pB6z/Iwam/0wNw//Ls/H/5tzz//38/f///////////6ioqEt3d3cA0dHRALW1tQD28fY10b7x/9S98P+tc/T/jhvu/6BJ8f+GHPT/y7Lw/8it9P/HxsZ3+vr6xra2tiWNjY0AgICAAJqamgC6uroA/v7+//36/v/v5/b/283u/8u09f/Hrvb/1sbu/+jf8f/28Pv//v3+/29vbwCUlJQAjo6OAKGhoQChoaEA/v7+/v/////+/v7////+/+zn9f/h1PP/4tP0/+rj9f/8+/7////////////////+enp6AHp6egD7+/sA+/v7AP///////////////////////v7/8u37//Pw+////v3//////////////////v7+/8LCwgDCwsIAmpqaAJqamgD6+vrz/v7+/////////////////+jo6M3q6urC/////////////////v7+//////+kpKQApKSkAJiYmACYmJgAysrKAMTExJv///////////////+BgYEAiIiIAP///////////////9/f36qjo6MAb29vAG9vbwCYmJgAmJiYAM7OzgB4eHgAAAAAAAAAAAAAAAAAQEBAAE9PTwBwcHAAra2tGsrKygd2dnYAq6urAG9vbwBvb28A/P8AAPwfAAD8HwAA/B8AAIQBAACAAAAAAAEAAAAAAACAAQAA8AsAAOAHAADAAwAAwAMAAMADAADhhwAA//8AAA==",
    name: "中国色"
  },
  {
    path: "https://sunpma.com/other/rgb/",
    icon: "https://sunpma.com/other/rgb/favicon.ico",
    name: "颜色转换"
  },
  {
    path: "https://csscoco.com/inspiration/#/",
    icon: "https://csscoco.com/inspiration/logo2.png",
    name: "CSS-Inspiration"
  },
  {
    path: "https://es6.ruanyifeng.com/#docs/style",
    icon: "https://es6.ruanyifeng.com/favicon.ico",
    name: "ES6"
  },
  {
    path: "https://any86.github.io/any-rule/",
    icon: "https://any86.github.io/any-rule/favicon.ico",
    name: "正则大全"
  },
  {
    path: "http://www.axios-js.com/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAOVBMVEUAAAAOg80Og80Og80Og80Og80Og80Og80Og80Og80Ng83+//96u+Pw9/w4mdZdrN5OpNvi8Pkci9DPUFIgAAAACnRSTlMAgg5p79JArCOQIVC21wAAAJtJREFUOMvNk0EOhSAMRKVFQASL3v+w39hPGgUhrvStaGaSaWg7nAFEGBoY6701t7KbtN/Rk6vKgKP/MyooZcNytiBcw7U/oa0pwwVpRcKFIge53ChlJdHGD3UYFBdhXhd+LescqoaYDfFFQ6CDcGcQnhokItFOCvGz/0C1YfXGjf2FYZyqrZxynaVtr72B5uEgPD49aaUI753/D4cwGeO3K3hWAAAAAElFTkSuQmCC",
    name: "axios"
  },
  {
    path: "https://www.lodashjs.com/",
    icon: "https://www.lodashjs.com/img/favicon.ico",
    name: "lodash"
  },
  {
    path: "https://jquery.cuishifeng.cn/",
    icon: "https://jquery.cuishifeng.cn/images/favicon.ico",
    name: "jquery"
  },
  {
    path: "https://zeptojs.bootcss.com/#",
    icon: "https://zeptojs.bootcss.com/favicon.png",
    name: "zeptojs"
  },
  {
    path: "https://www.jq22.com/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAaVBMVEUAAAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAC/AAD2oEmJAAAAInRSTlMAmzUP+t+lCfbJaCDFrZKFVD8lBfG1iS8W9efg2Ly7fXNjGDhiAQAAAJVJREFUGNNVzzcSwzAMBdElxSAqWcE5+9//kDZFNX4VBsUOQNZOzdB5Y/lz6hQqivl4CCNLr1Q2o9PPI8abwlqotRpoJEvLTkUdz5Jhotdm5iLPB6PNmVqOgVFFYpYcKXIoCZtrHrNjOe6lzoLPUasGlna96CVZCOpPQDVFqn3IU5Ku6e5UP99NRd4EbUyksMY7t73/BepCDu3jUWD3AAAAAElFTkSuQmCC",
    name: "jq22"
  },
  {
    path: "http://momentjs.cn/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAOVBMVEUAAABPqJhgsqZhsaZgsqdfsKVgqp9gsaVgsqdhsaZgsadgsadgsqdhsaZgsadhsKZerqFfsaVhsqeYee2DAAAAEnRSTlMACvLjlkgYk/Tl2ce/sYJkJi0/lvYMAAAA5klEQVQ4y4WT6xKFIAiEl9LsfvH9H/YEp4FIZ/x+NEUri4gwKIZ17rp5DZFQMoQuK10Y4KF9zI5xJ7d8yQXLK8nVS6hP8SQ6Y3o+L10vgemAckyieHKQ5N8ez5wltonLP7bzewJUICSO7mIw8np8BeAcI5sE9qdSQFxHAIj7c6AU4OCOESKXi5oAvLkoDqkuSOKxsqwqkOQr5vt51gXn/WsG10h1AXGVL0EWvgK1MIG30CINX6Ru0/Db1EYZvlHaasO3Wg/L8Idlx23447aBMWxg/MgpNnKtoW2OffvitK9e+/I2r/8P/+wZUefpsikAAAAASUVORK5CYII=",
    name: "Moment"
  },
  {
    path: "https://v5.bootcss.com/",
    icon: "https://icons.bootcss.com/assets/img/favicons/favicon.ico",
    name: "bootstrap"
  },
  {
    path: "https://3.swiper.com.cn/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAtFBMVEUAAAAZTeAYTOAZTeAZTeAZTeAZTeAZTeAZTeAZTeAZTeAZTeAZTeD///8aT+EUSeAIP94dUeEANNwANtwAMdsALtsMQ98DO90AOd3L1/i3x/aAnO7G0/izxPWuwPSds/JYfelFb+YRRt8AKNr2+P7o7fzW4PrQ2vmOqPB5mO1eg+pRd+dLdOc7aOU0YuQvXuMnWOIAINjt8fzd5Pq7y/ahtvKUrPGIpO92lu13lOxghOlQeOcxA9krAAAADHRSTlMA8t2TWwjq2LKsYFFcuDlSAAABBklEQVQY02WRV47DMAxEbcepJtXl3mKn92wv97/XSlok+Qg/CPCBA45GnqvhbDr2/fF0NvTuFQZAaJLKtAvCGxsAgIh326KljAweTB3WeZPj64mBo5Fhco9fQugjbjVAZG6MALp4kV8pgWv5YeBo6IUAkBzxsycA7HcemzH0JhaecMl7SnjCuBknXmA6TStc7lPdEbAVeL7phMU1YrZj0lHfQeBMthlieXbUt3K7S1V6WOHizJx84hYZJ1QnOTbKHnKW4rm1QvQF36S15MyL940mQCTHjbLm3TNFte61UKJ8oUYR/QciClwV30WWXVIOt5gobeuqbn4Syx4hS6WUSCi5h/z8HX/uFRgwHT/1cgAAAABJRU5ErkJggg==",
    name: "swiper"
  },
  {
    path: "http://mockjs.com/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAWlBMVEUAAAAzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWYzmWa92eXlAAAAHXRSTlMA9fHp36OOcfnu4sREMCMYrZ55SykO0ru5ZmBUNL2BOjEAAAB5SURBVBjTtc/LDoUgDEXRw0sEFNSr993//02biLHOdQ1KskMHxU16Z5VJfhGpONooj92i4qRrTqXGBw2/QJXptziRFDr8Z/7pSWo10YioRDIDNwqwJHzR8HzByd0ZHT+e52EE8KY2gxd2tnDMtgGO+szn26M26YOrrB/QEOQopbCpAAAAAElFTkSuQmCC",
    name: "mockjs"
  },
  {
    path: "https://dayjs.fenxianglu.cn/",
    icon: "https://dayjs.fenxianglu.cn/assets/favicon.png",
    name: "dayjs"
  },
  {
    path: "https://bennettfeely.com/ztext/",
    icon: "https://bennettfeely.com/ztext/img/icon/favicon-32x32.png",
    name: "ztext"
  },
  {
    path: "https://mathjs.org/",
    icon: "https://mathjs.org/favicon.ico",
    name: "mathjs"
  },
  {
    path: "https://pqina.nl/flip/#nutshell",
    icon: "https://pqina.nl/favicon.ico",
    name: "flip"
  },
  {
    path: "https://carlosroso.com/notyf/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAvVBMVEUAAABnM5plMphoNZtjLphpNp1oNJ1oM53d0uhmMplgK5VpNp1nM5tmNJphLZbg1uqrkMahgr96TqZoNJxnNZtmM5n///9SGI1mM5lnNJpVHI1dJ5NwQaBhLJfBrNWlh8NkLpqzmsxzRKFeJpZbI5KhgsBkMJfFsdhpN5tgK5VOE4k5AHvJt9tQFYr39Prl2+28ptGtksiff8BXHpBIC4Xs5fLTxOK4oNCvlMmZdrttPZ5eKZRFCITOvd6AV6sYiQobAAAAFnRSTlMAYQiS2N2qpf7v7+jYXO/+/v7+6JJcOnQN3QAAARJJREFUGNNFkdeWgyAURdGYXqYfAkpQxBrTe5n5/88ayNLkPPCwOWtx2Zc84jpdbzDwuo5LnnHaMdVpqmncdhrWCmkQ9Q6HXhTQsFUzwSWwyrIVILn4tmwc8hkqxU1UhUqEP+aNIZU4lzxlLOVlhE/95RInDvBbFmxqwopCiSlzSIeukS9ygywV2hwdMtpJnKjPLEp939c3NiJ9tt8iW1iaMqUCFUz7ZHK97BPMTJft/gAsj2JAPL3K5oaa7k1tNhJv2jMPbRDNt7abC34vENGOHWmJk+laWpRHBLFjh/9A3V2UZ0g6dOtvGppgnVfmJhzXQpIlsnuCh5DWS91777KX60ZdI9m/sl0j+bWOSX/UrOMfp4skeMXaozYAAAAASUVORK5CYII=",
    name: "notyf"
  },
  {
    path: "https://ejs.bootcss.com/",
    icon: "https://ejs.bootcss.com/assets/images/ejs-logo.png",
    name: "ejs"
  },
  {
    path: "https://ahooks.gitee.io/zh-CN",
    icon: "https://ahooks.gitee.io/simple-logo.svg",
    name: "ahooks"
  },
  {
    path: "https://printjs.crabbly.com/",
    icon: "https://printjs.crabbly.com/assets/favicon.ico",
    name: "printjs"
  },
  {
    path: "https://v2.formilyjs.org/zh-CN",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAA21BMVEUAAAB3d/j///+Dg/j7+/99ffj8/P////+AgPf////////////////+/v90dPf///99ffh1dff///////////9nZ/r///96evhwcPf///9/f/j///////9+fvj///94ePuAgPd0dPd1dfb///+Fhfb///+Bgfd1dff///////91dfd8fPh0dPh3d/lgYPqDg/ZZWfmRkfhxcfeSkvh9ffhqavv///94ePuEhPb///94ePn////19f7////39/+EhPj///9/f/eAgPeEhPd1dfaMjPaHh/ZqavaRkfVhsfKTAAAAQHRSTlMACek1EhIL+u18dmE1CfTx68jHmJN2bGlnZltWMCsqGA79/fX17ubS0cK/nZyQj4B/enZkYV1bUS0iIh0PDgEBzH/cEwAAAOBJREFUGNNV0NV6wzAMhuE/TE2ZVxgzM0+yk3b3f0WzY6fb3hPp+Q50IGizBNaNh9oL9cxyWc43cYvoUc+nku/r5rtEUQ44+4LbS10ai4lLSgqMJTPHo7mHlIxjoMOV72ugZ2L0iR2hW3kOJXOr6juFSiK+QyVMuy0d10Wzcxvg17sPBNv4z1EhDP+G8WmzWAch0d7J8MO06SErRZCT5ma6XaxY2/0CGX1gsBJCNXEEdG1N4D2P2qrKATDRpTWcNaAsYxbSAfKI6OAN1pTlg31hhtqrPDNLnxab6F3BSqp7P/NDKGVFVFkfAAAAAElFTkSuQmCC",
    name: "formilyjs"
  },
  {
    path: "https://www.tailwindcss.cn/",
    icon: "https://www.tailwindcss.cn/favicon-32x32.png",
    name: "tailwindcss"
  },
  {
    path: "https://eslint.org/",
    icon: "https://eslint.org/icon.svg",
    name: "ESlint"
  },
  {
    path: "http://mozilla.github.io/pdf.js/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAB41BMVEUAAADb3N3Mzc3f4eLa29vX19ioqqjj5ebf4uPa2tvf3+DR0tL+PQL/CAD+QAbU09P+CQDPz8/Nzs/MzMzLycrNx8a7ubnU0dLHyMvk5+jn6ura8Pbh4+Pf4uTf393k/P/kz8re39/WusHN6/jU1dXR0dHOqK3R0dG/8P//Pwb/CwD/NwD/AADYzsrKysrBj4S2qajPzs/NzM3Lycmtq6vKysq6+P/////l5ufp7O3j+//o6+zw8vPj5Obz9fXh4+T+hnLw///8/P3y8/Tu7/Ht7vDl6On/6eP/5t3/4df/1s//2Mv/saP+n47/pYr/ooX/blb+Sy//XCj/JgH7///e+f76+/v4+fr39/j/9O7r7e7/7+vn6er/3dX/29D+2dDnysb/w7nnvrj/wrbnuLD/uKv/rpX/rZL+lIP/inf/cV3/gVr/fFL9XUD/ZjX/SQ7/MwD/AAD3///r/f/q/P3u8fHi4+Ty5OHp5OHf4eH/49zq0c7/0snnzcnkzsjtz8X/0cLnx8LjxcHyxr7mv7rsvbXjt7D/v6z8v6z/vaj8rZz/oZb9q5L/o4//mYb/nX//nH3/j3r/fGn9fWb9UzT8YDH8SCn9SCf/QyH/UR7/Sxj+LA3/Qwz/Lwn/OAD9FgD/AwAWJNbVAAAAN3RSTlMAyUn+4KIG+ezqx4B1dG5tbVFLOS4mIBYP+vPw7OPW0c61s7KumpORamVlZGReXUpHRkZANTQazyzIeQAAAWFJREFUGNNN0GVvAkEQgOHB6u7u7g7b4yilOBQo1Iu7S93d3d1+avcgTXg/PslmZwagm0ljZWbT8xIA8ujZmSwaswcgh8MVIXK5OIWVkrjGECOumA6QSyKOmCDQLEGIuDzeFI+RC9CfiDhUpFAgYOOmk/IB8ssQSeHQCDsar6IIIKFyYZBqYjiGw7WAsero0Gy3mo8PJHMzMoNxpwZj2/P5w9go/8U9+vqu9flvT59aAdI96w6L3Kbc5OvCYbVs98yfBpDlVjrYXo1JrtF9fqskxhttBkaP4FrJ18gN97q3kPPEJ3VhTL+6iHhnLLa7yG9QIXOqpGr8vGUs8IM/egx8fGld6qDqMtQMAA0bq1t2697+ypxEoZCatjsB17QYP/x46QCF7YQ+uiY3ipPJhRR2zaM4nIph37gQzSI9Psjk9AhaKi8Cqo766hKSQCIhh5FU19gL/xXkpNKYtFR6AVD9AZlrXqqK270iAAAAAElFTkSuQmCC",
    name: "PDFjs"
  },
  {
    path: "https://windicss.org/",
    icon: "https://windicss.org/assets/logo.svg",
    name: "windicss"
  },
  {
    path: "https://bulma.zcopy.site/",
    icon: "https://bulma.zcopy.site/favicons/favicon-32x32.png?v=201701041855",
    name: "bulma"
  },
  {
    path: "https://element.eleme.cn/#/zh-CN",
    icon: "https://element.eleme.cn/favicon.ico",
    name: "element"
  },
  {
    path: "https://element-plus.gitee.io/zh-CN/",
    icon: "https://element-plus.gitee.io/images/element-plus-logo-small.svg",
    name: "element-plus"
  },
  {
    path: "https://www.antdv.com/docs/vue/introduce-cn/",
    icon: "https://qn.antdv.com/favicon.ico",
    name: "antdvue"
  },
  {
    path: "https://ant.design/docs/react/introduce-cn",
    icon: "https://gw.alipayobjects.com/zos/rmsportal/rlpTLlbMzTNYuZGGCVYM.png",
    name: "antdreact"
  },
  {
    path: "https://mobile.ant.design/zh",
    icon: "https://gw.alipayobjects.com/zos/bmw-prod/69a27fcc-ce52-4f27-83f1-c44541e9b65d.svg",
    name: "antd-mobile"
  },
  {
    path: "http://ng.ant.design/docs/introduce/zh",
    icon: "https://img.alicdn.com/tfs/TB1Jr2ASQvoK1RjSZFDXXXY3pXa-200-200.png",
    name: "ng-zorro"
  },
  {
    path: "https://ng.mobile.ant.design/#/docs/introduce/zh",
    icon: "https://gw.alipayobjects.com/zos/rmsportal/vCgyalwcqlpHLsJOIMwA.ico",
    name: "ng-zorro-mobile"
  },
  {
    path: "https://vant-contrib.gitee.io/vant/v3/#/zh-CN",
    icon: "https://img.yzcdn.cn/vant/logo.png",
    name: "vant"
  },
  {
    path: "https://www.uviewui.com/",
    icon: "https://www.uviewui.com/favicon.ico",
    name: "uview"
  },
  {
    path: "https://www.naiveui.com/zh-CN/light",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAAulBMVEUAAABfvCFfvCFfvCFiuiFZsR5gvCFhvSZIlxRYrh1jwSNZrR1evCBcuh9LmBZHmhRWuB0Y7ghgvSBluCNssyVdviFfvCNhvShivSNfvCFivyhjwiRduyBjxiZhuCFhvCJElRJJjRJZwCBgux5GmxReuyBDzRdguyBMnBZbvSNGlxRXsh1EzhdsvidfvCFMlxdiwiNevB5ntiNNlRdevSFYsR5KkxZJkhZLkRZKjxZhvyJYrh1dviBJmRbyYbrtAAAALnRSTlMA8O/7/vn3oHD7+PeuiGRKOTkb7unlxrWxsaObl46Ffnt4dGtramFbVEE8LSYZ/KDcqQAAAK1JREFUGNNV0FcSwjAMBNCFQAglhdB77yCb9AL3vxYeJ2Mm+7dv9CEJlRxGl0rfN0gD8HyV/UREOtD3Oo8CJm+iuuge794ltCQMvA8PE/sPjYB4M/laCmokIPJ9A5grCCPG2A4rUhOpgOhoZiVkSyMRknZ5CbTA1mcszgXUJEwBw2dxW0EPgFWApgDXNFcwk5vbISftJmEMGaejO4AuYIMirguIWwKz8qXzcF3pP+RHHB0EPeUeAAAAAElFTkSuQmCC",
    name: "NaiveUI"
  },
  {
    path: "https://varlet.gitee.io/varlet-ui/#/zh-CN/home",
    icon: "https://varlet.gitee.io/varlet-ui/varlet_icon.png",
    name: "varlet-ui"
  },
  {
    path: "https://arco.design/react/docs/start",
    icon: "https://unpkg.byted-static.com/latest/byted/arco-config/assets/favicon.ico",
    name: "arco-design"
  },
  {
    path: "https://mui.com/zh/getting-started/installation/",
    icon: "https://mui.com/static/favicon.ico",
    name: "MUI"
  },
  {
    path: "https://tdesign.tencent.com/",
    icon: "https://cdc.cdn-go.cn/tdc/latest/images/tdesign.svg",
    name: "TDesign"
  },
  {
    path: "https://nutui.jd.com/jdt/#/",
    icon: "https://img12.360buyimg.com/imagetools/jfs/t1/101920/21/28459/834912/62870b04E0e1f20ec/17425bea5318a22c.png",
    name: "NutUI-JDT"
  },
  {
    path: "https://vuetifyjs.com/zh-Hans/",
    icon: "https://vuetifyjs.com/favicon.ico",
    name: "Vuetify"
  },
  {
    path: "https://semi.design/zh-CN/",
    icon: "https://lf9-static.semi.design/obj/semi-tos/images/favicon.ico",
    name: "SemiDesign"
  },
  {
    path: "http://iview.talkingdata.com/#/components/guide/install",
    icon: "https://file.iviewui.com/file/favicon.ico",
    name: "iView"
  },
  {
    path: "https://quark-design.hellobike.com/#/",
    icon: "https://quark-design.hellobike.com/assets/favicon.7b83b3c2.ico",
    name: "Quark"
  },
  {
    path: "https://v2.cn.vuejs.org/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vue2"
  },
  {
    path: "https://cn.vuejs.org/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vue3"
  },
  {
    path: "https://react.docschina.org/",
    icon: "https://react.docschina.org/favicon.ico",
    name: "react"
  },
  {
    path: "https://www.react-native.cn/",
    icon: "https://react.docschina.org/favicon.ico",
    name: "react-native"
  },
  {
    path: "https://umijs.org/",
    icon: "https://img.alicdn.com/tfs/TB1YHEpwUT1gK0jSZFhXXaAtVXa-28-27.svg",
    name: "UmiJs"
  },
  {
    path: "https://www.nextjs.cn/",
    icon: "https://www.nextjs.cn/static/favicon/favicon.ico",
    name: "nextJs"
  },
  {
    path: "https://angular.cn/",
    icon: "https://angular.cn/assets/images/favicons/favicon.ico",
    name: "angular"
  },
  {
    path: "https://www.sveltejs.cn/",
    icon: "https://www.sveltejs.cn/favicon.png",
    name: "svelte"
  },
  {
    path: "https://www.solidjs.cn/",
    icon: "https://www.solidjs.cn/img/favicons/favicon-32x32.png",
    name: "solid"
  },
  {
    path: "https://uniapp.dcloud.io/",
    icon: "https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a90b5f95-90ba-4d30-a6a7-cd4d057327db/d23e842c-58fc-4574-998d-17fdc7811cc3.png?v=1556263038788",
    name: "uniapp"
  },
  {
    path: "https://www.expressjs.com.cn/",
    icon: "https://www.expressjs.com.cn/images/favicon.png",
    name: "express"
  },
  {
    path: "https://eggjs.org/zh-cn/",
    icon: "https://www.eggjs.org/favicon.png",
    name: "egg"
  },
  {
    path: "https://www.koajs.com.cn/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAZlBMVEUAAADm5ubp6enm5ubm5ubn5+fn5+fm5ubm5ubm5ubo6Ojn5+fm5ubm5ubm5ubm5ubn5+fm5ubn5+fm5ubn5+fm5ubm5ubo6Ojp6enm5ubl5eXm5ubm5ubn5+fm5ubn5+fn5+fm5uYpO5XIAAAAIXRSTlMA+QvNEt0p8NhiQcN7eNP06+C9t7KwZlFKHh0U8caBfSA1Ep9WAAAA0klEQVQoz4WS3bKCMBCDu4VSwBZFwX/P8Xv/l3SpOOgomosA2ZmQTWt+4VR1K1h1Vf4i23BmhA920g8ZZMh+Lwxvx4d+FVy94d+YPza1Qw6jTwZuJ6LuuciuhezuFnA6oimsLRpUbokpz5naRmGERFvjh2wVa+UFi3K5LPWhH2sq5Y6tsqMwigKnvKVXLpPkSZvl+DQulfmIuYF8s+o//3yKexniXp7inrwuGKYFw2NBraRt3it5L9GlEudrTzjOHJS6Rc8IH615Rn6/DL1ehh+4AebIG/AZyLvIAAAAAElFTkSuQmCC",
    name: "koa"
  },
  {
    path: "http://www.electronjs.org/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAABU1BMVEUAAABHg444d4FFgo0+gIlGhY9BfIZEgoxBfodKg49GhJFCg4xHhI5KhpM/gIk+e4VFgo5GhI1JhJA/folFgIpHhpJFhZFGgIpMipdIhY46foRGho9JhJNMiZZPiJNJhY9DfIdFhZBNjZhGg41IgI5Bg5BLhpE3f4Q4d4FDf4tBeoQ1eYFGgY1Hh5BGgpBOi5dJhI8ydn4/cX5MkZtPiZM5goxHg45RhJA2ZGxKjZk6a3NIhZA6a3JTiJZEgJBAeoVMj5tEjpVGg45Df4hEgIk+gotDf4Y9foVLi5RKe4o4gos9c35Egos9goxMhpNPk59Hi5Y6folSmaRNg4xDfo01dn5FgY5Jh5Q2eIBGf5BDh5A9e4RCdYJBfIooeok3anFWnao0YWpMiZghPkM6en4UVVo9g4dCf444dXkvYFxRjZlAgY8aZmoYLTFEhY1KiJRHg48cJcIYAAAAb3RSTlMApAWbL7S0ramoiYdeST4V7dbQzMG+trSzsKKYloSCe3ZybmphVlJEOSogGQsE+Ofm3t7d2MnIxsbFxMLBwLu7urq6uLaxsa+uq6OioZ6ZlZCPjo2Hh4F1aGdlY2JZV1JRT0NAPDw5NColJBwVExHoZehoAAAA/klEQVQY02XN1XLDMBRF0e3YYeakYcYyMzMzM2P6/0+1nHo6TdaMpHPugy66D0miTWLIN0mriln22f5NOpvHe/U3spmNpgtQ0sbVdDSJYJheKirK+2V0TnaZ84WQyQBkJ4C6xZ9Ry0YKhrNA7ABOPfsIOQvYt9SQn8LuKaNxLsPiufgzJXm/gMcHUCKUBwyoXN8IjQa8Wmu9Dq1ZrdvicajtRXorIBTXWOlzo7kL02QfhN3uEy2fWeDmUw0d4SRU/DO3ItvIBNfVEDCFcLs4DswnSofO566FUaAUr1HveYLrHVmSjUd79/zK9Yt709lcqIuMVasjs7SIBcfjev4B4XwduCpD/JcAAAAASUVORK5CYII=",
    name: "electron"
  },
  {
    path: "https://tauri.app/zh/",
    icon: "https://tauri.app/zh/meta/favicon-32x32.png",
    name: "tauri"
  },
  {
    path: "https://developers.weixin.qq.com/miniprogram/dev/framework/",
    icon: "https://res.wx.qq.com/a/wx_fed/assets/res/NTI4MWU5.ico",
    name: "微信小程序"
  },
  {
    path: "https://taro-docs.jd.com/docs",
    icon: "https://cdn.docschina.org/home/logo/taro.png",
    name: "Taro"
  },
  {
    path: "https://www.nuxtjs.cn/",
    icon: "https://www.nuxtjs.cn/favicon.ico",
    name: "nuxtJs"
  },
  {
    path: "https://vuepress.vuejs.org/zh/",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "vuePress"
  },
  {
    path: "https://flutterchina.club/",
    icon: "https://doc.flutterchina.club/images/favicon.png",
    name: "flutter"
  },
  {
    path: "https://zeroing.jd.com/micro-app/",
    icon: "https://zeroing.jd.com/micro-app/favicon.ico",
    name: "MicroApp"
  },
  {
    path: "https://qiankun.umijs.org/zh",
    icon: "https://gw.alipayobjects.com/mdn/rms_655822/afts/img/A*4sIUQpcos_gAAAAAAAAAAAAAARQnAQ",
    name: "qiankun"
  },
  {
    path: "https://lit.dev/",
    icon: "https://lit.dev/images/flame-favicon.svg",
    name: "lit"
  },
  {
    path: "https://docs.nestjs.com/",
    icon: "https://docs.nestjs.com/favicon.ico",
    name: "nestjs"
  },
  {
    path: "https://echarts.apache.org/zh/index.html",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAt1BMVEUAAACqMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2qMU2oK0iqMU2qMU2qMU2qMU2qMU2qMU3///+mJ0SlJUOpL0unKUaoLEjjusKxRFzy4OPt09jWnKjTlKGpLUr47e7u19vrztTgs7zKf4/Db4HAZHi8XXK6VmyuOlSsNVD79vbmw8rcrLbXoKrLgpHIe4vCbH+2T2apL0qfFjZpasZcAAAAGnRSTlMAlWUM5O3V6KdN2cqunINzXVUZFO/cz82ySm4ogdMAAADeSURBVBjTTZDXdoMwEEQXEBhcEpfUQRIl4O7YcXr5/+/KypKM7+M9Gs3ZoRNRFgzi+H40iehMlsBxO/buChcEndNSSlV29hFAga9NNa8VDCF39NkdmpxZfhZgejPKgMXLU255lvZpAKg2dzTQLIc0gP5d5Z6D+SChGPro0j4vWKJcn+WPWnAT3QFq7t1mX9UF+jQy7Usn93+rd+gHmgCQOyer7UfTqpAiYey37XptZb27mRKNYexxW63fzKG6vCYmgF2kLE6TpHZhth2p3zkUXvU465mFaSJEMgynZPgHXx8i16SguRAAAAAASUVORK5CYII=",
    name: "echarts"
  },
  {
    path: "https://www.highcharts.com/docs/index",
    icon: "https://www.highcharts.com/docs/img/favicon.png",
    name: "highcharts"
  },
  {
    path: "https://antv.gitee.io/zh",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAB7FBMVEUAAAB3I///oiSFP/+Zav//oiV4J///eAZ0H/9pB/+UX/9rCf+ccf+MT///lxN/PP9nA/95LP+OYP94Jf9pB/9tEf9xGf//oyWPVf6AOP+ISP56Lf5rC/9nLP9uE///fw3/niRxKf+LT///oiZzHP+EPv//lgb/nySCPf+UYf//jxpvFf+NYf9+Nf93JP//dQD/hhf/eQyRXP+CPf9zHf97Lf9zHP//dgx/Nv9oBv//gxNwFf//oCD/jhr/gxL/eAuDPv//iRf/cQmMU/98Lf//fxCFRf//cQZzHP+IS/+JY/+RW/+ANv9qCf9sDP9xGP98Mf9wFv+FQ/9oBP//jxr/iRZtD///ixeCPP5zHf7/oSn+ihj/niT/mwr/ixiCOf9oBv+WY/9zGv9+Mv//bwX/dQKJYf//hxZ9Mf//fA1nAf93If+COv//jRP/dQV4KP//lQNzGv//eQD/dwv/dAOHYv94Jv//lyD/jhluFP9rDf92I/9yHP9nBf9+Nf+JS/98Mf90IP9pCf//niSSYP+CPP96LP94J/9wGP9lAP//fQ9xGf//hhX/dwv/cQiUZv+QWf+NUv+KT/+GRv+FQ/+EQv9/Of97MP95Kv//iRaacP+PWv+NVP9tEf//ghJ3KP9iAP//lhn/fA//bQd3u5e+AAAAd3RSTlMAHB0c/oF+ODcwJCEdHREN/PHbzce6iIiAfX19dmNiX15HNyAXEAT5+PX08fDw7+/r6+nl5OPg39ra2dXU08/MysnGwsLBubiqqKWlpaWim5mOjYmCgoCAfX18fHt0cW9vbWxrZ2RgWlRUU1JOTURDPCsmGhYKB7ly4CgAAAG8SURBVDjLrdBXUyJBFAXgg4AgArqua17XvDnnnHPOOeecd53gAApMQEBBQMzhj3p7iiocGHjye7jndtV56G6shBqX2+22MFar1eNx9YJ0V5BuL0jnFttEXOBEYdwvhcZ8oyMb38F7ZEi3/gkVbLbgRHhOyReCdXg6/z+HCnXBYFxRI8sKl/CjP2cTFbomJ+O7X7Q9rGxtbX7Q0tLU9PIv8PXu42cns9nsfZDTU1O7YOLYzMwhMK5EInEZRT5q2sI66C76fD4PCvzeGY0ez+3WZDJ5DqTiUaPdbm/sAHkbi8UcyLmVSqW6KDvyb8Ov7Yvn83+5IRQ6Qdl3tJ+5A8brQN7zdDr9hfL9MNnRR1vzWhhslaQDLM9qmsaufkWSjI0PmUzmNaUjGt1PYfH7/TdgsDccXsXyVOwbzTN06oHBd1mWKyn/vKHhlOVZOhitFgRh2b7tHwo4A4FAA3SfaW1HkQuiKFrA7BPFPSj2M6Io10FeKZFIJ0zc5DjOSbmZ4w7DTO80x60BGqhXBVP3eJ6vBg2qmZvm+fprequEtkHdVZR0cIDU9qCkdla4jTLqVbW2BmVUqeonlFVd+AVLbMSMkdB0vK0AAAAASUVORK5CYII=",
    name: "antv"
  },
  {
    path: "https://d3js.org/",
    icon: "https://d3js.org/favicon.png",
    name: "d3"
  },
  {
    path: "https://threejs.org/",
    icon: "https://threejs.org/files/favicon.ico",
    name: "threejs"
  },
  {
    path: "http://fabricjs.com/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAABVlBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////z//voAgNX+///l+P/b8Pj++fXt9fT08/OBzvKy4PHD4+1nu+cdq+aXz+UAkt9et907rd1HpNmGrdhho9V3pdNEodODpsoAd8cAcMJaib76///l/f/e9//H7v/0+/38//q66Prp9PfF6Pfq8/aa2/b//vRoyfTt7vNlxfK/1OyAy+xLvewfr+utzOqfyelSvOl4wui/2ucQn+fJ3OaNwOY9seUkpOSr0eKfwOF7u+AOoeB7wd8hp99uvd4wqN2Hv9xJqtwTlty6zdsAjNtltdpotdkAhdYmoNVaqtRSqNIAi9AAedAckM8AhM4AdM0AfcwNhMtrmspwocgnhsgAbchZmcdXjMMMesNSib49hbo2gLoAbLkAu1TxAAAAFHRSTlPyAPX6zsCPWVD04NrTx55/Z2ReRvE+JeEAAAD0SURBVBjTZZDVcsNADEWlNlQm2Q40zFxmZmZumBn+/yWbTTLjsc/LSmdmR1cCRIMWZGj1iIBzpECHsEQq9DCjllMwQcadPZPZKJPjMEZk9nk8l7xdOXhwbhAAsFr0StIJe9dcyXTsayhJcOcLV7Tqet0XPptccpbDMf+9gxWB8lAeh4J3t//RbvDiPBQ/HMhAPFJpl268P/VOK2IlLp8aFvb9LRd1booi0UBWn4nWz+yWVO17i0bSX7QdvbhZhL/sr41LFn47nPlw8GA+KXFq4pI1VoE4u4/X7/b+mhrFNdioSTCQigVAndLNIiAuakDG9DxiD+xkLIdf3pBuAAAAAElFTkSuQmCC",
    name: "fabricjs"
  },
  {
    path: "https://apexcharts.com/",
    icon: "https://apexcharts.com/wp-content/themes/apexcharts/favicon.ico",
    name: "ApexCharts"
  },
  {
    path: "https://www.uisdc.com/",
    icon: "https://www.uisdc.com/favicon.ico",
    name: "优设网"
  },
  {
    path: "https://www.ui.cn/",
    icon: "https://www.ui.cn/Public/img/favicon.ico",
    name: "UI中国"
  },
  {
    path: "https://www.zcool.com.cn/",
    icon: "https://static.zcool.cn/git_z/z/site/favicon.ico?version=1644228200344",
    name: "站酷"
  },
  {
    path: "https://huaban.com/",
    icon: "https://huaban.com/favicon.ico",
    name: "花瓣"
  },
  {
    path: "https://www.meiye.art/",
    icon: "https://icon.meiye.art/favicon.ico",
    name: "美叶"
  },
  {
    path: "https://www.51yuansu.com/",
    icon: "https://www.51yuansu.com/favicon.ico",
    name: "觅元素"
  },
  {
    path: "http://www.qijishow.com/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAABmFBMVEUCACcPFjsOEjgPFToICjAEBCsjNVcbKEwLDzQiMlUXIUUUHEECACcgL1IfL1EOEzgNEjgCACcEBCoSGj8OFDkWH0QZJkkVHkIKDzQXIUUQFjsNETcEBCwRGT0JDDInOlwHCC8AAB0lN1ogMVMeLlEsJE0TDD0JDDAM//9j/8hv/7QjNVccKEwZI0cbIUYTHEATG0AMAjYHBy4JACkAACMc//xL/9JV/85p/7pX/7g33bUTqbVg/7MqtqwSlKVm5JIaXXkfTmwpPV8yZFodK04eJEgqSEUfG0EUF0AbFTseCTULCTUBACUAARMs/+08/+Iq/+FW/99I/9yY/9dA/9E2/81S/8sRuMRD/8IewMJR/8Au378ZtLmB/7Z3/7YNprWI/7QftLQOo7JL6qw3yKIipaE8wZ18/5lKtI9Y0Y0xlo1AoopOvIkne4BVmn48m3ofXWlbomYdR2UYQ2IcR101a1wsU1sqTFkoMFRKfFMeL1BFcU8mJ0skH0sxVUkcQUgjIkcdFUUhQUIoQT4cKj0THTMUADAICSs6GL8/AAAAEXRSTlPw8fDv8PDv7/Dv7+/h7+/h4XqC/BgAAAFdSURBVBjTZc5VW8JgGMbxBwTBdu/esR4bIAgSEraC0ml3d3d3x9d2g+vyxP/Z/Tu6oUlXV2syNdRUq9U0mky1dbpmwCTl8zrsNqvVamuzO7w+WsRQ1c5akvY2mzUe1zRpoUhdGR2vj7ctak9v3vs/zJxH+2YW1wYG9h3+CpKUP7U+ONTa2jE8MnbgZClSD1UizfpXVOwYnZp17zppWtSDnguwuYto39zWwuR8/42TCnAqYpG25DKpfL63030YpNRHZjDzHEmzPsv30XiP5zpIkZg3g4HBqlJZZ29X914wS3I8YwCDxGNODIRPO3uWEwLJYcZlAKMsMTzmw5tdnlihgHlGko1gFGSXxIQvp/uXrhTMSK6QYARAQkgWiG23J/aZJiQ5hBAAEAgJRWajezWRPvtAAkKEhoTKpZOJna/nuwcFqVNDLeXl+D3i+tFIQ6IcUkoKEYkUKwvq4V/1v62WPkhRiVSvAAAAAElFTkSuQmCC",
    name: "奇迹秀"
  },
  {
    path: "https://cn.spline.design/learning-community",
    icon: "https://super-static-assets.s3.amazonaws.com/266a4be2-7da9-4794-9ed0-4c4c28f12a49/images/a482e888-17a9-4e61-8e6c-d890947d51b9.png",
    name: "Spline"
  },
  {
    path: "https://www.iconfont.cn/",
    icon: "https://img.alicdn.com/imgextra/i2/O1CN01ZyAlrn1MwaMhqz36G_!!6000000001499-73-tps-64-64.ico",
    name: "iconfont"
  },
  {
    path: "https://fontawesome.com/",
    icon: "https://fontawesome.com/favicon.ico",
    name: "fontawesome"
  },
  {
    path: "https://icomoon.io/",
    icon: "https://icomoon.io/favicon.ico",
    name: "icomoon"
  },
  {
    path: "https://icons.mono.company/",
    icon: "https://icons.mono.company/favicon.ico",
    name: "icomoon"
  },
  {
    path: "https://iconpark.oceanengine.com/official",
    icon: "https://lf1-cdn2-tos.bytegoofy.com/bydesign/iconparksite/logo.svg",
    name: "iconPark"
  },
  {
    path: "https://arco.design/iconbox/libs",
    icon: "https://unpkg.byted-static.com/byted/arco-config/1.0.9/assets/icon-box.ico",
    name: "iconBox"
  },
  {
    path: "https://www.fonts.net.cn/",
    icon: "https://www.fonts.net.cn/favicon.ico",
    name: "字体天下"
  },
  {
    path: "https://www.emojiall.com/zh-hans",
    icon: "https://www.emojiall.com/favicon.ico",
    name: "emojiall"
  },
  {
    path: "https://icons.bootcss.com/",
    icon: "https://icons.bootcss.com/assets/img/favicons/favicon.ico",
    name: "bootstrap-icons"
  },
  {
    path: "https://www.xicons.org/#/zh-CN",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAjVBMVEUAAAAwMDAvLy8wMDAtLS0yMjIvLy8yMjIxMTExMTEwMDAnJycoKCgbGxswMDA0NDQyMjIxMTE2NjYwMDAzMzMwMDAtLS0xMTEpKSkwMDAdHR0nJycwMDA1NTUfHx8yMjI9PT0xMTE8PDwrKysmJiY6OjoXFxcZGRkwMDBBQUEwMDAzMzM2NjYqKiotLS1A2t1UAAAAKnRSTlMA37HvqH/807iRZWVPHPLx4+Hf2srJv6ufmoeCd25gXFhUTExEPDQsIhc1lM3vAAAAfUlEQVQY063NRw6DQBBE0bJnhmicc86huvH9j8cGIQ1IbOAv36IKvTZaHppoKE10QYSWHrH7ApmLn7glSYlv4RSYkx+EZPWpkqbUFzDkoBrYKSl7+IiVMEIdrdDW8UIhrz5mKtsN85+HMwkA/S+ANU1pR5o7cJ7kJ9hxiM4VWvcKfokRwVwAAAAASUVORK5CYII=",
    name: "xicons"
  },
  {
    path: "https://www.googlefonts.cn/",
    icon: "https://www.googlefonts.cn/Public/google/zh-cn/gstatic/google_fonts_lodp.ico",
    name: "googlefonts"
  },
  {
    path: "https://eveningwater.github.io/to-offer/#/",
    icon: "https://www.eveningwater.com/static/image/icon.jpg",
    name: "剑指offer算法题"
  },
  {
    path: "https://books.halfrost.com/leetcode/",
    icon: "https://leetcode.cn/favicon.ico",
    name: "leetcode"
  },
  {
    path: "https://nodejs.org/zh-cn/",
    icon: "https://nodejs.org/static/images/favicons/favicon.ico",
    name: "node"
  },
  {
    path: "https://www.tslang.cn/",
    icon: "https://www.tslang.cn/assets/images/icons/favicon.ico",
    name: "Typescript"
  },
  {
    path: "https://www.sass.hk/",
    icon: "https://www.sass.hk/favicon.ico",
    name: "sass"
  },
  {
    path: "https://less.bootcss.com/",
    icon: "https://less.bootcss.com/public/ico/favicon.ico",
    name: "less"
  },
  {
    path: "https://www.stylus-lang.cn/",
    icon: "https://www.stylus-lang.cn/favicon.ico",
    name: "stylus"
  },
  {
    path: "https://git-scm.com/",
    icon: "https://git-scm.com/favicon.ico",
    name: "git"
  },
  {
    path: "https://www.dartcn.com/guides/language/language-tour#rune",
    icon: "https://www.dartcn.com/assets/shared/dart/icon/64.png",
    name: "dart"
  },
  {
    path: "https://www.yuque.com/",
    icon: "https://gw.alipayobjects.com/zos/rmsportal/UTjFYEzMSYVwzxIGVhMu.png",
    name: "语雀"
  },
  {
    path: "https://note.youdao.com/",
    icon: "https://note.youdao.com/favicon.ico?v=2",
    name: "有道云"
  },
  {
    path: "https://shimo.im/",
    icon: "https://assets.shimonote.com/from_qbox/favicon.ico",
    name: "石墨文档"
  },
  {
    path: "https://modao.cc/",
    icon: "https://images.modao.cc/images/favicon.ico",
    name: "墨刀"
  },
  {
    path: "https://www.baklib.com/",
    icon: "https://saas.bk-cdn.com/193ddf40-81f3-453a-8527-4537633827a5/icon11590542409650.png",
    name: "baklib"
  },
  {
    path: "https://doc.weiyun.com/",
    icon: "https://img.weiyun.com/vipstyle/nr/box/img/favicon.ico?max_age=31536000",
    name: "微云文档"
  },
  {
    path: "https://pan.baidu.com/",
    icon: "https://pan.baidu.com/m-static/base/static/images/favicon.ico",
    name: "百度网盘"
  },
  {
    path: "https://www.aliyundrive.com/",
    icon: "https://gw.alicdn.com/imgextra/i3/O1CN01aj9rdD1GS0E8io11t_!!6000000000620-73-tps-16-16.ico",
    name: "阿里云盘"
  },
  {
    path: "https://www.wjx.cn/",
    icon: "https://www.wjx.cn/favicon.ico",
    name: "问卷星"
  },
  {
    path: "https://jinshuju.net/",
    icon: "https://gd-assets.jinshujucdn.com/favicon.ico",
    name: "金数据"
  },
  {
    path: "https://h5.bce.baidu.com/",
    icon: "https://h5.bce.baidu.com/favicon.ico",
    name: "百度H5"
  },
  {
    path: "https://www.tubiaoxiu.com/",
    icon: "https://www.tubiaoxiu.com/landing/img/logo_small16.png",
    name: "图表秀"
  },
  {
    path: "https://web.baimiaoapp.com/",
    icon: "https://xlimage.uzero.cn/shinescan/res/favicon.ico",
    name: "白猫"
  },
  {
    path: "https://www.kdocs.cn/",
    icon: "https://qn.cache.wpscdn.cn/kdocs/mobile/touch/apple-152.png",
    name: "金山文档"
  },
  {
    path: "https://www.figma.com/",
    icon: "https://static.figma.com/app/icon/1/favicon.ico",
    name: "figma"
  },
  {
    path: "https://mastergo.com/",
    icon: "https://mastergo.com/favicon.ico",
    name: "masterGo"
  },
  {
    path: "https://js.design/",
    icon: "https://img.js.design/assets/webImg/favicon.ico",
    name: "即时设计"
  },
  {
    path: "https://www.uisdc.com/category/hot-download/tools-download",
    icon: "https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-1.png",
    name: "神器推荐专栏"
  },
  {
    path: "https://uiiiuiii.com/inspirations/featured/tool",
    icon: "https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-2.png",
    name: "设计师神器"
  },
  {
    path: "https://ac.aliyun.com/application/webdesign/sumei?source=5176.11533457&userCode=itzns7ko",
    icon: "https://image.uisdc.com/wp-content/uploads/2020/04/nav-aliyun-202018.png",
    name: "阿里云建站神器"
  },
  {
    path: "https://www.uisdc.com/ppt-artifact-islide",
    icon: "https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-3.png",
    name: "PPT神器iSlide"
  },
  {
    path: "https://jdrd.jd.com/",
    icon: "https://image.uisdc.com/wp-content/uploads/2020/03/jdr-design-nav.png",
    name: "京东JDRDesign"
  },
  {
    path: "https://uiiiuiii.com/software/5103.html",
    icon: "https://image.uisdc.com/wp-content/uploads/2018/08/sdcnav-9-6.png",
    name: "Adobe全家桶"
  },
  {
    path: "https://www.epub360.com/",
    icon: "https://image.uisdc.com/wp-content/uploads/2019/11/nav-1107-epub.png",
    name: "H5制作神器"
  },
  {
    path: "https://www.npmjs.com/",
    icon: "https://static.npmjs.com/b0f1a8318363185cc2ea6a40ac23eeb2.png",
    name: "npm"
  },
  {
    path: "https://www.yarnpkg.cn/",
    icon: "https://www.yarnpkg.cn/favicon-32x32.png?v=6143f50112ddba9fdb635b0af2f32aff",
    name: "yarn"
  },
  {
    path: "https://pnpm.io/zh/",
    icon: "https://d33wubrfki0l68.cloudfront.net/2f3acb83b7d2349f2194bc38c0f22f295908dc33/6a6e6/zh/img/pnpm-no-name-with-frame.svg",
    name: "pnpm"
  },
  {
    path: "https://www.babeljs.cn/",
    icon: "https://www.babeljs.cn/img/favicon.png",
    name: "babel"
  },
  {
    path: "https://www.rollupjs.com/",
    icon: "https://www.rollupjs.com/img/favicon.png",
    name: "rollup"
  },
  {
    path: "https://www.webpackjs.com/",
    icon: "https://www.webpackjs.com/icon_144x144.png",
    name: "webpack"
  },
  {
    path: "https://www.gulpjs.com.cn/",
    icon: "https://www.gulpjs.com.cn/img/favicon.png",
    name: "gulp"
  },
  {
    path: "https://vitejs.cn/",
    icon: "https://vitejs.cn/logo.svg",
    name: "vite"
  },
  {
    path: "https://codepen.io/",
    icon: "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico",
    name: "CodePen"
  },
  {
    path: "https://parceljs.org/",
    icon: "https://parceljs.org/favicon.fe6f9d11.ico",
    name: "parcel"
  },
  {
    path: "https://c.runoob.com/more/svgeditor/",
    icon: "https://c.runoob.com/more/svgeditor/images/favicon.svg",
    name: "SVG编辑器"
  },
  {
    path: "https://getwaves.io/",
    icon: "https://getwaves.io/favicon-32x32.png?v=f13a1a2e88a9720e746d5561039d3f5f",
    name: "SVG波浪线"
  },
  {
    path: "https://undraw.co/illustrations",
    icon: "https://undraw.co/favicon.ico",
    name: "unDraw"
  },
  {
    path: "https://www.shapedivider.app/",
    icon: "https://www.shapedivider.app/favicon.ico",
    name: "ShapeDividers"
  },
  {
    path: "https://iradesign.io/gallery/illustrations",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAvVBMVEUAAAB9l+5+me4qV+nw9P8ZPuR/me9/mu5+me9+mO57lu5+me5+me5+me98l+52k+50kO5Ocuh+me/F0Pd9mO2Bm+99mPB7l+56lu50kO53ke1tjuzN1vsAEc5/mu5+mO68yfV9l+6wwfXO2fiQpu+YrvKTqfDI0vh+mu96le+nt/N6l+14l+54lO56le9zkuy2w/Zxkexpie3e5PrCzvhkf+7///9Sc++Qp//////U//8AAL9+me57l+54le2/NIBQAAAAPHRSTlMAw+APDAz99unUeuTYyINfPhvMxby0oZ2PXEYwGwf57dK8u7m5rpKQkIeBfnd1aVJRTDgwKiggHwwHBgRBaZZ6AAAAo0lEQVQY002ORRKEQBAEewx3h4V1d9eB/z9rA5ggyEtX5qkh/4DAuX6hhxtW5avne8moqrTTgSrbS8VMhBZxGTGqaAskQowdSlPpvpVFQBYwVCJ6rkgXjsqYk9swa4OpgcNV5f1UD42fdA3gYrk0x5vaE6nEdj2IarD6BsFqLY805uoea58yeDibYkk2CxBkE45839N/0PGIOJ5zAj3IDtnN+APgxQzTwvXUNwAAAABJRU5ErkJggg==",
    name: "IRADesign"
  },
  {
    path: "https://delesign.com/free-designs/graphics/",
    icon: "https://delesign.com/public/images/favicon-ico.png",
    name: "FreeDesigns"
  },
  {
    path: "https://datav.aliyun.com/portal/school/atlas/area_selector?spm=a2crr.b71357980.0.0.15cd26c0dUO9va",
    icon: "https://datav.aliyun.com/favicon.ico",
    name: "地理小工具"
  },
  {
    path: "https://cubic-bezier.com/#.17,.67,.83,.67",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAY1BMVEX///8AAADr+fr////1/PwAqrsAprgKrr5hy9XU8fQUssH0+/xQxdEitsUwusg/v8zE7PAds8P////p+Pqw5eqp4+mN2eH3/P2h4OeQ2+I2vMra8/a86e2b3uRrzthKw89Ewc0z3iepAAAABXRSTlPwAO/g70YqzXQAAADSSURBVDjLhdNLbsMwDARQuqlGjqiv/3HaJPc/ZWUHBYwWpGdH8GGkDak5CTUXog8hn0QXaohbKyQMHVVwgxEC12+g/QVHCOzT9QicL+4da12Myf4F6/h4+pqXnzLPfP8HbHmXr7kf2hjc8YljJl7K/gMBLLPftwLAyLGOIsCjq3sZIHbboDTkEUYB8BxUYO8TjALQsjMKqNMNGkDioINhhFEAAq86+P6C0UDgpIJaYIKVAdKc/BJkEPucXk+5AVMuBtofit22MhBzPb+s09s8ve4fJkoXyynCJe4AAAAASUVORK5CYII=",
    name: "贝塞尔曲线"
  },
  {
    path: "https://animista.net/",
    icon: "https://animista.net/favicon.ico",
    name: "css动画"
  },
  {
    path: "https://cssgradient.io/",
    icon: "https://cssgradient.io/images/favicon-23859487.png",
    name: "css渐变色"
  },
  {
    path: "https://shadows.brumm.af/",
    icon: "https://shadows.brumm.af/favicon.svg",
    name: "css平滑阴影"
  },
  {
    path: "https://bennettfeely.com/clippy/",
    icon: "https://bennettfeely.com/clippy/pics/favicon.png",
    name: "css图片剪辑"
  },
  {
    path: "https://cssgrid-generator.netlify.app/",
    icon: "https://cssgrid-generator.netlify.app/favicon.ico",
    name: "cssGrid"
  },
  {
    path: "https://ui.glass/generator/",
    icon: "https://ui.glass/generator/favicon-32x32.png",
    name: "GlassUI"
  },
  {
    path: "https://animxyz.com/",
    icon: "https://animxyz.com/assets/static/animxyz-logo.ce0531f.39f3bde368e480505b70778acaa2ac74.png",
    name: "animxyz"
  },
  {
    path: "https://whirl.netlify.app/",
    icon: "https://whirl.netlify.app/favicon.ico",
    name: "whirl"
  },
  {
    path: "https://cli.im/",
    icon: "https://static.clewm.net/static/images/favicon.ico",
    name: "草料二维码"
  },
  {
    path: "https://tinypng.com/",
    icon: "https://tinypng.com/images/favicon.ico",
    name: "图片压缩"
  },
  {
    path: "https://fanyi.baidu.com/",
    icon: "https://fanyi-cdn.cdn.bcebos.com/static/translation/img/favicon/favicon-32x32_ca689c3.png",
    name: "百度翻译"
  },
  {
    path: "https://lanhuapp.com/web/",
    icon: "https://lhcdn.lanhuapp.com/web/static/favicon.ico?v=2",
    name: "蓝湖"
  },
  {
    path: "https://www.aconvert.com/cn/",
    icon: "https://www.aconvert.com/favicon.ico",
    name: "格式转换"
  },
  {
    path: "https://www.toptal.com/developers/css/sprite-generator",
    icon: "https://www.toptal.com/developers/css/sprite-generator/static/images/favicon.png",
    name: "生成精灵图"
  },
  {
    path: "https://www.m3u8.tv/",
    icon: "https://www.m3u8.tv/favicon.ico",
    name: "m3u8"
  },
  {
    path: "https://ps.gaoding.com/#/",
    icon: "https://ps.gaoding.com/favicon.ico",
    name: "在线ps"
  },
  {
    path: "https://www.pixilart.com/draw",
    icon: "https://www.pixilart.com/images/favicon/favicon-32x32.png?v=jw6qNMPWz4",
    name: "像素风设计"
  },
  {
    path: "https://tool.lu/",
    icon: "https://tool.lu/favicon.ico",
    name: "在线工具"
  },
  {
    path: "https://ikuuu.ltd/",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAB+FBMVEUAAABsaXQAAAASERMODg8iISQHBwgGBgbAvs8gISaPkJze3ud4eIFtbXYcGR0NDQ7X1+JZVl9ycnsDAwPOztpvb3YICAlbWV8PDhB3d4HAwNFSUV5ISEzAvsMtLTJyc4Dk5Os0NDspKCwcGx4DAwQkJCjGxMssLDKjlKfh4eojIyjMy9Z7e4IWFhm/vciWh5lGRkwhISRkZG2CgosAAABubXV6e4UREROYmKaFgolIR01qaXNubXeXl58JCQoCAgLY2OF6doOEhIuVlZ3Fw9Db2+VOTlWNjZe4uMoZGRsJCQp9foPHx9aDg4yXl58wMDZUU1bc3OdHR04ODg+SkpptbXbX1+IqKjE+OkAAAABiYmrOztsAAABnZ24AAACAgIpJSU89PUJYWF4zMjdaWmBSUlYxMTQEAwTi4urOw9rRzdO5tbuZmaNtbXZdW2VKSlBCQkdAQEM+PkI7O0AmJSkdHSH09PTp6e/m5u3o5+jAtc2ioqSKiph9fYZxcXlvbnllZmtXV15FRkwtLjIqKS4KCgvg3+Xe3ePW1t7Rz9bDw9HBwc/MzMzFxcy4rMi/wMTBwcG6ur+foK+jn6aWl6WfnKKVlZ+VlZmRkZWCgZB6eoR8cH9yaHVWXm5bYGxkZGthYWc8SF9QUFg2QFQ8PD42NjwcHB4XFxkq2LhvAAAAYHRSTlMABBMH/qQ1HwX+/fv54YV3dGtoWFRQOy0nJBAP/vz8+/r5+Pj49vX08u/v7erq5uXl393V0s7MycC+uLOvpY6Ni4qCfn18dnNycm5paGdmYlhSUk5NSkhHQC8fHBsQDQt3c833AAABSElEQVQY02LADhh9nbxFxaNChYREJSVD/Jzd40GC1jNr5A2MeGpreXh5uSZNMYsFKRWsysxryq2rq8/NzavPzHQE65fQn93cmJeSkpSU1Fg4RyMCYihfSUF+fn4CCDS1sDJDrApXTCwqSiwuTgSCpf4gEY6wwCD15OS0gpSkorTkNDk3AddoBhFNBWPV5rkNPZWVE1LnJZfoZFQ4MPhwdywoLV3YX9ZXVVa9mLM0tZdbADAGKQ8LrVaWZdlZWZ1Z1SwsLIa2IlJAU6XZ7FXSCydnZ09NS09nZWeE+pTJpLVwRnvb9CXpy204YN7nYE3oyigvz+helGoqDQsSF+2cmmk5OVyz5qdYMUMFxfUqJjYkpSYUFCcr8cWBhZhjgi3VZGVaSjiVdc3thNmYGIF6mSLZxAIEPfn5vYTF2JnYJYCCQFFmZgZiAACAmlhoJvl29QAAAABJRU5ErkJggg==",
    name: "ikuuu-vpn"
  },
  {
    path: "https://www.baidufe.com/fehelper/index/index.html",
    icon: "https://www.baidufe.com/fehelper/static/img/favicon.ico",
    name: "Web助手FeHelper"
  },
  {
    path: "https://www.aigei.com/",
    icon: "https://cdn-sqn.aigei.com/assets/site/img/icon/favicon.ico",
    name: "爱给"
  },
  {
    path: "https://wallhaven.cc/",
    icon: "https://wallhaven.cc/favicon.ico",
    name: "WallHaven"
  },
  {
    path: "https://jsbench.me/",
    icon: "https://jsbench.me/assets/icons/favicon-32x32.png",
    name: "JSBench"
  },
  {
    path: "https://jex.im/regulex",
    icon: "https://jex.im/favicon.ico",
    name: "Regulex"
  },
  {
    path: "https://c.runoob.com/",
    icon: "https://static.runoob.com/images/c-runoob-logo.ico",
    name: "菜鸟工具"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=VisualStudioExptTeam.vscodeintellicode",
    icon: "https://visualstudioexptteam.gallerycdn.vsassets.io/extensions/visualstudioexptteam/vscodeintellicode/1.2.22/1656630439318/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Intelli Code"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=oderwat.indent-rainbow",
    icon: "https://oderwat.gallerycdn.vsassets.io/extensions/oderwat/indent-rainbow/8.3.1/1649543509070/Microsoft.VisualStudio.Services.Icons.Default",
    name: "indent-rainbow"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=hollowtree.vue-snippets",
    icon: "https://cn.vuejs.org/logo.svg",
    name: "Vue 3 Snippets"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=chendm.translate",
    icon: "https://fanyi-cdn.cdn.bcebos.com/static/translation/img/favicon/favicon-32x32_ca689c3.png",
    name: "translateNamed"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=techer.open-in-browser",
    icon: "https://techer.gallerycdn.vsassets.io/extensions/techer/open-in-browser/2.0.0/1534049617896/Microsoft.VisualStudio.Services.Icons.Default",
    name: "open in browser"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=ritwickdey.LiveServer",
    icon: "https://ritwickdey.gallerycdn.vsassets.io/extensions/ritwickdey/liveserver/5.7.5/1646738284779/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Live Server"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=christian-kohler.path-intellisense",
    icon: "https://christian-kohler.gallerycdn.vsassets.io/extensions/christian-kohler/path-intellisense/2.8.1/1654770568656/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Path Intellisense"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=formulahendry.code-runner",
    icon: "https://formulahendry.gallerycdn.vsassets.io/extensions/formulahendry/code-runner/0.11.8/1654961830194/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Code Runner"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=kisstkondoros.vscode-gutter-preview",
    icon: "https://kisstkondoros.gallerycdn.vsassets.io/extensions/kisstkondoros/vscode-gutter-preview/0.30.0/1644076638771/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Image preview"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=alefragnani.project-manager",
    icon: "https://alefragnani.gallerycdn.vsassets.io/extensions/alefragnani/project-manager/12.6.0/1653251367800/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Project Manager"
  },
  {
    path: "https://marketplace.visualstudio.com/items?itemName=Zignd.html-css-class-completion",
    icon: "https://zignd.gallerycdn.vsassets.io/extensions/zignd/html-css-class-completion/1.20.0/1608419199966/Microsoft.VisualStudio.Services.Icons.Default",
    name: "Intelli Sense for CSS classnames in"
  }
]

export const allNavigationUrl = [
  {
    "name": "cssfilters（CSS滤镜）  ",
    "path": "https://www.cssfilters.co",
    "icon": "https://www.cssfilters.co/images/icons/favicon.ico"
  },
  {
    "name": "即时设计 - 可实时协作的专业 UI  设计工具",
    "path": "https://js.design/?source=uisdc&plan=sjgj",
    "icon": "https://img.js.design/assets/webImg/favicon.ico"
  },
  {
    "name": "包小盒 - 在线3D包装设计工具",
    "path": "https://www.baoxiaohe.com",
    "icon": "https://www.baoxiaohe.com/favicon.ico"
  },
  {
    "name": "高品质的免费模型",
    "path": "https://mockups-design.com",
    "icon": "https://mockups-design.com/wp-content/uploads/2017/08/cropped-favicon-32x32.png"
  },
  {
    "name": "来画视频 -  有创意的AI动画短视频制作平台",
    "path": "https://www.laihua.com",
    "icon": "https://www.laihua.com/favicon.ico"
  },
  {
    "name": "一帧秒创 - AI视频创作,图文转视频",
    "path": "https://aigc.yizhentv.com",
    "icon": "https://aigc.yizhentv.com/favicon.ico"
  },
  {
    "name": "Photopea | Online  Photo Editor",
    "path": "https://www.photopea.com",
    "icon": "https://www.photopea.com/promo/thumb256.png"
  },
  {
    "name": "造物云 - 实时云渲染 | 在线生成3D效果",
    "path": "https://www.zaowuyun.com/",
    "icon": "https://www.zaowuyun.com/favicon.ico"
  },
  {
    "name": "Hand-Drawn  Illustration Library",
    "path": "https://openpeeps.com",
    "icon": "https://assets.website-files.com/5e51b3b0337309d672efd94c/5e51ce488c34f8b713499e29_icon32w.png"
  },
  {
    "name": "创建动画水效果",
    "path": "https://watereffect.net",
    "icon": "https://watereffect.net/favicon.ico"
  },
  {
    "name": "泼辣修图 polarr",
    "path": "https://v3.polarr.co",
    "icon": "https://v3.polarr.co/favicon-32x32.png"
  },
  {
    "name": "创客贴 - 平面设计作图神器",
    "path": "https://www.chuangkit.com",
    "icon": "https://www.chuangkit.com/favicon.ico"
  },
  {
    "name": "Fotor懒设计 - 在线设计工具",
    "path": "https://www.fotor.com.cn",
    "icon": "https://www.fotor.com.cn/favicon.ico"
  },
  {
    "name": "稿定设计 - 在线设计_免费设计素材",
    "path": "https://www.gaoding.com",
    "icon": "https://www.gaoding.com/favicon.ico"
  },
  {
    "name": "图怪兽 - 在线图片编辑器",
    "path": "https://818ps.com",
    "icon": "https://818ps.com/favicon.ico"
  },
  {
    "name": "可批量编辑图片的所有工具",
    "path": "https://www.iloveimg.com/zh-cn",
    "icon": "https://www.iloveimg.com/favicon.ico"
  },
  {
    "name": "Canva在线平面设计软件",
    "path": "https://www.canva.cn",
    "icon": "https://www.canva.cn/favicon.ico"
  },
  {
    "name": "图帮主 - 轻量级在线平面设计工具",
    "path": "https://www.tubangzhu.com",
    "icon": "https://www.tubangzhu.com/favicon.ico"
  },
  {
    "name": "i排版 - 公众号编辑_微信排版工具",
    "path": "http://ipaiban.com",
    "icon": "http://ipaiban.com/src/img/favicon.ico"
  },
  {
    "name": "96微信编辑器 -  微信公众平台图文排版工具",
    "path": "http://bj.96weixin.com",
    "icon": "http://bj.96weixin.com/favicon.ico"
  },
  {
    "name": "小蚂蚁微信编辑器_微信图文编辑",
    "path": "http://www.xmyeditor.com",
    "icon": "http://www.xmyeditor.com/favicon.ico"
  },
  {
    "name": "135编辑器官网-微信排版编辑器",
    "path": "https://www.135editor.com",
    "icon": "https://www.135editor.com/favicon.ico"
  },
  {
    "name": "易点微信编辑器 - 微信公众平台素材编辑",
    "path": "https://www.wxeditor.com",
    "icon": "https://www.wxeditor.com/favicon.ico"
  },
  {
    "name": "新榜微信编辑器—让你的图文编辑生动有趣",
    "path": "https://edit.newrank.cn",
    "icon": "https://edit.newrank.cn/favicon.ico"
  },
  {
    "name": "Method Draw─矢量图形在线编辑",
    "path": "https://c.runoob.com/more/svgeditor",
    "icon": "https://c.runoob.com/favicon.ico"
  },
  {
    "name": "图表秀 - 图表数据可视化工具",
    "path": "https://www.tubiaoxiu.com",
    "icon": "https://www.tubiaoxiu.com/landing/img/logo_small16.png"
  },
  {
    "name": "文图 - 制作可视化数据报告",
    "path": "https://wentu.io",
    "icon": "https://www.wentu.io/img/apple-touch-icon.png"
  },
  {
    "name": "免费在线-视频-音频-格式转换工具",
    "path": "http://www.alltoall.net",
    "icon": "https://www.alltoall.net/qfy-content/uploads/2016/06/fa70f63e4bbcc259632aae74746ce5d6.png"
  },
  {
    "name": "免费在线视频音频转换文档转换压缩转换",
    "path": "https://cn.office-converter.com",
    "icon": "https://cn.office-converter.com/favicon.ico"
  },
  {
    "name": "在线转换文档，图像，视频，音频文件",
    "path": "https://www.aconvert.com/cn",
    "icon": "https://www.aconvert.com/favicon.ico"
  },
  {
    "name": "在线剪辑视频",
    "path": "https://online-video-cutter.com/cn",
    "icon": "https://online-video-cutter.com/static/i/v3/favicon.svg"
  },
  {
    "name": "SubPlayer - 在线字幕编辑器",
    "path": "https://subplayer.js.org",
    "icon": "https://subplayer.js.org/favicon.ico"
  },
  {
    "name": "在线录音-直接在网页中录音并导出mp3格式至本地",
    "path": "https://online-voice-recorder.com/cn",
    "icon": "https://online-video-cutter.com/static/i/v3/favicon.svg"
  },
  {
    "name": "Online MP3 Cutter -  剪辑歌曲，制作铃声",
    "path": "https://mp3cut.net/cn",
    "icon": "https://online-video-cutter.com/static/i/v3/favicon.svg"
  },
  {
    "name": "分秒帧MediaTrack - 音视频协作",
    "path": "https://www.mediatrack.cn",
    "icon": "https://www.mediatrack.cn/favicon.ico"
  },
  {
    "name": "讯飞配音-提供文字转语音，语音合成",
    "path": "http://peiyin.xunfei.cn",
    "icon": "http://peiyin.xunfei.cn/themes/images/bigpeiyinge_favicon.ico"
  },
  {
    "name": "淘金阁 - 视频配音在线生成",
    "path": "http://www.51taojinge.com/include/voice/voice.php",
    "icon": "http://cdnnew.51taojinge.com/Static/images/favicon.ico?v=2063"
  },
  {
    "name": "魔音工坊 - 达人热推的短视频/有声书AI配音平台",
    "path": "https://www.moyin.com",
    "icon": "https://www.moyin.com/favicon.ico"
  },
  {
    "name": "传影DIY_在线视频制作_电子相册",
    "path": "https://www.chuanying520.com",
    "icon": "https://www.chuanying520.com/favicon.ico"
  },
  {
    "name": "影大师 - 在线视频制作，电子相册",
    "path": "http://www.yingdashi.cn",
    "icon": "https://www.yingdashi.cn/images/favicon.ico"
  },
  {
    "name": "Online-Down.. -  全球视频在线下载",
    "path": "https://www.online-downloader.com/index-Chinese",
    "icon": "https://www.online-downloader.com/Download-Font/favicon-32x32.png"
  },
  {
    "name": "58图库 - 电商历史图库 TOP卖家快照",
    "path": "http://www.58tu.com",
    "icon": "http://www.58tu.com/Public/favicon.ico"
  },
  {
    "name": "阿里巴巴原创保护-创意|短视频|图片保护",
    "path": "https://yc.alibaba.com",
    "icon": "https://yc.alibaba.com/favicon.ico"
  },
  {
    "name": "反恶联盟-反打假/防敲诈",
    "path": "https://www.felmvip.com",
    "icon": "https://www.felmvip.com/static/images/favicon.ico"
  },
  {
    "name": "卖家网-领先的电商服务平台",
    "path": "http://www.maijia.com",
    "icon": "http://www.maijia.com/favicon.ico"
  },
  {
    "name": "电商报-电商数据_电子商务数据_电商行业数据",
    "path": "http://www.dsb.cn/shuju",
    "icon": "http://www.dsb.cn/favicon.ico"
  },
  {
    "name": "抖查查 -  短视频&直播电商数据分析服务平台",
    "path": "https://www.douchacha.com",
    "icon": "https://www.douchacha.com/favicon.ico"
  },
  {
    "name": "蝉妈妈 - 抖音  小红书一站式数据分析服务平台",
    "path": "https://www.chanmama.com",
    "icon": "https://cdn-static.chanmama.com/sub-module/web/favicon.ico"
  },
  {
    "name": "巨量创意",
    "path": "https://cc.oceanengine.com",
    "icon": "https://lf9-static.bytednsdoc.com/obj/cc-static/1605083490030.svg"
  },
  {
    "name": "巨量千川-登录",
    "path": "http://qianchuan.jinritemai.com/login",
    "icon": "https://lf1-cdn2-tos.bytegoofy.com/ad/ecom_marketing_fe/favicon.ico"
  },
  {
    "name": "服务导航 - 新抖",
    "path": "https://xd.newrank.cn/home/index",
    "icon": "https://chs.newrank.cn/favicon/xd/favicon.ico"
  },
  {
    "name": "算数指数-巨量算数",
    "path": "https://trendinsight.oceanengine.com/arithmetic-index",
    "icon": "https://lf3-static.bytednsdoc.com/obj/eden-cn/kyhgpdeh7nuvanuhd/count/trendinsight.ico"
  },
  {
    "name": "西贴 - 历史价格查询|网上商城价格走势图",
    "path": "http://www.xitie.com",
    "icon": "http://www.xitie.com/favicon.ico"
  },
  {
    "name": "亲查查-旺旺小号降权_信誉查询_照妖镜验号",
    "path": "https://www.qinchacha.com",
    "icon": "https://www.qinchacha.com/favicon.ico"
  },
  {
    "name": "看店宝_淘宝店铺和天猫数据查询分析",
    "path": "https://kandianbao.com",
    "icon": "https://k.tqdn.cn/favicon.ico"
  },
  {
    "name": "抖商眼-抖音生意参谋",
    "path": "https://doushangyan.com/?s=mjbox",
    "icon": "https://doushangyan.com/static/favicon.ico"
  },
  {
    "name": "星图-抖音头条商业内容智能交易&管理平台",
    "path": "https://star.toutiao.com",
    "icon": "https://star.toutiao.com/favicon.ico"
  },
  {
    "name": "小旺神_新版生意参谋指数一键还原",
    "path": "https://xiaowangshen.com/?f=cj",
    "icon": "https://xws.tqdn.cn/images/favicon.ico?t=1553074981"
  },
  {
    "name": "店侦探-电商数据分析专家",
    "path": "https://www.dianzhentan.com",
    "icon": "https://d.tqdn.cn/static/favicon.ico?q=1637049270"
  },
  {
    "name": "店透视-生意参谋指数转换 自动计算  批量下载",
    "path": "https://www.diantoushi.com",
    "icon": "https://assets.diantoushi.com/cnd/img/dts-headicon.png"
  },
  {
    "name": "淘大象-旺旺信用查询/宝贝查询",
    "path": "http://www.taodaxiang.com",
    "icon": "http://www.taodaxiang.com/favicon.ico"
  },
  {
    "name": "幕思城将军令-关键词淘词/数据分析运营策略…",
    "path": "http://soft.musicheng.com",
    "icon": "http://soft.musicheng.com/favicon.ico"
  },
  {
    "name": "阿里指数-行业大盘/属性细分/阿里排行",
    "path": "http://index.1688.com",
    "icon": "https://img.alicdn.com/tfs/TB1uh..zbj1gK0jSZFuXXcrHpXa-16-16.ico"
  },
  {
    "name": "美女时钟-品牌商家与网红达人合作平台",
    "path": "http://www.sodao.com",
    "icon": "http://www.sodao.com/favicon.ico"
  },
  {
    "name": "买家秀拍摄-淘宝买家秀兼职寄拍平台",
    "path": "http://www.tbqq.net",
    "icon": "http://www.tbqq.net/favicon.ico"
  },
  {
    "name": "折800-精选商品折扣1折特卖",
    "path": "https://www.zhe800.com",
    "icon": "https://www.zhe800.com/favicon.ico"
  },
  {
    "name": "美丽说-女性时尚分享网站",
    "path": "http://www.meilishuo.com",
    "icon": "https://s10.mogucdn.com/mlcdn/c45406/180926_7l1b34jf8c9fei50e7dbijc7d39gh_48x48.png"
  },
  {
    "name": "小红书-全世界的好东西",
    "path": "https://www.xiaohongshu.com",
    "icon": "https://www.xiaohongshu.com/favicon.ico"
  },
  {
    "name": "贝店-我的店!带你买遍全球好货",
    "path": "http://www.beidian.com",
    "icon": "http://www.beidian.com/favicon.ico"
  },
  {
    "name": "微店 - 基于社交关系的电商平台",
    "path": "https://www.weidian.com",
    "icon": "https://assets.geilicdn.com/fxxxx/favicon.ico"
  },
  {
    "name": "聚美优品-极速免税店 品牌防伪码",
    "path": "http://sh.jumei.com",
    "icon": "http://sh.jumei.com/favicon.ico"
  },
  {
    "name": "海拍客-做母婴，就用海拍客！",
    "path": "https://mall.hipac.cn/mall/view/login/login.html?loginFlag=false",
    "icon": "https://mall.hipac.cn/favicon.ico"
  },
  {
    "name": "网易考拉 - 正品低价_海外直采_海外直邮",
    "path": "https://www.kaola.com/?zn=top",
    "icon": "https://img.alicdn.com/tfs/TB13g4aqXP7gK0jSZFjXXc5aXXa-32-32.ico"
  },
  {
    "name": "达令家-享受美物,致敬生活！",
    "path": "http://www.dalingjia.com",
    "icon": "http://aimg8.dlszyht.net.cn/user_icon/201708241010271520368.ico"
  },
  {
    "name": "云集-购物享受批发价",
    "path": "https://www.yunjiglobal.com",
    "icon": "https://www.yunjiglobal.com/favicon.ico"
  },
  {
    "name": "每日一淘-精选美食社交电商平台",
    "path": "https://www.mryitao.cn",
    "icon": "https://www.mryitao.cn/img/logo.png"
  },
  {
    "name": "17网 - 让服装行业更美好",
    "path": "https://gz.17zwd.com",
    "icon": "https://gz.17zwd.com/favicon.ico"
  },
  {
    "name": "服装流行趋势企划",
    "path": "https://www.pop-fashion.com/trends/?pid=021521&bd_vid=10652155847360356368",
    "icon": "https://www.pop-fashion.com/favicon.ico?20200903"
  },
  {
    "name": "宏鼎品库 - 批发档口，一手货源",
    "path": "https://www.ppkoo.com/index/index.html?from=header",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8de9437.ico"
  },
  {
    "name": "四季青服装网",
    "path": "https://www.sjq.cn",
    "icon": "https://www.sjq.cn/favicon.ico"
  },
  {
    "name": "搜款网 - 杭州女装站 -  真实档口一手货源",
    "path": "https://www.vvic.com/hznz",
    "icon": "https://www.vvic.com/favicon.ico"
  },
  {
    "name": "衣联网 - 服装批发市场新的领航者",
    "path": "https://www.eelly.com",
    "icon": "https://www.eelly.com/favicon.ico"
  },
  {
    "name": "生意网 - 童装贸易信息服务平台",
    "path": "http://www.3e3e.cn",
    "icon": "http://www.3e3e.cn/favicon.ico"
  },
  {
    "name": "水墨佳人服饰",
    "path": "http://www.ismjr.com",
    "icon": "http://www.ismjr.com/favicon.ico"
  },
  {
    "name": "Web前端导航",
    "path": "http://www.alloyteam.com/nav",
    "icon": "http://www.alloyteam.com/favicon.ico"
  },
  {
    "name": "千图前端开发工具",
    "path": "http://tool.58pic.com/development",
    "icon": "http://tool.58pic.com/favicon.ico"
  },
  {
    "name": "CSS参考手册",
    "path": "http://css.doyoe.com",
    "icon": "http://css.doyoe.com/doyoe.ico"
  },
  {
    "name": " 菜鸟教程 ",
    "path": "http://www.runoob.com",
    "icon": "http://www.runoob.com/favicon.ico"
  },
  {
    "name": " w3school 在线教程 ",
    "path": "https://www.w3school.com.cn",
    "icon": "https://www.w3school.com.cn/ui2019/logo-32-red.png"
  },
  {
    "name": " 编程学习网 - PHP/laravel/swoole ",
    "path": "http://www.phpxs.com",
    "icon": "http://www.phpxs.com/favicon.ico"
  },
  {
    "name": " 前端人的俱乐部 ",
    "path": "http://f2er.club",
    "icon": "http://f2er.club/img/favicon.ico"
  },
  {
    "name": "掘金 - 帮助开发者成长的社区",
    "path": "https://juejin.im",
    "icon": "https://juejin.im/favicon.ico"
  },
  {
    "name": "印记中文 - 权威技术中文文档社区",
    "path": "https://docschina.org",
    "icon": "https://docschina.org/favicon.ico"
  },
  {
    "name": "LintCode - 炼码",
    "path": "https://www.lintcode.com",
    "icon": "https://jsn.lintcode.com/static/img/favicon.ico"
  },
  {
    "name": "Lodash文档 -  JavaScript实用工具库",
    "path": "https://www.lodashjs.com",
    "icon": "https://www.lodashjs.com/img/favicon.ico"
  },
  {
    "name": "代码学院 - Web开发基础教程大全",
    "path": "http://codexy.cn",
    "icon": "http://codexy.cn/favicon.ico"
  },
  {
    "name": "How2J 的 Java教程",
    "path": "https://how2j.cn",
    "icon": "https://how2j.cn/favicon.ico"
  },
  {
    "name": "w3cschool -  编程狮，随时随地学编程",
    "path": "https://www.w3cschool.cn",
    "icon": "https://www.w3cschool.cn/favicon.ico"
  },
  {
    "name": "易百教程™ - 专注于IT教程和实例",
    "path": "https://yiibai.com",
    "icon": "https://yiibai.com/favicon.ico"
  },
  {
    "name": "SplitShire - 前端印记",
    "path": "https://www.htmltrip.com/sites/122.html",
    "icon": "https://www.htmltrip.com/favicon.ico"
  },
  {
    "name": "搜字网 - 按品名类别/艺术风格查看",
    "path": "http://www.sozi.cn",
    "icon": "http://www.sozi.cn/favicon.ico"
  },
  {
    "name": "字客网 -  字体授权/来源/类型/格式/风格",
    "path": "https://www.fontke.com",
    "icon": "https://www.fontke.com/favicon.ico"
  },
  {
    "name": "云字库 - 文鼎字体战略合作伙伴",
    "path": "http://www.yestone.com/fonts/overview",
    "icon": "http://www.yestone.com/favicon.ico"
  },
  {
    "name": "求字体网 -  字体一键补齐/可读取PSD/AI/CDR/JPG",
    "path": "http://www.qiuziti.com",
    "icon": "https://www.qiuziti.com/image/icon.ico"
  },
  {
    "name": "猫啃网 - 最新最全的可免费商用中文字体",
    "path": "https://www.maoken.com",
    "icon": "https://www.maoken.com/favicon.ico"
  },
  {
    "name": "字由 - 设计师必备字体利器",
    "path": "http://www.hellofont.cn",
    "icon": "http://www.hellofont.cn/favicon.ico"
  },
  {
    "name": "字体天下 -  提供各类字体的免费下载和在线预览服务",
    "path": "http://www.fonts.net.cn",
    "icon": "http://www.fonts.net.cn/favicon.ico"
  },
  {
    "name": "书法迷 - 在线书法字典",
    "path": "http://www.shufami.com",
    "icon": "http://www.shufami.com/favicon.ico"
  },
  {
    "name": "书法字体 - 在线书法字体转换",
    "path": "http://www.shufaziti.com",
    "icon": "http://www.shufami.com/favicon.ico"
  },
  {
    "name": "书同文汉字网 - 设计师必备字体利器",
    "path": "https://hanzi.unihan.com.cn",
    "icon": "https://hanzi.unihan.com.cn/favicon.ico"
  },
  {
    "name": "古书竖文转换器 -  可粘贴到论坛/博客/留言板",
    "path": "http://www.aies.cn/shuwen.htm",
    "icon": "http://www.aies.cn/favicon.ico"
  },
  {
    "name": "FONTS•LOL - 彩色字体铸造厂",
    "path": "http://www.fonts.lol",
    "icon": "http://www.fonts.lol/images/lol_fav.ico"
  },
  {
    "name": "Dafont - 大量免费的英文字体",
    "path": "https://www.dafont.com",
    "icon": "https://www.dafont.com/favicon.ico"
  },
  {
    "name": "Wordmark.it -  在线预览本地字体",
    "path": "https://wordmark.it",
    "icon": "https://wordmark.it/assets/meta/favicon-light.ico"
  },
  {
    "name": "萝卜工坊 - 快速转换模拟手写字体文档",
    "path": "http://www.beautifulcarrot.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e301cb.png"
  },
  {
    "name": "文字云 - Word Art  Cloud美寄词云",
    "path": "https://www.moage.cn/wordart?union=2",
    "icon": "https://www.moage.cn/favicon.ico"
  },
  {
    "name": "Billfish素材管家 - 创意设计必备素材管理工具",
    "path": "https://www.billfish.cn/",
    "icon": "https://www.billfish.cn/wp-content/themes/wordpress-theme-billfish/assets/images/common/logo.png"
  },
  {
    "name": "千图网 - 设计创意/办公创意/正版商用",
    "path": "http://www.58pic.com",
    "icon": "http://www.58pic.com/favicon.ico"
  },
  {
    "name": "摄图网 - 正版商用设计高清图",
    "path": "http://699pic.com",
    "icon": "http://699pic.com/favicon.ico"
  },
  {
    "name": "大美工 - 设计优选",
    "path": "http://dameigong.cn",
    "icon": "http://dameigong.cn/favicon.ico"
  },
  {
    "name": "美工云 - 专业海外商业设计素材分享平台",
    "path": "http://meigongyun.com",
    "icon": "http://meigongyun.com/favicon.ico"
  },
  {
    "name": "未来mac下载 - mac软件大全",
    "path": "https://mac.orsoon.com/",
    "icon": "https://cdn.mac89.com/orsoon_node/static/orsoon.png"
  },
  {
    "name": "图翼网 - 设计师互动分享平台",
    "path": "http://www.tuyiyi.com",
    "icon": "http://www.tuyiyi.com/favicon.ico"
  },
  {
    "name": "模库网_中国免费设计素材图片库",
    "path": "https://www.mcool.com",
    "icon": "https://www.mcool.com/favicon.ico"
  },
  {
    "name": "我图网 - 正版商用设计作品交易平台",
    "path": "http://www.ooopic.com",
    "icon": "http://www.ooopic.com/favicon.ico"
  },
  {
    "name": "包图网 - 专注原创商用设计图片下载",
    "path": "https://ibaotu.com",
    "icon": "https://ibaotu.com/favicon.ico"
  },
  {
    "name": "昵图网 - 原创素材共享平台",
    "path": "http://www.nipic.com/index.html",
    "icon": "http://www.nipic.com/favicon.ico"
  },
  {
    "name": "图品汇 -  电商设计/电脑小报/PPT/简历",
    "path": "http://www.88tph.com",
    "icon": "http://www.88tph.com/favicon.ico"
  },
  {
    "name": "16素材网 - PSD素材 / 高清图片  / 矢量素材",
    "path": "http://www.16sucai.com",
    "icon": "http://www.16sucai.com/favicon.ico"
  },
  {
    "name": "拍信- 中国领先的创意内容素材平台",
    "path": "https://www.paixin.com",
    "icon": "https://www.paixin.com/favicon.ico"
  },
  {
    "name": "颜格视觉_专注提供海外创意广告设计图片素材",
    "path": "https://www.youngem.com",
    "icon": "https://www.youngem.com/favicon.ico"
  },
  {
    "name": "云瑞设计 -  免费设计素材_设计资源_设计模板",
    "path": "https://www.yrucd.com/category/designresources",
    "icon": "https://www.yrucd.com/favicon.ico"
  },
  {
    "name": "图标、插图、照片、音乐和设计工具",
    "path": "https://photos.icons8.com",
    "icon": "https://icons8.com/vue-static/landings/primary-landings/favs/icons8_fav_32×32.png"
  },
  {
    "name": "免费插图，美丽的免费艺术| 混音器",
    "path": "https://mixkit.co/free-stock-art",
    "icon": "https://mixkit.co/favicon.ico"
  },
  {
    "name": "一套免费的开源插图",
    "path": "https://www.opendoodles.com",
    "icon": "https://assets.website-files.com/5d5d5904f8a21bfe5ff69367/5da4e9a054896ed6d830e869_icon-32w.png"
  },
  {
    "name": "无限的股票视频、音乐、照片和图形",
    "path": "https://elements.envato.com",
    "icon": "https://elements.envato.com/favicon.ico"
  },
  {
    "name": "高图网-免费无版权高清图片下载",
    "path": "http://www.gaoimg.com",
    "icon": "http://www.gaoimg.com/favicon.ico"
  },
  {
    "name": "设计小咖-有格调的设计素材资源站",
    "path": "https://www.iamxk.com",
    "icon": "https://www.iamxk.com/favicon.ico"
  },
  {
    "name": "满足各种需求的视频 - 完全免费",
    "path": "http://coverr.co",
    "icon": "http://coverr.co/favicon.ico"
  },
  {
    "name": "素材公社 -  电商/UI/VI设计/广告设计",
    "path": "http://www.tooopen.com",
    "icon": "http://www.tooopen.com/favicon.ico"
  },
  {
    "name": "68Design - 图库壁纸 | 矢量  | 图标 | PSD",
    "path": "http://sc.68design.net",
    "icon": "http://sc.68design.net/favicon.ico"
  },
  {
    "name": "红动中国 -  原创设计稿/摄影图/接单推广/修改赚钱",
    "path": "http://sucai.redocn.com",
    "icon": "http://sucai.redocn.com/favicon.ico"
  },
  {
    "name": "觅知网 -  PPT模板,PS,矢量图片素材库",
    "path": "https://www.51miz.com",
    "icon": "https://www.51miz.com/favicon.ico"
  },
  {
    "name": "熊猫办公 -  Word/Excel/PPT/AE/MG动画/音效/配乐",
    "path": "http://www.tukuppt.com/ppt/?plan=10013-298-18655",
    "icon": "http://www.tukuppt.com/favicon.ico"
  },
  {
    "name": "二哈办公-音效素材下载-音效大全-配乐",
    "path": "http://www.ohipic.com/sound/0--0-default/p_30",
    "icon": "http://www.ohipic.com/favicon.ico"
  },
  {
    "name": "香当网 -  国内知名文档分享平台/Word/PPT/PDF",
    "path": "https://www.xiangdang.net",
    "icon": "https://static.xiangdang.net/favicon.ico"
  },
  {
    "name": "爱给网 -  音效配乐_3D模型_视频素材_游戏素材",
    "path": "http://www.aigei.com",
    "icon": "http://www.aigei.com/favicon.ico"
  },
  {
    "name": "PixelSquid - 全透视角度素材",
    "path": "https://www.pixelsquid.com",
    "icon": "https://www.pixelsquid.com/favicon.ico"
  },
  {
    "name": "P站 - Pixiv Illustration Collection",
    "path": "https://pixivic.com/?VNK=5b94dd06",
    "icon": "https://static.ehd.name/pc/icon.png"
  },
  {
    "name": "触站 - P站-Pixiv-原创画师分享平台（原画师通）",
    "path": "https://www.huashi6.com/",
    "icon": "https://www.huashi6.com/favicon.ico"
  },
  {
    "name": "Free Stock Video - 高清版税免费视频下载",
    "path": "https://mazwai.com",
    "icon": "https://mazwai.com/assets/images/favicon-32x32.png"
  },
  {
    "name": "Gaiamount - 电影创作者家园",
    "path": "https://gaiamount.com",
    "icon": "https://gaiamount.com/favicon.png"
  },
  {
    "name": "潮点视频 - 权高清视频素材下载网站-正版可商用",
    "path": "https://shipin520.com/shipin/3468.html",
    "icon": "https://shipin520.com/favicon.ico"
  },
  {
    "name": "光厂(VJ师) - 视频素材，我们更专业",
    "path": "https://www.vjshi.com",
    "icon": "https://www.vjshi.com/favicon.ico"
  },
  {
    "name": "Motion Places - 免费库存视频",
    "path": "https://www.motionplaces.com",
    "icon": ""
  },
  {
    "name": "淘声 - toSound声音搜索引擎",
    "path": "https://www.tosound.com",
    "icon": "https://www.tosound.com/favicon.ico"
  },
  {
    "name": "Iconfont - 阿里巴巴矢量图标库",
    "path": "http://www.iconfont.cn/home/index?spm=a313x.7781069.1998910419.2",
    "icon": "https://img.alicdn.com/imgextra/i2/O1CN01ZyAlrn1MwaMhqz36G_!!6000000001499-73-tps-64-64.ico"
  },
  {
    "name": "bootcss自带的Glyphicons图标",
    "path": "https://v3.bootcss.com/components/#glyphicons",
    "icon": "https://v3.bootcss.com/favicon.ico"
  },
  {
    "name": "Feather – 主题羽毛图标",
    "path": "https://feathericons.com",
    "icon": "https://feathericons.com/favicon.ico"
  },
  {
    "name": "DryIcons — 图标和矢量图形",
    "path": "https://dryicons.com",
    "icon": "https://dryicons.com/assets/logo-60ed9738166a3dbdde06f0387d00aefba463a25a5a84ffcbcd9f5042f50bc0e6.svg"
  },
  {
    "name": "千库网 -  免抠/背景/字库/文档/视频音频",
    "path": "http://588ku.com/sucai/tubiao",
    "icon": "http://588ku.com/favicon.ico"
  },
  {
    "name": "PNG图标 - 懒人图库png格式图片下载",
    "path": "http://www.lanrentuku.com/png",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e1f7e3.ico"
  },
  {
    "name": "icons8 - GIF加载SVG生成器",
    "path": "https://icons8.com/preloaders",
    "icon": "https://icons8.com/preloaders/img/favicons/favicon-32x32.png"
  },
  {
    "name": "Logonews - 标志情报局",
    "path": "https://www.logonews.cn",
    "icon": "https://www.logonews.cn/favicon.ico"
  },
  {
    "name": "人工智能为您在线LOGO设计 企业VI",
    "path": "https://www.logosc.cn",
    "icon": "https://www.logosc.cn/favicon-logosc.ico?v=4"
  },
  {
    "name": "标智客 - 免费logo设计生成",
    "path": "http://www.logomaker.com.cn",
    "icon": "http://www.logomaker.com.cn/favicon.ico"
  },
  {
    "name": "U钙网 - 免费快速设计LOGO",
    "path": "http://www.uugai.com",
    "icon": "http://www.uugai.com/favicon.ico"
  },
  {
    "name": "Logo Free - LOGO在线制作",
    "path": "http://www.logofree.cn",
    "icon": "http://www.logofree.cn/uploads/image/20170623/17/favicon.jpg"
  },
  {
    "name": "Logoaa -  分分钟做一个logo",
    "path": "https://www.logoaa.com",
    "icon": "https://www.logoaa.com/favicon.ico"
  },
  {
    "name": "标点狗 -  点一点做LOGO",
    "path": "https://www.logoko.com.cn",
    "icon": "https://www.logoko.com.cn/favicon.ico"
  },
  {
    "name": "Logo Lounge -  大量logo为设计师提供创作灵感",
    "path": "https://www.logolounge.com",
    "icon": "http://www.logofree.cn/uploads/image/20170623/17/favicon.jpg"
  },
  {
    "name": "logopond -  全球最大的logo资源库",
    "path": "https://logopond.com",
    "icon": "https://logopond.com/favicon.ico"
  },
  {
    "name": "为您的获取徽标的最智能、最快捷的方式",
    "path": "https://www.squadhelp.com/logos",
    "icon": "https://www.squadhelp.com/favicon.ico"
  },
  {
    "name": "用于网页设计的 PNG 图像和剪贴画",
    "path": "https://pngimg.com",
    "icon": "https://pngimg.com/favicon.ico"
  },
  {
    "name": "特色图标 - 68Design |  素材•欣赏",
    "path": "http://sc.68design.net/tb",
    "icon": "http://sc.68design.net/favicon.ico"
  },
  {
    "name": "站长素材PNG图标 -  icon/png图标下载大全",
    "path": "http://sc.chinaz.com/tubiao",
    "icon": "http://sc.chinaz.com/favicon.ico"
  },
  {
    "name": "UE设计图标 -  提供国外资源源文件下载分享",
    "path": "http://dl.uedna.com/tag/图标",
    "icon": "https://www.uemo.net/favicon.ico"
  },
  {
    "name": "觅元素 - PNG免抠元素/图标/背景",
    "path": "http://www.51yuansu.com/?z=rersvhmgj",
    "icon": "http://www.51yuansu.com/favicon.ico"
  },
  {
    "name": "▩ Vector Magic - 位图转矢量",
    "path": "https://zh.vectormagic.com",
    "icon": "https://d2f7anuvnar8n5.cloudfront.net/p/assets/m/drawings/vector-magic-favicon-16_0ecdb1e703ec78d5817712c998c3584b.png"
  },
  {
    "name": "聚折良品-汇聚全网折扣精品&分享平台",
    "path": "http://www.ju77.com/i/77-3-53.htm",
    "icon": "http://ju77.qingting365.cn/img/icon.ico"
  },
  {
    "name": "贝省 - 我的省钱助手",
    "path": "https://www.beisheng.com",
    "icon": "https://h0.beicdn.com/open201946/38e7f128ff4e3e19_40x40.png"
  },
  {
    "name": "易购网-商城返利/超级红包/签到赚钱",
    "path": "http://www.egou.com",
    "icon": ""
  },
  {
    "name": "返还网-网购省钱助手/搜淘宝券",
    "path": "http://www.fanhuan.com",
    "icon": "http://www.fanhuan.com/favicon.ico"
  },
  {
    "name": "宝贝网-免费购物试用平台",
    "path": "http://www.baobeio.com",
    "icon": "http://www.baobeio.com/favicon.ico"
  },
  {
    "name": "众划算-一个省钱购物的平台",
    "path": "http://www.zhonghuasuan.com",
    "icon": "http://www.zhonghuasuan.com/favicon.ico"
  },
  {
    "name": "领有惠（每天惠）-O2O活动平台 [  手机APP ]",
    "path": "https://jinshuju.net/f/q7D4N7",
    "icon": "https://jinshuju.net/favicon.ico"
  },
  {
    "name": "淘客喵-发群助手/查券/分佣",
    "path": "http://www.taokemiao.com",
    "icon": "http://static.taokemiao.com/static/images/logo/favicon.ico"
  },
  {
    "name": "淘客助手-整合全网数据 打造淘客生态",
    "path": "http://www.taokezhushou.com",
    "icon": "http://www.taokezhushou.com/favicon.ico"
  },
  {
    "name": "大淘客-打造导购内容生态",
    "path": "http://www.dataoke.com",
    "icon": "http://www.dataoke.com/favicon.ico"
  },
  {
    "name": "互力微信淘客系统-智能化微信淘客盈利系统",
    "path": "http://www.fqurl.cn",
    "icon": "http://www.fqurl.cn/favicon.ico"
  },
  {
    "name": "素材情报监测、创意灵感搜索产品",
    "path": "https://cem.marketingdesk.cn/lp/page27/?platform_source=3&page_id=1111&bd_vid=11625828099449893436",
    "icon": "https://cem.marketingdesk.cn/favicon.ico"
  },
  {
    "name": "36氪研究院  - 专注新经济领域研究",
    "path": "https://36kr.com/academe.html",
    "icon": "https://36kr.com/favicon.ico"
  },
  {
    "name": "广告人的网址导航",
    "path": "https://www.addog.vip",
    "icon": "https://www.addog.vip/favicon.ico"
  },
  {
    "name": "人人都是产品经理",
    "path": "http://www.woshipm.com",
    "icon": "http://www.woshipm.com/favicon.ico"
  },
  {
    "name": "知识星球-运营高品质社群，知识变现的工具",
    "path": "https://www.zsxq.com",
    "icon": "https://www.zsxq.com/favicon.ico"
  },
  {
    "name": "好奇心日报 - 好奇驱动你的世界",
    "path": "http://www.qdaily.com",
    "icon": "http://www.qdaily.com/favicon.ico"
  },
  {
    "name": "PITCHINA - 顶尖创意人社区",
    "path": "http://www.pitchina.com.cn",
    "icon": "http://www.pitchina.com.cn/Public/Home/images/icon.ico"
  },
  {
    "name": "SocialBeta -  社交媒体和数字营销平台",
    "path": "https://socialbeta.com",
    "icon": "https://socialbeta.com/favicon.ico"
  },
  {
    "name": "梅花网-营销作品宝库",
    "path": "https://www.meihua.info",
    "icon": "https://www.meihua.info/static/images/icon/meihua.ico"
  },
  {
    "name": "我是文案 - 文案與文字工作者心得分享",
    "path": "http://copywrite-tw.com",
    "icon": "http://copywrite-tw.com/wp-content/uploads/2019/12/cropped-我是文案-32x32.jpg"
  },
  {
    "name": "TOPYS | 全球顶尖创意分享平台",
    "path": "https://www.topys.cn",
    "icon": "https://img-sz.topys.cn/ico/favicon.ico"
  },
  {
    "name": "数英网-数字媒体及职业招聘网站",
    "path": "https://www.digitaling.com",
    "icon": "https://www.digitaling.com/favicon.ico"
  },
  {
    "name": "文案狗 | 给文案狗一点灵感!",
    "path": "http://www.wenangou.com",
    "icon": "http://www.wenangou.com/favicon.ico"
  },
  {
    "name": "4A广告提案网",
    "path": "http://www.4aquan.com",
    "icon": ""
  },
  {
    "name": "广告门 | 一个行业的跌宕起伏",
    "path": "http://www.adquan.com",
    "icon": "http://www.adquan.com/favicon.ico"
  },
  {
    "name": "鸟哥笔记 - 做运营推广 上鸟哥笔记",
    "path": "http://www.niaogebiji.com",
    "icon": "http://www.niaogebiji.com/favicon.ico"
  },
  {
    "name": "暖石网 - 从零开始学运营",
    "path": "https://www.nuanshi100.com",
    "icon": "https://nuanshi-static.oss-cn-beijing.aliyuncs.com/naunshi.ico/favicon.ico"
  },
  {
    "name": "网络广告人社区 -  国内外创意广告和营销案例",
    "path": "https://iwebad.com",
    "icon": "https://iwebad.com/favicon.ico"
  },
  {
    "name": "运营派 - 运营、市场、营销、文案学习平台",
    "path": "https://www.yunyingpai.com",
    "icon": "https://image.yunyingpai.com/wp/2022/01/RmyhegeskCWnJ5VHeTlU.jpg"
  },
  {
    "name": "运营学堂-专业的互联网运营、新媒体运营",
    "path": "http://www.yunyingxuetang.com",
    "icon": "http://www.yunyingxuetang.com/images/favicon.ico"
  },
  {
    "name": "今日热榜官网",
    "path": "https://tophub.today",
    "icon": "https://tophub.today/favicon.ico"
  },
  {
    "name": "姑婆那些事儿 -  互联网推广运营知识分享平台",
    "path": "http://www.gupowang.com",
    "icon": "http://www.gupowang.com/favicon.ico"
  },
  {
    "name": "押韵助手 - 在线查询押韵的字、词、诗、歌",
    "path": "https://yayun.la/",
    "icon": "https://yayun.la/favicon.ico"
  },
  {
    "name": "小发猫AI智能写作v1.1.8",
    "path": "http://www.xiaofamao.com",
    "icon": "http://www.xiaofamao.com/favicon.ico"
  },
  {
    "name": "押韵助手  - 在线查询押韵的字、词、诗、歌",
    "path": "https://yayun.la",
    "icon": "https://yayun.la/favicon.ico"
  },
  {
    "name": "畅无线书屋 - 畅无线书屋小说阅读网",
    "path": "https://ku30000.com",
    "icon": "https://changwifi.net/favicon.ico"
  },
  {
    "name": "腾讯问卷 - 免费好用的问卷调查系统",
    "path": "https://wj.qq.com",
    "icon": "https://wj.qq.com/favicon.ico"
  },
  {
    "name": "巧匠课堂 -  PS/AI/3Dmax/AE/PR…",
    "path": "https://www.qiaojiang.tv",
    "icon": "https://www.qiaojiang.tv/favicon.ico"
  },
  {
    "name": "虎课网 - 零基础入门学习40+软件",
    "path": "https://huke88.com",
    "icon": "https://huke88.com/favicon.ico"
  },
  {
    "name": "视达 - 电商设计/视觉营销/运营",
    "path": "http://shida66.com",
    "icon": "http://shida66.com/favicon.ico"
  },
  {
    "name": "翼狐网 - 零基础从入门到精通60+",
    "path": "https://www.yiihuu.com",
    "icon": "https://www.yiihuu.com/favicon.ico"
  },
  {
    "name": "羽兔网 - 年轻人都在用的自学设计平台",
    "path": "https://www.yutu.cn",
    "icon": "https://www.yutu.cn/favicon.ico"
  },
  {
    "name": "ps教程自学网 -  滤镜教程/调色教程/合成教程…",
    "path": "http://www.16xx8.com",
    "icon": "http://www.16xx8.com/favicon.ico"
  },
  {
    "name": "我要自学网 -  平面/影视动画/程序开发/机械设计",
    "path": "http://www.51zxw.net/default.aspx",
    "icon": "http://www.51zxw.net/favicon.ico"
  },
  {
    "name": "学汇网 - 软件入门/系统课程/批改/直播",
    "path": "http://www.xuehui.com",
    "icon": "http://static.xuehui.com/www/pc/images/icon16.ico"
  },
  {
    "name": "51CTO学院 - 专业的IT技能学习平台",
    "path": "http://edu.51cto.com",
    "icon": "http://edu.51cto.com/favicon.ico"
  },
  {
    "name": "平面设计学习日记网 - 职业路径/学习日记",
    "path": "http://www.xxriji.cn",
    "icon": "http://www.xxriji.cn/favicon.ico"
  },
  {
    "name": "直线网 - 高品质数字艺术学习平台",
    "path": "http://www.linecg.com",
    "icon": "https://www.linecg.com/favicon.ico"
  },
  {
    "name": "优优教程网 -  软件/教程/设计师/自动标注/灵感",
    "path": "https://uiiiuiii.com",
    "icon": "https://uiiiuiii.com/favicon.ico"
  },
  {
    "name": "CAD自学网 - CAD视频教程/软件下载",
    "path": "http://www.cadzxw.com",
    "icon": "http://www.cadzxw.com/favicon.ico"
  },
  {
    "name": "思缘教程 -  设计/摄影/前端//设计欣赏/问题求助",
    "path": "http://www.missyuan.net",
    "icon": "http://www.missyuan.net/favicon.ico"
  },
  {
    "name": "猫课 - 微电商/淘宝京东无货源开店",
    "path": "https://www.maoke123.com",
    "icon": "https://www.maoke123.com/favicon.ico"
  },
  {
    "name": "网易公开课 - 国际名校..  中国大学视频公开课",
    "path": "https://open.163.com",
    "icon": "https://open.163.com/favicon.ico"
  },
  {
    "name": "网易云课堂 - 在线实用技能学习平台",
    "path": "http://study.163.com",
    "icon": "http://study.163.com/favicon.ico"
  },
  {
    "name": "风变编程 - 用有趣好玩的方式 学编程",
    "path": "https://www.pypypy.cn",
    "icon": "https://www.pypypy.cn/favicon.ico"
  },
  {
    "name": "python - 英文官方版",
    "path": "https://www.python.org",
    "icon": "https://www.python.org/favicon.ico"
  },
  {
    "name": "Django 文档 - 关于 Django  的一切",
    "path": "https://docs.djangoproject.com/zh-hans/2.1",
    "icon": "https://docs.djangoproject.com/favicon.ico"
  },
  {
    "name": "慕课 - 中国大学MOOC 国家精品课程",
    "path": "https://www.icourse163.org",
    "icon": "https://edu-image.nosdn.127.net/32a8dd2a-b9aa-4ec9-abd5-66cd8751befb.png?imageView&quality=100"
  },
  {
    "name": "菜鸟C4D - 与你一起C4D从零开始！",
    "path": "http://www.c4dcn.com",
    "icon": "http://www.c4dcn.com/favicon.ico"
  },
  {
    "name": "ExcelHome云课堂 -  专业的职场技能充电站",
    "path": "http://ke.wooffice.net",
    "icon": "http://ke.wooffice.net/favicon.ico"
  },
  {
    "name": "方方格子 - 专注Excel学习交流  资源下载",
    "path": "http://ffcell.com/index.aspx",
    "icon": "http://ffcell.com/images/f21.png"
  },
  {
    "name": "站酷高高手 - 艺术设计在线教育平台",
    "path": "https://www.gogoup.com",
    "icon": "https://static.gogoup.com/new_static/img/favicon.ico?v=235"
  },
  {
    "name": "蓝桥云课 - 连接高校和企业",
    "path": "https://www.lanqiao.cn/",
    "icon": "https://www.lanqiao.cn/favicon.ico"
  },
  {
    "name": "玺承云学堂 - 电商在线教育品牌",
    "path": "https://yxt.xcect.com/",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f848a4.jpg"
  },
  {
    "name": "Finger - 你的口袋乐器教师",
    "path": "https://www.finger66.com/web",
    "icon": "https://www.finger66.com/favicon.ico"
  },
  {
    "name": "flowkey - 最创新的学琴方式",
    "path": "https://app.flowkey.com",
    "icon": "https://app.flowkey.com/favicon.ico"
  },
  {
    "name": "编织人生 - 权威手工编织网站",
    "path": "http://www.bianzhirensheng.com",
    "icon": "http://www.bianzhirensheng.com/favicon.ico"
  },
  {
    "name": "日日煮 DayDayCook -  发现生活的味道",
    "path": "http://www.daydaycook.com.cn/daydaycook",
    "icon": "http://www.daydaycook.com.cn/favicon.ico"
  },
  {
    "name": "下厨房 - 唯有美食与爱不可辜负",
    "path": "http://www.xiachufang.com",
    "icon": "http://www.xiachufang.com/favicon.ico"
  },
  {
    "name": "美食杰 - 美食|菜谱大全|食谱|美食网",
    "path": "https://www.meishij.net",
    "icon": "https://www.meishij.net/favicon.ico"
  },
  {
    "name": "追梦设计-淘宝/天猫装修助手",
    "path": "http://www.bangox.com/plugin.php?id=doiog_qbmb:qbmbx74",
    "icon": "http://www.bangox.com/favicon.ico"
  },
  {
    "name": "疯狂的美工-电商设计师服务平台",
    "path": "https://www.fkdmg.com",
    "icon": "https://www.fkdmg.com/favicon.ico"
  },
  {
    "name": "稿定设计-淘宝天猫/京东/阿里[免费生成]",
    "path": "http://www.001daima.com/index.php?get=active&getmo",
    "icon": "http://www.001daima.com/favicon.ico"
  },
  {
    "name": "EasyCSS网页版-网店高级装修工具",
    "path": "http://easycss.design61.cn",
    "icon": "http://file.alicdm.com/img/taomage.ico"
  },
  {
    "name": "小语言淘宝天猫版-其他店铺装修工具5款",
    "path": "http://zxtb.net/temp/index.php",
    "icon": "http://www.zxtb.net/favicon.ico"
  },
  {
    "name": "醉语言编辑器-淘宝DIY在线布局工具",
    "path": "http://www.ecplay.com/z1_8.html",
    "icon": "http://www.ecplay.com/favicon.ico"
  },
  {
    "name": "荣青设计-模板创意装修辅助工具",
    "path": "http://www.47rq.com/tool/index.php",
    "icon": "http://www.47rq.com/favicon.ico"
  },
  {
    "name": "350店铺模板-智能装修模块 告别代码",
    "path": "http://login.350.net/?redirect_url=http://u.350.net/",
    "icon": "http://login.350.net/favicon.ico"
  },
  {
    "name": " HTML编辑器（淘宝） ",
    "path": "http://www.bangox.com/plugin.php?id=doiog_qr10:qr11",
    "icon": "http://www.bangox.com/favicon.ico"
  },
  {
    "name": " 淘宝开放平台Widget ",
    "path": "http://open.taobao.com/docs/doc.htm?articleId=102541&docType=1&spm=a219a.7386797.0.0.V2QGy3&treeId=10",
    "icon": "https://img.alicdn.com/favicon.ico"
  },
  {
    "name": "奇迹秀—因设计·而美丽",
    "path": "http://www.qijishow.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e6a924.ico"
  },
  {
    "name": "站酷 - 中国人气设计师互动平台",
    "path": "https://www.zcool.com.cn",
    "icon": "https://static.zcool.cn/git_z/z/site/favicon.ico?version=1644228114726"
  },
  {
    "name": "花瓣 - 花瓣•陪你做生活的设计师",
    "path": "http://huaban.com",
    "icon": "http://huaban.com/favicon.ico"
  },
  {
    "name": "大作 - 全球顶级100✚ 设计网站",
    "path": "http://www.bigbigwork.com",
    "icon": "http://www.bigbigwork.com/favicon.ico"
  },
  {
    "name": "致设计 - 电商设计师交流平台",
    "path": "http://www.zhisheji.com",
    "icon": "http://www.zhisheji.com/favicon.ico"
  },
  {
    "name": "UICN用户体验设计平台",
    "path": "https://www.ui.cn",
    "icon": "https://www.ui.cn/favicon.ico"
  },
  {
    "name": "古田路9号-品牌创意平台",
    "path": "http://www.gtn9.com",
    "icon": "http://www.gtn9.com/favicon.ico"
  },
  {
    "name": "优设网 - 设计师交流学习平台",
    "path": "https://www.uisdc.com",
    "icon": "https://www.uisdc.com/favicon.ico"
  },
  {
    "name": "HTML素材网-陪你做好前端设计！",
    "path": "http://www.htmlsucai.com",
    "icon": "http://www.htmlsucai.com/favicon.ico"
  },
  {
    "name": "原创馆（腾讯）-  创意素材征集/优质作品变现",
    "path": "https://ycg.qq.com",
    "icon": "https://ycg.qq.com/favicon.ico"
  },
  {
    "name": "视觉•me -  设计师&插画师交流平台",
    "path": "http://shijue.me",
    "icon": "http://shijue.me/favicon.ico"
  },
  {
    "name": "视觉中国 -  创意图片/创意视频/设计/音乐",
    "path": "https://www.vcg.com",
    "icon": "https://www.vcg.com/favicon.ico"
  },
  {
    "name": "美叶 - 为设计师提供有价值的设计参考",
    "path": "https://www.meiye.art",
    "icon": "https://www.meiye.art/favicon.ico"
  },
  {
    "name": "图形海报 - 来自 44 个不同国家的",
    "path": "https://www.typographicposters.com",
    "icon": "https://www.typographicposters.com/favicon.ico"
  },
  {
    "name": "4DB - 日本网页设计聚合网站",
    "path": "http://4db.cc",
    "icon": "http://4db.cc/common/4db.ico"
  },
  {
    "name": "AD518 - 最设计",
    "path": "https://ad518.com",
    "icon": "https://ad518.com/favicon.ico"
  },
  {
    "name": "网站大奖 - 最佳网页设计趋势",
    "path": "https://www.awwwards.com/?ref=iamxk",
    "icon": "https://www.awwwards.com/favicon.ico"
  },
  {
    "name": "国外设计欣赏网站 - DOOOOR",
    "path": "https://www.doooor.com",
    "icon": "https://www.doooor.com/favicon.ico"
  },
  {
    "name": "收集 UI -  从每日ui档案及其他地方收集的灵感",
    "path": "http://collectui.com",
    "icon": "https://collectui.com/img/favicon.ico"
  },
  {
    "name": "Reeoo - 网页设计灵感和网站画廊",
    "path": "https://reeoo.com",
    "icon": "https://reeoo.com/favicon.ico"
  },
  {
    "name": "爱果果 - 酷站  H5   UI  网页模板  素材   案例",
    "path": "https://www.iguoguo.net",
    "icon": "https://www.iguoguo.net/favicon.ico"
  },
  {
    "name": "最佳网页设计奖 - 网页设计灵感",
    "path": "http://www.webdesignfile.com",
    "icon": "http://www.webdesignfile.com/favicon.ico"
  },
  {
    "name": "世界广告™  - Clio 网络的一部分",
    "path": "http://www.adsoftheworld.com",
    "icon": "https://image.adsoftheworld.com/static/favicon.ico"
  },
  {
    "name": "Carpenter  Collective  设计+品牌工作室",
    "path": "http://carpentercollective.com",
    "icon": "http://carpentercollective.com/favicon.ico"
  },
  {
    "name": "用户体验法则",
    "path": "https://lawsofux.com",
    "icon": "https://lawsofux.com/icons/favicon.ico"
  },
  {
    "name": "LETTERS   INC.",
    "path": "https://www.letters-inc.jp",
    "icon": "https://www.letters-inc.jp/favicon.ico"
  },
  {
    "name": "illust-AC -  免費插圖向量圖素材下載",
    "path": "https://zh-tw.ac-illust.com",
    "icon": "https://www.ac-illust.com/favicon.ico"
  },
  {
    "name": "最佳着陆页示例学习设计  书籍和UI 套件",
    "path": "https://www.lapa.ninja",
    "icon": "https://www.lapa.ninja/favicon.ico"
  },
  {
    "name": "노트폴리오 :: 크리에이티브  네트워크",
    "path": "https://notefolio.net",
    "icon": "https://notefolio.net/favicon.ico"
  },
  {
    "name": "图虫网 - 优质摄影师交流社区",
    "path": "https://tuchong.com",
    "icon": "https://tuchong.com/favicon.ico"
  },
  {
    "name": "500PX -  出色的摄影师社区/500px",
    "path": "https://500px.me",
    "icon": "https://500px.me/favicon.ico"
  },
  {
    "name": "天空之城 -  全球航拍&专业摄影师社交平台",
    "path": "https://www.skypixel.com",
    "icon": "https://spcn-webfront.skypixel.com/skypixel/public/favicon.ico"
  },
  {
    "name": "poco摄影网 - 中国领先时尚摄影平台",
    "path": "https://www.poco.cn/x/works/list",
    "icon": "https://www.poco.cn/favicon.ico"
  },
  {
    "name": "Pixabay - 免费正版高清图片素材库",
    "path": "https://pixabay.com",
    "icon": "https://pixabay.com/favicon.ico"
  },
  {
    "name": "Pexels - 免费摄影图库 Free",
    "path": "https://www.pexels.com",
    "icon": "https://www.pexels.com/favicon.ico"
  },
  {
    "name": "蜂鸟在线 -  摄影爱好者分享技巧&作品",
    "path": "https://photo.fengniao.com/f_101.html",
    "icon": "https://photo.fengniao.com/favicon.ico"
  },
  {
    "name": "站酷海洛 - 正版图片/视频/音乐/字体",
    "path": "https://www.hellorf.com",
    "icon": "https://static.hellorf.com/v180629120952/favicon.ico"
  },
  {
    "name": "中国图库 -  拍遍中国/拍遍美食/征图/维权",
    "path": "http://www.tukuchina.cn",
    "icon": "http://www.tukuchina.cn/favicon.ico"
  },
  {
    "name": "Nicepik - 基于AI的数百万张图库  Free",
    "path": "https://www.pikrepo.com",
    "icon": "https://www.pikrepo.com/public/css/favicon.ico"
  },
  {
    "name": "StockSnap -美丽的免费库存照片  Free",
    "path": "https://stocksnap.io",
    "icon": "https://stocksnap.io/img/favicon.ico"
  },
  {
    "name": "Coverr - 免费网页背景影片  Free",
    "path": "http://www.coverr.co",
    "icon": "http://www.coverr.co/favicon.ico"
  },
  {
    "name": "美丽而独特免费库存照片",
    "path": "https://www.splitshire.com",
    "icon": "https://www.splitshire.com/favicon.ico"
  },
  {
    "name": "免费使用的图片 - 由各地的创作者提供支持",
    "path": "https://unsplash.com",
    "icon": "https://unsplash.com/favicon.ico"
  },
  {
    "name": "公共领域照片 - 个人和专业项目的创意摄影",
    "path": "http://skuawk.com",
    "icon": "http://skuawk.com/favicon.ico"
  },
  {
    "name": "免费的库存照片、背景和免费的高分辨率图像",
    "path": "https://picjumbo.com",
    "icon": "https://picjumbo.com/favicon.ico"
  },
  {
    "name": "Kaboompics - 免费库存照片 +  调色板",
    "path": "https://kaboompics.com",
    "icon": "https://kaboompics.com/favicon.ico"
  },
  {
    "name": "真正独一无二、奇思妙想 - 永远免费",
    "path": "https://gratisography.com",
    "icon": "https://gratisography.com/wp-content/themes/gratis-v3/favicon.png"
  },
  {
    "name": "纸杯蛋糕 - 免版税图片用于商业用途",
    "path": "http://cupcake.nilssonlee.se",
    "icon": "http://cupcake.nilssonlee.se/favicon.ico"
  },
  {
    "name": "Streetwill - 免费高分辨率照片",
    "path": "http://streetwill.co",
    "icon": "http://streetwill.co/favicon64.png?v=2"
  },
  {
    "name": "由 Oliur Ra​​hman  提供的免费素材照片",
    "path": "http://photos.oliur.com",
    "icon": "https://www.oliur.com/wp-content/uploads/2020/04/cropped-oliur-favicon-32x32.png"
  },
  {
    "name": "免费高分辨率摄影",
    "path": "https://www.lifeofpix.com",
    "icon": "https://www.lifeofpix.com/favicon.ico"
  },
  {
    "name": "DesignersPics -  商业和个人作品的免费照片",
    "path": "http://www.designerspics.com",
    "icon": "http://www.designerspics.com/favicon.ico"
  },
  {
    "name": "好看的免费素材照片",
    "path": "https://stocksnap.io",
    "icon": "https://stocksnap.io/img/favicon.ico"
  },
  {
    "name": "免费图片 - 商业用途的高分辨率图像",
    "path": "https://burst.shopify.com",
    "icon": "https://cdn.shopifycloud.com/stock_photos/assets/global/favicon-ab7018e1fe708a49edcfecce3166032fbeeb1fd7ba4a078c366de344d32ee193.png"
  },
  {
    "name": "Stock - 免费图片、图像和矢量图",
    "path": "https://www.stockvault.net",
    "icon": "https://www.stockvault.net/favicon.ico"
  },
  {
    "name": "Wallhaven.cc - 高清壁纸",
    "path": "https://wallhaven.cc",
    "icon": "https://wallhaven.cc/favicon.ico"
  },
  {
    "name": "高清壁纸：下载高清壁纸（1080p至4K）",
    "path": "https://www.hdwallpapers.net",
    "icon": "https://www.hdwallpapers.net/favicon.ico"
  },
  {
    "name": "iStock - 热门免费食物照片",
    "path": "https://www.foodiesfeed.com",
    "icon": "https://www.foodiesfeed.com/wp-content/uploads/2022/11/cropped-android-chrome-512x512-1-32x32.png"
  },
  {
    "name": "FOTOMEN - – Photo  your life！",
    "path": "https://fotomen.cn",
    "icon": "https://cdn.fotomen.cn/./2022/01/nikonbaobbiao003-90x90.png?_upt=b7711d171644393942"
  },
  {
    "name": "摄影笔记 - 每天一条摄影新知",
    "path": "http://www.sybj.com",
    "icon": "http://www.sybj.com/favicon.ico"
  },
  {
    "name": "中华珍宝馆 - 当代馆 | 历代馆 |  精选馆",
    "path": "http://www.ltfc.net",
    "icon": "http://www.ltfc.net/favicon.ico"
  },
  {
    "name": "ArchDaily | 传播世界建筑",
    "path": "https://www.archdaily.cn/cn",
    "icon": "https://www.archdaily.cn/favicon.ico"
  },
  {
    "name": "免费提供一些高质量的基督教图片库",
    "path": "http://beta.freelyphotos.com",
    "icon": "http://beta.freelyphotos.com/favicon.ico"
  },
  {
    "name": "黑光图库_人像摄影作品展示平台",
    "path": "https://tu.heiguang.com",
    "icon": "https://tu.heiguang.com/favicon.ico"
  },
  {
    "name": "WChictopia -  穿搭造型的灵感来源",
    "path": "http://www.chictopia.com",
    "icon": "http://www.chictopia.com/favicon.ico"
  },
  {
    "name": "百度指数 - 百度数据趋势研究",
    "path": "http://index.baidu.com/v2/index.html",
    "icon": "http://index.baidu.com/favicon.ico"
  },
  {
    "name": "微热点 - 微博数据分析和微博热点",
    "path": "http://www.wrd.cn",
    "icon": "http://www.wrd.cn/favicon.ico"
  },
  {
    "name": "清博 -  融媒体、舆论和产业营销大数据服务者",
    "path": "http://home.gsdata.cn",
    "icon": "http://home.gsdata.cn/images/logo.ico"
  },
  {
    "name": "知微事见 - 最全的互联网社会热点聚合平台",
    "path": "http://ef.zhiweidata.com",
    "icon": "http://ef.zhiweidata.com/favicon.ico"
  },
  {
    "name": "爱奇艺指数 - 监控热点趋势",
    "path": "http://index.iqiyi.com",
    "icon": "https://www.iqiyi.com/favicon.ico"
  },
  {
    "name": "头条指数 - 热点事件分析和行业报告下载",
    "path": "https://index.toutiao.com",
    "icon": "https://lf3-static.bytednsdoc.com/obj/eden-cn/kyhgpdeh7nuvanuhd/count/trendinsight.ico"
  },
  {
    "name": "阿拉丁指数 - 全网小程序观察与连接平台",
    "path": "http://www.aldzs.com",
    "icon": "http://www.aldzs.com/favicon.ico"
  },
  {
    "name": "百度风云榜 - 百度搜索风云排行榜",
    "path": "http://top.baidu.com",
    "icon": "https://www.baidu.com/favicon.ico"
  },
  {
    "name": "网易新闻 - 网易新闻热度排行榜",
    "path": "http://news.163.com/rank",
    "icon": "http://news.163.com/favicon.ico"
  },
  {
    "name": "新榜 - 内容产业服务平台",
    "path": "https://www.newrank.cn",
    "icon": "https://chs.newrank.cn/favicon/favicon.ico"
  },
  {
    "name": "360趋势 -  基于360搜索数据关键词趋势",
    "path": "https://trends.so.com",
    "icon": "https://trends.so.com/favicon.ico"
  },
  {
    "name": "寻艺 - 明星排行榜",
    "path": "http://www.xunyee.cn/rank.html",
    "icon": "http://www.xunyee.cn/favicon.ico"
  },
  {
    "name": "AIPage - 百度智能建站",
    "path": "https://aipage.bce.baidu.com",
    "icon": "https://aipage.bce.baidu.com/favicon.ico"
  },
  {
    "name": "UEMO - 魔艺极速建站",
    "path": "http://www.uemo.net",
    "icon": "http://www.uemo.net/favicon.ico"
  },
  {
    "name": "轻站 - 轻站无代码,零代码建站平台",
    "path": "https://www.qingzhan.com",
    "icon": "https://www.qingzhan.com/favicon.ico"
  },
  {
    "name": "Zion无代码开发平台 - 让微信小程序定制开发更轻松",
    "path": "https://www.functorz.com/",
    "icon": "https://www.functorz.com/favicon.ico"
  },
  {
    "name": "MetInfo - 米拓建站CMS系统",
    "path": "http://www.metinfo.cn",
    "icon": "http://www.metinfo.cn/favicon.ico"
  },
  {
    "name": "意派Coolsite",
    "path": "https://www.coolsite360.com",
    "icon": "https://o3bnyc.creatby.com/admin-coolsite360/favicon.ico"
  },
  {
    "name": "上线了 - 创建网站和小程序",
    "path": "https://www.sxl.cn",
    "icon": "https://www.sxl.cn/favicon.ico"
  },
  {
    "name": "建站小二 -  小程序/公众号/建网站/IWS建站",
    "path": "https://www.jzx2.com",
    "icon": "https://www.jzx2.com/favicon.ico"
  },
  {
    "name": "SiteStar建站之星 -  PC/手机/微信网站/小程序/APP",
    "path": "http://www.sitestar.cn",
    "icon": "http://www.sitestar.cn/favicon.ico"
  },
  {
    "name": "凡科建站 - PC/手机/微信三合一",
    "path": "http://jz.faisco.com",
    "icon": "http://jz.faisco.com/favicon.ico"
  },
  {
    "name": "宝站美 - 企业建站 无需技术 识字即可  自助建站",
    "path": "https://www.baozhanmei.com",
    "icon": "https://www.baozhanmei.com/favicon.ico"
  },
  {
    "name": "起飞页 - 自助建站 | 响应式网站 |  H5网站模板",
    "path": "https://www.qifeiye.com",
    "icon": "https://www.qifeiye.com/qfy-content/uploads/2015/02/0fc92860801c7d70b470ebd24aec4634.png"
  },
  {
    "name": "建站ABC - 建站免费、快速、灵活",
    "path": "https://www.ev123.net",
    "icon": "https://www.ev123.net/favicon.ico"
  },
  {
    "name": "卓老师建站 - 免费快速智能自助建站",
    "path": "http://www.zhuolaoshi.com",
    "icon": "http://www.zhuolaoshi.com/favicon.ico"
  },
  {
    "name": "Strikingly - 免费建站工具",
    "path": "https://cn.strikingly.com",
    "icon": "https://cn.strikingly.com/favicon.ico"
  },
  {
    "name": "PageAdmin - 自助建站系统",
    "path": "http://www.pageadmin.net",
    "icon": "http://www.pageadmin.net/favicon.ico"
  },
  {
    "name": "Bootstrap -  简洁、直观、强悍的前端开发框架",
    "path": "https://www.bootcss.com",
    "icon": "https://fastly.jsdelivr.net/npm/@bootcss/www.bootcss.com@0.0.52/dist/ico/apple-touch-icon-precomposed.png"
  },
  {
    "name": "主题盒子 -  wordpress企业博客主题",
    "path": "https://www.wpzt.net",
    "icon": "https://www.wpzt.net/wp-content/themes/t-box/static/images/favicon.ico"
  },
  {
    "name": "创建专业网站的绝佳平台",
    "path": "https://zh.wix.com",
    "icon": "https://zh.wix.com/favicon.ico"
  },
  {
    "name": "我要表白网-最浪漫的表白网页在线生成",
    "path": "http://www.51bbw.cn/index.html",
    "icon": "http://www.51bbw.cn/static/home/images/favicon.ico"
  },
  {
    "name": "一个最畅销的 WordPress 主题",
    "path": "http://stockholmlanding.select-themes.com",
    "icon": "https://stockholmlanding.qodeinteractive.com/wp-content/uploads/2020/10/Favicon.png"
  },
  {
    "name": "创客云-专注WordPress主题资源分享",
    "path": "https://www.22vd.com",
    "icon": "https://www.22vd.com/favicon.ico"
  },
  {
    "name": "人人秀 - 制作微信h5微场景平台",
    "path": "https://www.rrxiu.net",
    "icon": "https://www.rrxiu.net/favicon.ico"
  },
  {
    "name": "易企秀 -  邀请函模板|创意营销平台H5页面",
    "path": "http://www.eqxiu.com",
    "icon": "http://www.eqxiu.com/favicon.ico"
  },
  {
    "name": "秀米 - XIUMI",
    "path": "https://xiumi.us",
    "icon": "https://xiumi.us/favicon.ico"
  },
  {
    "name": "初页 - 最潮的音乐照片情感故事记录",
    "path": "http://chuye.cloud7.com.cn",
    "icon": "http://chuye.cloud7.com.cn/favicon.ico"
  },
  {
    "name": "百度H5 - 移动端H5页面快速制作工具",
    "path": "http://h5.baidu.com",
    "icon": "http://h5.baidu.com/favicon.ico"
  },
  {
    "name": "iH5 - 专业的H5制作工具",
    "path": "https://www.ih5.cn/not-logged-in",
    "icon": "https://cjj.zone/xxx/ico/i5.png"
  },
  {
    "name": " 17素材 - jQuery网页特效 ",
    "path": "http://www.17sucai.com",
    "icon": "http://www.17sucai.com/favicon.ico"
  },
  {
    "name": "Bootstrap模板_响应式网站模板",
    "path": "http://www.bootstrapmb.com",
    "icon": "http://www.bootstrapmb.com/content/images/fav.png"
  },
  {
    "name": "51前端js特效模板下载平台",
    "path": "https://www.51qianduan.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbfedd7f005.ico"
  },
  {
    "name": "jQueryfuns -  html响应式模板 jquery特效",
    "path": "http://www.jqueryfuns.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": " jQuery插件库 ",
    "path": "http://www.jq22.com",
    "icon": "http://www.jq22.com/favicon.ico"
  },
  {
    "name": " Explore Sandboxes - 代码沙盒 ",
    "path": "https://codesandbox.io/explore",
    "icon": "https://codesandbox.io/favicon.ico"
  },
  {
    "name": " 前端笔记-井井客 ",
    "path": "http://www.jingjingke.com",
    "icon": "http://www.jingjingke.com/favicon.ico"
  },
  {
    "name": " CSDN博客 - 专业IT技术发表平台 ",
    "path": "https://blog.csdn.net",
    "icon": "https://blog.csdn.net/favicon.ico"
  },
  {
    "name": " 博客园 - 代码改变世界 ",
    "path": "https://www.cnblogs.com",
    "icon": "https://www.cnblogs.com/favicon.ico"
  },
  {
    "name": " 图说 - 零编程 玩转图标 ",
    "path": "http://tushuo.baidu.com",
    "icon": "https://tushuo.baidu.com/homepage/asset/img/echarts-logo.png"
  },
  {
    "name": " 在线css圆角生成 ",
    "path": "http://tool.58pic.com/yuanjiaoshengcheng",
    "icon": "http://tool.58pic.com/favicon.ico"
  },
  {
    "name": "新拟态 - 生成Soft-UI CSS代码",
    "path": "https://neumorphism.io",
    "icon": "https://neumorphism.io/favicon.ico"
  },
  {
    "name": " CSS3纹理库 ",
    "path": "http://lea.verou.me/css3patterns",
    "icon": "http://lea.verou.me/favicon.ico"
  },
  {
    "name": " HTML和JS互转 ",
    "path": "http://www.linkwan.com/gb/broadmeter/tools/js2html.htm",
    "icon": "http://www.linkwan.com/favicon.ico"
  },
  {
    "name": " QQ在线代码 ",
    "path": "http://www.54kefu.net/qq_daima.html",
    "icon": "http://www.54kefu.net/favicon.ico"
  },
  {
    "name": " 微博秀 ",
    "path": "http://app.weibo.com/tool/weiboshow",
    "icon": "https://weibo.com/favicon.ico"
  },
  {
    "name": " 一键关注 ",
    "path": "https://open.weibo.com/widget/bulkfollow.php",
    "icon": "https://open.weibo.com/favicon.ico"
  },
  {
    "name": " 微博组件 ",
    "path": "https://open.weibo.com/widgets?cat=wb",
    "icon": "https://open.weibo.com/favicon.ico"
  },
  {
    "name": "Editor.md -  开源在线Markdown编辑器",
    "path": "https://pandao.github.io/editor.md",
    "icon": "https://pandao.github.io/editor.md/favicon.ico"
  },
  {
    "name": "百度脑图 - 在线思维导图工具",
    "path": "http://naotu.baidu.com",
    "icon": "http://naotu.baidu.com/favicon.ico"
  },
  {
    "name": "有道云笔记 -  图/文/语音/手写/OCR…",
    "path": "http://note.youdao.com/#f=topnav",
    "icon": "http://note.youdao.com/favicon.ico"
  },
  {
    "name": "印象笔记 -  创建文本&手写笔记",
    "path": "https://www.yinxiang.com",
    "icon": "https://www.yinxiang.com/favicon.ico"
  },
  {
    "name": "ProcessOn -  流程图/思维导图/原型图…",
    "path": "https://www.processon.com",
    "icon": "https://www.processon.com/favicon.ico"
  },
  {
    "name": "蓝湖 - 连接产品/设计/研发流程",
    "path": "https://lanhuapp.com/",
    "icon": "https://cjj.zone/xxx/ico/蓝湖.png"
  },
  {
    "name": "Eagle - 设计师图片管理工具",
    "path": "https://cn.eagle.cool",
    "icon": "https://cn.eagle.cool/favicon.png"
  },
  {
    "name": "WPS文档 - 多人实时协作编辑",
    "path": "https://drive.wps.cn",
    "icon": "https://drive.wps.cn/favicon.ico"
  },
  {
    "name": "Teambition · 简单高效的团队协作工具",
    "path": "https://www.teambition.com/",
    "icon": "https://www.teambition.com/favicon.ico"
  },
  {
    "name": "幕布 - 极简大纲笔记  一键生成思维导图",
    "path": "https://mubu.com",
    "icon": "https://mubu.com/favicon.ico"
  },
  {
    "name": "石墨文档 - 多人在线协作文档/表格",
    "path": "https://shimo.im/welcome",
    "icon": "https://assets.shimonote.com/from_qbox/favicon.ico"
  },
  {
    "name": "GitMind - 在线免费思维导图软件",
    "path": "https://gitmind.cn",
    "icon": "https://qncdnimg.aoscdn.com/local/gitmind.cn/img/favicon.png"
  },
  {
    "name": "伙伴云 - 数据驱动经营",
    "path": "https://www.huoban.com",
    "icon": "https://www.huoban.com/favicon.ico"
  },
  {
    "name": "BDP个人版 - 免费在线数据分析",
    "path": "https://me.bdp.cn/home.html",
    "icon": "https://me.bdp.cn/favicon.ico"
  },
  {
    "name": "语雀 - 专业的云端知识库",
    "path": "https://www.yuque.com",
    "icon": "https://www.yuque.com/favicon.ico"
  },
  {
    "name": "Adobe Color CC - 色輪 |  色彩配置",
    "path": "https://color.adobe.com/zh/create/color-wheel",
    "icon": "https://color.adobe.com/favicon.ico"
  },
  {
    "name": "Colour code -  单击锁定颜色（色调 ← → / 亮度↑ ↓）",
    "path": "https://colourco.de",
    "icon": "https://colourco.de/favicon.ico"
  },
  {
    "name": "CoolHue - Coolest  Gradient Hues 渐变色",
    "path": "https://webkul.github.io/coolhue",
    "icon": "https://webkul.github.io/coolhue/images/coolhue-logo.png"
  },
  {
    "name": "中國傳統顏色 - CHINESE  COLORS",
    "path": "http://zhongguose.com",
    "icon": "http://zhongguose.com/favicon.ico"
  },
  {
    "name": "中国/日本传统色彩 -  Chinese/Japanese",
    "path": "https://color.uisdc.com",
    "icon": "https://color.uisdc.com/favicon.ico"
  },
  {
    "name": "经典配色方案 -  红/橙/黄/绿/青/紫/无色系",
    "path": "http://www.shejidaren.com/examples/tools/jing-dian-pei-se-fang-an/#orange",
    "icon": "http://www.shejidaren.com/favicon.ico"
  },
  {
    "name": "千图在线配色 -  印象配色/智能配色/传图识色",
    "path": "http://www.58pic.com/peise",
    "icon": "http://www.58pic.com/favicon.ico"
  },
  {
    "name": "AirPano - 360°航拍全景  地球上最有趣的地方",
    "path": "http://www.airpano.org.cn",
    "icon": "http://www.airpano.org.cn/favicon.ico"
  },
  {
    "name": "全景网 - 专注于视觉价值的提升",
    "path": "http://www.quanjing.com",
    "icon": "http://www.quanjing.com/favicon.ico"
  },
  {
    "name": "720yun -  360度全方位的VR展示与体验",
    "path": "https://720yun.com/",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6f97686.ico"
  },
  {
    "name": "美丽中国_旅游目的地指南",
    "path": "http://www.quanjingke.com/dest",
    "icon": "http://www.quanjingke.com/favicon.ico"
  },
  {
    "name": "微动景-动景VR交流分享社区",
    "path": "https://we.taagoo.com",
    "icon": "https://we.taagoo.com/favicon.ico"
  },
  {
    "name": "全景展示_全景和虚拟现实服务平台",
    "path": "http://www.expoon.com/pano",
    "icon": "http://www.expoon.com/favicon.ico"
  },
  {
    "name": "全景故宫",
    "path": "http://webapp.vizen.cn/gugong_app_pc/index.html",
    "icon": "http://webapp.vizen.cn/favicon.ico"
  },
  {
    "name": "WHOIS ✚ 域名信息查询",
    "path": "https://whois.aliyun.com/?spm=5176.8142029.631162.26.bK4sgG",
    "icon": "https://img.alicdn.com/tfs/TB1_ZXuNcfpK1RjSZFOXXa6nFXa-32-32.ico"
  },
  {
    "name": "ICP/IP地址/域名信息备案管理系统",
    "path": "https://beian.miit.gov.cn/?token=23aa63a6-4e22-42a8-a883-b7653a4670e5#/Integrated/index",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6f96b09.ico"
  },
  {
    "name": "IP.CN -  IP查询/IP列表/DNS/手机/电话号码数据库",
    "path": "https://www.ipip.net/ip.html",
    "icon": "https://www.ipip.net/favicon.ico"
  },
  {
    "name": "同ip网站查询",
    "path": "https://www.webscan.cc",
    "icon": "https://www.webscan.cc/favicon.ico"
  },
  {
    "name": "天眼查 - 查公司/查老板/查关系…",
    "path": "http://www.tianyancha.com",
    "icon": "http://www.tianyancha.com/favicon.ico"
  },
  {
    "name": "企查查 - 查企业 就上企查查!",
    "path": "https://www.qichacha.com",
    "icon": "https://www.qichacha.com/favicon.ico"
  },
  {
    "name": "爱企查 -  免免费查老板、风险、工商注册信息查询系统",
    "path": "https://aiqicha.baidu.com/?from=allp",
    "icon": "https://aiqicha.baidu.com/favicon.ico"
  },
  {
    "name": "中国执行信息公开网 - 司法为民，司法便民",
    "path": "http://zxgk.court.gov.cn/?dt_dapp=1",
    "icon": "http://zxgk.court.gov.cn/static2/img/favicon.ico"
  },
  {
    "name": "工标网 - 查标准上工标网",
    "path": "http://www.csres.com",
    "icon": "http://www.csres.com/favicon.ico"
  },
  {
    "name": "在线国家标准查询 -  国内标准&国外标准",
    "path": "https://biaozhun.supfree.net",
    "icon": "https://www.supfree.net/favicon.ico"
  },
  {
    "name": "国家标准全文公开 - GB强制性国家标准",
    "path": "http://www.gb688.cn/bzgk/gb/index",
    "icon": "http://www.gb688.cn/bzgk//images/dzjg.png"
  },
  {
    "name": "路标 - 商标分类 / 商标公告 /  商标注册 / 商标查询",
    "path": "https://www.chatm.com",
    "icon": "https://www.chatm.com/favicon.ico"
  },
  {
    "name": "学信网 - 中国高等教育学生信息网",
    "path": "https://account.chsi.com.cn/passport/login",
    "icon": "https://account.chsi.com.cn/favicon.ico"
  },
  {
    "name": "全国认证认可信息公共服务平台",
    "path": "http://cx.cnca.cn/CertECloud/index/index/page",
    "icon": "http://cx.cnca.cn/CertECloud/resourses/images/tyCnca.ico"
  },
  {
    "name": "技能人才评价工作网",
    "path": "http://www.osta.org.cn",
    "icon": "https://dcs.conac.cn/image/blue.png"
  },
  {
    "name": "国务院政策文件库_中国政府网",
    "path": "http://www.gov.cn/zhengce/zhengcewenjianku/index.htm",
    "icon": "http://www.gov.cn/favicon.ico"
  },
  {
    "name": "知领政策库_政务服务_中国工程科技知识中心",
    "path": "http://policy.ckcest.cn",
    "icon": "http://policy.ckcest.cn/favicon.ico"
  },
  {
    "name": "国家税务总局",
    "path": "http://www.chinatax.gov.cn",
    "icon": "http://www.gov.cn/favicon.ico"
  },
  {
    "name": "白鹿智库-新政策,公共惠民政策,产业政策法规",
    "path": "http://www.bailuzhiku.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d76be4.ico"
  },
  {
    "name": "中华人民共和国人力资源和社会保障部",
    "path": "http://www.mohrss.gov.cn",
    "icon": "http://www.mohrss.gov.cn/favicon.ico"
  },
  {
    "name": "政策法规库",
    "path": "http://f.mnr.gov.cn",
    "icon": "http://www.mohrss.gov.cn/favicon.ico"
  },
  {
    "name": "国家政务服务平台",
    "path": "http://gjzwfw.www.gov.cn",
    "icon": "http://gjzwfw.www.gov.cn/favicon.ico"
  },
  {
    "name": "国家企业信用信息公示系统",
    "path": "http://www.gsxt.gov.cn/corp-query-homepage.html",
    "icon": "http://gjzwfw.www.gov.cn/favicon.ico"
  },
  {
    "name": "国家税务总局江苏省电子税务局",
    "path": "https://etax.jiangsu.chinatax.gov.cn/sso/login",
    "icon": "https://etax.jiangsu.chinatax.gov.cn/sso/static/img/favicon.ico"
  },
  {
    "name": "查询注册信息 - 手机号/邮箱注册过哪些？",
    "path": "https://reg007.com",
    "icon": "https://reg007.com/favicon.ico"
  },
  {
    "name": "手机靓号网",
    "path": "http://mm.zhibiwl.com/i.html",
    "icon": "https://cjj.zone/xxx/ico/手机卡.png"
  },
  {
    "name": "一证通查 - 全国移动电话卡“一证通查”",
    "path": "https://getsimnum.caict.ac.cn/",
    "icon": "https://getsimnum.caict.ac.cn/webicon.ico"
  },
  {
    "name": "买手机靓号就找“抢卡网”",
    "path": "http://www.qiangka.com",
    "icon": "http://www.qiangka.com/favicon.ico"
  },
  {
    "name": "腾讯网址安全检测 -  网站安全检测/诈骗信息查询",
    "path": "https://guanjia.qq.com/online_server/webindex.html",
    "icon": "https://guanjia.qq.com/favicon.ico"
  },
  {
    "name": "违章代码查询 - 12123违章查询",
    "path": "https://www.jiaoguan.com/",
    "icon": "https://www.jiaoguan.com/favicon.ico"
  },
  {
    "name": "家谱网 - 查询家谱就来中国家谱馆！",
    "path": "http://www.jiapu.tv",
    "icon": "http://www.jiapu.tv/favicon.ico"
  },
  {
    "name": "百度取证 -  知识产权/律师律所/广告治理/个人取证",
    "path": "http://quzheng.baidu.com",
    "icon": "http://quzheng.baidu.com/favicon.ico"
  },
  {
    "name": "小鸡词典 - 快速了解网络流行词汇",
    "path": "https://jikipedia.com",
    "icon": "https://jikipedia.com/favicon.ico"
  },
  {
    "name": "全历史 - 最别具一格的历史网站",
    "path": "https://www.allhistory.com",
    "icon": "https://www.allhistory.com/favicon.ico"
  },
  {
    "name": "动态图片基地 - 搞笑动态图片、动态图、gif图片",
    "path": "https://www.asqql.com/",
    "icon": "https://www.asqql.com/img/myico.ico"
  },
  {
    "name": "壁纸族 - 电脑桌面壁纸,手机壁纸,图片素材",
    "path": "https://www.bizhizu.cn/",
    "icon": "https://p1.xywm.ltd/2023/01/22/63cc399f46e4c.jpg"
  },
  {
    "name": "堆糖 - 美图壁纸兴趣社区",
    "path": "https://www.duitang.com/",
    "icon": "https://www.duitang.com/favicon.ico"
  },
  {
    "name": "逗比拯救世界 - 专业的表情包搜索网站",
    "path": "https://www.dbbqb.com/",
    "icon": "https://www.dbbqb.com/favicon.ico"
  },
  {
    "name": "闪萌 - 中文GIF搜索引擎_gif_动图_表情_斗图",
    "path": "http://www.weshineapp.com/",
    "icon": "http://www.weshineapp.com/favicon.ico"
  },
  {
    "name": "SOOGIF - gif制作,gif图片合成",
    "path": "https://gif-tool.whatthehell.cn/",
    "icon": "https://p1.xywm.ltd/2023/01/22/63cc3b4c14352.png"
  },
  {
    "name": "动图宇宙 - 流行明星动图网站 专业gif搜索引擎",
    "path": "http://www.dongtu.com/",
    "icon": "https://p1.xywm.ltd/2023/01/22/63cc3b5627cf3.png"
  },
  {
    "name": "GIF中文网 - 合成高清gif动图-mp4视频转gif动画",
    "path": "https://www.gif.cn/",
    "icon": "https://p1.xywm.ltd/2023/01/22/63cc3b3f4b893.png"
  },
  {
    "name": "GIPHY - Be Animated",
    "path": "https://giphy.com/",
    "icon": "https://giphy.com/favicon.ico"
  },
  {
    "name": "回车桌面 - 动态图片_高清动态手机壁纸大全",
    "path": "https://sj.enterdesk.com/dongtaibizhi/",
    "icon": "https://www.enterdesk.com/favicon.ico"
  },
  {
    "name": "彼岸桌面 - 电脑壁纸_手机壁纸_高清壁纸",
    "path": "http://www.netbian.com/",
    "icon": "http://www.netbian.com/favicon.ico"
  },
  {
    "name": "美桌壁纸 - 每桌，陪你下载生活的美",
    "path": "http://www.win4000.com/",
    "icon": "http://www.win4000.com/favicon.ico"
  },
  {
    "name": "Paper texture -  高画质纸张纹理素材 Free",
    "path": "http://free-paper-texture.com",
    "icon": "https://free-paper-texture.com/06_paper/wp-content/themes/paper/images/logo.ico"
  },
  {
    "name": "百变纹理 - TRANSPARENT  TEXTURES",
    "path": "https://www.transparenttextures.com",
    "icon": "https://www.transparenttextures.com/favicon.png"
  },
  {
    "name": "纹理库 - The Pattern  Library",
    "path": "http://thepatternlibrary.com/#jade",
    "icon": "http://thepatternlibrary.com/favicon.ico"
  },
  {
    "name": "在线纹理素材库 - Repeatable  SVG BG",
    "path": "http://www.heropatterns.com",
    "icon": "https://cjj.zone/xxx/ico/纹理.png"
  },
  {
    "name": "The Pattern  Library  - 超好看的平铺纹理图案",
    "path": "http://thepatternlibrary.com/#cocina",
    "icon": "http://thepatternlibrary.com/favicon.ico"
  },
  {
    "name": "炫酷光效在线制作 -  Interactive Generative Art",
    "path": "http://weavesilk.com",
    "icon": "http://weavesilk.com/favicon.ico"
  },
  {
    "name": "3D粒子波浪",
    "path": "http://www.jq22.com/yanshi1600",
    "icon": "http://www.jq22.com/favicon.ico"
  },
  {
    "name": "Star Emission -  放射烟花背景图",
    "path": "https://wangyasai.github.io/Stars-Emmision",
    "icon": "https://wangyasai.github.io/Stars-Emmision/image/1.ico"
  },
  {
    "name": "Flat Surface Shader -  晶格化背景图",
    "path": "http://matthew.wagerfield.com/flat-surface-shader",
    "icon": "http://matthew.wagerfield.com/favicon.ico"
  },
  {
    "name": "Voxelize Image - 体素图像",
    "path": "https://pissang.github.io/voxelize-image",
    "icon": "https://cjj.zone/xxx/ico/立体像素.png"
  },
  {
    "name": "PHOTOMOSH - 制作故障艺术效果",
    "path": "https://photomosh.com",
    "icon": "https://photomosh.com/favicon.ico"
  },
  {
    "name": "双色调图片 - Duotone by  ShapeFactory",
    "path": "https://duotone.shapefactory.co",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cc06cc9269e.png"
  },
  {
    "name": "迷幻波 - CodePen -  Psychedelic waves",
    "path": "https://codepen.io/Yakudoo/full/rJjOJx",
    "icon": "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
  },
  {
    "name": "梯度波 - CodePen SVG  Gradient Wave Generator",
    "path": "https://codepen.io/pissang/full/geajpX",
    "icon": "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
  },
  {
    "name": "色带 - CodePen -  Ribbons 2",
    "path": "https://codepen.io/tsuhre/full/BYbjyg",
    "icon": "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
  },
  {
    "name": "WebGL Fluid  Simulation - 流体模拟",
    "path": "https://paveldogreat.github.io/WebGL-Fluid-Simulation",
    "icon": "https://paveldogreat.github.io/WebGL-Fluid-Simulation/logo.png"
  },
  {
    "name": "SPACE TYPE GENERATOR  - 太空式发电机",
    "path": "http://spacetypegenerator.com/index.html",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8ef39d5.png"
  },
  {
    "name": "waveFont - 波纹文字",
    "path": "https://wangyasai.github.io/waveFont",
    "icon": "https://wangyasai.github.io/waveFont/image/1.ico"
  },
  {
    "name": "星空银河",
    "path": "http://html5.huceo.com/yinhe",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f377f8.png"
  },
  {
    "name": "三角特效-交互式三角形背景",
    "path": "http://html5.huceo.com/jhssjbj",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e96fdd.png"
  },
  {
    "name": "重力感应动画",
    "path": "http://html5.huceo.com/zlgy",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6fef07d.png"
  },
  {
    "name": "隧道穿梭",
    "path": "http://html5.huceo.com/suidao",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8eac9aa.png"
  },
  {
    "name": "弹性声波动画",
    "path": "http://html5.huceo.com/shengbo",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8eb5780.png"
  },
  {
    "name": "鼠标经过粒子散开动画",
    "path": "http://html5.huceo.com/lizisuankai",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "可视化音频动画特效",
    "path": "http://html5.huceo.com/viewaudio",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "菱形隧道 - 三维菱形隧道动画特效",
    "path": "http://html5.huceo.com/lxsd",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e75bb0.png"
  },
  {
    "name": "线条冲刺-观感强烈的线条冲刺",
    "path": "http://html5.huceo.com/codevember",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "可视化太空流星雨模拟器",
    "path": "https://www.meteorshowers.org",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e28db2.png"
  },
  {
    "name": "海洋之音",
    "path": "http://virtocean.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d801f5.png"
  },
  {
    "name": "探索地球的实时卫星图像",
    "path": "https://zoom.earth",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d7f2c8.png"
  },
  {
    "name": "感受四季自由变化-Rainyscope",
    "path": "http://rainyscope.com/#slide-1",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f421d0.png"
  },
  {
    "name": "3D模拟好奇号火星探测器",
    "path": "https://eyes.nasa.gov/curiosity",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8df0d75.png"
  },
  {
    "name": "奇迹秀-网站轰炸机",
    "path": "http://www.qijishow.com/Brand/Brand74/index.htm",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d7115d.png"
  },
  {
    "name": "佩林噪声",
    "path": "https://wangyasai.github.io/Perlin-Noise",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff700f886.png"
  },
  {
    "name": "Perlin Noise Painter",
    "path": "https://wangyasai.github.io/PerlinNoisePainter",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6f95b13.png"
  },
  {
    "name": "Bezier",
    "path": "https://wangyasai.github.io/Bezier",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "Particles Emission",
    "path": "https://wangyasai.github.io/Particles-Emission",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6fa44e8.png"
  },
  {
    "name": "Staggering Beauty",
    "path": "http://www.staggeringbeauty.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "在线百变万花尺Inspirograph",
    "path": "https://nathanfriend.io/inspirograph",
    "icon": "https://nathanfriend.io/inspiral-web/favicon.ico"
  },
  {
    "name": "新鲜有趣的鼠标互动-david.li",
    "path": "http://david.li",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "纯文字-ertdfgcvb",
    "path": "https://ertdfgcvb.xyz",
    "icon": "https://ertdfgcvb.xyz/ico/icon_192.png"
  },
  {
    "name": "银河系",
    "path": "http://stars.chromeexperiments.com",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "太阳系在线模拟",
    "path": "http://www.faustweb.net/solaris",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f19c06.png"
  },
  {
    "name": "一光年宇宙电台-Lightyear Fm",
    "path": "http://www.lightyear.fm",
    "icon": "http://www.lightyear.fm/apple-touch-icon-57x57.png"
  },
  {
    "name": "____________🚕_____",
    "path": "https://bruno-simon.com",
    "icon": "https://bruno-simon.com/favicon/favicon-32x32.png"
  },
  {
    "name": "坦克Battle City AI",
    "path": "http://juleswang.github.io/BattleCity.AI",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f31f07.png"
  },
  {
    "name": "指尖陀螺",
    "path": "https://ffffidget.com",
    "icon": "https://ffffidget.com/favicon.ico"
  },
  {
    "name": "高清豪华版捕鱼达人",
    "path": "http://html5.huceo.com/fishjoy",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "2017 Make Me Pulse",
    "path": "http://2017.makemepulse.com",
    "icon": "http://2017.makemepulse.com/assets/images/favicon.png"
  },
  {
    "name": "Emojis & Earth  Porn",
    "path": "http://emojisandearthporn.com",
    "icon": "http://emojisandearthporn.com/images/emojis/100/6.png"
  },
  {
    "name": "BAROQUE.ME",
    "path": "http://www.baroque.me",
    "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
  },
  {
    "name": "假装 Windows 升级界面",
    "path": "https://fakeupdate.net",
    "icon": "https://fakeupdate.net/favicon.gif"
  },
  {
    "name": "蓝屏anon.blue",
    "path": "https://anon.blue",
    "icon": "https://anon.blue/static/favicon.png"
  }
]

export const groupNavigationUrl = [
  {
    groupName: '在线设计工具',
    list: [
      {
        "name": "cssfilters（CSS滤镜）  ",
        "path": "https://www.cssfilters.co",
        "icon": "https://www.cssfilters.co/images/icons/favicon.ico"
      },
      {
        "name": "即时设计 - 可实时协作的专业 UI  设计工具",
        "path": "https://js.design/?source=uisdc&plan=sjgj",
        "icon": "https://img.js.design/assets/webImg/favicon.ico"
      },
      {
        "name": "包小盒 - 在线3D包装设计工具",
        "path": "https://www.baoxiaohe.com",
        "icon": "https://www.baoxiaohe.com/favicon.ico"
      },
      {
        "name": "高品质的免费模型",
        "path": "https://mockups-design.com",
        "icon": "https://mockups-design.com/wp-content/uploads/2017/08/cropped-favicon-32x32.png"
      },
      {
        "name": "来画视频 -  有创意的AI动画短视频制作平台",
        "path": "https://www.laihua.com",
        "icon": "https://www.laihua.com/favicon.ico"
      },
      {
        "name": "一帧秒创 - AI视频创作,图文转视频",
        "path": "https://aigc.yizhentv.com",
        "icon": "https://aigc.yizhentv.com/favicon.ico"
      },
      {
        "name": "Photopea | Online  Photo Editor",
        "path": "https://www.photopea.com",
        "icon": "https://www.photopea.com/promo/thumb256.png"
      },
      {
        "name": "造物云 - 实时云渲染 | 在线生成3D效果",
        "path": "https://www.zaowuyun.com/",
        "icon": "https://www.zaowuyun.com/favicon.ico"
      },
      {
        "name": "Hand-Drawn  Illustration Library",
        "path": "https://openpeeps.com",
        "icon": "https://assets.website-files.com/5e51b3b0337309d672efd94c/5e51ce488c34f8b713499e29_icon32w.png"
      },
      {
        "name": "创建动画水效果",
        "path": "https://watereffect.net",
        "icon": "https://watereffect.net/favicon.ico"
      },
      {
        "name": "泼辣修图 polarr",
        "path": "https://v3.polarr.co",
        "icon": "https://v3.polarr.co/favicon-32x32.png"
      },
      {
        "name": "创客贴 - 平面设计作图神器",
        "path": "https://www.chuangkit.com",
        "icon": "https://www.chuangkit.com/favicon.ico"
      },
      {
        "name": "Fotor懒设计 - 在线设计工具",
        "path": "https://www.fotor.com.cn",
        "icon": "https://www.fotor.com.cn/favicon.ico"
      },
      {
        "name": "稿定设计 - 在线设计_免费设计素材",
        "path": "https://www.gaoding.com",
        "icon": "https://www.gaoding.com/favicon.ico"
      },
      {
        "name": "图怪兽 - 在线图片编辑器",
        "path": "https://818ps.com",
        "icon": "https://818ps.com/favicon.ico"
      },
      {
        "name": "可批量编辑图片的所有工具",
        "path": "https://www.iloveimg.com/zh-cn",
        "icon": "https://www.iloveimg.com/favicon.ico"
      },
      {
        "name": "Canva在线平面设计软件",
        "path": "https://www.canva.cn",
        "icon": "https://www.canva.cn/favicon.ico"
      },
      {
        "name": "图帮主 - 轻量级在线平面设计工具",
        "path": "https://www.tubangzhu.com",
        "icon": "https://www.tubangzhu.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '在线编辑器',
    list: [
      {
        "name": "i排版 - 公众号编辑_微信排版工具",
        "path": "http://ipaiban.com",
        "icon": "http://ipaiban.com/src/img/favicon.ico"
      },
      {
        "name": "96微信编辑器 -  微信公众平台图文排版工具",
        "path": "http://bj.96weixin.com",
        "icon": "http://bj.96weixin.com/favicon.ico"
      },
      {
        "name": "小蚂蚁微信编辑器_微信图文编辑",
        "path": "http://www.xmyeditor.com",
        "icon": "http://www.xmyeditor.com/favicon.ico"
      },
      {
        "name": "135编辑器官网-微信排版编辑器",
        "path": "https://www.135editor.com",
        "icon": "https://www.135editor.com/favicon.ico"
      },
      {
        "name": "易点微信编辑器 - 微信公众平台素材编辑",
        "path": "https://www.wxeditor.com",
        "icon": "https://www.wxeditor.com/favicon.ico"
      },
      {
        "name": "新榜微信编辑器—让你的图文编辑生动有趣",
        "path": "https://edit.newrank.cn",
        "icon": "https://edit.newrank.cn/favicon.ico"
      },
      {
        "name": "Method Draw─矢量图形在线编辑",
        "path": "https://c.runoob.com/more/svgeditor",
        "icon": "https://c.runoob.com/favicon.ico"
      },
      {
        "name": "图表秀 - 图表数据可视化工具",
        "path": "https://www.tubiaoxiu.com",
        "icon": "https://www.tubiaoxiu.com/landing/img/logo_small16.png"
      },
      {
        "name": "文图 - 制作可视化数据报告",
        "path": "https://wentu.io",
        "icon": "https://www.wentu.io/img/apple-touch-icon.png"
      },
    ]
  },
  {
    groupName: '在线视频音频编辑器',
    list: [
      {
        "name": "免费在线-视频-音频-格式转换工具",
        "path": "http://www.alltoall.net",
        "icon": "https://www.alltoall.net/qfy-content/uploads/2016/06/fa70f63e4bbcc259632aae74746ce5d6.png"
      },
      {
        "name": "免费在线视频音频转换文档转换压缩转换",
        "path": "https://cn.office-converter.com",
        "icon": "https://cn.office-converter.com/favicon.ico"
      },
      {
        "name": "在线转换文档，图像，视频，音频文件",
        "path": "https://www.aconvert.com/cn",
        "icon": "https://www.aconvert.com/favicon.ico"
      },
      {
        "name": "在线剪辑视频",
        "path": "https://online-video-cutter.com/cn",
        "icon": "https://online-video-cutter.com/static/i/v3/favicon.svg"
      },
      {
        "name": "SubPlayer - 在线字幕编辑器",
        "path": "https://subplayer.js.org",
        "icon": "https://subplayer.js.org/favicon.ico"
      },
      {
        "name": "在线录音-直接在网页中录音并导出mp3格式至本地",
        "path": "https://online-voice-recorder.com/cn",
        "icon": "https://online-video-cutter.com/static/i/v3/favicon.svg"
      },
      {
        "name": "Online MP3 Cutter -  剪辑歌曲，制作铃声",
        "path": "https://mp3cut.net/cn",
        "icon": "https://online-video-cutter.com/static/i/v3/favicon.svg"
      },
      {
        "name": "分秒帧MediaTrack - 音视频协作",
        "path": "https://www.mediatrack.cn",
        "icon": "https://www.mediatrack.cn/favicon.ico"
      },
      {
        "name": "讯飞配音-提供文字转语音，语音合成",
        "path": "http://peiyin.xunfei.cn",
        "icon": "http://peiyin.xunfei.cn/themes/images/bigpeiyinge_favicon.ico"
      },
      {
        "name": "淘金阁 - 视频配音在线生成",
        "path": "http://www.51taojinge.com/include/voice/voice.php",
        "icon": "http://cdnnew.51taojinge.com/Static/images/favicon.ico?v=2063"
      },
      {
        "name": "魔音工坊 - 达人热推的短视频/有声书AI配音平台",
        "path": "https://www.moyin.com",
        "icon": "https://www.moyin.com/favicon.ico"
      },
      {
        "name": "传影DIY_在线视频制作_电子相册",
        "path": "https://www.chuanying520.com",
        "icon": "https://www.chuanying520.com/favicon.ico"
      },
      {
        "name": "影大师 - 在线视频制作，电子相册",
        "path": "http://www.yingdashi.cn",
        "icon": "https://www.yingdashi.cn/images/favicon.ico"
      },
      {
        "name": "Online-Down.. -  全球视频在线下载",
        "path": "https://www.online-downloader.com/index-Chinese",
        "icon": "https://www.online-downloader.com/Download-Font/favicon-32x32.png"
      },
    ]
  },
  {
    groupName: '电商工具',
    list: [
      {
        "name": "58图库 - 电商历史图库 TOP卖家快照",
        "path": "http://www.58tu.com",
        "icon": "http://www.58tu.com/Public/favicon.ico"
      },
      {
        "name": "阿里巴巴原创保护-创意|短视频|图片保护",
        "path": "https://yc.alibaba.com",
        "icon": "https://yc.alibaba.com/favicon.ico"
      },
      {
        "name": "反恶联盟-反打假/防敲诈",
        "path": "https://www.felmvip.com",
        "icon": "https://www.felmvip.com/static/images/favicon.ico"
      },
      {
        "name": "卖家网-领先的电商服务平台",
        "path": "http://www.maijia.com",
        "icon": "http://www.maijia.com/favicon.ico"
      },
      {
        "name": "电商报-电商数据_电子商务数据_电商行业数据",
        "path": "http://www.dsb.cn/shuju",
        "icon": "http://www.dsb.cn/favicon.ico"
      }
    ]
  },
  {
    groupName: '数据分析工具',
    list: [
      {
        "name": "抖查查 -  短视频&直播电商数据分析服务平台",
        "path": "https://www.douchacha.com",
        "icon": "https://www.douchacha.com/favicon.ico"
      },
      {
        "name": "蝉妈妈 - 抖音  小红书一站式数据分析服务平台",
        "path": "https://www.chanmama.com",
        "icon": "https://cdn-static.chanmama.com/sub-module/web/favicon.ico"
      },
      {
        "name": "巨量创意",
        "path": "https://cc.oceanengine.com",
        "icon": "https://lf9-static.bytednsdoc.com/obj/cc-static/1605083490030.svg"
      },
      {
        "name": "巨量千川-登录",
        "path": "http://qianchuan.jinritemai.com/login",
        "icon": "https://lf1-cdn2-tos.bytegoofy.com/ad/ecom_marketing_fe/favicon.ico"
      },
      {
        "name": "服务导航 - 新抖",
        "path": "https://xd.newrank.cn/home/index",
        "icon": "https://chs.newrank.cn/favicon/xd/favicon.ico"
      },
      {
        "name": "算数指数-巨量算数",
        "path": "https://trendinsight.oceanengine.com/arithmetic-index",
        "icon": "https://lf3-static.bytednsdoc.com/obj/eden-cn/kyhgpdeh7nuvanuhd/count/trendinsight.ico"
      },
      {
        "name": "西贴 - 历史价格查询|网上商城价格走势图",
        "path": "http://www.xitie.com",
        "icon": "http://www.xitie.com/favicon.ico"
      },
      {
        "name": "亲查查-旺旺小号降权_信誉查询_照妖镜验号",
        "path": "https://www.qinchacha.com",
        "icon": "https://www.qinchacha.com/favicon.ico"
      },
      {
        "name": "看店宝_淘宝店铺和天猫数据查询分析",
        "path": "https://kandianbao.com",
        "icon": "https://k.tqdn.cn/favicon.ico"
      },
      {
        "name": "抖商眼-抖音生意参谋",
        "path": "https://doushangyan.com/?s=mjbox",
        "icon": "https://doushangyan.com/static/favicon.ico"
      },
      {
        "name": "星图-抖音头条商业内容智能交易&管理平台",
        "path": "https://star.toutiao.com",
        "icon": "https://star.toutiao.com/favicon.ico"
      },
      {
        "name": "小旺神_新版生意参谋指数一键还原",
        "path": "https://xiaowangshen.com/?f=cj",
        "icon": "https://xws.tqdn.cn/images/favicon.ico?t=1553074981"
      },
      {
        "name": "店侦探-电商数据分析专家",
        "path": "https://www.dianzhentan.com",
        "icon": "https://d.tqdn.cn/static/favicon.ico?q=1637049270"
      },
      {
        "name": "店透视-生意参谋指数转换 自动计算  批量下载",
        "path": "https://www.diantoushi.com",
        "icon": "https://assets.diantoushi.com/cnd/img/dts-headicon.png"
      },
      {
        "name": "淘大象-旺旺信用查询/宝贝查询",
        "path": "http://www.taodaxiang.com",
        "icon": "http://www.taodaxiang.com/favicon.ico"
      },
      {
        "name": "幕思城将军令-关键词淘词/数据分析运营策略…",
        "path": "http://soft.musicheng.com",
        "icon": "http://soft.musicheng.com/favicon.ico"
      },
      {
        "name": "阿里指数-行业大盘/属性细分/阿里排行",
        "path": "http://index.1688.com",
        "icon": "https://img.alicdn.com/tfs/TB1uh..zbj1gK0jSZFuXXcrHpXa-16-16.ico"
      },
    ]
  },
  {
    groupName: '买家秀',
    list: [
      {
        "name": "美女时钟-品牌商家与网红达人合作平台",
        "path": "http://www.sodao.com",
        "icon": "http://www.sodao.com/favicon.ico"
      },
      {
        "name": "买家秀拍摄-淘宝买家秀兼职寄拍平台",
        "path": "http://www.tbqq.net",
        "icon": "http://www.tbqq.net/favicon.ico"
      }
    ]
  },
  {
    groupName: '精选购物网站',
    list: [
      {
        "name": "折800-精选商品折扣1折特卖",
        "path": "https://www.zhe800.com",
        "icon": "https://www.zhe800.com/favicon.ico"
      },
      {
        "name": "美丽说-女性时尚分享网站",
        "path": "http://www.meilishuo.com",
        "icon": "https://s10.mogucdn.com/mlcdn/c45406/180926_7l1b34jf8c9fei50e7dbijc7d39gh_48x48.png"
      },
      {
        "name": "小红书-全世界的好东西",
        "path": "https://www.xiaohongshu.com",
        "icon": "https://www.xiaohongshu.com/favicon.ico"
      },
      {
        "name": "贝店-我的店!带你买遍全球好货",
        "path": "http://www.beidian.com",
        "icon": "http://www.beidian.com/favicon.ico"
      },
      {
        "name": "微店 - 基于社交关系的电商平台",
        "path": "https://www.weidian.com",
        "icon": "https://assets.geilicdn.com/fxxxx/favicon.ico"
      },
      {
        "name": "聚美优品-极速免税店 品牌防伪码",
        "path": "http://sh.jumei.com",
        "icon": "http://sh.jumei.com/favicon.ico"
      },
      {
        "name": "海拍客-做母婴，就用海拍客！",
        "path": "https://mall.hipac.cn/mall/view/login/login.html?loginFlag=false",
        "icon": "https://mall.hipac.cn/favicon.ico"
      },
      {
        "name": "网易考拉 - 正品低价_海外直采_海外直邮",
        "path": "https://www.kaola.com/?zn=top",
        "icon": "https://img.alicdn.com/tfs/TB13g4aqXP7gK0jSZFjXXc5aXXa-32-32.ico"
      },
      {
        "name": "达令家-享受美物,致敬生活！",
        "path": "http://www.dalingjia.com",
        "icon": "http://aimg8.dlszyht.net.cn/user_icon/201708241010271520368.ico"
      },
      {
        "name": "云集-购物享受批发价",
        "path": "https://www.yunjiglobal.com",
        "icon": "https://www.yunjiglobal.com/favicon.ico"
      },
      {
        "name": "每日一淘-精选美食社交电商平台",
        "path": "https://www.mryitao.cn",
        "icon": "https://www.mryitao.cn/img/logo.png"
      }
    ]
  },
  {
    groupName: '精选货源网',
    list: [
      {
        "name": "17网 - 让服装行业更美好",
        "path": "https://gz.17zwd.com",
        "icon": "https://gz.17zwd.com/favicon.ico"
      },
      {
        "name": "服装流行趋势企划",
        "path": "https://www.pop-fashion.com/trends/?pid=021521&bd_vid=10652155847360356368",
        "icon": "https://www.pop-fashion.com/favicon.ico?20200903"
      },
      {
        "name": "宏鼎品库 - 批发档口，一手货源",
        "path": "https://www.ppkoo.com/index/index.html?from=header",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8de9437.ico"
      },
      {
        "name": "四季青服装网",
        "path": "https://www.sjq.cn",
        "icon": "https://www.sjq.cn/favicon.ico"
      },
      {
        "name": "搜款网 - 杭州女装站 -  真实档口一手货源",
        "path": "https://www.vvic.com/hznz",
        "icon": "https://www.vvic.com/favicon.ico"
      },
      {
        "name": "衣联网 - 服装批发市场新的领航者",
        "path": "https://www.eelly.com",
        "icon": "https://www.eelly.com/favicon.ico"
      },
      {
        "name": "生意网 - 童装贸易信息服务平台",
        "path": "http://www.3e3e.cn",
        "icon": "http://www.3e3e.cn/favicon.ico"
      },
      {
        "name": "水墨佳人服饰",
        "path": "http://www.ismjr.com",
        "icon": "http://www.ismjr.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '代码学习',
    list: [
      {
        "name": "Web前端导航",
        "path": "http://www.alloyteam.com/nav",
        "icon": "http://www.alloyteam.com/favicon.ico"
      },
      {
        "name": "千图前端开发工具",
        "path": "http://tool.58pic.com/development",
        "icon": "http://tool.58pic.com/favicon.ico"
      },
      {
        "name": "CSS参考手册",
        "path": "http://css.doyoe.com",
        "icon": "http://css.doyoe.com/doyoe.ico"
      },
      {
        "name": " 菜鸟教程 ",
        "path": "http://www.runoob.com",
        "icon": "http://www.runoob.com/favicon.ico"
      },
      {
        "name": " w3school 在线教程 ",
        "path": "https://www.w3school.com.cn",
        "icon": "https://www.w3school.com.cn/ui2019/logo-32-red.png"
      },
      {
        "name": " 编程学习网 - PHP/laravel/swoole ",
        "path": "http://www.phpxs.com",
        "icon": "http://www.phpxs.com/favicon.ico"
      },
      {
        "name": " 前端人的俱乐部 ",
        "path": "http://f2er.club",
        "icon": "http://f2er.club/img/favicon.ico"
      },
      {
        "name": "掘金 - 帮助开发者成长的社区",
        "path": "https://juejin.im",
        "icon": "https://juejin.im/favicon.ico"
      },
      {
        "name": "印记中文 - 权威技术中文文档社区",
        "path": "https://docschina.org",
        "icon": "https://docschina.org/favicon.ico"
      },
      {
        "name": "LintCode - 炼码",
        "path": "https://www.lintcode.com",
        "icon": "https://jsn.lintcode.com/static/img/favicon.ico"
      },
      {
        "name": "Lodash文档 -  JavaScript实用工具库",
        "path": "https://www.lodashjs.com",
        "icon": "https://www.lodashjs.com/img/favicon.ico"
      },
      {
        "name": "代码学院 - Web开发基础教程大全",
        "path": "http://codexy.cn",
        "icon": "http://codexy.cn/favicon.ico"
      },
      {
        "name": "How2J 的 Java教程",
        "path": "https://how2j.cn",
        "icon": "https://how2j.cn/favicon.ico"
      },
      {
        "name": "w3cschool -  编程狮，随时随地学编程",
        "path": "https://www.w3cschool.cn",
        "icon": "https://www.w3cschool.cn/favicon.ico"
      },
      {
        "name": "易百教程™ - 专注于IT教程和实例",
        "path": "https://yiibai.com",
        "icon": "https://yiibai.com/favicon.ico"
      },
      {
        "name": "SplitShire - 前端印记",
        "path": "https://www.htmltrip.com/sites/122.html",
        "icon": "https://www.htmltrip.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '字体 （ .ttf )',
    list: [
      {
        "name": "搜字网 - 按品名类别/艺术风格查看",
        "path": "http://www.sozi.cn",
        "icon": "http://www.sozi.cn/favicon.ico"
      },
      {
        "name": "字客网 -  字体授权/来源/类型/格式/风格",
        "path": "https://www.fontke.com",
        "icon": "https://www.fontke.com/favicon.ico"
      },
      {
        "name": "云字库 - 文鼎字体战略合作伙伴",
        "path": "http://www.yestone.com/fonts/overview",
        "icon": "http://www.yestone.com/favicon.ico"
      },
      {
        "name": "求字体网 -  字体一键补齐/可读取PSD/AI/CDR/JPG",
        "path": "http://www.qiuziti.com",
        "icon": "https://www.qiuziti.com/image/icon.ico"
      },
      {
        "name": "猫啃网 - 最新最全的可免费商用中文字体",
        "path": "https://www.maoken.com",
        "icon": "https://www.maoken.com/favicon.ico"
      },
      {
        "name": "字由 - 设计师必备字体利器",
        "path": "http://www.hellofont.cn",
        "icon": "http://www.hellofont.cn/favicon.ico"
      },
      {
        "name": "字体天下 -  提供各类字体的免费下载和在线预览服务",
        "path": "http://www.fonts.net.cn",
        "icon": "http://www.fonts.net.cn/favicon.ico"
      },
      {
        "name": "书法迷 - 在线书法字典",
        "path": "http://www.shufami.com",
        "icon": "http://www.shufami.com/favicon.ico"
      },
      {
        "name": "书法字体 - 在线书法字体转换",
        "path": "http://www.shufaziti.com",
        "icon": "http://www.shufami.com/favicon.ico"
      },
      {
        "name": "书同文汉字网 - 设计师必备字体利器",
        "path": "https://hanzi.unihan.com.cn",
        "icon": "https://hanzi.unihan.com.cn/favicon.ico"
      },
      {
        "name": "古书竖文转换器 -  可粘贴到论坛/博客/留言板",
        "path": "http://www.aies.cn/shuwen.htm",
        "icon": "http://www.aies.cn/favicon.ico"
      },
      {
        "name": "FONTS•LOL - 彩色字体铸造厂",
        "path": "http://www.fonts.lol",
        "icon": "http://www.fonts.lol/images/lol_fav.ico"
      },
      {
        "name": "Dafont - 大量免费的英文字体",
        "path": "https://www.dafont.com",
        "icon": "https://www.dafont.com/favicon.ico"
      },
      {
        "name": "Wordmark.it -  在线预览本地字体",
        "path": "https://wordmark.it",
        "icon": "https://wordmark.it/assets/meta/favicon-light.ico"
      },
      {
        "name": "萝卜工坊 - 快速转换模拟手写字体文档",
        "path": "http://www.beautifulcarrot.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e301cb.png"
      },
      {
        "name": "文字云 - Word Art  Cloud美寄词云",
        "path": "https://www.moage.cn/wordart?union=2",
        "icon": "https://www.moage.cn/favicon.ico"
      }
    ]
  },
  {
    groupName: '素材下载',
    list: [
      {
        "name": "Billfish素材管家 - 创意设计必备素材管理工具",
        "path": "https://www.billfish.cn/",
        "icon": "https://www.billfish.cn/wp-content/themes/wordpress-theme-billfish/assets/images/common/logo.png"
      },
      {
        "name": "千图网 - 设计创意/办公创意/正版商用",
        "path": "http://www.58pic.com",
        "icon": "http://www.58pic.com/favicon.ico"
      },
      {
        "name": "摄图网 - 正版商用设计高清图",
        "path": "http://699pic.com",
        "icon": "http://699pic.com/favicon.ico"
      },
      {
        "name": "大美工 - 设计优选",
        "path": "http://dameigong.cn",
        "icon": "http://dameigong.cn/favicon.ico"
      },
      {
        "name": "美工云 - 专业海外商业设计素材分享平台",
        "path": "http://meigongyun.com",
        "icon": "http://meigongyun.com/favicon.ico"
      },
      {
        "name": "未来mac下载 - mac软件大全",
        "path": "https://mac.orsoon.com/",
        "icon": "https://cdn.mac89.com/orsoon_node/static/orsoon.png"
      },
      {
        "name": "图翼网 - 设计师互动分享平台",
        "path": "http://www.tuyiyi.com",
        "icon": "http://www.tuyiyi.com/favicon.ico"
      },
      {
        "name": "模库网_中国免费设计素材图片库",
        "path": "https://www.mcool.com",
        "icon": "https://www.mcool.com/favicon.ico"
      },
      {
        "name": "我图网 - 正版商用设计作品交易平台",
        "path": "http://www.ooopic.com",
        "icon": "http://www.ooopic.com/favicon.ico"
      },
      {
        "name": "包图网 - 专注原创商用设计图片下载",
        "path": "https://ibaotu.com",
        "icon": "https://ibaotu.com/favicon.ico"
      },
      {
        "name": "昵图网 - 原创素材共享平台",
        "path": "http://www.nipic.com/index.html",
        "icon": "http://www.nipic.com/favicon.ico"
      },
      {
        "name": "图品汇 -  电商设计/电脑小报/PPT/简历",
        "path": "http://www.88tph.com",
        "icon": "http://www.88tph.com/favicon.ico"
      },
      {
        "name": "16素材网 - PSD素材 / 高清图片  / 矢量素材",
        "path": "http://www.16sucai.com",
        "icon": "http://www.16sucai.com/favicon.ico"
      },
      {
        "name": "拍信- 中国领先的创意内容素材平台",
        "path": "https://www.paixin.com",
        "icon": "https://www.paixin.com/favicon.ico"
      },
      {
        "name": "颜格视觉_专注提供海外创意广告设计图片素材",
        "path": "https://www.youngem.com",
        "icon": "https://www.youngem.com/favicon.ico"
      },
      {
        "name": "云瑞设计 -  免费设计素材_设计资源_设计模板",
        "path": "https://www.yrucd.com/category/designresources",
        "icon": "https://www.yrucd.com/favicon.ico"
      },
      {
        "name": "图标、插图、照片、音乐和设计工具",
        "path": "https://photos.icons8.com",
        "icon": "https://icons8.com/vue-static/landings/primary-landings/favs/icons8_fav_32×32.png"
      },
      {
        "name": "免费插图，美丽的免费艺术| 混音器",
        "path": "https://mixkit.co/free-stock-art",
        "icon": "https://mixkit.co/favicon.ico"
      },
      {
        "name": "一套免费的开源插图",
        "path": "https://www.opendoodles.com",
        "icon": "https://assets.website-files.com/5d5d5904f8a21bfe5ff69367/5da4e9a054896ed6d830e869_icon-32w.png"
      },
      {
        "name": "无限的股票视频、音乐、照片和图形",
        "path": "https://elements.envato.com",
        "icon": "https://elements.envato.com/favicon.ico"
      },
      {
        "name": "高图网-免费无版权高清图片下载",
        "path": "http://www.gaoimg.com",
        "icon": "http://www.gaoimg.com/favicon.ico"
      },
      {
        "name": "设计小咖-有格调的设计素材资源站",
        "path": "https://www.iamxk.com",
        "icon": "https://www.iamxk.com/favicon.ico"
      },
      {
        "name": "满足各种需求的视频 - 完全免费",
        "path": "http://coverr.co",
        "icon": "http://coverr.co/favicon.ico"
      },
      {
        "name": "素材公社 -  电商/UI/VI设计/广告设计",
        "path": "http://www.tooopen.com",
        "icon": "http://www.tooopen.com/favicon.ico"
      },
      {
        "name": "68Design - 图库壁纸 | 矢量  | 图标 | PSD",
        "path": "http://sc.68design.net",
        "icon": "http://sc.68design.net/favicon.ico"
      },
      {
        "name": "红动中国 -  原创设计稿/摄影图/接单推广/修改赚钱",
        "path": "http://sucai.redocn.com",
        "icon": "http://sucai.redocn.com/favicon.ico"
      },
      {
        "name": "觅知网 -  PPT模板,PS,矢量图片素材库",
        "path": "https://www.51miz.com",
        "icon": "https://www.51miz.com/favicon.ico"
      },
      {
        "name": "熊猫办公 -  Word/Excel/PPT/AE/MG动画/音效/配乐",
        "path": "http://www.tukuppt.com/ppt/?plan=10013-298-18655",
        "icon": "http://www.tukuppt.com/favicon.ico"
      },
      {
        "name": "二哈办公-音效素材下载-音效大全-配乐",
        "path": "http://www.ohipic.com/sound/0--0-default/p_30",
        "icon": "http://www.ohipic.com/favicon.ico"
      },
      {
        "name": "香当网 -  国内知名文档分享平台/Word/PPT/PDF",
        "path": "https://www.xiangdang.net",
        "icon": "https://static.xiangdang.net/favicon.ico"
      },
      {
        "name": "爱给网 -  音效配乐_3D模型_视频素材_游戏素材",
        "path": "http://www.aigei.com",
        "icon": "http://www.aigei.com/favicon.ico"
      },
      {
        "name": "PixelSquid - 全透视角度素材",
        "path": "https://www.pixelsquid.com",
        "icon": "https://www.pixelsquid.com/favicon.ico"
      },
      {
        "name": "P站 - Pixiv Illustration Collection",
        "path": "https://pixivic.com/?VNK=5b94dd06",
        "icon": "https://static.ehd.name/pc/icon.png<span>P站 - Pixiv Illustration Collection"
      },
      {
        "name": "触站 - P站-Pixiv-原创画师分享平台（原画师通）",
        "path": "https://www.huashi6.com/",
        "icon": "https://www.huashi6.com/favicon.ico<span>触站 - P站-Pixiv-原创画师分享平台（原画师通）"
      },
      {
        "name": "Free Stock Video - 高清版税免费视频下载",
        "path": "https://mazwai.com",
        "icon": "https://mazwai.com/assets/images/favicon-32x32.png<span>Free Stock Video - 高清版税免费视频下载"
      },
      {
        "name": "Gaiamount - 电影创作者家园",
        "path": "https://gaiamount.com",
        "icon": "https://gaiamount.com/favicon.png<span>Gaiamount - 电影创作者家园"
      },
      {
        "name": "潮点视频 - 权高清视频素材下载网站-正版可商用",
        "path": "https://shipin520.com/shipin/3468.html",
        "icon": "https://shipin520.com/favicon.ico<span>潮点视频 - 权高清视频素材下载网站-正版可商用"
      },
      {
        "name": "光厂(VJ师) - 视频素材，我们更专业",
        "path": "https://www.vjshi.com",
        "icon": "https://www.vjshi.com/favicon.ico<span>光厂(VJ师) - 视频素材，我们更专业"
      },
      {
        "name": "Motion Places - 免费库存视频",
        "path": "https://www.motionplaces.com",
        "icon": "<span>Motion Places - 免费库存视频"
      },
      {
        "name": "淘声 - toSound声音搜索引擎",
        "path": "https://www.tosound.com",
        "icon": "https://www.tosound.com/favicon.ico"
      }
    ]
  },
  {
    groupName: 'icon图标',
    list: [
      {
        "name": "Iconfont - 阿里巴巴矢量图标库",
        "path": "http://www.iconfont.cn/home/index?spm=a313x.7781069.1998910419.2",
        "icon": "https://img.alicdn.com/imgextra/i2/O1CN01ZyAlrn1MwaMhqz36G_!!6000000001499-73-tps-64-64.ico"
      },
      {
        "name": "bootcss自带的Glyphicons图标",
        "path": "https://v3.bootcss.com/components/#glyphicons",
        "icon": "https://v3.bootcss.com/favicon.ico"
      },
      {
        "name": "Feather – 主题羽毛图标",
        "path": "https://feathericons.com",
        "icon": "https://feathericons.com/favicon.ico"
      },
      {
        "name": "DryIcons — 图标和矢量图形",
        "path": "https://dryicons.com",
        "icon": "https://dryicons.com/assets/logo-60ed9738166a3dbdde06f0387d00aefba463a25a5a84ffcbcd9f5042f50bc0e6.svg"
      },
      {
        "name": "千库网 -  免抠/背景/字库/文档/视频音频",
        "path": "http://588ku.com/sucai/tubiao",
        "icon": "http://588ku.com/favicon.ico"
      },
      {
        "name": "PNG图标 - 懒人图库png格式图片下载",
        "path": "http://www.lanrentuku.com/png",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e1f7e3.ico"
      },
      {
        "name": "icons8 - GIF加载SVG生成器",
        "path": "https://icons8.com/preloaders",
        "icon": "https://icons8.com/preloaders/img/favicons/favicon-32x32.png"
      }
    ]
  },
  {
    groupName: '在线LOGO生成',
    list: [
      {
        "name": "Logonews - 标志情报局",
        "path": "https://www.logonews.cn",
        "icon": "https://www.logonews.cn/favicon.ico"
      },
      {
        "name": "人工智能为您在线LOGO设计 企业VI",
        "path": "https://www.logosc.cn",
        "icon": "https://www.logosc.cn/favicon-logosc.ico?v=4"
      },
      {
        "name": "标智客 - 免费logo设计生成",
        "path": "http://www.logomaker.com.cn",
        "icon": "http://www.logomaker.com.cn/favicon.ico"
      },
      {
        "name": "U钙网 - 免费快速设计LOGO",
        "path": "http://www.uugai.com",
        "icon": "http://www.uugai.com/favicon.ico"
      },
      {
        "name": "Logo Free - LOGO在线制作",
        "path": "http://www.logofree.cn",
        "icon": "http://www.logofree.cn/uploads/image/20170623/17/favicon.jpg"
      },
      {
        "name": "Logoaa -  分分钟做一个logo",
        "path": "https://www.logoaa.com",
        "icon": "https://www.logoaa.com/favicon.ico"
      },
      {
        "name": "标点狗 -  点一点做LOGO",
        "path": "https://www.logoko.com.cn",
        "icon": "https://www.logoko.com.cn/favicon.ico"
      },
      {
        "name": "Logo Lounge -  大量logo为设计师提供创作灵感",
        "path": "https://www.logolounge.com",
        "icon": "http://www.logofree.cn/uploads/image/20170623/17/favicon.jpg"
      },
      {
        "name": "logopond -  全球最大的logo资源库",
        "path": "https://logopond.com",
        "icon": "https://logopond.com/favicon.ico"
      },
      {
        "name": "为您的获取徽标的最智能、最快捷的方式",
        "path": "https://www.squadhelp.com/logos",
        "icon": "https://www.squadhelp.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '透明免扣素材',
    list: [
      {
        "name": "用于网页设计的 PNG 图像和剪贴画",
        "path": "https://pngimg.com",
        "icon": "https://pngimg.com/favicon.ico"
      },
      {
        "name": "特色图标 - 68Design |  素材•欣赏",
        "path": "http://sc.68design.net/tb",
        "icon": "http://sc.68design.net/favicon.ico"
      },
      {
        "name": "站长素材PNG图标 -  icon/png图标下载大全",
        "path": "http://sc.chinaz.com/tubiao",
        "icon": "http://sc.chinaz.com/favicon.ico"
      },
      {
        "name": "UE设计图标 -  提供国外资源源文件下载分享",
        "path": "http://dl.uedna.com/tag/图标",
        "icon": "https://www.uemo.net/favicon.ico"
      },
      {
        "name": "觅元素 - PNG免抠元素/图标/背景",
        "path": "http://www.51yuansu.com/?z=rersvhmgj",
        "icon": "http://www.51yuansu.com/favicon.ico"
      },
      {
        "name": "▩ Vector Magic - 位图转矢量",
        "path": "https://zh.vectormagic.com",
        "icon": "https://d2f7anuvnar8n5.cloudfront.net/p/assets/m/drawings/vector-magic-favicon-16_0ecdb1e703ec78d5817712c998c3584b.png"
      }
    ]
  },
  {
    groupName: '试客平台',
    list: [
      {
        "name": "聚折良品-汇聚全网折扣精品&分享平台",
        "path": "http://www.ju77.com/i/77-3-53.htm",
        "icon": "http://ju77.qingting365.cn/img/icon.ico"
      },
      {
        "name": "贝省 - 我的省钱助手",
        "path": "https://www.beisheng.com",
        "icon": "https://h0.beicdn.com/open201946/38e7f128ff4e3e19_40x40.png"
      },
      {
        "name": "易购网-商城返利/超级红包/签到赚钱",
        "path": "http://www.egou.com",
        "icon": ""
      },
      {
        "name": "返还网-网购省钱助手/搜淘宝券",
        "path": "http://www.fanhuan.com",
        "icon": "http://www.fanhuan.com/favicon.ico"
      },
      {
        "name": "宝贝网-免费购物试用平台",
        "path": "http://www.baobeio.com",
        "icon": "http://www.baobeio.com/favicon.ico"
      },
      {
        "name": "众划算-一个省钱购物的平台",
        "path": "http://www.zhonghuasuan.com",
        "icon": "http://www.zhonghuasuan.com/favicon.ico"
      },
      {
        "name": "领有惠（每天惠）-O2O活动平台 [  手机APP ]",
        "path": "https://jinshuju.net/f/q7D4N7",
        "icon": "https://jinshuju.net/favicon.ico"
      }
    ]
  },
  {
    groupName: '淘宝客推广',
    list: [
      {
        "name": "淘客喵-发群助手/查券/分佣",
        "path": "http://www.taokemiao.com",
        "icon": "http://static.taokemiao.com/static/images/logo/favicon.ico"
      },
      {
        "name": "淘客助手-整合全网数据 打造淘客生态",
        "path": "http://www.taokezhushou.com",
        "icon": "http://www.taokezhushou.com/favicon.ico"
      },
      {
        "name": "大淘客-打造导购内容生态",
        "path": "http://www.dataoke.com",
        "icon": "http://www.dataoke.com/favicon.ico"
      },
      {
        "name": "互力微信淘客系统-智能化微信淘客盈利系统",
        "path": "http://www.fqurl.cn",
        "icon": "http://www.fqurl.cn/favicon.ico"
      }
    ]
  },
  {
    groupName: '广告 营销 文案',
    list: [
      {
        "name": "素材情报监测、创意灵感搜索产品",
        "path": "https://cem.marketingdesk.cn/lp/page27/?platform_source=3&page_id=1111&bd_vid=11625828099449893436",
        "icon": "https://cem.marketingdesk.cn/favicon.ico"
      },
      {
        "name": "36氪研究院  - 专注新经济领域研究",
        "path": "https://36kr.com/academe.html",
        "icon": "https://36kr.com/favicon.ico"
      },
      {
        "name": "广告人的网址导航",
        "path": "https://www.addog.vip",
        "icon": "https://www.addog.vip/favicon.ico"
      },
      {
        "name": "人人都是产品经理",
        "path": "http://www.woshipm.com",
        "icon": "http://www.woshipm.com/favicon.ico"
      },
      {
        "name": "知识星球-运营高品质社群，知识变现的工具",
        "path": "https://www.zsxq.com",
        "icon": "https://www.zsxq.com/favicon.ico"
      },
      {
        "name": "好奇心日报 - 好奇驱动你的世界",
        "path": "http://www.qdaily.com",
        "icon": "http://www.qdaily.com/favicon.ico"
      },
      {
        "name": "PITCHINA - 顶尖创意人社区",
        "path": "http://www.pitchina.com.cn",
        "icon": "http://www.pitchina.com.cn/Public/Home/images/icon.ico"
      },
      {
        "name": "SocialBeta -  社交媒体和数字营销平台",
        "path": "https://socialbeta.com",
        "icon": "https://socialbeta.com/favicon.ico"
      },
      {
        "name": "梅花网-营销作品宝库",
        "path": "https://www.meihua.info",
        "icon": "https://www.meihua.info/static/images/icon/meihua.ico"
      },
      {
        "name": "我是文案 - 文案與文字工作者心得分享",
        "path": "http://copywrite-tw.com",
        "icon": "http://copywrite-tw.com/wp-content/uploads/2019/12/cropped-我是文案-32x32.jpg"
      },
      {
        "name": "TOPYS | 全球顶尖创意分享平台",
        "path": "https://www.topys.cn",
        "icon": "https://img-sz.topys.cn/ico/favicon.ico"
      },
      {
        "name": "数英网-数字媒体及职业招聘网站",
        "path": "https://www.digitaling.com",
        "icon": "https://www.digitaling.com/favicon.ico"
      },
      {
        "name": "文案狗 | 给文案狗一点灵感!",
        "path": "http://www.wenangou.com",
        "icon": "http://www.wenangou.com/favicon.ico"
      },
      {
        "name": "4A广告提案网",
        "path": "http://www.4aquan.com",
        "icon": ""
      },
      {
        "name": "广告门 | 一个行业的跌宕起伏",
        "path": "http://www.adquan.com",
        "icon": "http://www.adquan.com/favicon.ico"
      },
      {
        "name": "鸟哥笔记 - 做运营推广 上鸟哥笔记",
        "path": "http://www.niaogebiji.com",
        "icon": "http://www.niaogebiji.com/favicon.ico"
      },
      {
        "name": "暖石网 - 从零开始学运营",
        "path": "https://www.nuanshi100.com",
        "icon": "https://nuanshi-static.oss-cn-beijing.aliyuncs.com/naunshi.ico/favicon.ico"
      },
      {
        "name": "网络广告人社区 -  国内外创意广告和营销案例",
        "path": "https://iwebad.com",
        "icon": "https://iwebad.com/favicon.ico"
      },
      {
        "name": "运营派 - 运营、市场、营销、文案学习平台",
        "path": "https://www.yunyingpai.com",
        "icon": "https://image.yunyingpai.com/wp/2022/01/RmyhegeskCWnJ5VHeTlU.jpg"
      },
      {
        "name": "运营学堂-专业的互联网运营、新媒体运营",
        "path": "http://www.yunyingxuetang.com",
        "icon": "http://www.yunyingxuetang.com/images/favicon.ico"
      },
      {
        "name": "今日热榜官网",
        "path": "https://tophub.today",
        "icon": "https://tophub.today/favicon.ico"
      },
      {
        "name": "姑婆那些事儿 -  互联网推广运营知识分享平台",
        "path": "http://www.gupowang.com",
        "icon": "http://www.gupowang.com/favicon.ico"
      },
      {
        "name": "押韵助手 - 在线查询押韵的字、词、诗、歌",
        "path": "https://yayun.la/",
        "icon": "https://yayun.la/favicon.ico"
      },
      {
        "name": "小发猫AI智能写作v1.1.8",
        "path": "http://www.xiaofamao.com",
        "icon": "http://www.xiaofamao.com/favicon.ico"
      },
      {
        "name": "押韵助手  - 在线查询押韵的字、词、诗、歌",
        "path": "https://yayun.la",
        "icon": "https://yayun.la/favicon.ico"
      },
      {
        "name": "畅无线书屋 - 畅无线书屋小说阅读网",
        "path": "https://ku30000.com",
        "icon": "https://changwifi.net/favicon.ico"
      },
      {
        "name": "腾讯问卷 - 免费好用的问卷调查系统",
        "path": "https://wj.qq.com",
        "icon": "https://wj.qq.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '精选教程',
    list: [
      {
        "name": "巧匠课堂 -  PS/AI/3Dmax/AE/PR…",
        "path": "https://www.qiaojiang.tv",
        "icon": "https://www.qiaojiang.tv/favicon.ico"
      },
      {
        "name": "虎课网 - 零基础入门学习40+软件",
        "path": "https://huke88.com",
        "icon": "https://huke88.com/favicon.ico"
      },
      {
        "name": "视达 - 电商设计/视觉营销/运营",
        "path": "http://shida66.com",
        "icon": "http://shida66.com/favicon.ico"
      },
      {
        "name": "翼狐网 - 零基础从入门到精通60+",
        "path": "https://www.yiihuu.com",
        "icon": "https://www.yiihuu.com/favicon.ico"
      },
      {
        "name": "羽兔网 - 年轻人都在用的自学设计平台",
        "path": "https://www.yutu.cn",
        "icon": "https://www.yutu.cn/favicon.ico"
      },
      {
        "name": "ps教程自学网 -  滤镜教程/调色教程/合成教程…",
        "path": "http://www.16xx8.com",
        "icon": "http://www.16xx8.com/favicon.ico"
      },
      {
        "name": "我要自学网 -  平面/影视动画/程序开发/机械设计",
        "path": "http://www.51zxw.net/default.aspx",
        "icon": "http://www.51zxw.net/favicon.ico"
      },
      {
        "name": "学汇网 - 软件入门/系统课程/批改/直播",
        "path": "http://www.xuehui.com",
        "icon": "http://static.xuehui.com/www/pc/images/icon16.ico"
      },
      {
        "name": "51CTO学院 - 专业的IT技能学习平台",
        "path": "http://edu.51cto.com",
        "icon": "http://edu.51cto.com/favicon.ico"
      },
      {
        "name": "平面设计学习日记网 - 职业路径/学习日记",
        "path": "http://www.xxriji.cn",
        "icon": "http://www.xxriji.cn/favicon.ico"
      },
      {
        "name": "直线网 - 高品质数字艺术学习平台",
        "path": "http://www.linecg.com",
        "icon": "https://www.linecg.com/favicon.ico"
      },
      {
        "name": "优优教程网 -  软件/教程/设计师/自动标注/灵感",
        "path": "https://uiiiuiii.com",
        "icon": "https://uiiiuiii.com/favicon.ico"
      },
      {
        "name": "CAD自学网 - CAD视频教程/软件下载",
        "path": "http://www.cadzxw.com",
        "icon": "http://www.cadzxw.com/favicon.ico"
      },
      {
        "name": "思缘教程 -  设计/摄影/前端//设计欣赏/问题求助",
        "path": "http://www.missyuan.net",
        "icon": "http://www.missyuan.net/favicon.ico"
      },
      {
        "name": "猫课 - 微电商/淘宝京东无货源开店",
        "path": "https://www.maoke123.com",
        "icon": "https://www.maoke123.com/favicon.ico"
      },
      {
        "name": "网易公开课 - 国际名校..  中国大学视频公开课",
        "path": "https://open.163.com",
        "icon": "https://open.163.com/favicon.ico"
      },
      {
        "name": "网易云课堂 - 在线实用技能学习平台",
        "path": "http://study.163.com",
        "icon": "http://study.163.com/favicon.ico"
      },
      {
        "name": "风变编程 - 用有趣好玩的方式 学编程",
        "path": "https://www.pypypy.cn",
        "icon": "https://www.pypypy.cn/favicon.ico"
      },
      {
        "name": "python - 英文官方版",
        "path": "https://www.python.org",
        "icon": "https://www.python.org/favicon.ico"
      },
      {
        "name": "Django 文档 - 关于 Django  的一切",
        "path": "https://docs.djangoproject.com/zh-hans/2.1",
        "icon": "https://docs.djangoproject.com/favicon.ico"
      },
      {
        "name": "慕课 - 中国大学MOOC 国家精品课程",
        "path": "https://www.icourse163.org",
        "icon": "https://edu-image.nosdn.127.net/32a8dd2a-b9aa-4ec9-abd5-66cd8751befb.png?imageView&quality=100"
      },
      {
        "name": "菜鸟C4D - 与你一起C4D从零开始！",
        "path": "http://www.c4dcn.com",
        "icon": "http://www.c4dcn.com/favicon.ico"
      },
      {
        "name": "ExcelHome云课堂 -  专业的职场技能充电站",
        "path": "http://ke.wooffice.net",
        "icon": "http://ke.wooffice.net/favicon.ico"
      },
      {
        "name": "方方格子 - 专注Excel学习交流  资源下载",
        "path": "http://ffcell.com/index.aspx",
        "icon": "http://ffcell.com/images/f21.png"
      },
      {
        "name": "站酷高高手 - 艺术设计在线教育平台",
        "path": "https://www.gogoup.com",
        "icon": "https://static.gogoup.com/new_static/img/favicon.ico?v=235"
      },
      {
        "name": "蓝桥云课 - 连接高校和企业",
        "path": "https://www.lanqiao.cn/",
        "icon": "https://www.lanqiao.cn/favicon.ico"
      },
      {
        "name": "玺承云学堂 - 电商在线教育品牌",
        "path": "https://yxt.xcect.com/",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f848a4.jpg"
      },
      {
        "name": "Finger - 你的口袋乐器教师",
        "path": "https://www.finger66.com/web",
        "icon": "https://www.finger66.com/favicon.ico"
      },
      {
        "name": "flowkey - 最创新的学琴方式",
        "path": "https://app.flowkey.com",
        "icon": "https://app.flowkey.com/favicon.ico"
      },
      {
        "name": "编织人生 - 权威手工编织网站",
        "path": "http://www.bianzhirensheng.com",
        "icon": "http://www.bianzhirensheng.com/favicon.ico"
      },
      {
        "name": "日日煮 DayDayCook -  发现生活的味道",
        "path": "http://www.daydaycook.com.cn/daydaycook",
        "icon": "http://www.daydaycook.com.cn/favicon.ico"
      },
      {
        "name": "下厨房 - 唯有美食与爱不可辜负",
        "path": "http://www.xiachufang.com",
        "icon": "http://www.xiachufang.com/favicon.ico"
      },
      {
        "name": "美食杰 - 美食|菜谱大全|食谱|美食网",
        "path": "https://www.meishij.net",
        "icon": "https://www.meishij.net/favicon.ico"
      }
    ]
  },
  {
    groupName: '店铺装修',
    list: [
      {
        "name": "追梦设计-淘宝/天猫装修助手",
        "path": "http://www.bangox.com/plugin.php?id=doiog_qbmb:qbmbx74",
        "icon": "http://www.bangox.com/favicon.ico"
      },
      {
        "name": "疯狂的美工-电商设计师服务平台",
        "path": "https://www.fkdmg.com",
        "icon": "https://www.fkdmg.com/favicon.ico"
      },
      {
        "name": "稿定设计-淘宝天猫/京东/阿里[免费生成]",
        "path": "http://www.001daima.com/index.php?get=active&getmo",
        "icon": "http://www.001daima.com/favicon.ico"
      },
      {
        "name": "EasyCSS网页版-网店高级装修工具",
        "path": "http://easycss.design61.cn",
        "icon": "http://file.alicdm.com/img/taomage.ico"
      },
      {
        "name": "小语言淘宝天猫版-其他店铺装修工具5款",
        "path": "http://zxtb.net/temp/index.php",
        "icon": "http://www.zxtb.net/favicon.ico"
      },
      {
        "name": "醉语言编辑器-淘宝DIY在线布局工具",
        "path": "http://www.ecplay.com/z1_8.html",
        "icon": "http://www.ecplay.com/favicon.ico"
      },
      {
        "name": "荣青设计-模板创意装修辅助工具",
        "path": "http://www.47rq.com/tool/index.php",
        "icon": "http://www.47rq.com/favicon.ico"
      },
      {
        "name": "350店铺模板-智能装修模块 告别代码",
        "path": "http://login.350.net/?redirect_url=http://u.350.net/",
        "icon": "http://login.350.net/favicon.ico"
      },
      {
        "name": " HTML编辑器（淘宝） ",
        "path": "http://www.bangox.com/plugin.php?id=doiog_qr10:qr11",
        "icon": "http://www.bangox.com/favicon.ico"
      },
      {
        "name": " 淘宝开放平台Widget ",
        "path": "http://open.taobao.com/docs/doc.htm?articleId=102541&docType=1&spm=a219a.7386797.0.0.V2QGy3&treeId=10",
        "icon": "https://img.alicdn.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '设计欣赏',
    list: [
      {
        "name": "奇迹秀—因设计·而美丽",
        "path": "http://www.qijishow.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e6a924.ico"
      },
      {
        "name": "站酷 - 中国人气设计师互动平台",
        "path": "https://www.zcool.com.cn",
        "icon": "https://static.zcool.cn/git_z/z/site/favicon.ico?version=1644228114726"
      },
      {
        "name": "花瓣 - 花瓣•陪你做生活的设计师",
        "path": "http://huaban.com",
        "icon": "http://huaban.com/favicon.ico"
      },
      {
        "name": "大作 - 全球顶级100✚ 设计网站",
        "path": "http://www.bigbigwork.com",
        "icon": "http://www.bigbigwork.com/favicon.ico"
      },
      {
        "name": "致设计 - 电商设计师交流平台",
        "path": "http://www.zhisheji.com",
        "icon": "http://www.zhisheji.com/favicon.ico"
      },
      {
        "name": "UICN用户体验设计平台",
        "path": "https://www.ui.cn",
        "icon": "https://www.ui.cn/favicon.ico"
      },
      {
        "name": "古田路9号-品牌创意平台",
        "path": "http://www.gtn9.com",
        "icon": "http://www.gtn9.com/favicon.ico"
      },
      {
        "name": "优设网 - 设计师交流学习平台",
        "path": "https://www.uisdc.com",
        "icon": "https://www.uisdc.com/favicon.ico"
      },
      {
        "name": "HTML素材网-陪你做好前端设计！",
        "path": "http://www.htmlsucai.com",
        "icon": "http://www.htmlsucai.com/favicon.ico"
      },
      {
        "name": "原创馆（腾讯）-  创意素材征集/优质作品变现",
        "path": "https://ycg.qq.com",
        "icon": "https://ycg.qq.com/favicon.ico"
      },
      {
        "name": "视觉•me -  设计师&插画师交流平台",
        "path": "http://shijue.me",
        "icon": "http://shijue.me/favicon.ico"
      },
      {
        "name": "视觉中国 -  创意图片/创意视频/设计/音乐",
        "path": "https://www.vcg.com",
        "icon": "https://www.vcg.com/favicon.ico"
      },
      {
        "name": "美叶 - 为设计师提供有价值的设计参考",
        "path": "https://www.meiye.art",
        "icon": "https://www.meiye.art/favicon.ico"
      },
      {
        "name": "图形海报 - 来自 44 个不同国家的",
        "path": "https://www.typographicposters.com",
        "icon": "https://www.typographicposters.com/favicon.ico"
      },
      {
        "name": "4DB - 日本网页设计聚合网站",
        "path": "http://4db.cc",
        "icon": "http://4db.cc/common/4db.ico"
      },
      {
        "name": "AD518 - 最设计",
        "path": "https://ad518.com",
        "icon": "https://ad518.com/favicon.ico"
      },
      {
        "name": "网站大奖 - 最佳网页设计趋势",
        "path": "https://www.awwwards.com/?ref=iamxk",
        "icon": "https://www.awwwards.com/favicon.ico"
      },
      {
        "name": "国外设计欣赏网站 - DOOOOR",
        "path": "https://www.doooor.com",
        "icon": "https://www.doooor.com/favicon.ico"
      },
      {
        "name": "收集 UI -  从每日ui档案及其他地方收集的灵感",
        "path": "http://collectui.com",
        "icon": "https://collectui.com/img/favicon.ico"
      },
      {
        "name": "Reeoo - 网页设计灵感和网站画廊",
        "path": "https://reeoo.com",
        "icon": "https://reeoo.com/favicon.ico"
      },
      {
        "name": "爱果果 - 酷站  H5   UI  网页模板  素材   案例",
        "path": "https://www.iguoguo.net",
        "icon": "https://www.iguoguo.net/favicon.ico"
      },
      {
        "name": "最佳网页设计奖 - 网页设计灵感",
        "path": "http://www.webdesignfile.com",
        "icon": "http://www.webdesignfile.com/favicon.ico"
      },
      {
        "name": "世界广告™  - Clio 网络的一部分",
        "path": "http://www.adsoftheworld.com",
        "icon": "https://image.adsoftheworld.com/static/favicon.ico"
      },
      {
        "name": "Carpenter  Collective  设计+品牌工作室",
        "path": "http://carpentercollective.com",
        "icon": "http://carpentercollective.com/favicon.ico"
      },
      {
        "name": "用户体验法则",
        "path": "https://lawsofux.com",
        "icon": "https://lawsofux.com/icons/favicon.ico"
      },
      {
        "name": "LETTERS   INC.",
        "path": "https://www.letters-inc.jp",
        "icon": "https://www.letters-inc.jp/favicon.ico"
      },
      {
        "name": "illust-AC -  免費插圖向量圖素材下載",
        "path": "https://zh-tw.ac-illust.com",
        "icon": "https://www.ac-illust.com/favicon.ico"
      },
      {
        "name": "最佳着陆页示例学习设计  书籍和UI 套件",
        "path": "https://www.lapa.ninja",
        "icon": "https://www.lapa.ninja/favicon.ico"
      },
      {
        "name": "노트폴리오 :: 크리에이티브  네트워크",
        "path": "https://notefolio.net",
        "icon": "https://notefolio.net/favicon.ico"
      }
    ]
  },
  {
    groupName: '高清图库',
    list: [
      {
        "name": "图虫网 - 优质摄影师交流社区",
        "path": "https://tuchong.com",
        "icon": "https://tuchong.com/favicon.ico"
      },
      {
        "name": "500PX -  出色的摄影师社区/500px",
        "path": "https://500px.me",
        "icon": "https://500px.me/favicon.ico"
      },
      {
        "name": "天空之城 -  全球航拍&专业摄影师社交平台",
        "path": "https://www.skypixel.com",
        "icon": "https://spcn-webfront.skypixel.com/skypixel/public/favicon.ico"
      },
      {
        "name": "poco摄影网 - 中国领先时尚摄影平台",
        "path": "https://www.poco.cn/x/works/list",
        "icon": "https://www.poco.cn/favicon.ico"
      },
      {
        "name": "Pixabay - 免费正版高清图片素材库",
        "path": "https://pixabay.com",
        "icon": "https://pixabay.com/favicon.ico"
      },
      {
        "name": "Pexels - 免费摄影图库 Free",
        "path": "https://www.pexels.com",
        "icon": "https://www.pexels.com/favicon.ico"
      },
      {
        "name": "蜂鸟在线 -  摄影爱好者分享技巧&作品",
        "path": "https://photo.fengniao.com/f_101.html",
        "icon": "https://photo.fengniao.com/favicon.ico"
      },
      {
        "name": "站酷海洛 - 正版图片/视频/音乐/字体",
        "path": "https://www.hellorf.com",
        "icon": "https://static.hellorf.com/v180629120952/favicon.ico"
      },
      {
        "name": "中国图库 -  拍遍中国/拍遍美食/征图/维权",
        "path": "http://www.tukuchina.cn",
        "icon": "http://www.tukuchina.cn/favicon.ico"
      },
      {
        "name": "Nicepik - 基于AI的数百万张图库  Free",
        "path": "https://www.pikrepo.com",
        "icon": "https://www.pikrepo.com/public/css/favicon.ico"
      },
      {
        "name": "StockSnap -美丽的免费库存照片  Free",
        "path": "https://stocksnap.io",
        "icon": "https://stocksnap.io/img/favicon.ico"
      },
      {
        "name": "Coverr - 免费网页背景影片  Free",
        "path": "http://www.coverr.co",
        "icon": "http://www.coverr.co/favicon.ico"
      },
      {
        "name": "美丽而独特免费库存照片",
        "path": "https://www.splitshire.com",
        "icon": "https://www.splitshire.com/favicon.ico"
      },
      {
        "name": "免费使用的图片 - 由各地的创作者提供支持",
        "path": "https://unsplash.com",
        "icon": "https://unsplash.com/favicon.ico"
      },
      {
        "name": "公共领域照片 - 个人和专业项目的创意摄影",
        "path": "http://skuawk.com",
        "icon": "http://skuawk.com/favicon.ico"
      },
      {
        "name": "免费的库存照片、背景和免费的高分辨率图像",
        "path": "https://picjumbo.com",
        "icon": "https://picjumbo.com/favicon.ico"
      },
      {
        "name": "Kaboompics - 免费库存照片 +  调色板",
        "path": "https://kaboompics.com",
        "icon": "https://kaboompics.com/favicon.ico"
      },
      {
        "name": "真正独一无二、奇思妙想 - 永远免费",
        "path": "https://gratisography.com",
        "icon": "https://gratisography.com/wp-content/themes/gratis-v3/favicon.png"
      },
      {
        "name": "纸杯蛋糕 - 免版税图片用于商业用途",
        "path": "http://cupcake.nilssonlee.se",
        "icon": "http://cupcake.nilssonlee.se/favicon.ico"
      },
      {
        "name": "Streetwill - 免费高分辨率照片",
        "path": "http://streetwill.co",
        "icon": "http://streetwill.co/favicon64.png?v=2"
      },
      {
        "name": "由 Oliur Ra​​hman  提供的免费素材照片",
        "path": "http://photos.oliur.com",
        "icon": "https://www.oliur.com/wp-content/uploads/2020/04/cropped-oliur-favicon-32x32.png"
      },
      {
        "name": "免费高分辨率摄影",
        "path": "https://www.lifeofpix.com",
        "icon": "https://www.lifeofpix.com/favicon.ico"
      },
      {
        "name": "DesignersPics -  商业和个人作品的免费照片",
        "path": "http://www.designerspics.com",
        "icon": "http://www.designerspics.com/favicon.ico"
      },
      {
        "name": "好看的免费素材照片",
        "path": "https://stocksnap.io",
        "icon": "https://stocksnap.io/img/favicon.ico"
      },
      {
        "name": "免费图片 - 商业用途的高分辨率图像",
        "path": "https://burst.shopify.com",
        "icon": "https://cdn.shopifycloud.com/stock_photos/assets/global/favicon-ab7018e1fe708a49edcfecce3166032fbeeb1fd7ba4a078c366de344d32ee193.png"
      },
      {
        "name": "Stock - 免费图片、图像和矢量图",
        "path": "https://www.stockvault.net",
        "icon": "https://www.stockvault.net/favicon.ico"
      },
      {
        "name": "Wallhaven.cc - 高清壁纸",
        "path": "https://wallhaven.cc",
        "icon": "https://wallhaven.cc/favicon.ico"
      },
      {
        "name": "高清壁纸：下载高清壁纸（1080p至4K）",
        "path": "https://www.hdwallpapers.net",
        "icon": "https://www.hdwallpapers.net/favicon.ico"
      },
      {
        "name": "iStock - 热门免费食物照片",
        "path": "https://www.foodiesfeed.com",
        "icon": "https://www.foodiesfeed.com/wp-content/uploads/2022/11/cropped-android-chrome-512x512-1-32x32.png"
      },
      {
        "name": "FOTOMEN - – Photo  your life！",
        "path": "https://fotomen.cn",
        "icon": "https://cdn.fotomen.cn/./2022/01/nikonbaobbiao003-90x90.png?_upt=b7711d171644393942"
      },
      {
        "name": "摄影笔记 - 每天一条摄影新知",
        "path": "http://www.sybj.com",
        "icon": "http://www.sybj.com/favicon.ico"
      },
      {
        "name": "中华珍宝馆 - 当代馆 | 历代馆 |  精选馆",
        "path": "http://www.ltfc.net",
        "icon": "http://www.ltfc.net/favicon.ico"
      },
      {
        "name": "ArchDaily | 传播世界建筑",
        "path": "https://www.archdaily.cn/cn",
        "icon": "https://www.archdaily.cn/favicon.ico"
      },
      {
        "name": "免费提供一些高质量的基督教图片库",
        "path": "http://beta.freelyphotos.com",
        "icon": "http://beta.freelyphotos.com/favicon.ico"
      },
      {
        "name": "黑光图库_人像摄影作品展示平台",
        "path": "https://tu.heiguang.com",
        "icon": "https://tu.heiguang.com/favicon.ico"
      },
      {
        "name": "WChictopia -  穿搭造型的灵感来源",
        "path": "http://www.chictopia.com",
        "icon": "http://www.chictopia.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '热点指数',
    list: [
      {
        "name": "百度指数 - 百度数据趋势研究",
        "path": "http://index.baidu.com/v2/index.html",
        "icon": "http://index.baidu.com/favicon.ico"
      },
      {
        "name": "微热点 - 微博数据分析和微博热点",
        "path": "http://www.wrd.cn",
        "icon": "http://www.wrd.cn/favicon.ico"
      },
      {
        "name": "清博 -  融媒体、舆论和产业营销大数据服务者",
        "path": "http://home.gsdata.cn",
        "icon": "http://home.gsdata.cn/images/logo.ico"
      },
      {
        "name": "知微事见 - 最全的互联网社会热点聚合平台",
        "path": "http://ef.zhiweidata.com",
        "icon": "http://ef.zhiweidata.com/favicon.ico"
      },
      {
        "name": "爱奇艺指数 - 监控热点趋势",
        "path": "http://index.iqiyi.com",
        "icon": "https://www.iqiyi.com/favicon.ico"
      },
      {
        "name": "头条指数 - 热点事件分析和行业报告下载",
        "path": "https://index.toutiao.com",
        "icon": "https://lf3-static.bytednsdoc.com/obj/eden-cn/kyhgpdeh7nuvanuhd/count/trendinsight.ico"
      },
      {
        "name": "阿拉丁指数 - 全网小程序观察与连接平台",
        "path": "http://www.aldzs.com",
        "icon": "http://www.aldzs.com/favicon.ico"
      },
      {
        "name": "百度风云榜 - 百度搜索风云排行榜",
        "path": "http://top.baidu.com",
        "icon": "https://www.baidu.com/favicon.ico"
      },
      {
        "name": "网易新闻 - 网易新闻热度排行榜",
        "path": "http://news.163.com/rank",
        "icon": "http://news.163.com/favicon.ico"
      },
      {
        "name": "新榜 - 内容产业服务平台",
        "path": "https://www.newrank.cn",
        "icon": "https://chs.newrank.cn/favicon/favicon.ico"
      },
      {
        "name": "360趋势 -  基于360搜索数据关键词趋势",
        "path": "https://trends.so.com",
        "icon": "https://trends.so.com/favicon.ico"
      },
      {
        "name": "寻艺 - 明星排行榜",
        "path": "http://www.xunyee.cn/rank.html",
        "icon": "http://www.xunyee.cn/favicon.ico"
      }
    ]
  },
  {
    groupName: '智能建站',
    list: [
      {
        "name": "AIPage - 百度智能建站",
        "path": "https://aipage.bce.baidu.com",
        "icon": "https://aipage.bce.baidu.com/favicon.ico"
      },
      {
        "name": "UEMO - 魔艺极速建站",
        "path": "http://www.uemo.net",
        "icon": "http://www.uemo.net/favicon.ico"
      },
      {
        "name": "轻站 - 轻站无代码,零代码建站平台",
        "path": "https://www.qingzhan.com",
        "icon": "https://www.qingzhan.com/favicon.ico"
      },
      {
        "name": "Zion无代码开发平台 - 让微信小程序定制开发更轻松",
        "path": "https://www.functorz.com/",
        "icon": "https://www.functorz.com/favicon.ico"
      },
      {
        "name": "MetInfo - 米拓建站CMS系统",
        "path": "http://www.metinfo.cn",
        "icon": "http://www.metinfo.cn/favicon.ico"
      },
      {
        "name": "意派Coolsite",
        "path": "https://www.coolsite360.com",
        "icon": "https://o3bnyc.creatby.com/admin-coolsite360/favicon.ico"
      },
      {
        "name": "上线了 - 创建网站和小程序",
        "path": "https://www.sxl.cn",
        "icon": "https://www.sxl.cn/favicon.ico"
      },
      {
        "name": "建站小二 -  小程序/公众号/建网站/IWS建站",
        "path": "https://www.jzx2.com",
        "icon": "https://www.jzx2.com/favicon.ico"
      },
      {
        "name": "SiteStar建站之星 -  PC/手机/微信网站/小程序/APP",
        "path": "http://www.sitestar.cn",
        "icon": "http://www.sitestar.cn/favicon.ico"
      },
      {
        "name": "凡科建站 - PC/手机/微信三合一",
        "path": "http://jz.faisco.com",
        "icon": "http://jz.faisco.com/favicon.ico"
      },
      {
        "name": "宝站美 - 企业建站 无需技术 识字即可  自助建站",
        "path": "https://www.baozhanmei.com",
        "icon": "https://www.baozhanmei.com/favicon.ico"
      },
      {
        "name": "起飞页 - 自助建站 | 响应式网站 |  H5网站模板",
        "path": "https://www.qifeiye.com",
        "icon": "https://www.qifeiye.com/qfy-content/uploads/2015/02/0fc92860801c7d70b470ebd24aec4634.png"
      },
      {
        "name": "建站ABC - 建站免费、快速、灵活",
        "path": "https://www.ev123.net",
        "icon": "https://www.ev123.net/favicon.ico"
      },
      {
        "name": "卓老师建站 - 免费快速智能自助建站",
        "path": "http://www.zhuolaoshi.com",
        "icon": "http://www.zhuolaoshi.com/favicon.ico"
      },
      {
        "name": "Strikingly - 免费建站工具",
        "path": "https://cn.strikingly.com",
        "icon": "https://cn.strikingly.com/favicon.ico"
      },
      {
        "name": "PageAdmin - 自助建站系统",
        "path": "http://www.pageadmin.net",
        "icon": "http://www.pageadmin.net/favicon.ico"
      },
      {
        "name": "Bootstrap -  简洁、直观、强悍的前端开发框架",
        "path": "https://www.bootcss.com",
        "icon": "https://fastly.jsdelivr.net/npm/@bootcss/www.bootcss.com@0.0.52/dist/ico/apple-touch-icon-precomposed.png"
      },
      {
        "name": "主题盒子 -  wordpress企业博客主题",
        "path": "https://www.wpzt.net",
        "icon": "https://www.wpzt.net/wp-content/themes/t-box/static/images/favicon.ico"
      },
      {
        "name": "创建专业网站的绝佳平台",
        "path": "https://zh.wix.com",
        "icon": "https://zh.wix.com/favicon.ico"
      },
      {
        "name": "我要表白网-最浪漫的表白网页在线生成",
        "path": "http://www.51bbw.cn/index.html",
        "icon": "http://www.51bbw.cn/static/home/images/favicon.ico"
      },
      {
        "name": "一个最畅销的 WordPress 主题",
        "path": "http://stockholmlanding.select-themes.com",
        "icon": "https://stockholmlanding.qodeinteractive.com/wp-content/uploads/2020/10/Favicon.png"
      },
      {
        "name": "创客云-专注WordPress主题资源分享",
        "path": "https://www.22vd.com",
        "icon": "https://www.22vd.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '专业的H5在线制作工具',
    list: [
      {
        "name": "人人秀 - 制作微信h5微场景平台",
        "path": "https://www.rrxiu.net",
        "icon": "https://www.rrxiu.net/favicon.ico"
      },
      {
        "name": "易企秀 -  邀请函模板|创意营销平台H5页面",
        "path": "http://www.eqxiu.com",
        "icon": "http://www.eqxiu.com/favicon.ico"
      },
      {
        "name": "秀米 - XIUMI",
        "path": "https://xiumi.us",
        "icon": "https://xiumi.us/favicon.ico"
      },
      {
        "name": "初页 - 最潮的音乐照片情感故事记录",
        "path": "http://chuye.cloud7.com.cn",
        "icon": "http://chuye.cloud7.com.cn/favicon.ico"
      },
      {
        "name": "百度H5 - 移动端H5页面快速制作工具",
        "path": "http://h5.baidu.com",
        "icon": "http://h5.baidu.com/favicon.ico"
      },
      {
        "name": "iH5 - 专业的H5制作工具",
        "path": "https://www.ih5.cn/not-logged-in",
        "icon": "https://cjj.zone/xxx/ico/i5.png"
      }
    ]
  },
  {
    groupName: 'web资源下载',
    list: [
      {
        "name": " 17素材 - jQuery网页特效 ",
        "path": "http://www.17sucai.com",
        "icon": "http://www.17sucai.com/favicon.ico"
      },
      {
        "name": "Bootstrap模板_响应式网站模板",
        "path": "http://www.bootstrapmb.com",
        "icon": "http://www.bootstrapmb.com/content/images/fav.png"
      },
      {
        "name": "51前端js特效模板下载平台",
        "path": "https://www.51qianduan.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbfedd7f005.ico"
      },
      {
        "name": "jQueryfuns -  html响应式模板 jquery特效",
        "path": "http://www.jqueryfuns.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": " jQuery插件库 ",
        "path": "http://www.jq22.com",
        "icon": "http://www.jq22.com/favicon.ico"
      },
      {
        "name": " Explore Sandboxes - 代码沙盒 ",
        "path": "https://codesandbox.io/explore",
        "icon": "https://codesandbox.io/favicon.ico"
      },
      {
        "name": " 前端笔记-井井客 ",
        "path": "http://www.jingjingke.com",
        "icon": "http://www.jingjingke.com/favicon.ico"
      },
      {
        "name": " CSDN博客 - 专业IT技术发表平台 ",
        "path": "https://blog.csdn.net",
        "icon": "https://blog.csdn.net/favicon.ico"
      },
      {
        "name": " 博客园 - 代码改变世界 ",
        "path": "https://www.cnblogs.com",
        "icon": "https://www.cnblogs.com/favicon.ico"
      },
      {
        "name": " 图说 - 零编程 玩转图标 ",
        "path": "http://tushuo.baidu.com",
        "icon": "https://tushuo.baidu.com/homepage/asset/img/echarts-logo.png"
      }
    ]
  },
  {
    groupName: '代码生成工具',
    list: [
      {
        "name": " 在线css圆角生成 ",
        "path": "http://tool.58pic.com/yuanjiaoshengcheng",
        "icon": "http://tool.58pic.com/favicon.ico"
      },
      {
        "name": "新拟态 - 生成Soft-UI CSS代码",
        "path": "https://neumorphism.io",
        "icon": "https://neumorphism.io/favicon.ico"
      },
      {
        "name": " CSS3纹理库 ",
        "path": "http://lea.verou.me/css3patterns",
        "icon": "http://lea.verou.me/favicon.ico"
      },
      {
        "name": " HTML和JS互转 ",
        "path": "http://www.linkwan.com/gb/broadmeter/tools/js2html.htm",
        "icon": "http://www.linkwan.com/favicon.ico"
      },
      {
        "name": " QQ在线代码 ",
        "path": "http://www.54kefu.net/qq_daima.html",
        "icon": "http://www.54kefu.net/favicon.ico"
      },
      {
        "name": " 微博秀 ",
        "path": "http://app.weibo.com/tool/weiboshow",
        "icon": "https://weibo.com/favicon.ico"
      },
      {
        "name": " 一键关注 ",
        "path": "https://open.weibo.com/widget/bulkfollow.php",
        "icon": "https://open.weibo.com/favicon.ico"
      },
      {
        "name": " 微博组件 ",
        "path": "https://open.weibo.com/widgets?cat=wb",
        "icon": "https://open.weibo.com/favicon.ico"
      },
      {
        "name": "Editor.md -  开源在线Markdown编辑器",
        "path": "https://pandao.github.io/editor.md",
        "icon": "https://pandao.github.io/editor.md/favicon.ico"
      }
    ]
  },
  {
    groupName: '协作办公',
    list: [
      {
        "name": "百度脑图 - 在线思维导图工具",
        "path": "http://naotu.baidu.com",
        "icon": "http://naotu.baidu.com/favicon.ico"
      },
      {
        "name": "有道云笔记 -  图/文/语音/手写/OCR…",
        "path": "http://note.youdao.com/#f=topnav",
        "icon": "http://note.youdao.com/favicon.ico"
      },
      {
        "name": "印象笔记 -  创建文本&手写笔记",
        "path": "https://www.yinxiang.com",
        "icon": "https://www.yinxiang.com/favicon.ico"
      },
      {
        "name": "ProcessOn -  流程图/思维导图/原型图…",
        "path": "https://www.processon.com",
        "icon": "https://www.processon.com/favicon.ico"
      },
      {
        "name": "蓝湖 - 连接产品/设计/研发流程",
        "path": "https://lanhuapp.com/",
        "icon": "https://cjj.zone/xxx/ico/蓝湖.png"
      },
      {
        "name": "Eagle - 设计师图片管理工具",
        "path": "https://cn.eagle.cool",
        "icon": "https://cn.eagle.cool/favicon.png"
      },
      {
        "name": "WPS文档 - 多人实时协作编辑",
        "path": "https://drive.wps.cn",
        "icon": "https://drive.wps.cn/favicon.ico"
      },
      {
        "name": "Teambition · 简单高效的团队协作工具",
        "path": "https://www.teambition.com/",
        "icon": "https://www.teambition.com/favicon.ico"
      },
      {
        "name": "幕布 - 极简大纲笔记  一键生成思维导图",
        "path": "https://mubu.com",
        "icon": "https://mubu.com/favicon.ico"
      },
      {
        "name": "石墨文档 - 多人在线协作文档/表格",
        "path": "https://shimo.im/welcome",
        "icon": "https://assets.shimonote.com/from_qbox/favicon.ico"
      },
      {
        "name": "GitMind - 在线免费思维导图软件",
        "path": "https://gitmind.cn",
        "icon": "https://qncdnimg.aoscdn.com/local/gitmind.cn/img/favicon.png"
      },
      {
        "name": "伙伴云 - 数据驱动经营",
        "path": "https://www.huoban.com",
        "icon": "https://www.huoban.com/favicon.ico"
      },
      {
        "name": "BDP个人版 - 免费在线数据分析",
        "path": "https://me.bdp.cn/home.html",
        "icon": "https://me.bdp.cn/favicon.ico"
      },
      {
        "name": "语雀 - 专业的云端知识库",
        "path": "https://www.yuque.com",
        "icon": "https://www.yuque.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '配色：色轮 调色板',
    list: [
      {
        "name": "Adobe Color CC - 色輪 |  色彩配置",
        "path": "https://color.adobe.com/zh/create/color-wheel",
        "icon": "https://color.adobe.com/favicon.ico"
      },
      {
        "name": "Colour code -  单击锁定颜色（色调 ← → / 亮度↑ ↓）",
        "path": "https://colourco.de",
        "icon": "https://colourco.de/favicon.ico"
      },
      {
        "name": "CoolHue - Coolest  Gradient Hues 渐变色",
        "path": "https://webkul.github.io/coolhue",
        "icon": "https://webkul.github.io/coolhue/images/coolhue-logo.png"
      },
      {
        "name": "中國傳統顏色 - CHINESE  COLORS",
        "path": "http://zhongguose.com",
        "icon": "http://zhongguose.com/favicon.ico"
      },
      {
        "name": "中国/日本传统色彩 -  Chinese/Japanese",
        "path": "https://color.uisdc.com",
        "icon": "https://color.uisdc.com/favicon.ico"
      },
      {
        "name": "经典配色方案 -  红/橙/黄/绿/青/紫/无色系",
        "path": "http://www.shejidaren.com/examples/tools/jing-dian-pei-se-fang-an/#orange",
        "icon": "http://www.shejidaren.com/favicon.ico"
      },
      {
        "name": "千图在线配色 -  印象配色/智能配色/传图识色",
        "path": "http://www.58pic.com/peise",
        "icon": "http://www.58pic.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '全景图',
    list: [
      {
        "name": "AirPano - 360°航拍全景  地球上最有趣的地方",
        "path": "http://www.airpano.org.cn",
        "icon": "http://www.airpano.org.cn/favicon.ico"
      },
      {
        "name": "全景网 - 专注于视觉价值的提升",
        "path": "http://www.quanjing.com",
        "icon": "http://www.quanjing.com/favicon.ico"
      },
      {
        "name": "720yun -  360度全方位的VR展示与体验",
        "path": "https://720yun.com/",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6f97686.ico"
      },
      {
        "name": "美丽中国_旅游目的地指南",
        "path": "http://www.quanjingke.com/dest",
        "icon": "http://www.quanjingke.com/favicon.ico"
      },
      {
        "name": "微动景-动景VR交流分享社区",
        "path": "https://we.taagoo.com",
        "icon": "https://we.taagoo.com/favicon.ico"
      },
      {
        "name": "全景展示_全景和虚拟现实服务平台",
        "path": "http://www.expoon.com/pano",
        "icon": "http://www.expoon.com/favicon.ico"
      },
      {
        "name": "全景故宫",
        "path": "http://webapp.vizen.cn/gugong_app_pc/index.html",
        "icon": "http://webapp.vizen.cn/favicon.ico"
      }
    ]
  },
  {
    groupName: '查询工具',
    list: [
      {
        "name": "WHOIS ✚ 域名信息查询",
        "path": "https://whois.aliyun.com/?spm=5176.8142029.631162.26.bK4sgG",
        "icon": "https://img.alicdn.com/tfs/TB1_ZXuNcfpK1RjSZFOXXa6nFXa-32-32.ico"
      },
      {
        "name": "ICP/IP地址/域名信息备案管理系统",
        "path": "https://beian.miit.gov.cn/?token=23aa63a6-4e22-42a8-a883-b7653a4670e5#/Integrated/index",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6f96b09.ico"
      },
      {
        "name": "IP.CN -  IP查询/IP列表/DNS/手机/电话号码数据库",
        "path": "https://www.ipip.net/ip.html",
        "icon": "https://www.ipip.net/favicon.ico"
      },
      {
        "name": "同ip网站查询",
        "path": "https://www.webscan.cc",
        "icon": "https://www.webscan.cc/favicon.ico"
      },
      {
        "name": "天眼查 - 查公司/查老板/查关系…",
        "path": "http://www.tianyancha.com",
        "icon": "http://www.tianyancha.com/favicon.ico"
      },
      {
        "name": "企查查 - 查企业 就上企查查!",
        "path": "https://www.qichacha.com",
        "icon": "https://www.qichacha.com/favicon.ico"
      },
      {
        "name": "爱企查 -  免免费查老板、风险、工商注册信息查询系统",
        "path": "https://aiqicha.baidu.com/?from=allp",
        "icon": "https://aiqicha.baidu.com/favicon.ico"
      },
      {
        "name": "中国执行信息公开网 - 司法为民，司法便民",
        "path": "http://zxgk.court.gov.cn/?dt_dapp=1",
        "icon": "http://zxgk.court.gov.cn/static2/img/favicon.ico"
      },
      {
        "name": "工标网 - 查标准上工标网",
        "path": "http://www.csres.com",
        "icon": "http://www.csres.com/favicon.ico"
      },
      {
        "name": "在线国家标准查询 -  国内标准&国外标准",
        "path": "https://biaozhun.supfree.net",
        "icon": "https://www.supfree.net/favicon.ico"
      },
      {
        "name": "国家标准全文公开 - GB强制性国家标准",
        "path": "http://www.gb688.cn/bzgk/gb/index",
        "icon": "http://www.gb688.cn/bzgk//images/dzjg.png"
      },
      {
        "name": "路标 - 商标分类 / 商标公告 /  商标注册 / 商标查询",
        "path": "https://www.chatm.com",
        "icon": "https://www.chatm.com/favicon.ico"
      },
      {
        "name": "学信网 - 中国高等教育学生信息网",
        "path": "https://account.chsi.com.cn/passport/login",
        "icon": "https://account.chsi.com.cn/favicon.ico"
      },
      {
        "name": "全国认证认可信息公共服务平台",
        "path": "http://cx.cnca.cn/CertECloud/index/index/page",
        "icon": "http://cx.cnca.cn/CertECloud/resourses/images/tyCnca.ico"
      },
      {
        "name": "技能人才评价工作网",
        "path": "http://www.osta.org.cn",
        "icon": "https://dcs.conac.cn/image/blue.png"
      },
      {
        "name": "国务院政策文件库_中国政府网",
        "path": "http://www.gov.cn/zhengce/zhengcewenjianku/index.htm",
        "icon": "http://www.gov.cn/favicon.ico"
      },
      {
        "name": "知领政策库_政务服务_中国工程科技知识中心",
        "path": "http://policy.ckcest.cn",
        "icon": "http://policy.ckcest.cn/favicon.ico"
      },
      {
        "name": "国家税务总局",
        "path": "http://www.chinatax.gov.cn",
        "icon": "http://www.gov.cn/favicon.ico"
      },
      {
        "name": "白鹿智库-新政策,公共惠民政策,产业政策法规",
        "path": "http://www.bailuzhiku.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d76be4.ico"
      },
      {
        "name": "中华人民共和国人力资源和社会保障部",
        "path": "http://www.mohrss.gov.cn",
        "icon": "http://www.mohrss.gov.cn/favicon.ico"
      },
      {
        "name": "政策法规库",
        "path": "http://f.mnr.gov.cn",
        "icon": "http://www.mohrss.gov.cn/favicon.ico"
      },
      {
        "name": "国家政务服务平台",
        "path": "http://gjzwfw.www.gov.cn",
        "icon": "http://gjzwfw.www.gov.cn/favicon.ico"
      },
      {
        "name": "国家企业信用信息公示系统",
        "path": "http://www.gsxt.gov.cn/corp-query-homepage.html",
        "icon": "http://gjzwfw.www.gov.cn/favicon.ico"
      },
      {
        "name": "国家税务总局江苏省电子税务局",
        "path": "https://etax.jiangsu.chinatax.gov.cn/sso/login",
        "icon": "https://etax.jiangsu.chinatax.gov.cn/sso/static/img/favicon.ico"
      },
      {
        "name": "查询注册信息 - 手机号/邮箱注册过哪些？",
        "path": "https://reg007.com",
        "icon": "https://reg007.com/favicon.ico"
      },
      {
        "name": "手机靓号网",
        "path": "http://mm.zhibiwl.com/i.html",
        "icon": "https://cjj.zone/xxx/ico/手机卡.png"
      },
      {
        "name": "一证通查 - 全国移动电话卡“一证通查”",
        "path": "https://getsimnum.caict.ac.cn/",
        "icon": "https://getsimnum.caict.ac.cn/webicon.ico"
      },
      {
        "name": "买手机靓号就找“抢卡网”",
        "path": "http://www.qiangka.com",
        "icon": "http://www.qiangka.com/favicon.ico"
      },
      {
        "name": "腾讯网址安全检测 -  网站安全检测/诈骗信息查询",
        "path": "https://guanjia.qq.com/online_server/webindex.html",
        "icon": "https://guanjia.qq.com/favicon.ico"
      },
      {
        "name": "违章代码查询 - 12123违章查询",
        "path": "https://www.jiaoguan.com/",
        "icon": "https://www.jiaoguan.com/favicon.ico"
      },
      {
        "name": "家谱网 - 查询家谱就来中国家谱馆！",
        "path": "http://www.jiapu.tv",
        "icon": "http://www.jiapu.tv/favicon.ico"
      },
      {
        "name": "百度取证 -  知识产权/律师律所/广告治理/个人取证",
        "path": "http://quzheng.baidu.com",
        "icon": "http://quzheng.baidu.com/favicon.ico"
      },
      {
        "name": "小鸡词典 - 快速了解网络流行词汇",
        "path": "https://jikipedia.com",
        "icon": "https://jikipedia.com/favicon.ico"
      },
      {
        "name": "全历史 - 最别具一格的历史网站",
        "path": "https://www.allhistory.com",
        "icon": "https://www.allhistory.com/favicon.ico"
      }
    ]
  },
  {
    groupName: 'Gif动态图',
    list: [
      {
        "name": "动态图片基地 - 搞笑动态图片、动态图、gif图片",
        "path": "https://www.asqql.com/",
        "icon": "https://www.asqql.com/img/myico.ico"
      },
      {
        "name": "壁纸族 - 电脑桌面壁纸,手机壁纸,图片素材",
        "path": "https://www.bizhizu.cn/",
        "icon": "https://p1.xywm.ltd/2023/01/22/63cc399f46e4c.jpg"
      },
      {
        "name": "堆糖 - 美图壁纸兴趣社区",
        "path": "https://www.duitang.com/",
        "icon": "https://www.duitang.com/favicon.ico"
      },
      {
        "name": "逗比拯救世界 - 专业的表情包搜索网站",
        "path": "https://www.dbbqb.com/",
        "icon": "https://www.dbbqb.com/favicon.ico"
      },
      {
        "name": "闪萌 - 中文GIF搜索引擎_gif_动图_表情_斗图",
        "path": "http://www.weshineapp.com/",
        "icon": "http://www.weshineapp.com/favicon.ico"
      },
      {
        "name": "SOOGIF - gif制作,gif图片合成",
        "path": "https://gif-tool.whatthehell.cn/",
        "icon": "https://p1.xywm.ltd/2023/01/22/63cc3b4c14352.png"
      },
      {
        "name": "动图宇宙 - 流行明星动图网站 专业gif搜索引擎",
        "path": "http://www.dongtu.com/",
        "icon": "https://p1.xywm.ltd/2023/01/22/63cc3b5627cf3.png"
      },
      {
        "name": "GIF中文网 - 合成高清gif动图-mp4视频转gif动画",
        "path": "https://www.gif.cn/",
        "icon": "https://p1.xywm.ltd/2023/01/22/63cc3b3f4b893.png"
      },
      {
        "name": "GIPHY - Be Animated",
        "path": "https://giphy.com/",
        "icon": "https://giphy.com/favicon.ico"
      },
      {
        "name": "回车桌面 - 动态图片_高清动态手机壁纸大全",
        "path": "https://sj.enterdesk.com/dongtaibizhi/",
        "icon": "https://www.enterdesk.com/favicon.ico"
      },
      {
        "name": "彼岸桌面 - 电脑壁纸_手机壁纸_高清壁纸",
        "path": "http://www.netbian.com/",
        "icon": "http://www.netbian.com/favicon.ico"
      },
      {
        "name": "美桌壁纸 - 每桌，陪你下载生活的美",
        "path": "http://www.win4000.com/",
        "icon": "http://www.win4000.com/favicon.ico"
      }
    ]
  },
  {
    groupName: '在线背景&纹理',
    list: [
      {
        "name": "Paper texture -  高画质纸张纹理素材 Free",
        "path": "http://free-paper-texture.com",
        "icon": "https://free-paper-texture.com/06_paper/wp-content/themes/paper/images/logo.ico"
      },
      {
        "name": "百变纹理 - TRANSPARENT  TEXTURES",
        "path": "https://www.transparenttextures.com",
        "icon": "https://www.transparenttextures.com/favicon.png"
      },
      {
        "name": "纹理库 - The Pattern  Library",
        "path": "http://thepatternlibrary.com/#jade",
        "icon": "http://thepatternlibrary.com/favicon.ico"
      },
      {
        "name": "在线纹理素材库 - Repeatable  SVG BG",
        "path": "http://www.heropatterns.com",
        "icon": "https://cjj.zone/xxx/ico/纹理.png"
      },
      {
        "name": "The Pattern  Library  - 超好看的平铺纹理图案",
        "path": "http://thepatternlibrary.com/#cocina",
        "icon": "http://thepatternlibrary.com/favicon.ico"
      },
      {
        "name": "炫酷光效在线制作 -  Interactive Generative Art",
        "path": "http://weavesilk.com",
        "icon": "http://weavesilk.com/favicon.ico"
      },
      {
        "name": "3D粒子波浪",
        "path": "http://www.jq22.com/yanshi1600",
        "icon": "http://www.jq22.com/favicon.ico"
      },
      {
        "name": "Star Emission -  放射烟花背景图",
        "path": "https://wangyasai.github.io/Stars-Emmision",
        "icon": "https://wangyasai.github.io/Stars-Emmision/image/1.ico"
      },
      {
        "name": "Flat Surface Shader -  晶格化背景图",
        "path": "http://matthew.wagerfield.com/flat-surface-shader",
        "icon": "http://matthew.wagerfield.com/favicon.ico"
      },
      {
        "name": "Voxelize Image - 体素图像",
        "path": "https://pissang.github.io/voxelize-image",
        "icon": "https://cjj.zone/xxx/ico/立体像素.png"
      },
      {
        "name": "PHOTOMOSH - 制作故障艺术效果",
        "path": "https://photomosh.com",
        "icon": "https://photomosh.com/favicon.ico"
      },
      {
        "name": "双色调图片 - Duotone by  ShapeFactory",
        "path": "https://duotone.shapefactory.co",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cc06cc9269e.png"
      },
      {
        "name": "迷幻波 - CodePen -  Psychedelic waves",
        "path": "https://codepen.io/Yakudoo/full/rJjOJx",
        "icon": "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
      },
      {
        "name": "梯度波 - CodePen SVG  Gradient Wave Generator",
        "path": "https://codepen.io/pissang/full/geajpX",
        "icon": "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
      },
      {
        "name": "色带 - CodePen -  Ribbons 2",
        "path": "https://codepen.io/tsuhre/full/BYbjyg",
        "icon": "https://cpwebassets.codepen.io/assets/favicon/favicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
      },
      {
        "name": "WebGL Fluid  Simulation - 流体模拟",
        "path": "https://paveldogreat.github.io/WebGL-Fluid-Simulation",
        "icon": "https://paveldogreat.github.io/WebGL-Fluid-Simulation/logo.png"
      },
      {
        "name": "SPACE TYPE GENERATOR  - 太空式发电机",
        "path": "http://spacetypegenerator.com/index.html",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8ef39d5.png"
      },
      {
        "name": "waveFont - 波纹文字",
        "path": "https://wangyasai.github.io/waveFont",
        "icon": "https://wangyasai.github.io/waveFont/image/1.ico"
      }
    ]
  },
  {
    groupName: '好玩有趣的网页',
    list: [
      {
        "name": "星空银河",
        "path": "http://html5.huceo.com/yinhe",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f377f8.png"
      },
      {
        "name": "三角特效-交互式三角形背景",
        "path": "http://html5.huceo.com/jhssjbj",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e96fdd.png"
      },
      {
        "name": "重力感应动画",
        "path": "http://html5.huceo.com/zlgy",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6fef07d.png"
      },
      {
        "name": "隧道穿梭",
        "path": "http://html5.huceo.com/suidao",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8eac9aa.png"
      },
      {
        "name": "弹性声波动画",
        "path": "http://html5.huceo.com/shengbo",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8eb5780.png"
      },
      {
        "name": "鼠标经过粒子散开动画",
        "path": "http://html5.huceo.com/lizisuankai",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "可视化音频动画特效",
        "path": "http://html5.huceo.com/viewaudio",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "菱形隧道 - 三维菱形隧道动画特效",
        "path": "http://html5.huceo.com/lxsd",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e75bb0.png"
      },
      {
        "name": "线条冲刺-观感强烈的线条冲刺",
        "path": "http://html5.huceo.com/codevember",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "可视化太空流星雨模拟器",
        "path": "https://www.meteorshowers.org",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8e28db2.png"
      },
      {
        "name": "海洋之音",
        "path": "http://virtocean.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d801f5.png"
      },
      {
        "name": "探索地球的实时卫星图像",
        "path": "https://zoom.earth",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d7f2c8.png"
      },
      {
        "name": "感受四季自由变化-Rainyscope",
        "path": "http://rainyscope.com/#slide-1",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f421d0.png"
      },
      {
        "name": "3D模拟好奇号火星探测器",
        "path": "https://eyes.nasa.gov/curiosity",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8df0d75.png"
      },
      {
        "name": "奇迹秀-网站轰炸机",
        "path": "http://www.qijishow.com/Brand/Brand74/index.htm",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d7115d.png"
      },
      {
        "name": "佩林噪声",
        "path": "https://wangyasai.github.io/Perlin-Noise",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff700f886.png"
      },
      {
        "name": "Perlin Noise Painter",
        "path": "https://wangyasai.github.io/PerlinNoisePainter",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6f95b13.png"
      },
      {
        "name": "Bezier",
        "path": "https://wangyasai.github.io/Bezier",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "Particles Emission",
        "path": "https://wangyasai.github.io/Particles-Emission",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff6fa44e8.png"
      },
      {
        "name": "Staggering Beauty",
        "path": "http://www.staggeringbeauty.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "在线百变万花尺Inspirograph",
        "path": "https://nathanfriend.io/inspirograph",
        "icon": "https://nathanfriend.io/inspiral-web/favicon.ico"
      },
      {
        "name": "新鲜有趣的鼠标互动-david.li",
        "path": "http://david.li",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "纯文字-ertdfgcvb",
        "path": "https://ertdfgcvb.xyz",
        "icon": "https://ertdfgcvb.xyz/ico/icon_192.png"
      },
      {
        "name": "银河系",
        "path": "http://stars.chromeexperiments.com",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "太阳系在线模拟",
        "path": "http://www.faustweb.net/solaris",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f19c06.png"
      },
      {
        "name": "一光年宇宙电台-Lightyear Fm",
        "path": "http://www.lightyear.fm",
        "icon": "http://www.lightyear.fm/apple-touch-icon-57x57.png"
      },
      {
        "name": "____________🚕_____",
        "path": "https://bruno-simon.com",
        "icon": "https://bruno-simon.com/favicon/favicon-32x32.png"
      },
      {
        "name": "坦克Battle City AI",
        "path": "http://juleswang.github.io/BattleCity.AI",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8f31f07.png"
      },
      {
        "name": "指尖陀螺",
        "path": "https://ffffidget.com",
        "icon": "https://ffffidget.com/favicon.ico"
      },
      {
        "name": "高清豪华版捕鱼达人",
        "path": "http://html5.huceo.com/fishjoy",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "2017 Make Me Pulse",
        "path": "http://2017.makemepulse.com",
        "icon": "http://2017.makemepulse.com/assets/images/favicon.png"
      },
      {
        "name": "Emojis & Earth  Porn",
        "path": "http://emojisandearthporn.com",
        "icon": "http://emojisandearthporn.com/images/emojis/100/6.png"
      },
      {
        "name": "BAROQUE.ME",
        "path": "http://www.baroque.me",
        "icon": "https://p1.xywm.ltd/2023/01/21/63cbff8d8bd78.png"
      },
      {
        "name": "假装 Windows 升级界面",
        "path": "https://fakeupdate.net",
        "icon": "https://fakeupdate.net/favicon.gif"
      },
      {
        "name": "蓝屏anon.blue",
        "path": "https://anon.blue",
        "icon": "https://anon.blue/static/favicon.png"
      }
    ]
  }
]
