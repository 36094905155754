<template>
  <div class="picture-page">
    <form ref="formElement" class="picture-form">
<!--      <uswitch v-model:modelValue="form.isOpen" :active=0 labelName="是否开放" propName="isOpen" />-->
<!--      <uswitch v-model:modelValue="form.isVip" :active=1 labelName="是否VIP权限" propName="isVip" />-->
<!--      <button type="button" class="submit" @click="handleSubmit">-->
<!--        <i class="iconfont icon-success"></i>-->
<!--        提交-->
<!--      </button>-->
      <div class="col-left">
        <div class="module">
          <div class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>标题</span>
              <input
                id="title"
                class="item-input"
                required
                type="text"
                name="search"
                maxlength="16"
                v-model.trim="form.title"
                placeholder="标题"
              />
            </div>
          </div>
          <div class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>类型</span>
              <select class="select" v-model="form.categoryId">
                <option v-for="(item, index) in pictureStore.pictureCategoryList" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="form-item">
              <uswitch v-model:modelValue="form.isOpen" :active=0 labelName="是否开放" propName="isOpen" />
            </div>
            <div class="form-item">
              <uswitch v-model:modelValue="form.isVip" :active=1 labelName="VIP权限" propName="isVip" />
            </div>

          </div>
          <div class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>标签</span>
              <div class="tags">
                <div v-for="(item, index) in pictureStore.pictureTagList" class="tag" :class="form.tags.includes(item.id) ? 'selected' : ''" @click="selectTag(item)">
                  <i class="thirdiconfont el-icon-third-tag-i"></i>
                  {{ item.name}}
                </div>
              </div>

            </div>
          </div>
          <div class="line">
            <upload
              ref="upload"
              style="display: none"
              type="jpg,jpeg,png"
              :bulk="2"
              :multiple = true
              @data="res"
              :module-type="3"
              v-model:loading="loading"
            />
            <div class="button-group">
              <button type="button" class="submit" :disabled="loading" @click="selectImage">
                <i class="iconfont icon-success"></i>
                上传图片({{ form.pictures.length }})
              </button>
              <div>
                <button type="submit" class="submit" @click="handleSubmit">
                  <i class="iconfont icon-success"></i>
                  提交
                </button>
              </div>
            </div>

          </div>
          <div class="line">
            <div class="image-area">
              <div v-if="loading" class="loading">
                <load :loading = "loading" />
              </div>
              <div class="pic" v-for="(item,index) in form.pictures" :key="index">
                <div class="picBox">
                  <uimage class="img" :src="item.filePath" alt="" @click="viewImg(item.filePath)"  />
                  <span class="img-del" @click="deleteItem(index)"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, reactive, ref, toRaw, watchEffect } from 'vue'
  import { usePictureStore } from '/@/stores/picture'
  import { VIP, Open } from '/@/constants/state'
  import Upload from "/@/components/common/upload.vue"
  import Load from "/@/components/common/load.vue"
  import { META } from "/@/config/app.config";
  import { LanguageKey } from "/@/language";
  import { useEnhancer } from "/@/app/enhancer";
  import { useUniversalFetch } from "/@/universal";
  import Toast from "/@/composables/toast/index"

  export default defineComponent({
    name: 'PictureUploadPage',
    components: {
      Load,
      Upload
    },
    props: {
      pictureId: {
        type: String,
        required: false
      }
    },
    setup() {
      const { router, globalState, meta, i18n  } = useEnhancer()
      const  pictureStore = usePictureStore()
      meta(() => ({
        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `boiling publish`,
        keywords: `boiling publish keys`
      }))
      const form = reactive({
        title: null,
        pictures: [],
        tags: [],
        categoryId: null,
        isOpen: Open.YES,
        isVip: VIP.NO,
        agent: globalState.userAgent.original
      })

      const formElement = ref<HTMLFormElement>()

      const selectTag = (item: any) => {
        if(form.tags.includes(item.id)){
          form.tags = form.tags.filter(e => e !== item.id);
        }else{
          form.tags.push(item.id)
        }
        console.log(`tags:${form.tags}`)
      }

      const handleSubmit = (event) => {
        event.preventDefault()
        const check_status = formElement.value?.checkValidity()
        if (!check_status) {
          formElement.value?.reportValidity()
          return
        }
        if(form.pictures.length == 0){
          Toast.error("请选择图片")
          return
        }

        try {
          pictureStore.postPicture(toRaw(form)).then((response) => {
            if(response.data){
              Toast.success("上传成功！")
              cleanForm()
              router.push({
                path: '/picture'
              })
            }
          }).catch((e) => {
            Toast.error(e)
          })
        }catch (error: any) {
          console.warn('submit picture failed:', error)
          throw error.message
        }
        return
      }
      const cleanForm = () => {
          form.title = null,
          form.pictures = [],
          form.tags = [],
          form.categoryId = null,
          form.isOpen = Open.YES,
          form.isVip = VIP.NO,
          form.agent = globalState.userAgent.original
      }

      const upload = ref(null);
      const loading  = ref(false);
      const selectImage = () => {
        upload.value.inputClick();
      }

      const res = (data: any) =>{
        form.pictures.push(...data.data)
      }

      const viewImg = (url: string) => {
        window.$popup.vImage(url)
      }
      const deleteItem = (i) => {
        form.pictures.splice(i,1)
      }

      useUniversalFetch(() => pictureStore.fetchCategoryTag())
      return {
        VIP,
        Open,
        form,
        viewImg,
        loading,
        upload,
        res,
        deleteItem,
        selectTag,
        selectImage,
        handleSubmit,
        formElement,
        pictureStore
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .picture-page {
    position: relative;
    width: $container-width + 200;
    margin: 0 auto;
    padding-top: 2.1rem;
    padding-bottom: 3.1rem;
    .picture-form{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .col-left{
        flex: 1;
        .module{
          padding: $lg-gap;
          @include radius-box($sm-radius);
          @include common-bg-module();


        }
      }
    }

  }
  ::v-deep{
    .md{
      --md-bk-color: $module-bg;
    }
  }
  .line{
    display: flex;
    //justify-content: space-between;
    align-items: center;
    width: 100%;
    .form-item{
      display: flex;
      align-items: center;
      flex: 1;
      margin-bottom: $lg-gap;
      padding: 0 $lg-gap;
      /*justify-content: space-between;*/
      .label{
        display: flex;
        justify-content: right;
        align-items: center;
        margin-right: $lg-gap;
        width: 80px;
      }
      label{
        display: flex;
        align-items: center;
        margin: 0 10px 0px 5px;
        cursor: pointer;
      }
      textarea,
      input[type='text'] {
        /*width: 80%;*/
        flex: 1;
        height: 2em;
        line-height: 2em;
        background-color: $module-bg-darker-1;
        @include radius-box($sm-radius);
        @include background-transition();
        border: 1px solid $text-divider;
        resize:none;
        width: 11em;
        margin-right: 0;
        padding: 0;
        text-indent: 0.5em;
        &::-webkit-calendar-picker-indicator {
          display: none;
        }
        &:focus,
        &:hover {
          background-color: $module-bg-hover;
          border: 1px solid $primary;
        }
      }
      .select{
        flex: 1;
        background-color: $module-bg-darker-1;
        @include radius-box($sm-radius);
        @include background-transition();
        border: 1px solid $text-divider;
      }
      .item-input{
        height: 2em;
        line-height: 2em;
        background-color: $module-bg-darker-1;
        @include background-transition();
        &:hover {
          background-color: $module-bg-hover;
        }
      }
      .tags{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 10px 5px;
        flex: 1;
        .tag{
          display: flex;
          align-items: center;
          padding: 0 $xs-gap;
          border: 1px solid $text-divider;
          cursor: pointer;
          margin-right: $lg-gap;
          &:hover {
            border-color: $primary;
            color: $primary;
          }
          &.selected{
            border-color: $primary;
            color: $primary;
          }
          @include radius-box($xs-radius);
          .thirdiconfont,
          .iconfont {
            font-size: 1.5rem;
          }
        }
      }


    }
  }
  .button-group{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 $lg-gap;
    .submit{
      $height: 2em;
      display: block;
      width: 100px;

      //margin-top: $lg-gap;
      line-height: $height;
      border: 1px solid;
      border-color: $primary;
      color: $primary;
      font-size: $font-size-small;
      text-align: center;
      letter-spacing: 1px;
      transition: color $transition-time-fast, background-color $transition-time-fast;
      @include radius-box($xs-radius);

      &:hover {
        color: $text-reversal;
        background-color: $primary;
      }
    }
  }

  .image-area{
    position: relative;
    $img-h-w: 100px;
    display: flex;
    min-height: 100px;
    width: 100%;
    padding: $sm-gap;
    margin-top: 10px;
    flex-wrap: wrap;
    border: 1px dotted $text-divider;
    .loading{
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 100%;
      overflow-y: auto;
      background-color: $module-bg-lighter;
    }
    .pic {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
      width: $img-h-w;
      height: $img-h-w;
      .picBox {
        width: $img-h-w;
        height: $img-h-w;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 2px;
      }
      .img {
        max-height: $img-h-w;
        max-width: $img-h-w;
        border-radius: 2px;
        width: $img-h-w;
        height: $img-h-w;

        backgroundPosition: '50% 40%';
        backgroundSize: cover;
        //background-repeat: no-repeat;
        //background-size: cover;
        //flex: 0 0 auto;
        position: relative;
        cursor: pointer;
      }
      .img-del{
        cursor: pointer;
        width: 18px;
        height: 18px;
        position: absolute;
        top: .98px;
        right: 2px;
        border-radius: 50%;
        border: 1px solid #c5c5c5;
        background: rgba(0,0,0,.4);
        transform: rotate(
            45deg
        );
        &:before{
          content: "";
          width: .8rem;
          height: 1px;
          display: block;
          position: absolute;
          background: #fff;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
        &:after{
          content: "";
          width: .8rem;
          height: 1px;
          display: block;
          position: absolute;
          background: #fff;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%) rotate(90deg);
        }
      }
    }
  }
</style>
