/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { isClient } from '/@/app/environment'
import { TunnelModule } from '/@/constants/tunnel'
import type { InstagramMediaItem } from '/@/server/getters/instagram'
import { delayPromise } from '/@/utils/delayer'
import tunnel from '/@/services/tunnel'
import {OriginState, UniversalKeyValue} from "@/constants/state";
import {Tag} from "@/stores/tag";
import {Category} from "@/stores/category";
import {Language} from "@/language";
import localhost from '/@/services/localhost'
import {ARTICLE_API_PATH} from "@/stores/article";
import nodepress from "@/services/nodepress";

const LENS_NORMAL_DELAY = 480

export interface Adv {
  id: string
  name: string
  cover: string
  link: string
  type: number
}

export const useAdvStore = defineStore('adv', {
  state: () => ({
    advList: {
      fetching: false,
      data: [] as Array<Adv>
    },
  }),
  actions: {
    fetchAdvList() {

      this.advList.fetching = true
      const fetch = localhost.get<any>('/blog/web/adv/api/list');
      const promise = isClient ? delayPromise(520, fetch) : fetch
      return promise
        .then((response) => {
          this.advList.data = response.data
          // console.log(response.data)
        })
        .finally(() => {
          this.advList.fetching = false
        })
    },
  }
})
