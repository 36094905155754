<template>
  <div id="toolbox">
    <div class="tools">
      <button class="rss" :class="theme" @click="toggleTheme">
        <i class="iconfont" :class="themeIcon"></i>
      </button>
      <button class="feedback" :title="t(LanguageKey.FEEDBACK)" @click="handleFeedback">
        <i class="iconfont icon-mail-plane" />
      </button>
<!--      <button class="category" id="category" style="">-->
<!--        <span class="category-item"><i class="iconfont icon-category"></i></span>-->
<!--      </button>-->
      <button v-if="userStore.isLogin && userStore.isUploadAuth" class="upload" @click="goUpload">
        <i class="thirdiconfont el-icon-third-share-upload"></i>
      </button>
      <button
        class="to-page-top"
        :title="t(LanguageKey.TO_TOP)"
        @click="scrollToPageTop"
        @mouseover="setTopButtonState(true, true)"
        @mouseleave="setTopButtonState(false)"
      >
        <i class="iconfont icon-totop" />
      </button>
      <button
        class="to-page-bottom"
        :title="t(LanguageKey.TO_BOTTOM)"
        @click="scrollToNextScreen"
        @mouseover="setBottomButtonState(true, true)"
        @mouseleave="setBottomButtonState(false)"
      >
        <i class="iconfont icon-tobottom" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUserStore } from '/@/stores/user'
  import { LanguageKey } from '/@/language'
  import { Theme } from '/@/composables/theme'
  import { scrollToPageTop, scrollToNextScreen } from '/@/utils/scroller'
  import { VALUABLE_LINKS } from '/@/config/app.config'

  export default defineComponent({
    name: 'Toolbox',
    setup() {
      const { i18n, theme, router, globalState } = useEnhancer()
      const userStore = useUserStore()
      const animationFrameID = ref(0)
      const isTopButtonMouseOver = ref(false)
      const isBottomButtonMouseOver = ref(false)

      const themeValue = theme.theme
      const themeIcon = computed(() => {
        const themeIconMap = {
          [Theme.Light]: 'icon-sun',
          [Theme.Dark]: 'icon-moon'
        }
        return themeIconMap[themeValue.value]
      })

      const toggleTheme = () => {
        theme.toggle()
      }
      const goUpload = () => {
        router.push('/picture-upload')
      }

      const handleFeedback = () => {
        globalState.toggleSwitcher('feedback', true)
      }

      const slowMoveToAnyWhere = () => {
        const step = () => {
          let targetScrollY = window.scrollY
          const currentScrollY = document.body.scrollHeight - window.innerHeight
          if (isBottomButtonMouseOver.value) {
            targetScrollY += 1
          }
          if (isTopButtonMouseOver.value) {
            targetScrollY -= 1
          }
          if (targetScrollY < 0) {
            targetScrollY = 0
          } else if (targetScrollY >= currentScrollY) {
            targetScrollY = currentScrollY
          }
          const canScrollTo = targetScrollY > 0 && targetScrollY < currentScrollY
          if (!canScrollTo) {
            return false
          }
          window.scrollTo(0, targetScrollY)
          if (isBottomButtonMouseOver.value || isTopButtonMouseOver.value) {
            animationFrameID.value = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(animationFrameID.value)
            return false
          }
        }
        animationFrameID.value = window.requestAnimationFrame(step)
      }

      return {
        VALUABLE_LINKS,
        LanguageKey,
        t: i18n.translate,
        scrollToPageTop,
        scrollToNextScreen,
        handleFeedback,
        theme: themeValue,
        themeIcon,
        toggleTheme,
        goUpload,
        userStore,
        setTopButtonState(state: boolean, isStartSlow = false) {
          isTopButtonMouseOver.value = state
          window.cancelAnimationFrame(animationFrameID.value)
          isStartSlow && slowMoveToAnyWhere()
        },
        setBottomButtonState(state: boolean, isStartSlow = false) {
          isBottomButtonMouseOver.value = state
          window.cancelAnimationFrame(animationFrameID.value)
          isStartSlow && slowMoveToAnyWhere()
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  #toolbox {
    $size: $lg-gap * 2.8;
    position: fixed;
    top: 50%;
    right: 0;
    cursor: pointer;
    z-index: $z-index-toolbox;
    width: 100%;
    /*bottom: 28rem;*/
    .tools {
      position: absolute;
      /*right: -12rem;*/
      right: 0;
      top: 50%;
      /*bottom: 5rem;*/
      width: $size;
      /*overflow: hidden;*/

      &:first-child {
        border-top-left-radius: $xs-radius;
        border-top-right-radius: $xs-radius;
      }
      &:last-child {
        border-bottom-left-radius: $xs-radius;
        border-bottom-right-radius: $xs-radius;
      }

      .rss,
      .upload,
      .to-page-top,
      .to-page-bottom,
      /*.category,*/
      .feedback {
        display: block;
        width: $size;
        height: $size;
        line-height: $size;
        text-align: center;
        @include common-bg-module($transition-time-fast);
      }

      .rss {
        color: $rss-primary;
        &:hover {
          background-color: $rss-primary;
          color: $white;
        }
      }

      .upload {
        color: $upload-primary;
        &:hover {
          background-color: $upload-primary;
          color: $white;
        }
      }
      .category {
        transition: opacity $transition-time-fast,
        transform $transition-time-normal cubic-bezier(0.65, 0.05, 0.36, 1);
        /*height: $size;*/
        transform: translateX(0rem);
        z-index: $z-index-toolbox + 1;
        display: flex;
        height: $size;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-color: $module-bg-opaque;
        /*opacity: 0.5;*/
        &:hover {
          opacity: 0.9;
          transform: translateX(-50rem);
          border-top-left-radius: $sm-radius;
          border-bottom-left-radius: $sm-radius;
        }
        .category-item{
          padding: 0 $lg-gap;
        }
      }

      @keyframes feedback-button {
        0% {
          background-position: 92% 0%;
        }
        50% {
          background-position: 9% 100%;
        }
        100% {
          background-position: 92% 0%;
        }
      }

      .feedback {
        color: $white;
        opacity: 0.8;
        background-color: $primary-lighter;
        background: linear-gradient(1deg, $surmon, #9d0f98);
        background-size: 400% 400%;
        animation: feedback-button 3s ease infinite;
        transition: opacity $transition-time-fast;

        .iconfont {
          display: inline-block;
        }

        &:hover {
          opacity: 1;
          background-color: $primary;
        }
      }

      .to-page-bottom {
        height: $size * 0.618;
        line-height: $size * 0.618;
      }
    }
  }
</style>
