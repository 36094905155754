<template>
  <div class="daily">
    <div class="group">
      <div class="header">今日新闻</div>
      <div class="content">
        <news :data="dailyStore.newsData.data" />
      </div>
    </div>
    <div class="group">
      <div class="header">星座运势</div>
      <div class="content">
        <star :data="dailyStore.starData.data" />
      </div>
    </div>
<!--    <div class="group">-->
<!--      <div class="header">历史上的今日</div>-->
<!--      <div class="content">-->
<!--        <div class="tag"></div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, reactive, ref, toRaw, toRefs, onMounted} from "vue";
  import { useUniversalFetch } from '/@/universal'
  import { META } from "/@/config/app.config";
  import { LanguageKey } from "/@/language";
  import { useEnhancer } from "/@/app/enhancer";
  import { useDailyStore } from '/@/stores/scavenge'
  import News from "./news.vue";
  import Star from "./star.vue";


  export default defineComponent({
    name: "Daily",
    components: { News, Star },
    setup(){
      const { globalState, meta, i18n  } = useEnhancer()

      const dailyStore = useDailyStore()

      onMounted(() => {
        dailyStore.fetchNewsData()
        dailyStore.fetchStarData()
      })

      meta(() => ({
        title: `今日看点 - ${META.title}`,
        description: `${META.description}`,
        keywords: `今日看点,${META.keywords}`
      }))

      return {
        dailyStore
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .daily{
    min-height: 800px;
    padding: $lg-gap;
    @include radius-box($sm-radius);
    background-color: $module-bg;
    display: flex;
    flex-direction: column;
    .group{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .header{
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }
</style>
