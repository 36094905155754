<template>
  <div class="star">
    <div class="tag">
      <template v-for="(item, index) in data" :key="index">
        <button
          class="item"
          :class="[item.index, { active: activeTid === item.index }]"
          :title="item.xz"
          @click="handleSwitch(item.index)"
        >
          {{ item.xz }}
        </button>
      </template>
    </div>
    <div class="data">
<!--      <div class="name">{{ activeData?.xz }}</div>-->
      <div class="prop">
        <div class="base-prop">
          <div class="base">
            <span class="prop-key">贵人方向：</span>
            <span class="prop-val">{{ activeData?.grfw }}</span>
          </div>
          <div class="base">
            <span class="prop-key">贵人星座：</span>
            <span class="prop-val">{{ activeData?.grxz }}</span>
          </div>
          <div class="base">
            <span class="prop-key">幸运数字：</span>
            <span class="prop-val">{{ activeData?.xysz }}</span>
          </div>
          <div class="base">
            <span class="prop-key">幸运颜色：</span>
            <span class="prop-val">{{ activeData?.xyys }}</span>
          </div>
          <div class="other">
            <span class="prop-key">爱情运势：</span>
            <span class="prop-val">{{ activeData?.aqys }}</span>
          </div>
          <div class="other">
            <span class="prop-key">财富运势：</span>
            <span class="prop-val">{{ activeData?.cfys }}</span>
          </div>
          <div class="other">
            <span class="prop-key">事业运势：</span>
            <span class="prop-val">{{ activeData?.syys }}</span>
          </div>
          <div class="other">
            <span class="prop-key">整体运势：</span>
            <span class="prop-val">{{ activeData?.ztys }}</span>
          </div>
          <div class="other">
            <span class="prop-key">提示：</span>
            <span class="prop-val">{{ activeData?.ts }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, reactive, ref, toRaw, toRefs, PropType} from "vue";
  import {LanguageKey} from "/@/language";
  import {useEnhancer} from "/@/app/enhancer";

  export default defineComponent({
    name: "Star",
    props: {
      data: {
        type: Array,
        default: []
      }
    },
    setup(props){
      const { globalState, meta, i18n  } = useEnhancer()
      console.log(props.data)
      const activeTid = ref(0)
      const activeData = computed(() => props.data.find((t) => t.index === activeTid.value)!)

      const handleSwitch = (index: number) => {
        activeTid.value = index
      }
      return {
        activeTid,
        activeData,
        handleSwitch
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .star{
    display: flex;
    flex-direction: column;
    .tag{
      display: flex;
      justify-items: start;
      align-items: center;
      margin-bottom: 10px;
      .item{
        padding: $sm-gap;
        background: $module-bg-darker-1;
        border-radius: 5px;
        line-height: 1rem;
        margin-right: $xs-gap;
        cursor: pointer;
        &:hover{
          opacity: 0.5;
        }
      }
    }
    .data{
      .name{
        $size: 50px;
        height: $size;
        width: $size;
      }
      .prop{
        .base-prop{
          display: flex;
          flex-direction: column;
          .base{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 5px 0;
            .prop-key{
              width: 70px;
              text-align: right;
            }
            .prop-val{
              background: $grey;
              padding: $xs-gap;
              border-radius: 5px;
            }
          }
          .other{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 5px 0;
            .prop-key{
              width: 70px;
              text-align: right;
            }
            .prop-val{
              flex: 1;
              //background: $grey;
              padding: $xs-gap;
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
</style>
