/**
 * @file Route transformer
 * @module transform.route
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { RouteName, CategorySlug } from '/@/app/router'

type RouteRecordName = string | symbol | null | undefined

export const getTagFlowRoute = (tagSlug: string) => {
  return `/tag/${tagSlug}`
}

export const getCategoryFlowRoute = (categoryId: string) => {
  return `/category/${categoryId}`
}

export const getSaunterBoneFlowRoute = (boneId: string) => {
  if(boneId){
    return `/saunter/${boneId}`
  }else{
    return `/saunter`
  }
}

export const getDateFlowRoute = (date: string) => {
  return `/date/${date}`
}

export const getArticleDetailRoute = (articleId: string | number) => {
  return `/article/${articleId}`
}

export const getUserDetailRoute = (userId: string | number) => {
  return `/user/${userId}`
}

export const getUserCenterRoute = (userId: string | number, module: string) => {
  return `/user/${userId}/${module}`
}


export const getPageRoute = (routeName: RouteName) => {
  return `/${routeName}`
}

//文章详情
export const isArticleDetail = (name: RouteRecordName) => name === RouteName.Article
//首页
export const isHome = (name: RouteRecordName) => name === RouteName.Home
//圈文
export const isSaunter = (name: RouteRecordName) => name === RouteName.Saunter

export const isSearchFlow = (name: RouteRecordName) => name === RouteName.SearchFlow
export const isGuestbook = (name: RouteRecordName) => name === RouteName.Guestbook
export const isFreelancer = (name: RouteRecordName) => name === RouteName.Freelancer
export const isApp = (name: RouteRecordName) => name === RouteName.App
