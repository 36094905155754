<template>
  <aside class="aside">
    <div class="aside-user">
      <div class="avatar">
        <uimage :src="userStore.user.avatar" :alt="userStore.user.nickName" />
      </div>
      <div class="profile">
        <h3 v-if="userStore.isLogin" class="name">{{ userStore.user.nickName }}</h3>
        <div class="lbtn" v-else>
          <button
            v-for="(target, index) in targets" :key="index"
            class="item"
            :class="[target.id, { active: activeTid === target.id }]"
            :title="target.title"
            @click="authLogin(target.id)"
          >
            <i :class="target.icon" class="thirdiconfont image"></i>
          </button>
        </div>

<!--        <p class="slogan">-->
<!--          <i18n :k="LanguageKey.APP_SLOGAN" />-->
<!--        </p>-->
      </div>
    </div>
    <!--  主题/语言  -->
    <div class="aside-tool">
      <div class="item" @click="toggleTheme">
        <i class="iconfont" :class="themeIcon"></i>
      </div>
      <div class="item">
        <div @click="goUserDetailRoute(userStore.getUserId)"><i class="iconfont icon-setting"></i></div>
      </div>
    </div>
    <!--  菜单  -->
    <div class="aside-nav">
      <nav class="nav-list">
          <router-link v-for="(menu, index) in navStore.getNavList(0, 2)" :key="menu.id"  :to="menu.path" class="item">
<!--            <uimage v-if="menu.imageIcon" class="image-icon" :src="menu.imageIcon" />-->
            <i v-if="menu.icon" class="iconfont" :class="menu.icon"></i>
            <webfont class="text" bolder uppercase>
              {{menu.name}}
            </webfont>
          </router-link>
      </nav>
    </div>
  </aside>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { Language, LanguageKey } from '/@/language'
  import { RouteName, CategorySlug } from '/@/app/router'
  import { Theme } from '/@/composables/theme'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUserStore } from '/@/stores/user'
  import { getDefaultAvatar } from '/@/transforms/avatar'
  import {getPageRoute, getUserDetailRoute} from '/@/transforms/route'
  import { VALUABLE_LINKS, META } from '/@/config/app.config'
  import localhost from "/@/services/localhost";
  import { useNavStore } from '/@/stores/nav'


  export default defineComponent({
    name: 'MobileAside',
    setup() {
      const { theme, i18n, globalState, router } = useEnhancer()
      const userStore = useUserStore()
      const navStore = useNavStore()
      // const avatar = computed(() => userStore.adminInfo.data?.avatar || getDefaultAvatar())

      const goUserDetailRoute = (userId: string) => {
        router.push(getUserDetailRoute(userId))
      }
      const themeIcon = computed(() => {
        const themeIconMap = {
          [Theme.Light]: 'icon-sun',
          [Theme.Dark]: 'icon-moon'
        }
        return themeIconMap[theme.theme.value]
      })

      const targets = [
        {
          id: 'gitee',
          title: 'Gitee',
          icon: 'el-icon-third-gitee'
        },
        {
          id: 'qq',
          title: 'QQ',
          icon: 'el-icon-third-social-qq'
        }
      ]
      const authLogin = async (source: string) => {
        // let params = new URLSearchParams();
        // params.append("source", source);
        try {
          await userStore.fetchUserLogin(source).then((res) => {
            window.location.href = res.data
          })
        } catch (error) {
          console.log("跳转失败！")
        }
      }
      const languageIcon = computed(() => {
        const languageIconMap = {
          [Language.Chinese]: 'icon-chinese',
          [Language.English]: 'icon-english'
        }
        return languageIconMap[i18n.language.value]
      })

      const handleLogin = () => {
        globalState.toggleSwitcher('login', true)
      }

      // useUniversalFetch(() => metaStore.fetchAdminInfo())

      return {
        VALUABLE_LINKS,
        LanguageKey,
        RouteName,
        CategorySlug,
        getPageRoute,
        userStore,
        navStore,
        author: META.author,
        themeIcon,
        languageIcon,
        handleLogin,
        authLogin,
        targets,
        getUserDetailRoute,
        goUserDetailRoute,
        toggleTheme: theme.toggle,
        tooggleLanguage: i18n.toggle
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .aside {
    $border-color: darken($mobile-aside-bg, 3%);
    display: block;
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    color: $white;

    .aside-user {
      $size: 58px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      padding: $gap * 2;
      border-bottom: 1px solid $border-color;

      .avatar {
        width: $size;
        height: $size;
        flex-shrink: 0;
        display: flex;
        justify-items: center;
        align-items: center;
        img {
          width: $size;
          height: $size;
          max-width: 100%;
          border: 2px solid $white;
          @include radius-box(100%);
        }
      }

      .profile {
        height: $size;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $lg-gap;

        .name {
          font-weight: bold;
          margin-top: 0;
          margin-bottom: $sm-gap;
          color: $surmon;
        }

        .slogan {
          margin: 0;
          max-width: 10rem;
          color: $surmon;
          @include text-overflow();
        }

        .lbtn{
          display: flex;
          justify-items: center;
          align-items: center;
        }
        .item{
          margin-left: $lg-gap;
        }
        .gitee{
          i{
            color: $gitee-primary-hover;
          }
        }
        .qq{
          i{
            color: $twitter-primary-hover;
          }
        }
      }
    }

    .aside-tool {
      padding: $gap 0;
      display: flex;
      border-bottom: 1px solid $border-color;

      .item {
        width: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-h2;
      }
    }

    .aside-nav {
      width: 100%;
      margin-top: $sm-gap;

      .nav-list {
        padding: 0;
        margin: 0;

        .item {
          display: block;
          width: 100%;
          height: 3em;
          line-height: 3em;
          padding: 0 1em;
          margin-bottom: 0.5em;
          border: none;
          text-decoration: none;
          font-weight: 700;
          color: $white;
          &:last-child {
            margin-bottom: 0;
          }

          &.link-active {
            color: $primary;
            font-weight: bold;
            background-color: $body-bg;
          }

          &.app {
            display: flex;
            align-items: center;
            margin-top: $gap;
            color: $surmon;
            .icon {
              width: 1em;
              height: 1em;
              margin-right: 1em;
              border-radius: $xs-radius;
            }
          }

          .iconfont {
            width: 1em;
            margin-right: 1em;
            display: inline-block;
            font-weight: normal;
          }

          .newscript {
            margin-left: $sm-gap;
            font-size: $font-size-small;
          }
        }
      }
    }
  }
</style>
