<template>
  <div class="picture">
    图库
  </div>
</template>

<script lang="ts">
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "UserPicture",
    components: {

    },
    setup() {

      return {

      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .picture{

  }
</style>
