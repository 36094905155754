<template>
  <div class="tag-category">
    <div class="category-list" key="list">
      <router-link
        class="item"
        v-for="(category, index) in categoryStore.sorted"
        :title="`${category.name}`"
        :to="getCategoryFlowRoute(category.id)"
        :key="index"
      >
        <i class="iconfont thirdiconfont el-icon-third-tag-i" />
        <span class="name">
              <span>{{category.name}}</span>
<!--              <span class="count">({{ category.num || 0 }})</span>-->
            </span>
      </router-link>
    </div>
<!--    <div ref="wordCloud" id="wordCloud" class="wordCloud"/>-->
<!--    <placeholder-->
<!--      :data="categoryStore.sorted.length"-->
<!--      :fetching="categoryStore.fetching"-->
<!--      :i18n-key="LanguageKey.TAG_PLACEHOLDER"-->
<!--    >-->
<!--      <template #loading>-->
<!--        <ul class="tag-list-skeleton" key="skeleton">-->
<!--          <li v-for="item in 14" :key="item" class="item">-->
<!--            <skeleton-line />-->
<!--          </li>-->
<!--        </ul>-->
<!--      </template>-->
<!--      <template #default>-->
<!--        -->
<!--      </template>-->
<!--    </placeholder>-->
    <div class="tag">
      <router-link
        class="item"
        v-for="(tag, index) in tagStore.tagList"
        :title="`${tag.name}`"
        :to="getTagFlowRoute(tag.id)"
        :key="index"
      >
<!--        <i class="iconfont thirdiconfont el-icon-third-tag-i" />-->
        <span class="name">
              <span>{{tag.name}}</span>
<!--              <span class="count">({{ tag.num || 0 }})</span>-->
            </span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, onBeforeMount, ref} from 'vue'
  import {useArticleCategoryStore, Category, useArticleTagStore} from '/@/stores/article'
  import { LanguageKey } from '/@/language'
  import { getTagFlowRoute, getCategoryFlowRoute } from '/@/transforms/route'

  // import * as echarts from 'echarts';
  // import 'echarts-wordcloud/dist/echarts-wordcloud';
  // import 'echarts-wordcloud/dist/echarts-wordcloud.min';
  // import points from "echarts/types/src/layout/points";
  import {useUniversalFetch} from "/@/universal";
  import {useEnhancer} from "/@/app/enhancer";

  export default defineComponent({
    name: 'DesktopAsideTag',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      // echarts,
    },
    setup() {

      const { router, globalState } = useEnhancer()
      const getTagRoute = (tagId: string) => {
        router.push(getTagFlowRoute(tagId))
      }

      const categoryStore = useArticleCategoryStore()
      // const getTagIcon = (category: Category) => {
      //   return getExtendValue(tag.extends || [], 'icon') || 'icon-tag'
      // }
      const tagStore = useArticleTagStore()
      // echart节点
      // const charts = ref<HTMLElement | null>(null);

      //随机颜色
      const randomColor = () => {
        return 'rgb(' + [
          Math.round(Math.random() * 160),
          Math.round(Math.random() * 160),
          Math.round(Math.random() * 160)
        ].join(',') + ')';
      }
      onMounted(() => {
        // 获取节点
        // console.log(`init echarts`)
        // const myChart = echarts.init(
        //   document.getElementById('wordCloud') as HTMLElement,
        // );
        // myChart.on('click', function (param) {
        //   getTagRoute(param.data.value)
        // });
        // tagStore.fetchTag().then(res => {
        //   tagStore.tagList = res.data
        //   const data = tagStore.tagList.map(val => ({
        //     ...val,
        //     value: val.id,
        //     textStyle:{
        //       color: randomColor(),
        //       lineHeight: 24,
        //       borderRadius: 10,
        //       width: 100,
        //     },
        //     // 鼠标放上的效果
        //     emphasis: {
        //       textStyle: {
        //         color: 'black',
        //       },
        //     }
        //   }));
        //   myChart.setOption({
        //     // tooltip: {
        //     //   backgroundColor: '',
        //     //   borderColor: 'red',
        //     //   textStyle: {
        //     //     color: '#fff',
        //     //   },
        //     // },
        //     series: [{
        //       type: 'wordCloud',
        //       shape: 'circle',
        //       gridSize: 15,
        //       //maskImage: maskImage,
        //       left: 'center',
        //       top: 'center',
        //       width: "250",
        //       height: "300",
        //       right: null,
        //       bottom: null,
        //       sizeRange: [20, 40],
        //       rotationRange: [0, 0],
        //       // rotationStep: 45,
        //       autoSize: {
        //         enable: true,
        //         minSize: 10
        //       },
        //       // textPadding: 10,
        //       // rotationStep: 45,
        //       // gridSize: 8,
        //       drawOutOfBound: false,
        //       textStyle: {
        //         normal: {
        //           fontFamily: 'sans-serif',
        //           fontWeight: 'bold',
        //           // 回调函数 或 颜色值
        //           color: function () {
        //             // 默认 随机颜色
        //             return 'rgb(' + [
        //               Math.round(Math.random() * 160),
        //               Math.round(Math.random() * 160),
        //               Math.round(Math.random() * 160)
        //             ].join(',') + ')';
        //           }
        //         },
        //         // 鼠标进入样式
        //         emphasis: {
        //           focus: "self",
        //           textStyle: {
        //             shadowBlur: 5,
        //             shadowColor: "#F5F5F5",
        //           },
        //         },
        //       },
        //       // emphasis: { // 字高亮时显示的效果
        //       //   textStyle: {
        //       //     shadowBlur: 10, // 阴影距离
        //       //     shadowColor: '#red', // 阴影颜色
        //       //   },
        //       // },
        //       data: data,
        //     }],
        //   });
        // })

      });
      useUniversalFetch(() => tagStore.fetchAll())

      return {
        LanguageKey,
        categoryStore,
        tagStore,
        getTagFlowRoute,
        getCategoryFlowRoute,
        // charts
      }
    }
  })
</script>

<style lang="scss" scoped>
  @use 'sass:math';
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .tag-category {
    margin-bottom: 0;
    /*overflow-y: auto;*/
    width: $aside-width;
    max-width: $aside-width;
    border-top: $gap solid transparent;
    border-bottom: $gap solid transparent;
    .wordCloud{
      width: $aside-width;
      max-width: $aside-width;
      height: 300px;
      margin-top: 5px;
    }
    .tag-list-skeleton {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0 $gap;
      overflow: hidden;

      .item {
        width: calc(50% - #{math.div($gap, 2)});
        height: 1.4em;
        margin-right: 0;
        margin-bottom: $gap;

        &:nth-child(2n-1) {
          margin-right: $gap;
        }
        &:nth-child(4n-1) {
          width: 30%;
          margin-right: $gap;
        }
        &:nth-child(4n) {
          width: calc(70% - #{$gap});
        }
        &:nth-last-child(1),
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }

    .tag,
    .category-list {
      list-style: none;
      padding: 0;
      overflow: hidden;
      margin-left: $gap;
      margin-top: -$gap;
      margin-bottom: $gap;

      .item {
        $height: 2em;
        display: inline-flex;
        margin-right: $sm-gap;
        margin-top: $gap;
        height: $height;
        line-height: $height;
        font-size: $font-size-h6;
        font-family: $font-family-normal;
        @include radius-box($xs-radius);

        .iconfont {
          width: 2em;
          text-align: center;
          background-color: $module-bg-darker-1;
          @include background-transition();
        }

        .name {
          display: block;
          padding: 0 $sm-gap;
          @include background-transition();

          .count {
            margin-left: $xs-gap;
            color: $text-secondary;
          }
        }

        &.link-active {
          .iconfont {
            color: $module-bg-opaque;
            background-color: $primary-translucent;
          }
          .name {
            font-weight: bold;
            color: $text-reversal;
            background-color: $primary;
            .count {
              color: $text-reversal;
            }
          }
        }

        &:not(.link-active) {
          .name {
            background-color: $module-bg-hover;
          }

          &:hover {
            .iconfont {
              background-color: $module-bg-hover;
            }
            .name {
              background-color: $module-bg-darker-3;
            }
          }
        }
      }
    }

    .tag{
      margin-top: $gap;
      border-top: 1px dashed $module-bg-darker-3;
    }
  }
</style>
