<template>
  <div class="profile">
    <form id="profile-form" ref="formElement">
      <div class="profile-form">
        <div class="info">
          <div class="form-item name">
            <div class="label"><span style="color: red">*</span>昵称</div>
            <input
              v-model="profile.nickName"
              required
              type="text"
              name="name"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check">20</span>
          </div>
          <div class="form-item position">
            <div class="label"><span style="color: red">*</span>职位</div>
            <input
              v-model="profile.occupation"
              required
              type="text"
              name="position"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check">20</span>
          </div>
          <div class="form-item company">
            <div class="label">公司</div>
            <input
              v-model="profile.company"
              type="text"
              name="company"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check">20</span>
          </div>
          <div class="form-item introduce">
            <div class="label">介绍</div>
            <textarea
              rows="5"
              resize=none
              v-model="profile.summary"
              name="url"
              autocomplete="on"
              maxlength="200"
            />
            <span class="check">200</span>
          </div>
        </div>
        <div class="avatar">
          <div class="img-box">
            <uimage class="image" :src="profile.avatar" />
            <div v-if="loading" class="loading">
              <load :loading = "loading" />
            </div>
          </div>

          <upload
            ref="upload"
            style="display: none"
            type="jpg,jpeg,png"
            :bulk="2"
            :multiple = true
            @data="res"
            v-model:loading="loading"
          />
          <button type="button" :disabled="loading" @click="selectAvatar" class="btn">
            <div>上传头像</div>
            <i class="iconfont icon-new-window-s"></i>
          </button>
        </div>
      </div>
      <div class="buttons">
        <button type="submit" class="item submit" @click="handleSubmit">
          <i class="iconfont icon-mail-plane" />
          <span class="text">
          提交
        </span>
        </button>
      </div>
    </form>

  </div>
</template>

<script lang="ts">
  import {defineComponent, computed, reactive, ref, toRaw, toRefs} from "vue";
  import { useUniversalFetch } from '/@/universal'
  import { useUserInfoStore } from '/@/stores/user'
  import { META } from "/@/config/app.config";
  import {LanguageKey} from "/@/language";
  import {useEnhancer} from "/@/app/enhancer";
  import Upload from "/@/components/common/upload.vue"
  import Load from "/@/components/common/load.vue"


  export default defineComponent({
    name: "Profile",
    components: {
      Upload,
      Load
    },
    setup(){
      const { globalState, meta, i18n  } = useEnhancer()
      const userInfoStore = useUserInfoStore()
      const profile = reactive({
        nickName: '',
        occupation: '',
        company: '',
        summary: '',
        avatar:'/images/default-avatar.svg'
      })
      meta(() => ({
        title: `个人-基本信息 - ${META.title}`,
        description: `${META.description}`,
        keywords: `个人基本信息,${META.keywords}`
      }))

      const subscribe = userInfoStore.$subscribe((mutation, state) => {
        /*
        * mutation主要包含三个属性值：
        *   events：当前state改变的具体数据，包括改变前的值和改变后的值等等数据
        *   storeId：是当前store的id
        *   type：用于记录这次数据变化是通过什么途径，主要有三个分别是
        *         “direct” ：通过 action 变化的
                  ”patch object“ ：通过 $patch 传递对象的方式改变的
                  “patch function” ：通过 $patch 传递函数的方式改变的
        *
        * */
        // 我们就可以在此处监听store中值的变化，当变化为某个值的时候，去做一些业务操作之类的
        if (state.userBase){
          profile.nickName = state.userBase.data?.nickName
          profile.avatar = state.userBase.data?.avatar
          profile.company = state.userBase.data?.company
          profile.occupation = state.userBase.data?.occupation
          profile.summary = state.userBase.data?.summary
        }
      }, {detached: false})
      const upload = ref(null);
      const loading  = ref(false);
      const bulk = ref(1);
      const selectAvatar = () => {
        upload.value.inputClick();
      }

      const res = (data: object) =>{
        console.log(`${data}`)
        profile.avatar = data?.data[0].filePath
      }
      const formElement = ref<HTMLFormElement>()
      const handleSubmit = (event) => {
        event.preventDefault()
        const formElement = document.getElementById("profile-form")! as HTMLFormElement
        const check_status = formElement.checkValidity()
        formElement.reportValidity()
        if(!check_status){
          return
        }
        userInfoStore.updateUserBaseInfo(toRaw(profile))
      }

      useUniversalFetch(() => {
        return Promise.all([
          userInfoStore.fetchUserBaseInfo()
        ])
      })
      console.log(`profile:${profile}`)
      return {
        upload,
        profile,
        bulk,
        selectAvatar,
        handleSubmit,
        res,
        loading,
        userInfoStore
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $avatar-size: 10rem;
  .profile{
    height: 800px;
    padding: $lg-gap;
    @include radius-box($sm-radius);
    background-color: $module-bg;
/*    background-color: $module-bg;
    &:hover {
      background-color: $module-bg-opaque;
    }*/
    .profile-form {
      /*padding-left: 5.2rem;*/
      display: flex;
      justify-content: center;
      .info {
        flex: 1;
        display: flex;
        padding: $lg-gap;
        margin-bottom: $gap;
        flex-direction: column;
        justify-content: center;
        @include radius-box($sm-radius);
        background-color: $module-bg;
        &:hover {
          background-color: $module-bg-opaque;
        }
        .form-item{
          display: flex;
          margin-bottom: $lg-gap;
          padding: $sm-gap $lg-gap;
          .label{
            display: flex;
            justify-content: right;
            align-items: center;
            margin-right: $lg-gap;
            width: 50px;
          }
          .check{
            display: flex;
            justify-content: left;
            align-items: center;
            margin-left: $sm-gap;
            width: 10px;
            color: $text-divider;
          }
          textarea,
          input {
            width: 80%;
            line-height: 2em;
            text-indent: 3px;
            background-color: $module-bg-darker-1;
            @include radius-box($sm-radius);
            @include background-transition();
            border: 1px solid $text-divider;
            resize:none;
            &:focus,
            &:hover {
              background-color: $module-bg-hover;
              border: 1px solid $primary;
            }
          }
        }
        .name,
        .email,
        .site {

        }
      }
      .avatar{

        width: 300px;
        display: flex;
        margin-bottom: $gap;
        margin-left: $gap * 2;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include radius-box($sm-radius);
        background-color: $module-bg;
        &:hover {
          background-color: $module-bg-opaque;
        }
        .img-box{
          position: relative;
          .image{
            position: relative;
            width: $avatar-size;
            height: $avatar-size;
            border-radius: 50%;
            margin-bottom: $lg-gap;
          }
          .loading{
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            width: 100%;
            overflow-y: auto;
            background-color: $module-bg-lighter;
          }
        }

        .btn{
          /*display: flex;*/
          justify-content: center;
          justify-items: center;
          font-size: $font-size-h2;
          background-color: $text-secondary;
          padding: 0 $gap;
          //margin-right: $gap;
          height: 3rem;
          display: inline-flex;
          align-items: center;
          border-radius: $sm-radius;
          color: $white;
          transition: all $transition-time-fast;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: right;

      .item {
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $mini-radius;
        &[disabled] {
          opacity: 0.7;
        }
        &:not([disabled]):hover {
          color: $link-hover;
        }

        &.cancel {
          width: 7rem;
          color: $text-secondary;
          &:not([disabled]):hover {
            color: $link-color;
          }
        }

        &.submit {
          width: 12rem;
          color: $text-secondary;
          background-color: $module-bg-darker-2;
          &:not([disabled]):hover {
            background-color: $module-bg-darker-3;
          }
        }

        .iconfont {
          margin-right: 1em;
        }

        .text {
          font-weight: bold;
        }
      }
    }
  }
</style>
