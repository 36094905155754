/**
 * @file friend state
 * @module store.friend
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import localhost from '/@/services/localhost'


export interface Friend {
  id: string
  title: string
  icon: string
  description: string
  link: string
}

export const useFriendStore = defineStore('friend', {
  state: () => ({
    friendList: {
      fetching: false,
      data: [] as Array<Friend>
    },
  }),

  actions: {
    fetchFriendList() {
      this.friendList.fetching = true
      const fetch = localhost.get<any>('/blog/web/friend/api/list').then((response) => {
          this.friendList.data = response.data
          // console.log(response.data)
        })
        .finally(() => {
          this.friendList.fetching = false
        })
    },
  }
})
