/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import localhost from '/@/services/localhost'

// export interface Navigation {
//   id: string
//   name: string
//   path: string
//   navType: number
//   terminal: number
//   icon: string
// }

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    navigationOfficeList: {
      fetching: false,
      data: []
    },
    navigationResource: {
      fetching: false,
      recommendList: [],
      groupList: []
    },
  }),
  //获取各个导航
  getters: {

  },
  actions: {
    fetchNavigationOfficeList() {
      this.navigationOfficeList.fetching = true
      const fetch = localhost.get<any>('/blog/web/navigation/api/office/list').then((response) => {
          this.navigationOfficeList.data = response.data
        })
        .finally(() => {
          this.navigationOfficeList.fetching = false
        })
    },

    fetchNavigationResourceList() {
      this.navigationResource.fetching = true
      const fetch = localhost.get<any>('/blog/web/navigation/api/resource/list').then((response) => {
        this.navigationResource.recommendList = response.data.recommendList
        this.navigationResource.groupList = response.data.groupList
      })
        .finally(() => {
          this.navigationResource.fetching = false
        })
    },


    postSubmit(navigationList: any) {
      const fetch = localhost.post<any>('/blog/web/navigation/api/submit', navigationList).then((response) => {
        console.log(`导入成功！`)
      })
        .finally(() => {
          console.log(`导入完成！`)
        })
    },


    postClick(navigation: Partial<any>) {
      const fetch = localhost.post<any>('/blog/web/navigation/api/click', navigation)
    }
  }
})

export const useDailyStore = defineStore('daily', {
  state: () => ({
    newsData: {
      fetching: false,
      data: {}
    },

    starData: {
      fetching: false,
      data: []
    },
  }),

  getters: {

  },
  actions: {
    fetchNewsData() {
      this.newsData.fetching = true
      const fetch = localhost.get<any>('/blog/web/daily/api/news').then((response) => {
        this.newsData.data = response.data
      })
        .finally(() => {
          this.newsData.fetching = false
        })
    },

    fetchStarData() {
      this.starData.fetching = true
      const fetch = localhost.get<any>('/blog/web/daily/api/star').then((response) => {
        this.starData.data = response.data
        console.log(response.data)
      })
        .finally(() => {
          this.starData.fetching = false
        })
    }
  }
})
