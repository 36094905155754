<template>
  <div class="author">
    <div class="header">
      <span class="title">
        <i class="iconfont icon-user" />
        <webfont class="text">
<!--          <i18n :k="LanguageKey.HOT_ARTICLE_LIST_TITLE" />-->
          关于作者
        </webfont>
      </span>
    </div>
    <placeholder :data="articleDetailStore.article" :loading="articleDetailStore.fetching" :i18n-key="LanguageKey.ARTICLE_PLACEHOLDER">
      <template #loading>
        <ul class="article-list-skeleton" key="skeleton">
          <li v-for="item in 5" :key="item" class="item">
            <skeleton-line />
          </li>
        </ul>
      </template>
      <template #default>
        <div class="main">
          <div class="info">
            <div class="avatar">
              <uimage class="image" :src="user?.base.avatar" />
            </div>
            <div class="base">
              <span class="nickName"><router-link :to=getUserDetailRoute(user?.base.id)>{{ user?.base.nickName }}</router-link><level :level=user?.base.level /></span>
              <span class="post">{{ user?.base.occupation }}</span>
            </div>
          </div>
          <div class="other">
            <div class="statistics">
              <div class="item">
                <p class="title">
                  <span class="text">文章</span>
<!--                  <i class="iconfont icon-success"></i>-->
                </p>
                <div class="content">{{ user?.articleNum }}</div>
              </div>
              <div class="item">
                <p class="title">
                  <span class="text">圈文</span>
<!--                  <i class="iconfont icon-success"></i>-->
                </p>
                <div class="content">{{ user?.boilingNum }}</div>
              </div>
              <div class="item">
                <p class="title">
                  <span class="text">关注</span>
<!--                  <i class="iconfont icon-success"></i>-->
                </p>
                <div class="content">{{ user?.followNum }}</div>
              </div>
              <div class="item">
                <p class="title">
                  <span class="text">阅读</span>
<!--                  <i class="iconfont icon-success"></i>-->
                </p>
                <div class="content">{{ user?.byViewNum }}</div>
              </div>

            </div>
          </div>
        </div>
      </template>
    </placeholder>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import * as ANCHORS from '/@/constants/anchor'
  import { LanguageKey } from '/@/language'
  import { useArticleDetailStore } from '/@/stores/article'
  import { getUserDetailRoute } from '/@/transforms/route'
  import { scrollToAnchor } from '/@/utils/scroller'
  import { numberSplit } from '/@/transforms/text'
  import Level from '/@/components/widget/level.vue'

  export default defineComponent({
    name: 'DesktopAsideAuthor',
    components: {
      Level
    },
    setup() {
      const articleDetailStore = useArticleDetailStore()
      const user = computed(() => articleDetailStore.author)
      return {
        ANCHORS,
        LanguageKey,
        articleDetailStore,
        getUserDetailRoute,
        user
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .author {
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      height: 3em;
      line-height: 3em;
      margin: 0;
      padding: 0 $gap;
      border-bottom: 1px dotted $module-bg-darker-1;

      .title {
        .iconfont {
          margin-right: $sm-gap;
        }
      }
    }

    .article-list-skeleton {
      list-style: none;
      padding: $gap;
      margin: 0;

      > .item {
        height: 1em;
        margin-bottom: $gap;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .main{
      $size: 5rem;
      padding: $sm-gap;
      //display: flex;
      //flex-direction: column;
      //align-items: center;
      //justify-content: center;
      .info{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .avatar{
          display: flex;
          align-items: center;
          margin-right: $lg-gap;
          > .image{

            width: $size;
            height: $size;
            border-radius: 100%;
            border: 5px solid $module-bg;
            box-sizing: content-box;
            transition: transform $transition-time-slow;
            &:hover {
              transform: rotate(360deg);
            }
          }
        }
        .base{
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-items: center;
          .nickName,
          .post{
            padding: 5px 0;
          }
          .nickName{
            font-size: $font-size-h3;
            font-weight: bold;
            cursor: pointer;
          }
        }

      }
      .other{
        .statistics {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            .title {
              display: flex;
              align-items: center;
              margin-bottom: 0;

              .text {
                text-transform: uppercase;
                color: $text-secondary;
              }

              .iconfont {
                margin-left: $sm-gap;
                font-size: $font-size-h6;
                color: $text-divider;
                opacity: 0.5;
              }
            }

            .content {
              font-size: $font-size-h6;
              //font-weight: bold;
            }
          }
        }
      }
    }


  }
</style>
