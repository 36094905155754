<template>
  <div class="login">
    <div class="tips">
      <span>请选择以下方式登入</span>
    </div>
    <div class="main">
      <button
        v-for="(target, index) in targets" :key="index"
        class="item"
        :class="[target.id, { active: activeTid === target.id }]"
        :title="target.title"
        @click="authLogin(target.id)"
      >
            <i :class="target.icon" class="thirdiconfont image"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed, toRaw, ref } from 'vue'
  import { LanguageKey } from '/@/language/key'
  import { META, SPONSOR_LINKS } from '/@/config/app.config'
  import localhost from "/@/services/localhost";
  import { useUserStore } from "/@/stores/user";


  enum Event {
    Close = 'close'
  }

  export default defineComponent({
    name: 'Login',
    emits: [Event.Close],
    setup(_, context) {
      const targets = [
        {
          id: 'gitee',
          title: 'Gitee',
          icon: 'el-icon-third-gitee'
        },
        {
          id: 'qq',
          title: 'QQ',
          icon: 'el-icon-third-social-qq'
        }
      ]
      const handleClose = () => context.emit(Event.Close)
      const activeTid = ref(targets[0].id)
      const userStore = useUserStore()
      const activeTarget = computed(() => targets.find((t) => t.id === activeTid.value)!)
      const authLogin = async (source: string) => {
        // let params = new URLSearchParams();
        // params.append("source", source);
        try {
          await userStore.fetchUserLogin(source).then((res) => {
            window.location.href = res.data
          })
        } catch (error) {
          console.log("跳转失败！")
        }


        // localhost.get<any>(`/auth/render/${source}`).then((res) => {
        //   window.location.href = res.data
        // });
      }


      return {
        LanguageKey,
        META,
        targets,
        activeTid,
        activeTarget,
        authLogin,
        handleClose
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .login {
    width: 48rem !important;
    background-color: $module-bg-lighter !important;
    display: flex;
    flex-direction: column;
    .tips {
      line-height: 3em;
      text-align: center;
      background-color: $module-bg-lighter;
      border-bottom: 1px dashed $module-bg-darker-1;
    }
    .main{
      display: flex;
      justify-content: center;
      padding: 4rem;
      .item {
        height: 8rem;
        min-width: 8rem;
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        //.logo {
        //  $size: 3rem;
        //  width: $size;
        //  height: $size;
        //  display: inline-block;
        //  filter: grayscale(1);
        //  text-align: right;
        //
        //  img {
        //    height: $size;
        //    object-fit: contain;
        //  }
        //}

        .title {
          font-weight: bold;
          margin-left: $gap;
          padding: 3px 0;
          color: $text-secondary;
          transition: color $transition-time-fast;
          border-bottom: 2px solid transparent;
        }

        &.gitee,
        &.qq {
          i{
            font-size: 5rem;
          }
        }
        &.gitee{
          i{
            &:hover{
              color: $gitee-primary-hover;
            }
          }
        }
        &.qq{
          i{
            &:hover{
              color: $twitter-primary-hover;
            }
          }
        }
      }
    }
  }
</style>
