<template>
  <div class="article-page">
    <placeholder :loading="fetching">
      <template #loading>
        <article-skeleton :social-count="isMobile ? 3 : 8" :related-count="isMobile ? 2 : 3" />
      </template>
      <template #default>
        <div v-if="article">
          <div class="module margin background overflow">
            <article-content
              :id="ANCHORS.ARTICLE_CONTENT_ELEMENT_ID"
              :readmore-id="ANCHORS.ARTICLE_READMORE_ELEMENT_ID"
              :article="article"
            />
            <div class="divider"></div>
            <article-meta
              :id="ANCHORS.ARTICLE_META_ELEMENT_ID"
              :article="article"
              :plain="isMobile"
            >
              <template #action>
                <article-upvote
                  :likes="article.likeNum"
                  :is-like="isLike"
                  :is-collect="isCollect"
                  :enabled-parkinson="!isMobile && (userAgent.isChrome || userAgent.isFirefox)"
                  @like="handleLike"
                  @collect="handleCollect"
                />
              </template>
            </article-meta>
          </div>
          <div class="module margin background">
            <div class="bridge left"></div>
            <div class="bridge right"></div>
            <article-share
              :id="ANCHORS.ARTICLE_SHARE_ELEMENT_ID"
              :article-id="articleId"
              :likes="article?.likeNum || 0"
              :socials="
                isMobile ? [SocialMedia.Wechat, SocialMedia.Weibo, SocialMedia.Twitter] : []
              "
            />
          </div>
          <div class="module margin overflow">
            <article-neighbour :prev="store.prevArticle" :next="store.nextArticle" />
          </div>
          <div class="module margin overflow">
            <article-related
              :id="ANCHORS.ARTICLE_RELATED_ELEMENT_ID"
              :columns="isMobile ? 2 : 3"
              :count="isMobile ? 4 : 6"
              :articles="store.relatedArticles"
            />
          </div>
        </div>
      </template>
    </placeholder>
    <div class="comment">
      <comment :module-type="ModuleState.Article" :plain="isMobile" :fetching="fetching" :post-id="articleId" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, watch, onBeforeMount, ref, onMounted} from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUniversalFetch } from '/@/universal'
  import { useArticleDetailStore } from '/@/stores/article'
  import { useUserStore } from '/@/stores/user'
  import { useCommentStore } from '/@/stores/comment'
  import * as ANCHORS from '/@/constants/anchor'
  import { UNDEFINED } from '/@/constants/value'
  import { LanguageKey } from '/@/language'
  import { ModuleState } from '/@/constants/state'
  import { SocialMedia } from '/@/components/widget/share.vue'
  import Comment from '/@/components/comment/index.vue'
  import ArticleSkeleton from './skeleton.vue'
  import ArticleContent from './content.vue'
  import ArticleShare from './share.vue'
  import ArticleMeta from './meta.vue'
  import ArticleUpvote from './upvote.vue'
  import ArticleRelated from './related.vue'
  import ArticleNeighbour from './neighbour.vue'
  import Toast from "/@/composables/toast";
import {META} from "@/config/app.config";

  export default defineComponent({
    name: 'ArticleDetail',
    components: {
      Comment,
      ArticleSkeleton,
      ArticleContent,
      ArticleShare,
      ArticleMeta,
      ArticleUpvote,
      ArticleRelated,
      ArticleNeighbour
    },
    props: {
      articleId: {
        type: String,
        required: true
      },
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      console.log(`prop articleId:${props.articleId}`)
      const { i18n, meta, globalState } = useEnhancer()
      const userStore = useUserStore()
      const commentStore = useCommentStore()

      const articleDetailStore = useArticleDetailStore()
      const article = computed(() => articleDetailStore.article || UNDEFINED)

      // 是否点赞/收藏
      const isLike = computed(() => userStore.votes.likes.includes(props.articleId))
      const isCollect = computed(() => userStore.votes.collects.includes(props.articleId))

      //此处设计不当
      const loadComment = ref(true)
      const fetching = computed(() => articleDetailStore.fetching)
      // const isLiked = computed(() =>
      //   Boolean(article.value && universalStore.isLikedPage(article.value.id))
      // )

      const handleCollect = async (callback) => {
        if(!userStore.isLogin){
          Toast.error("请登入！")
          return
        }
        try {
          const flag = await articleDetailStore.postArticleCollect(article.value!.id)
          userStore.changesVotes(flag, 'collects', article.value!.id);
          callback?.()
        } catch (error) {
          const message = i18n.t(LanguageKey.POST_ACTION_ERROR)
          console.warn(message, error)
          alert(message)
        }
      }

      const handleLike = async (callback) => {
        if(!userStore.isLogin){
          Toast.error("请登入！")
          return
        }
        try {
          const flag = await articleDetailStore.postArticleLike(article.value!.id)
          userStore.changesVotes(flag, 'likes', article.value!.id)
          callback?.()
        } catch (error) {
          const message = i18n.t(LanguageKey.POST_ACTION_ERROR)
          console.warn(message, error)
          alert(message)
        }
      }

      // meta(() => ({
      //   title: `导航 - ${META.title}`,
      //   description: `${META.description}`,
      //   keywords: `导航,${META.keywords}`
      // }))

      meta(() => ({
        pageTitle: `${article.value?.title}`,
        description: `${article.value?.description}`,
        keywords: `${article.value?.title}`
      }))

      const fetchArticleDetail = (articleId: string) => {
        return Promise.all([
          articleDetailStore.fetchCompleteArticle({ articleId }),
          commentStore.fetchList({ resourceId: articleId,moduleType: ModuleState.Article, pageNum: 1})
        ])
      }

      onBeforeMount(() => {
        watch(
          () => props.articleId,
          (articleId) => {
            commentStore.createCommentData(articleId)
            fetchArticleDetail(articleId)

          },
          { flush: 'post' }
        )
      })
      onMounted(() => {
        fetchArticleDetail(props.articleId)
      })
      //onBeforeMount
      // useUniversalFetch(() => fetchArticleDetail(props.articleId))

      return {
        ANCHORS,
        SocialMedia,
        userAgent: globalState.userAgent,
        store: articleDetailStore,
        article,
        fetching,
        ModuleState,
        // isLiked,
        loadComment,
        handleLike,
        isLike,
        isCollect,
        handleCollect
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .article-page {
    .module {
      position: relative;

      &.margin {
        margin-bottom: $lg-gap;
      }

      &.background {
        border-radius: $sm-radius;
        @include common-bg-module();
      }

      &.overflow {
        overflow: hidden;
      }

      .divider {
        border-top: 2px dotted $module-bg-darker-1;
      }

      .bridge {
        $distance: 3rem;
        position: absolute;
        top: -$lg-gap;
        width: $lg-gap;
        height: $lg-gap;
        background: linear-gradient(to bottom, $module-bg, $module-bg-darker-1);
        &.left {
          left: $distance;
        }
        &.right {
          right: $distance;
        }
      }
    }
  }
</style>
