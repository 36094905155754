/**
 * @file Root store
 * @module store
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { createPinia, Pinia } from 'pinia'
import { GlobalState } from '/@/app/state'
import { getSSRContext } from '/@/universal'

import {useArticleListStore, useArticleDetailStore, useArticleCategoryStore, useArticleTagStore} from './article'
import { useBoneStore, useBoilingStore } from './saunter'
import { useCategoryStore } from './category'
import { useTagStore } from './tag'
import { useCommentStore } from './comment'
import { useStatisticStore } from './statistic'
import { useLensStore } from './lens'
import { useTwitterStore } from './twitter'
import { useWallpaperStore } from './wallpaper'
import { usePictureStore } from "./picture";
import { useUserStore, useUserInfoStore, useUserBackgroundStore } from "./user"
import { useAdvStore } from "./adv"
import { useNavStore } from "./nav"
import { useRuleStore } from "./exp"
import { useFriendStore } from "./friend"
import { useConfigStore } from "./config"
import { useNavigationStore, useDailyStore } from "./scavenge"

export const useStores = (_pinia?: Pinia) => ({
  articleList: useArticleListStore(_pinia),
  articleDetail: useArticleDetailStore(_pinia),
  articleCategory: useArticleCategoryStore(_pinia),
  articleTag: useArticleTagStore(_pinia),
  bone: useBoneStore(_pinia),
  boiling: useBoilingStore(_pinia),
  category: useCategoryStore(_pinia),
  tag: useTagStore(_pinia),
  comment: useCommentStore(_pinia),
  statistic: useStatisticStore(_pinia),
  user: useUserStore(_pinia),
  userInfo: useUserInfoStore(_pinia),
  userBackground: useUserBackgroundStore(_pinia),
  lens: useLensStore(_pinia),
  picture: usePictureStore(_pinia),//图片
  adv: useAdvStore(_pinia),//广告
  nav: useNavStore(_pinia),//导航
  friend: useFriendStore(_pinia),//友链
  rule: useRuleStore(_pinia),//规则
  config: useConfigStore(_pinia),//规则
  twitter: useTwitterStore(_pinia),
  wallpaper: useWallpaperStore(_pinia),

  navigation: useNavigationStore(_pinia),//导航工具
  daily: useDailyStore(_pinia),//看点
})

export interface UniversalStoreConfig {
  globalState: GlobalState
}
export const createUniversalStore = (config: UniversalStoreConfig) => {
  const pinia = createPinia()

  const fetchBasicStore = () => {
    // https://pinia.esm.dev/ssr/#using-the-store-outside-of-setup
    const stores = useStores(pinia)
    const initFetchTasks = [
      stores.nav.fetchNavList(),
      stores.adv.fetchAdvList(),
      stores.friend.fetchFriendList(),
      stores.articleCategory.fetchAll(),
      stores.articleTag.fetchAll(),
      stores.picture.fetchCategoryTag(),
      stores.rule.fetchRuleList(),
      stores.userInfo.fetchJoinList()
    ]

    // fetch hot articles when desktop only
    if (!config.globalState.userAgent.isMobile) {
      initFetchTasks.push(stores.articleList.fetchHottestList())
    }
    return Promise.all(initFetchTasks)
  }

  const getUserIsLogin = () => {
    const stores = useStores(pinia)
    return stores.user.isLogin;
  }

  return {
    get stores() {
      return useStores(pinia)
    },
    state: pinia.state,
    install: pinia.install,
    serverPrefetch: fetchBasicStore,
    initOnSSRClient() {
      const contextStore = getSSRContext('store')
      if (contextStore) {
        pinia.state.value = contextStore
      } else {
        // fallback when SSR page error
        fetchBasicStore()
      }
    },
    initOnSPAClient() {
      useStores(pinia).user.initOnClient()
      useStores(pinia).config.fetchConfigList()
      return fetchBasicStore()
    },
    //自定义获取 2022-08-31
    // loginStatus: getUserIsLogin()
  }
}
