/**
 * @file App router
 * @module app.router
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import {createRouter, NavigationGuard, NavigationGuardNext, RouteRecordRaw, RouterHistory} from 'vue-router'
import {LanguageKey} from '/@/language'
import {BAD_REQUEST, NOT_FOUND, FORBIDDEN} from '/@/constants/error'
import {isValidDateParam} from '/@/transforms/validate'
import {scrollToPageTop} from '/@/utils/scroller'
import {LayoutColumn} from './state'
// mobile flow
import MobileFlow from '/@/components/flow/mobile/index.vue'
// desktop flow
import IndexFlowPage from '/@/pages/index/index.vue'
import SaunterPage from '/@/pages/saunter/index.vue'

import UserPage from '/@/pages/user/index.vue'
import UserArticlePage from '/@/pages/user/article/index.vue'
import UserBoilingPage from '/@/pages/user/boiling/index.vue'
import UserFollowPage from '/@/pages/user/follow/index.vue'
import UserCommentPage from '/@/pages/user/comment/index.vue'
import UserPicturePage from '/@/pages/user/picture/index.vue'

import ProfilePage from '/@/pages/setting/profile.vue'
import AccountPage from '/@/pages/setting/account.vue'
import BackgroundPage from '/@/pages/setting/background.vue'

import ScavengePage from '/@/pages/scavenge/index.vue'
import ScavengeNavigationPage from '/@/pages/scavenge/navigation.vue'
import ScavengeNewsPage from '/@/pages/scavenge/daily/index.vue'
import ScavengeRecommendPage from '/@/pages/scavenge/recommend.vue'
import ScavengeResourcePage from '/@/pages/scavenge/resource.vue'

import PicturePage from '/@/pages/picture/index.vue'
import MobilePicturePage from '/@/pages/picture/mobile/index.vue'
import PictureUploadPage from '/@/pages/picture/publish/index.vue'

import WritePage from '/@/pages/write/index.vue'

import CategoryFlowPage from '/@/pages/category.vue'
import TagFlowPage from '/@/pages/tag.vue'
import DateFlowPage from '/@/pages/date.vue'
import SearchFlowPage from '/@/pages/search.vue'
// core pages
import ArticleDetailPage from '/@/pages/article/index.vue'
import AboutDesktopPage from '/@/pages/about/desktop.vue'
import AboutMobilePage from '/@/pages/about/mobile.vue'
import GuestbookPage from '/@/pages/guestbook.vue'
import AppPage from '/@/pages/app.vue'
// service pages
import JobPage from '/@/pages/job.vue'
import FreelancerPage from '/@/pages/freelancer.vue'
import NavigationPage from '/@/pages/navigation.vue'
import MerchPage from '/@/pages/merch/index.vue'
import SponsorPage from '/@/pages/sponsor.vue'

import { useUserStore } from '/@/stores/user'
import 'vue-router'

/**
 * vue3 路由元信息 https://router.vuejs.org/zh/guide/advanced/meta.html
 *
 */
declare module 'vue-router' {
  interface RouteMeta {
    responsive?: boolean
    layout?: LayoutColumn
    validate?: (params: any) => Promise<any>
    /** seconds | infinity | false: disabled  */
    ssrCacheAge: number | false,
    auth?: boolean
  }
}

export enum CategorySlug {
  Code = 'code',
  Insight = 'insight'
}

export enum RouteName {
  Home = 'home',
  Article = 'article-detail',
  CategoryFlow = 'category-flow',
  TagFlow = 'tag-flow',
  DateFlow = 'date-flow',
  SearchFlow = 'search-flow',
  Archive = 'archive',
  Saunter = 'saunter',
  User = 'User',
  User_Article = 'user-article',
  User_Boiling = 'user-boiling',
  User_Follow = 'user-follow',
  User_Picture = 'user-picture',
  User_Comment = 'user-comment',
  User_Setting = 'user-setting',

  Setting_Profile = 'setting-profile',
  Setting_Account = 'setting-account',
  Setting_Background = 'setting-background',

  Scavenge = 'scavenge',
  ScavengeNavigation = 'scavenge-navigation',
  ScavengeNews = 'scavenge-news',
  ScavengeRecommend = 'scavenge-recommend',
  ScavengeResource = 'scavenge-resource',

  Picture = 'picture',
  PictureUpload = 'picture-upload',

  Write = 'write',
  Guestbook = 'guestbook',
  About = 'about',
  App = 'app',
  Freelancer = 'freelancer',
  Navigation = 'navigation',
  Job = 'job',
  Merch = 'merch',
  Lens = 'lens',
  Sponsor = 'sponsor',
  Chat = 'chat',
  Error = 'error'

}

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteName.Home,
    components: {
      default: IndexFlowPage,
      mobile: MobileFlow
    },
    meta: {
      layout: LayoutColumn.Right,
      responsive: true,
      ssrCacheAge: 60 * 2 // 2 mins
    }
  },
  {
    path: '/article/:articleId',
    name: RouteName.Article,
    components: {
      default: ArticleDetailPage,
      mobile: ArticleDetailPage
    },
    props: {
      default: (to) => ({ articleId: to.params.articleId }),
      mobile: (to) => ({
        isMobile: true,
        articleId: to.params.articleId
      })
    },
    meta: {
      layout: LayoutColumn.Right,
      responsive: true,
      ssrCacheAge: 30, // 30 seconds
      async validate({ route, i18n }) {
        if (!Number.isInteger(Number(route.params.articleId))) {
          return Promise.reject({
            code: BAD_REQUEST,
            message: i18n.t(LanguageKey.QUERY_PARAMS_ERROR) + 'Article ID → <number>'
          })
        }
      }
    }
  },
  {
    path: '/category/:categoryId',
    name: RouteName.CategoryFlow,
    components: {
      default: CategoryFlowPage,
      mobile: MobileFlow
    },
    props: {
      default: (to) => ({ categoryId: to.params.categoryId }),
      mobile: (to) => ({ categoryId: to.params.categoryId })
    },
    meta: {
      layout: LayoutColumn.Right,
      responsive: true,
      ssrCacheAge: 60 * 2, // 2 mins
      async validate({ route, i18n }) {
        const { categoryId } = route.params
        if (!categoryId) {
          return Promise.reject({
            code: BAD_REQUEST,
            message: i18n.t(LanguageKey.QUERY_PARAMS_ERROR) + 'Category slug → <string>'
          })
        }
      }
    }
  },
  {
    path: '/picture',
    name: RouteName.Picture,
    components: {
      default: PicturePage,
      mobile: MobilePicturePage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Full,
      ssrCacheAge: 60 * 2, // 1 hours
    }
  },
  {
    path: '/picture-upload/:pictureId?',
    name: RouteName.PictureUpload,
    components: {
      default: PictureUploadPage,
      mobile: PictureUploadPage
    },
    props: {
      default: (to) => ({ pictureId: to.params.pictureId }),
      mobile: (to) => ({ pictureId: to.params.pictureId })
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Full,
      ssrCacheAge: 60 * 2, // 1 hours
      auth: true
    }
  },
  {
    path: '/write/:articleId?',
    name: RouteName.Write,
    components: {
      default: WritePage,
      mobile: WritePage
    },
    props: {
      default: (to) => ({ articleId: to.params.articleId }),
      mobile: (to) => ({ articleId: to.params.articleId })
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Full,
      ssrCacheAge: 60 * 2, // 1 hours
      auth: true
    }
  },
  {
    path: '/saunter/:boneId?',
    name: RouteName.Saunter,
    components: {
      default: SaunterPage,
      mobile: SaunterPage
    },
    props: {
      default: (to) => ({ boneId: to.params.boneId }),
      mobile: (to) => ({
        isMobile: true,
        boneId: to.params.boneId
      })
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Normal,
      ssrCacheAge: 60 * 2 // 2 mins

    },

  },
  {
    path: '/user/:userId',
    name: RouteName.User,
    components: {
      default: UserPage,
      mobile: UserPage
    },
    props: {
      default: (to) => ({ userId: to.params.userId }),
      mobile: (to) => ({
        isMobile: true,
        userId: to.params.userId
      })
    },
    redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return '/user/' + to.params.userId + '/article'
    },
    meta: {
      layout: LayoutColumn.Right,
      responsive: true,
      ssrCacheAge: 60 * 2, // 2 mins
    },
    children: [
      {
        path: '',
        name: RouteName.User_Article,
        components: {
          default: UserArticlePage,
          mobile: UserArticlePage
        },
        props: {
          default: (to) => ({ userId: to.params.userId }),
          mobile: (to) => ({
            isMobile: true,
            userId: to.params.userId
          })
        },
        meta: {
          responsive: false,
          ssrCacheAge: 60 * 2 // 2 mins
        }
      },
      {
        path: 'article',
        name: RouteName.User_Article,
        components: {
          default: UserArticlePage,
          mobile: UserArticlePage
        },
        props: {
          default: (to) => ({ userId: to.params.userId }),
          mobile: (to) => ({
            isMobile: true,
            userId: to.params.userId
          })
        },
        meta: {
          responsive: false,
          ssrCacheAge: 60 * 2 // 2 mins
        }
      },
      {
        path: 'boiling',
        name: RouteName.User_Boiling,
        components: {
          default: UserBoilingPage,
          mobile: UserBoilingPage
        },
        props: {
          default: (to) => ({ userId: to.params.userId }),
          mobile: (to) => ({
            isMobile: true,
            userId: to.params.userId
          })
        },
        meta: {
          responsive: false,
          ssrCacheAge: 60 * 2 // 2 mins
        }
      },
      {
        path: 'follow',
        name: RouteName.User_Follow,
        components: {
          default: UserFollowPage,
          mobile: UserFollowPage
        },
        props: {
          default: (to) => ({ userId: to.params.userId }),
          mobile: (to) => ({
            isMobile: true,
            userId: to.params.userId
          })
        },
        meta: {
          responsive: false,
          ssrCacheAge: 60 * 2 // 2 mins
        }
      },
      {
        path: 'comment',
        name: RouteName.User_Comment,
        components: {
          default: UserCommentPage,
          mobile: UserCommentPage
        },
        meta: {
          responsive: false,
          ssrCacheAge: 60 * 2 // 2 mins
        }
      },
      {
        path: 'picture',
        name: RouteName.User_Picture,
        components: {
          default: UserPicturePage,
          mobile: UserPicturePage
        },
        meta: {
          responsive: false,
          ssrCacheAge: 60 * 2 // 2 mins
        }
      }
    ]
  },
  {
    path: '/scavenge',
    name: RouteName.Scavenge,
    components: {
      default: ScavengePage,
      mobile: ScavengePage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2 // 2 mins
    }
  },
  {
    path: '/scavenge/navigation',
    name: RouteName.ScavengeNavigation,
    components: {
      default: ScavengeNavigationPage,
      mobile: ScavengeNavigationPage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2 // 2 mins
    }
  },
  {
    path: '/scavenge/news',
    name: RouteName.ScavengeNews,
    components: {
      default: ScavengeNewsPage,
      mobile: ScavengeNewsPage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2 // 2 mins
    }
  },
  {
    path: '/scavenge/recommend',
    name: RouteName.ScavengeRecommend,
    components: {
      default: ScavengeRecommendPage,
      mobile: ScavengeRecommendPage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2 // 2 mins
    }
  },
  {
    path: '/scavenge/resource',
    name: RouteName.ScavengeResource,
    components: {
      default: ScavengeResourcePage,
      mobile: ScavengeResourcePage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2 // 2 mins
    }
  },
  {
    path: '/setting/profile',
    name: RouteName.Setting_Profile,
    components: {
      default: ProfilePage,
      mobile: ProfilePage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2, // 2 mins
      auth: true
    }
  },
  {
    path: '/setting/account',
    name: RouteName.Setting_Account,
    components: {
      default: AccountPage,
      mobile: BackgroundPage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2, // 2 mins
      auth: true
    }
  },
  {
    path: '/setting/background',
    name: RouteName.Setting_Background,
    components: {
      default: BackgroundPage,
      mobile: BackgroundPage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Left,
      ssrCacheAge: 60 * 2, // 2 mins
      auth: true
    }
  },
  {
    path: '/tag/:tagId',
    name: RouteName.TagFlow,
    components: {
      default: TagFlowPage,
      mobile: MobileFlow
    },
    props: {
      default: (to) => ({ tagId: to.params.tagId }),
      mobile: (to) => ({ tagId: to.params.tagId })
    },
    meta: {
      responsive: true,
      ssrCacheAge: 60 * 2, // 2 mins
      async validate({ route, i18n }) {
        const { tagId } = route.params
        if (!tagId) {
          return Promise.reject({
            code: BAD_REQUEST,
            message: i18n.t(LanguageKey.QUERY_PARAMS_ERROR) + 'Tag slug → <string>'
          })
        }
      }
    }
  },
  {
    path: '/date/:date',
    name: RouteName.DateFlow,
    components: {
      default: DateFlowPage,
      mobile: MobileFlow
    },
    props: {
      default: (to) => ({ date: to.params.date }),
      mobile: (to) => ({ date: to.params.date })
    },
    meta: {
      responsive: true,
      ssrCacheAge: 60 * 60 * 24, // 24 hours
      async validate({ route, i18n }) {
        const { date } = route.params
        if (!date || !isValidDateParam(date)) {
          return Promise.reject({
            code: BAD_REQUEST,
            message: i18n.t(LanguageKey.QUERY_PARAMS_ERROR) + `Invalid date ${date || ''}`
          })
        }
      }
    }
  },
  {
    path: '/search/:keyword',
    name: RouteName.SearchFlow,
    components: {
      default: SearchFlowPage,
      mobile: MobileFlow
    },
    props: {
      default: (to) => ({ keyword: to.params.keyword }),
      mobile: (to) => ({ keyword: to.params.keyword })
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Right,
      ssrCacheAge: false,
      async validate({ route, i18n }) {
        if (!route.params.keyword) {
          return Promise.reject({
            code: BAD_REQUEST,
            message: i18n.t(LanguageKey.QUERY_PARAMS_ERROR) + 'Keywords ?'
          })
        }
      }
    }
  },

  {
    path: '/about',
    name: RouteName.About,
    components: {
      default: AboutDesktopPage,
      mobile: AboutMobilePage
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Full,
      ssrCacheAge: 60 * 60 * 4 // 4 hours
    }
  },
  {
    path: '/guestbook',
    name: RouteName.Guestbook,
    components: {
      default: GuestbookPage,
      mobile: GuestbookPage
    },
    props: {
      mobile: {
        isMobile: true
      }
    },
    meta: {
      responsive: true,
      ssrCacheAge: 60 * 1 // 1 mins
    }
  },
  {
    path: '/app',
    name: RouteName.App,
    components: {
      default: AppPage,
      mobile: AppPage
    },
    props: {
      mobile: {
        isMobile: true
      }
    },
    meta: {
      responsive: true,
      layout: LayoutColumn.Full,
      ssrCacheAge: Infinity
    }
  },
  {
    path: '/merch',
    name: RouteName.Merch,
    component: MerchPage,
    meta: {
      responsive: false,
      layout: LayoutColumn.Full,
      ssrCacheAge: 60 * 60 * 12 // 12 hours
    }
  },
  {
    path: '/job',
    name: RouteName.Job,
    component: JobPage,
    meta: {
      responsive: false,
      layout: LayoutColumn.Full,
      ssrCacheAge: Infinity
    }
  },
  {
    path: '/freelancer',
    name: RouteName.Freelancer,
    component: FreelancerPage,
    meta: {
      responsive: false,
      layout: LayoutColumn.Full,
      ssrCacheAge: Infinity
    }
  },
  {
    path: '/navigation',
    name: RouteName.Navigation,
    component: NavigationPage,
    meta: {
      responsive: false,
      layout: LayoutColumn.Full,
      ssrCacheAge: Infinity
    }
  },
  {
    path: '/sponsor',
    name: RouteName.Sponsor,
    component: SponsorPage,
    meta: {
      responsive: false,
      layout: LayoutColumn.Full,
      ssrCacheAge: Infinity
    }
  },
  {
    name: RouteName.Error,
    path: '/:error(.*)',
    component: {},
    meta: {
      ssrCacheAge: false,
      async validate({ i18n }) {
        return Promise.reject({
          code: NOT_FOUND,
          message: i18n.t(LanguageKey.NOT_FOUND)
        })
      }
    }
  }
]

export interface RouterCreatorOptions {
  history: RouterHistory
  beforeMiddleware?: NavigationGuard | NavigationGuard[]
  afterMiddleware?: NavigationGuardNext | NavigationGuardNext[]
}
export const createUniversalRouter = (options: RouterCreatorOptions) => {
  const router = createRouter({
    routes,
    strict: true,
    history: options.history,
    linkActiveClass: 'link-active',
    scrollBehavior: () => scrollToPageTop()
  })

  if (options.beforeMiddleware) {
    Array.isArray(options.beforeMiddleware)
      ? options.beforeMiddleware.forEach(router.beforeResolve)
      : router.beforeResolve(options.beforeMiddleware)
  }
  if (options.afterMiddleware) {
    Array.isArray(options.afterMiddleware)
      ? options.afterMiddleware.forEach(router.afterEach)
      : router.afterEach(options.afterMiddleware)
  }

  return router
}
