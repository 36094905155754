/**
 * @file State constant
 * @module constant.state
 * @author potatomelon <https://gitee.com/xiaofango>
 */
// 模块类型
export enum ModuleState {
  User = 0, // 用户
  Article = 1, // 文章
  Boiling = 2, // 圈文
  Picture = 3, // 图库
  Comment = 4 // 评论/留言
}


// 转载状态
export enum OriginState {
  Original = 0, // 原创
  Reprint = 1, // 转载
  Hybrid = 2 // 混合
}

// 评论状态
export enum CommentState {
  Open = 0, // 是
  Close = 1, // 否
}

// 发布状态
export enum PublishState {
  Publish = 0, // 审核通过
  Draft = 1, // 审核中
  Reject = 2, // 审核拒绝
}
// 评论宿主页面的 POST_ID 类型
export enum CommentPostType {
  Guestbook = 0 // 留言板
}

// 评论本身的类型
export enum CommentParentType {
  Self = '0' // 自身一级评论
}

// 排序状态
export enum SortType {
  Asc = 1, // 升序
  Desc = -1, // 降序
  Hottest = 2 // 热序
}

export interface UniversalKeyValue {
  name: string
  value: string
}

// 是否VIP
export enum VIP {
  NO = 0, // 是
  YES = 1 // 否
}

// 是否开放
export enum Open {
  YES = 0, // 是
  NO = 1 // 否
}
