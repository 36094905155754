/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { isClient } from '/@/app/environment'
import { TunnelModule } from '/@/constants/tunnel'
import type { InstagramMediaItem } from '/@/server/getters/instagram'
import { delayPromise } from '/@/utils/delayer'
import tunnel from '/@/services/tunnel'
import {OriginState, UniversalKeyValue} from "@/constants/state";
import {Tag} from "@/stores/tag";
import {Category} from "@/stores/category";
import {Language} from "@/language";
import localhost from '/@/services/localhost'
import {Article, ARTICLE_API_PATH, ArticleHeading} from "@/stores/article";
import {PropType} from "vue";
import {User} from "./user";
import Toast from "/@/composables/toast/index"
import {markdownToHTML} from "/@/transforms/markdown";
import {getArticleContentHeadingElementID} from "/@/constants/anchor";
import {PICTURE_API_PATH} from "@/stores/picture";

const LENS_NORMAL_DELAY = 480

export type UserInfo = User

export interface Bone {
  id: string
  name: string
  sort?: number
  categoryId?: number
  categoryName?: string
  description?: string
  icon?: string
  isRecommend?: number
  addNum?: number
  quoteNum?: number
}

export interface BoneMap {
  categoryId: number
  categoryName: string
  boneList: Array<Boiling>,
}

export interface Boiling {
  id: string
  userId: number
  user: User
  boneId: number
  boneName: string
  topicId: number
  content: string
  pictures: []
  likeNum: number
  commentNum: number
  agent: string
  ip: string
  city: string
  publishDate: string
}



export const SAUNTER_API_PATH = '/blog/web/saunter'

export const useBoneStore = defineStore('bone', {
  state: () => ({
    boneMap: [] as Array<BoneMap>,
    boneCategoryList: [] as Array<Bone>
  }),
  getters: {
    fullBoneCategoryList: (state) => {
      const add = [
        {
          id: "0",
          name: "最新",
          micon: "icon-peace"
        },
        {
          id: "1",
          name: "热门",
          micon: "icon-hot-fill"
        },
        {
          id: "2",
          name: "推荐",
          micon: "icon-top-menu"
        },
      ]
      state.boneCategoryList.unshift(...add);
      return state.boneCategoryList;
    },
    getBoneById: (state): (boneId: string) => Bone => (boneId: string) => {
      let bone
      state.boneMap.forEach((item) => {
        item.boneList.forEach((i) => {
          if(i.id == boneId){
            bone = i
          }
        })
      })
      return bone
    },
  },
  actions: {
    fetchBoneList(params: any = {}) {
      const fetch = localhost.get<any>(`${SAUNTER_API_PATH}/api/bone/list`, { params })
        .then((response) => {
          this.boneMap = response.data
          //console.log(`bonMap:${JSON.stringify(this.boneMap)}`)
        })
        .finally(() => {
          // this.boneList = false
        })
    },
    fetchBoneCategoryList() {
      const fetch = localhost.get<any>(`${SAUNTER_API_PATH}/api/bone/category/list`)
        .then((response) => {
          this.boneCategoryList = response.data
        })
        .finally(() => {
          // this.boneList = false
        })
    },
  }
})

export const useBoilingStore = defineStore('boiling', {
  state: () => ({
    list: {
      fetching: false,
      data: [] as Array<Boiling>,
      pagination: null as null | $TODO
    },
    userBoilingList: {
      fetched: false,
      fetching: false,
      data: [] as Array<Boiling>,
      pagination: null as null | $TODO
    }
  }),
  // getters: {},
  actions: {
    fetchBoilingList(params: any = {}) {
      const isRestart = !params.pageNum || params.pageNum === 1

      // 清空已有数据
      if (isRestart) {
        this.list.data = []
        this.list.pagination = null
      }

      this.list.fetching = true
      const fetch = localhost.get<any>(`${SAUNTER_API_PATH}/api/boiling/list`, { params })
      const promise = isClient ? delayPromise(520, fetch) : fetch
      return promise.then((response) => {
        this.list.data.push(...response.data)
        this.list.pagination = response.pagination
      })
        .finally(() => {
          this.list.fetching = false
        })
    },

    changeVoteBoilingListById(flag: boolean, key: string, id: string){
      const boiling = this.list.data.find((item) => item.id === id)
      if (boiling) {
        flag ? boiling[key]++ : boiling[key]--
      }
    },

    fetchUserBoilingList(params: any = {}) {
      const isRestart = !params.pageNum || params.pageNum === 1

      // 清空已有数据
      if (isRestart) {
        this.userBoilingList.data = []
        this.userBoilingList.pagination = null
      }
      this.userBoilingList.fetching = true
      const fetch = localhost.get<any>(`${SAUNTER_API_PATH}/api/center/list`, { params })
      const promise = isClient ? delayPromise(520, fetch) : fetch
      return promise.then((response) => {
        this.userBoilingList.data.push(...response.data)
        this.userBoilingList.pagination = response.pagination
      })
        .finally(() => {
          this.userBoilingList.fetching = false
        })
    },
    //发布圈文
    saveBoiling(params: Partial<any>) {
      return localhost.post<any>(`${SAUNTER_API_PATH}/boiling/save`, params)
    },
    postBoilingLike(boilingId: string) {
      return localhost
        .post(`${SAUNTER_API_PATH}/like`, { resourceId: boilingId })
    },
  }
})

//markdown -> html
const renderMarkdown = (markdown: string): { html: string } => {
  const html = markdownToHTML(markdown, {
    sanitize: false,
    relink: false
  })
  return { html }
}
