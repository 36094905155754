<template>
  <div class="meta" :class="{ plain }">
    <div class="actions">
      <slot name="action"></slot>
    </div>
    <div class="line">
      <i18n zh="本文于" en="Published at" />
      <router-link
        class="link date"
        :title="article.createTime"
        :to="getDateLink(article.createTime)"
      >
        <udate to="YMDm" :date="article.createTime" separator="/" />
      </router-link>
      <i18n zh="发布在" en="in" />

      <router-link
        v-if="article.categoryId"
        class="link category"
        :title="`分类：${firstUpperCase(article.categoryName)}`"
        :to="getCategoryFlowRoute(article.categoryId)"
      >
        <i18n :zh="article.categoryName" :en="firstUpperCase(article.categoryName)" />
      </router-link>
      <span v-else>
        <i18n zh="未知分类下" en="(no catgory)" />
      </span>
      <template v-if="plain"><br /></template>
      <template v-else><divider type="vertical" size="sm" /></template>
      <span v-for="(tag, index) in article.tagList" :key="index">
        <router-link
          class="link tag"
          :title="`标签：${tag.name}`"
          :to="getTagFlowRoute(tag.id)"
        >
          <i18n :zh="`#${tag.name}`" />
        </router-link>
        <span v-if="article.tagList[index + 1]">
          <i18n zh="、" en="," />
        </span>
      </span>
    </div>
    <div class="line">
      <i class="icon iconfont icon-copyright"></i>
      <i18n>
        <template #zh>
          <ulink
            class="link copyright"
            href="https://creativecommons.org/licenses/by-nc/3.0/cn/deed.zh"
            >自由转载 - 署名 - 非商业性使用</ulink
          >
        </template>
        <template #en>
          <ulink
            class="link copyright"
            href="https://creativecommons.org/licenses/by-nc/3.0/cn/deed.en"
            >Creative Commons BY-NC 3.0 CN</ulink
          >
        </template>
      </i18n>
      <template v-if="plain"><br /></template>
      <template v-else><divider type="vertical" /></template>
      <span class="link permalink" @click="copy(articleURL)">
        {{ articleURL }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType } from 'vue'
  import { VALUABLE_LINKS } from '/@/config/app.config'
  import { LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { useArticleDetailStore, Article } from '/@/stores/article'
  import { tagEnName } from '/@/stores/tag'
  import { dateToYMD } from '/@/transforms/moment'
  import { firstUpperCase } from '/@/transforms/text'
  import { getPageURL } from '/@/transforms/url'
  import {
    getArticleDetailRoute,
    getTagFlowRoute,
    getCategoryFlowRoute,
    getDateFlowRoute
  } from '/@/transforms/route'
  import { copy } from '/@/utils/clipboard'

  export default defineComponent({
    name: 'ArticleMeta',
    props: {
      article: {
        type: Object as PropType<Article>,
        required: true
      },
      plain: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const { i18n, globalState } = useEnhancer()
      const articleDetailStore = useArticleDetailStore()
      const articleURL = computed(() => getPageURL(getArticleDetailRoute(props.article.id)))
      const likes = computed(() => props.article.likeNum)

      const handleOpenSponsor = () => {
        globalState.toggleSwitcher('sponsor', true)
      }


      const getDateLink = (date: string) => {
        return getDateFlowRoute(dateToYMD(new Date(date)))
      }

      return {
        VALUABLE_LINKS,
        LanguageKey,
        articleURL,
        likes,
        copy,
        tagEnName,
        firstUpperCase,
        handleOpenSponsor,
        getDateLink,
        getTagFlowRoute,
        getCategoryFlowRoute
      }
    }
  })
</script>

<style lang="scss" scoped>
  @use 'sass:math';
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .meta {
    position: relative;
    padding: 2rem;
    text-align: center;
    &.plain {
      .line {
        height: auto;
        line-height: 2;
      }
    }

    .actions {
      margin-top: $sm-gap;
      margin-bottom: 2rem;
    }

    .line {
      $size: 2rem;
      height: $size;
      line-height: $size;
      margin-bottom: $lg-gap;
      font-weight: bold;
      color: $text-secondary;
      &:last-child {
        margin: 0;
      }

      .icon {
        font-weight: normal;
        font-size: $font-size-small;
        margin-right: $xs-gap;
      }

      .link {
        border-bottom: 1px solid transparent;
        &:hover {
          text-decoration: none;
          border-color: initial;
        }

        &.date,
        &.category,
        &.tag {
          margin: 0 $xs-gap;
          color: $text;
          &:hover {
            color: $link-hover;
          }
        }

        &.date {
          text-transform: uppercase;
        }

        &.copyright,
        &.permalink {
          color: $text-secondary;
          &:hover {
            color: $text;
          }
        }

        &.permalink {
          border-bottom: 1px solid;
          cursor: pointer;
        }
      }
    }
  }
</style>
