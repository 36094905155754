/**
 * @file App config
 * @module config.app
 * @author potatomelon <https://gitee.com/xiaofango>
 */

export const DEFAULT_DELAY = 468
export const LONG_ARTICLE_THRESHOLD = 2000
export const PRIMARY_COLOR = '#0088f5'
export const GA_MEASUREMENT_ID = 'UA-84887611-3'
export const ADSENSE_CLIENT_ID = 'ca-pub-4710915636313788'

export const META = Object.freeze({
  title: '土豆社区',
  zh_sub_title: '土豆社区',
  en_sub_title: 'Because the mountain is there',
  description: '开发者的开放社区博客，一个不止于技术的博客社区平台',
  keywords: '土豆社区,土豆博客,土豆瓜,potatomelon,potatomelon.com',
  url: 'https://potatomelon.com',
  domain: 'potatomelon.com',
  author: 'potatomelon'
})

export const THIRD_IDS = Object.freeze({
  YOUTUBE_CHANNEL_ID: 'UCoL-j6T28PLSJ2U6ZdONS0w',
  MUSIC_163_BGM_ALBUM_ID: '6744545449',
  GITHUB_USER_ID: 'potatomelon',
  TWITTER_USER_ID: 'potatomelon7788',
  INSTAGRAM_USERNAME: 'potatomelon666',
  INSTAGRAM_FB_ID: '123456789'
})

export const GEO_INFO = Object.freeze({
  zh_title: '背井离乡，暂居深圳',
  en_title: 'Omicron prison, Shanghai',
  coordinates: [114.038064,22.540112]
})

export const MAPBOX_CONFIG = Object.freeze({
  TOKEN: 'pk.eyJ1IjoicG90YXRvbWVsb24iLCJhIjoiY2xjZnpvY3drMDRnNjNva2J3Y3FrNjlheiJ9.3DPwPVbHImYWGDXpiQM6Uw',
  // STYLE_LIGHT: 'mapbox://styles/potatomelon/clcg0ezwt000615pn30uomasn',
  // STYLE_DARK: 'mapbox://styles/potatomelon/clcg0ezwt000615pn30uomasn',
  STYLE_LIGHT: 'mapbox://styles/mapbox/light-v11',
  STYLE_DARK: 'mapbox://styles/mapbox/light-v11',
  ZOOM: 14,
  CENTER: [114.038064,22.540112]
})

export const SPONSOR_LINKS = Object.freeze({
  PAYPAL: 'https://paypal.me/surmon',
  GITHUB_SPONSORS: 'https://github.com/sponsors/surmon-china',
  BTC_ADDRESS: 'bc1qhpdu03tnexkj4xsm3lqzyjdddz6z0rj2n7fsze',
  ETH_ADDRESS: '0xaD556974D449126efdeF23f4FF581861C301cB77'
})

export const VALUABLE_LINKS = Object.freeze({
  RSS: '/rss.xml',
  BEIAN: 'https://beian.miit.gov.cn/',

  UPTIME_STATUS: 'https://stats.uptimerobot.com/Q2k7OTXxJN',
  GITHUB_SURMON_ME: 'https://github.com/surmon-china/surmon.me',
  GITHUB_NODEPRESS: 'https://github.com/surmon-china/nodepress',
  GITHUB_SURMON_ME_NATIVE: 'https://github.com/surmon-china/surmon.me.native',
  GITHUB_BLOG_STAR_LIST: 'https://github.com/stars/surmon-china/lists/surmon-me',
  APP_APK_FILE:
    'https://raw.githubusercontent.com/surmon-china/surmon.me.native/master/dist/android/surmon.me.apk',
  GITHUB: 'https://github.com/surmon-china',
  UPWORK: 'https://www.upwork.com/freelancers/~0142e621258ac1770d',
  MARKDOWN: 'https://daringfireball.net/projects/markdown/',
  GOOGLE_MY_MAP: `https://www.google.com/maps/d/embed?mid=1sRx6t0Yj1TutbwORCvjwTMgr70r62Z6w&z=3`,
  GOOGLE_ROAD_MAP: 'https://www.google.com/maps/d/embed?mid=1sRx6t0Yj1TutbwORCvjwTMgr70r62Z6w&z=3',
  DISCORD_GROUP: 'https://discord.surmon.me',
  TELEGRAM_GROUP: 'https://t.me/joinchat/F6wOlxYwSCUpZTYj3WTAWA',
  SPOTIFY: 'https://open.spotify.com/user/v0kz9hpwpbqnmtnrfhbyl812o',
  MUSIC_163: `https://music.163.com/#/playlist?id=${THIRD_IDS.MUSIC_163_BGM_ALBUM_ID}`,
  YOUTUBE_CHANNEL: `https://www.youtube.com/channel/${THIRD_IDS.YOUTUBE_CHANNEL_ID}`,
  TELEGRAM: 'https://t.me/surmon',
  OPENSEA: 'https://opensea.io/surmon',
  DOUBAN: 'https://www.douban.com/people/nocower',
  ZHIHU: 'https://www.zhihu.com/people/surmon',
  QUORA: 'https://www.quora.com/profile/Surmon',
  LINKEDIN: 'https://www.linkedin.com/in/surmon',
  INSTAGRAM: `https://www.instagram.com/${THIRD_IDS.INSTAGRAM_USERNAME}`,
  TWITTER: `https://twitter.com/${THIRD_IDS.TWITTER_USER_ID}`
})
