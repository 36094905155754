<template>
  <div class="guestbook-page" :class="{ dark: isDarkTheme }">
    <responsive>
      <template #desktop>
        <div class="desktop-banner">
          <uimage class="image" :src="bannerImage" />
<!--          <button class="like" :class="{ liked: false }" :disabled="false" @click="handleLike">-->
<!--            <i class="icon iconfont icon-heart"></i>-->
<!--            <span class="count">{{ isLiked ? `${siteLikes - 1} + 1` : siteLikes }}</span>-->
<!--          </button>-->
          <span class="slogan">
            <webfont class="text">
              <i18n :k="LanguageKey.GUESTBOOK_SLOGAN" />
            </webfont>
          </span>
        </div>
      </template>
      <template #mobile>
        <page-banner
          class="mobile-banner"
          :is-mobile="true"
          :position="100"
          :blur="false"
          :image="bannerImage"
        >
          <template #title>
            <i18n :k="LanguageKey.PAGE_GUESTBOOK" />
          </template>
          <template #description>
            <i18n :k="LanguageKey.GUESTBOOK_SLOGAN" />
          </template>
        </page-banner>
      </template>
    </responsive>
    <div class="comment">
      <comment post-id="0" :plain="isMobile" :module-type="ModuleState.Comment" :fetching="mockCommentLoading" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed } from 'vue'
  import { isClient } from '/@/app/environment'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUniversalFetch } from '/@/universal'
  import { useCommentStore } from '/@/stores/comment'
  import { CommentPostType } from '/@/constants/state'
  import { Language, LanguageKey } from '/@/language'
  import { firstUpperCase } from '/@/transforms/text'
  import { META } from '/@/config/app.config'
  import PageBanner from '/@/components/common/fullpage/banner.vue'
  import Comment from '/@/components/comment/index.vue'
  import { ModuleState } from '/@/constants/state'

  export default defineComponent({
    name: 'GuestbookPage',
    components: {
      PageBanner,
      Comment
    },
    props: {
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const GUESTBOOK_POST_ID = CommentPostType.Guestbook
      const { i18n, meta, globalState, isDarkTheme, isZhLang } = useEnhancer()
      const commentStore = useCommentStore()
      const siteLikes = computed(() => 0)
      const bannerImage = `/images/page-guestbook/banner.jpg`
      // MARK: [SSR & not first page] only
      const mockCommentLoading = ref(isClient && globalState.isHydrated.value)

      meta(() => ({
        title: `留言 - ${META.title}`,
        description: `给${META.author}留言`,
        keywords: `留言,${META.keywords}`
      }))

      const fetchAllData = () => {
        return Promise.all([
          commentStore.fetchList({ resourceId: "0", moduleType: ModuleState.Comment, pageNum: 1})
        ]).then(() => {
          mockCommentLoading.value = false
        })

      }

      const handleLike = async () => {
        // if (isLiked.value) {
        //   return false
        // }
        //
        // try {
        //   await metaStore.postSiteLike()
        //   universalStore.likePage(GUESTBOOK_POST_ID)
        // } catch (error) {
        //   const message = i18n.t(LanguageKey.POST_ACTION_ERROR)
        //   console.warn(message, error)
        //   alert(message)
        // }
      }

      useUniversalFetch(() => fetchAllData())

      return {
        LanguageKey,
        bannerImage,
        mockCommentLoading,
        isDarkTheme,
        siteLikes,
        ModuleState,
        handleLike
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .guestbook-page {
    .mobile-banner {
      margin-bottom: $lg-gap;
    }

    .desktop-banner {
      position: relative;
      margin-bottom: $lg-gap;
      width: 100%;
      height: 19rem;
      border: 0;
      background-color: $module-bg;
      @include radius-box($lg-radius);

      .image {
        width: 100%;
        margin-top: -($gap * 6);
        transition: all $transition-time-slow;
        &:hover {
          transform: rotate(2deg) scale(1.1);
        }
      }

      .like {
        position: absolute;
        left: $lg-gap * 2;
        bottom: $gap * 2;
        display: inline-flex;
        align-items: center;

        &.liked,
        &:hover {
          .icon {
            color: $red;
          }
        }

        .icon {
          margin-right: $sm-gap;
          color: rgba($red, 0.6);
          font-size: $font-size-h2;
          @include color-transition();
        }

        .count {
          color: rgba($white, 0.8);
          font-weight: bold;
        }
      }

      .slogan {
        $size: 2em;
        display: block;
        position: absolute;
        right: $lg-gap * 2;
        bottom: $gap * 2;
        height: $size;
        line-height: $size;
        padding: 0 $sm-gap;
        padding-left: 3rem;
        border-top-right-radius: $mini-radius;
        border-bottom-right-radius: $mini-radius;
        background: linear-gradient(to left, $module-bg-lighter, $module-bg, transparent);
        opacity: 0.8;
        cursor: none;

        .text {
          letter-spacing: 0.3px;
          color: $text;
          background-clip: text;
          background-image: url('/images/page-guestbook/banner.jpg');
          background-position: 100% 80%;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &.dark {
      .desktop-banner {
        .slogan {
          .text {
            -webkit-text-fill-color: $text !important;
          }
        }
      }
    }
  }
</style>
