/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import localhost from '/@/services/localhost'

export interface Nav {
  id: string
  name: string
  path: string
  navType: number
  terminal: number
  icon: string
}

export const useNavStore = defineStore('nav', {
  state: () => ({
    navList: {
      fetching: false,
      data: [] as Array<Nav>
    },
  }),
  //获取各个导航
  getters: {
    getNavList: (state): (navType: number, terminal: number) => Array<Nav> => (navType: number, terminal: number) =>{
      const navs = state.navList.data.filter(
        (nav) => nav.navType == navType && (nav.terminal == terminal || nav.terminal == 0)
      )
      return navs;
    }
  },
  actions: {
    fetchNavList() {
      this.navList.fetching = true
      const fetch = localhost.get<any>('/blog/web/nav/api/list').then((response) => {
          this.navList.data = response.data
          // console.log(response.data)
        })
        .finally(() => {
          this.navList.fetching = false
        })
    },
  }
})
