/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { isClient } from '/@/app/environment'
import { TunnelModule } from '/@/constants/tunnel'
import type { InstagramMediaItem } from '/@/server/getters/instagram'
import { delayPromise } from '/@/utils/delayer'
import tunnel from '/@/services/tunnel'
import {OriginState, UniversalKeyValue} from "@/constants/state";
import {Tag} from "@/stores/tag";
import {Category} from "@/stores/category";
import {Language} from "@/language";
import localhost from '/@/services/localhost'
import {ARTICLE_API_PATH} from "@/stores/article";
import nodepress from "@/services/nodepress";

const LENS_NORMAL_DELAY = 480

export const PICTURE_API_PATH = '/blog/web/picture'

export const usePictureStore = defineStore('picture', {
  state: () => ({
    pictureList: {
      fetching: false,
      data: [] as Array<any>,
      pagination: null as null | $TODO
    },
    pictureCategoryList: [] as Array<any>,
    pictureTagList: [] as Array<any>,
  }),

  getters: {

    //随机颜色
    randomColor(): string {
      return 'rgb(' + [
        Math.round(Math.random() * 160),
        Math.round(Math.random() * 160),
        Math.round(Math.random() * 160)
      ].join(',') + ')';
    },
    //渲染标签样式
    tagData: (state) => {
      const tagList = [...state.pictureTagList]
      tagList.map((val) => {
        return {
          ...val,
          value: val.id,
          textStyle:{
            color: 'rgb(' + [
              Math.round(Math.random() * 160),
              Math.round(Math.random() * 160),
              Math.round(Math.random() * 160)
            ].join(',') + ')',
            lineHeight: 24,
            borderRadius: 10,
            width: 100,
          },
          // 鼠标放上的效果
          emphasis: {
            textStyle: {
              color: 'black',
            },
          }
        }
      })
      console.log(`getter: tagData-${tagList}`)
      return tagList
    }
  },
  actions: {
    //图片列表
    fetchPictureList(params: any = {}) {
      this.pictureList.fetching = true
      this.pictureList.data = []
      this.pictureList.pagination = null
      const fetch = localhost.get<any>(`${PICTURE_API_PATH}/api/list2`, { params });
      this.pictureList.fetching = true
      const promise = isClient ? delayPromise(520, fetch) : fetch
      return promise
        .then((response) => {
          this.pictureList.data = response.data
          this.pictureList.pagination = response.pagination
        })
        .finally(() => {
          this.pictureList.fetching = false
        })
    },

    //图片上传
    postPicture(params: Partial<any>) {
      return localhost.post<any>(`${PICTURE_API_PATH}/save`, params)
    },

    //图片标签
    fetchPictureCategoryList() {
      const fetch = localhost.get<any>(`${PICTURE_API_PATH}/api/category/list`).then((response) => {
          this.pictureCategoryList = response.data
        })
    },

    //图片标签
    fetchPictureTagList() {
      return localhost.get<any>(`${PICTURE_API_PATH}/api/tag/list`).then((response) => {
        this.pictureTagList = response.data
      })
    },

    fetchCategoryTag() {
      return Promise.all([
        this.fetchPictureCategoryList(),
        this.fetchPictureTagList()
      ])
    },

    postPictureLike(pictureId: string) {
      return localhost
        .post(`${PICTURE_API_PATH}/like`, { resourceId: pictureId })
        .then((response) => {
          this.pictureList.data.forEach((p, i) => {
            if(this.pictureList.data[i].id == pictureId){
              this.pictureList.data[i].isLike = response.data
              this.pictureList.data[i].likeNum = response.data ? this.pictureList.data[i].likeNum + 1 : this.pictureList.data[i].likeNum - 1
            }
          })
          return response.data
        })
    },

    postPictureCollect(pictureId: string) {
      return localhost
        .post(`${PICTURE_API_PATH}/collect`, { resourceId: pictureId })
        .then((response) => {
          this.pictureList.data.forEach((p, i) => {
            if(this.pictureList.data[i].id == pictureId){
              this.pictureList.data[i].isCollect = response.data
              this.pictureList.data[i].collectNum = response.data ? this.pictureList.data[i].collectNum + 1 : this.pictureList.data[i].collectNum - 1
            }
          })
          return response.data
        })
    }
  }
})
