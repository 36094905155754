<template>
  <header :id="HEADER_ELEMENT_ID" class="header enable-nav">
    <div class="header-container container">
      <div class="header-header">
        <uimage cdn src="/images/logo4.png" class="header-logo" />
<!--        <webfont class="header-slogan">-->
<!--          <i18n :k="LanguageKey.APP_SLOGAN" />-->
<!--        </webfont>-->
        <router-link
          to="/"
          class="header-link"
          :title="t(LanguageKey.APP_SLOGAN)"
        />
      </div>
      <div>
        <!--    TODO 添加菜单    -->
      </div>
      <div class="toolbox">
        <button v-if="userStore.isLogin" @click="goWrite" class="write">写文章</button>
        <!--    个人中心    -->
<!--        <dropdown-menu v-if="userStore.isLogin" class="center enable-item">-->
<!--          <template #data>-->
<!--            <img v-if="userStore.isLogin" class="header-img" :src="userStore.getCurrentUser.avatar" />-->
<!--          </template>-->
<!--          <dropdown-item><router-link :to=getUserDetailRoute(userStore.getUserId)>个人中心</router-link></dropdown-item>-->
<!--          <dropdown-item>个人设置</dropdown-item>-->
<!--          <dropdown-item @click="logout">退出</dropdown-item>-->
<!--        </dropdown-menu>-->
        <button v-if="userStore.isLogin" class="center enable-item">
          <img v-if="userStore.isLogin" class="header-img" :src="userStore.getCurrentUser.avatar" />
          <div class="center-item">
            <ul class="ul-list">
              <li class="item"><router-link :to=getUserDetailRoute(userStore.getUserId)>个人中心</router-link></li>
              <li class="item" @click="logout">退出</li>
            </ul>
          </div>
        </button>

        <button v-else class="login" @click="handleLogin">请登入</button>
      </div>
    </div>
    <div class="header-nav">
      <nav class="nav-list container">
        <template v-for="(menu, index) in navStore.getNavList(0, 0)" :key="menu.id">
          <span v-if="index > 0" class="divider"></span>
          <ulink
            class="item"
            :to="menu.path"
            :href="menu.path"
          >
<!--            <uimage v-if="menu.imageIcon" class="image-icon" :src="menu.imageIcon" />-->
            <i v-if="menu.icon" class="iconfont" :class="menu.icon"></i>
            <webfont class="text" bolder uppercase>
              {{menu.name}}
            </webfont>
          </ulink>
        </template>
      </nav>
    </div>
  </header>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUserStore } from '/@/stores/user'
  import { getUserDetailRoute } from '/@/transforms/route'
  import { LanguageKey } from '/@/language'
  import { Theme } from '/@/composables/theme'
  import { HEADER_ELEMENT_ID } from '/@/constants/anchor'
  import { useNavStore } from '/@/stores/nav'
  import { useUniversalFetch } from "/@/universal";
  import DropdownMenu from "/@/components/common/dropdown/dropdown.vue";
  import DropdownItem from "/@/components/common/dropdown/item.vue";

  export default defineComponent({
    name: 'DesktopHeader',
    components: {DropdownMenu, DropdownItem },
    setup() {
      const { i18n, theme, globalState, router } = useEnhancer()
      // enable header nav bar when full page layout
      const isEnabledNav = computed(() => !globalState.layoutColumn.value.isNormal)
      const userStore = useUserStore()
      const navStore = useNavStore()
      // const isLogin = computed(() => userStore.isLogin)
      const userId = computed(() => userStore.getUserId)
      const themeValue = theme.theme
      const themeIcon = computed(() => {
        const themeIconMap = {
          [Theme.Light]: 'icon-sun',
          [Theme.Dark]: 'icon-moon'
        }
        return themeIconMap[themeValue.value]
      })

      const isOnShow = ref(false)
      const isShow = computed(() => isOnShow.value)

      const show = () => {
        isOnShow.value  = !isOnShow.value
      }

      const tooggleLanguage = () => {
        i18n.toggle()
      }

      const handleLogin = () => {
        globalState.toggleSwitcher('login', true)
      }

      const logout = () => {
        userStore.fetchUserLogout()
      }

      const goWrite = () => {
        router.push({
          path: '/write'
        })
      }

      // const fetchArticleDetail = (articleId: string) => {
      //   return Promise.all([
      //     navStore.fetchCompleteArticle({ articleId }),
      //     commentStore.fetchList({ resourceId: articleId })
      //   ])
      // }

      useUniversalFetch(() => Promise.all([userStore.initOnClient()]))

      return {
        HEADER_ELEMENT_ID,
        LanguageKey,
        isEnabledNav,
        t: i18n.t,
        language: i18n.language,
        tooggleLanguage,
        theme: themeValue,
        themeIcon,
        isShow,
        userStore,
        handleLogin,
        logout,
        show,
        // isLogin,
        userId,
        getUserDetailRoute,
        navStore,
        goWrite
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    z-index: $z-index-header;
    background-color: $module-bg;
    border-bottom: 1px solid $module-bg-darker-2;
    @include backdrop-blur(5px);

    &.enable-nav:hover {
      .header-nav {
        @include visible();
      }
    }

    .header-container {
      height: 100%;
      display: flex;
      justify-content: space-between;

      .header-header {
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        padding-left: $sm-gap;
        width: 29em;
        overflow: hidden;

        @keyframes logo-blink {
          0% {
            mask-position: -30%;
          }
          100% {
            mask-position: 666%;
          }
        }

        .header-logo {
          width: 4.75rem;
          margin-right: $gap * 4;
          filter: $theme-logo-rotate;
          .logo-st {
            fill: $primary;
          }
        }

        .header-slogan {
          color: $primary;
          font-size: $font-size-h5;
        }

        .header-link {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .toolbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        opacity: 0.9;
        @include visibility-transition();
        &:hover {
          opacity: 1;
        }

        .write{
          border-radius: $sm-radius;
          background-color: #1890ff;
          color: white;
          padding: 5px 10px;
        }

        .center {
          position: relative;
          display: block;
          text-transform: uppercase;
          margin: 0 $gap;
          width: 2.5rem;
          height: 2.5rem;
          //line-height: 2.5rem;
          &.enable-item:hover{
            .center-item {
              @include visible();
            }
          }
          .icon-user{
            font-size: 2rem;
          }
          .header-img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .center-item{
            position: absolute;
            top: 30px;
            left: 0px;
            z-index: $z-index-progress-bar;
            margin: 5px 0;
            background-color: $module-bg-opaque;
            border: 1px solid $text-divider;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            @include hidden();
            @include visibility-transition();
            .ul-list{
              padding: 5px 0;
              margin: 0;
              list-style-type: none;
              .item{
                padding: 5px $lg-gap;
                text-align: left;
                &:hover{
                  background-color: $module-bg-opaque;
                  color: $surmon;
                }
              }
            }
          }
          &::before {
            content: '';
            display: block;
            width: 50%;
            height: 2px;
            position: absolute;
            left: 25%;
            bottom: -2px;
          }

          &.menu {
            cursor: none;
            &::before {
              background-color: $black;
            }
          }

          &.theme {
            &::before {
              background-color: $primary;
            }
          }

          &.language {
            font-weight: bold;
            &.en {
              &::before {
                background-color: $en-primary;
              }
            }
            &.zh {
              &::before {
                background-color: $zh-primary;
              }
            }
          }

        }
        .login {
          padding-top: 2px;
          padding-bottom: 2px;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          &:hover {
            .iconfont {
              font-weight: normal;
            }
            font-weight: bold;
            border-bottom-color: $text-secondary;
          }

          .iconfont {
            margin: 0 $sm-gap;
            color: $disqus-primary;
            font-size: $font-size-small;
            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .header-nav {
      width: 100%;
      height: 4rem;
      background-color: $primary-lighter;
      @include hidden();
      @include visibility-transition();

      .nav-list {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .divider {
          height: 6px;
          width: 1px;
          background-color: $module-bg-translucent;
        }

        .item {
          position: relative;
          display: inline-flex;
          align-items: center;
          color: $text-reversal;
          opacity: 0.7;
          @include visibility-transition();
          &:hover {
            opacity: 1;
          }
          &.link-active {
            .text {
              padding-top: 4px;
              padding-bottom: 2px;
              border-bottom: 2px solid;
            }
          }

          > .iconfont {
            margin-right: $sm-gap;
          }

          .image-icon {
            width: 1em;
            height: 1em;
            margin-right: $sm-gap;
            border-radius: $xs-radius;
          }

          .superscript {
            margin-left: $xs-gap;
          }

          &.new {
            margin-right: $xs-gap;
          }

          .newscript {
            position: absolute;
            right: -1.2em;
            top: -0.1em;
            font-size: $font-size-small - 3;
            opacity: 0.8;
          }
        }
      }
    }
  }
</style>
