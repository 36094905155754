<template>
  <placeholder :loading="fetching">
    <template #loading>
      <div class="topbar-skeleton" key="skeleton">
        <div class="left">
          <skeleton-line class="skeleton-item count" />
          <skeleton-line class="skeleton-item sort" v-if="!plain" />
        </div>
        <div class="right">
          <skeleton-line class="skeleton-item user" />
        </div>
      </div>
    </template>
    <template #default>
      <div class="topbar" :class="{ plain }" key="element">
        <div class="statistics">
          <div class="total" :class="{ loading }">
            <i class="iconfont icon-discussion"></i>
            <i18n>
              <template #zh>
                共 <span class="count">{{ statisticsText }}</span> 条看法
              </template>
              <template #en>
                <span class="count">{{ statisticsText }}</span> comments
              </template>
            </i18n>
          </div>
          <select class="sort" name="sort" :value="sort" @change="handleSort($event.target)">
            <option :value="SortType.Desc">
              <i18n :k="LanguageKey.COMMENT_SORT_NEW" />
            </option>
            <option :value="SortType.Hottest">
              <i18n :k="LanguageKey.COMMENT_SORT_HOT" />
            </option>
            <option :value="SortType.Asc">
              <i18n :k="LanguageKey.COMMENT_SORT_OLD" />
            </option>
          </select>
        </div>
        <div class="user">
          <div class="unlogin" v-if="!login">
            <button class="disqus" @click="handleLogin">请登入</button>
          </div>
        </div>
      </div>
    </template>
  </placeholder>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType } from 'vue'
  import { META, VALUABLE_LINKS } from '/@/config/app.config'
  import { LanguageKey } from '/@/language'
  import { SortType } from '/@/constants/state'
  import { useEnhancer } from '/@/app/enhancer'
  import { openWindow } from '/@/utils/opener'
  import nodepress from '/@/services/nodepress'
  import { CommentEvents } from './helper'

  export default defineComponent({
    name: 'CommentTopbar',
    props: {
      postId: {
        type: String,
        required: true
      },
      sort: {
        type: Number as PropType<SortType>,
        required: true
      },
      total: {
        type: Number,
        default: 0,
        required: false
      },
      loaded: {
        type: Number,
        default: 0,
        required: false
      },
      fetching: {
        type: Boolean,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
      plain: {
        type: Boolean,
        default: false
      },
      login: {
        type: Boolean,
        default: false
      },
    },
    emits: [CommentEvents.Sort],
    setup(props, context) {
      const { globalState } = useEnhancer()
      const statisticsText = computed(() => {
        return props.loading ? `···` : `${props.loaded} / ${props.total}`
      })

      const disqusThreadMap = new Map<number, any>()

      const handleSort = (target: any) => {
        const value = Number(target?.value)
        if (value !== props.sort) {
          context.emit(CommentEvents.Sort, value)
        }
      }

      const handleLogin = () => {
        globalState.toggleSwitcher('login', true)
      }

      return {
        statisticsText,
        VALUABLE_LINKS,
        LanguageKey,
        SortType,
        handleSort,
        handleLogin
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';
  $topbar-size: 2em;

  .topbar,
  .topbar-skeleton {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .topbar-skeleton {
    .left,
    .right {
      height: $topbar-size;
    }
    .skeleton-item {
      &.count {
        width: 12rem;
        margin-right: $gap;
      }
      &.sort {
        width: 8rem;
      }
      &.user {
        width: 8rem;
      }
    }

    .left {
      display: flex;
    }
  }

  .topbar {
    &.plain {
      flex-direction: column;
      align-items: baseline;
      height: 6rem;
    }

    .statistics {
      display: flex;
      height: $topbar-size;
      line-height: $topbar-size;

      .total {
        padding: 0;
        padding-right: 0.6em;
        background-color: $module-bg-darker-1;
        @include radius-box($xs-radius);
        will-change: width;
        transition: all $transition-time-fast;
        max-width: 180px;
        &.loading {
          max-width: 130px;
        }

        .iconfont {
          display: inline-block;
          width: $topbar-size;
          height: $topbar-size;
          text-align: center;
          margin-right: $xs-gap;
          background-color: $module-bg-darker-2;
          @include background-transition();
        }

        .count {
          font-weight: bold;
          margin: 0 $xs-gap;
        }
      }

      .sort,
      .disqus {
        margin-left: $gap;
        background-color: $module-bg-darker-1;
        @include radius-box($xs-radius);
        cursor: pointer;
        &:hover {
          background-color: $module-bg-darker-2;
        }
      }

      .sort {
        padding: 0 $xs-gap;
        font-weight: bold;
      }

      .disqus {
        width: $topbar-size;
        text-align: center;
        &:hover {
          color: $white;
          background-color: $disqus-primary;
        }
      }
    }

    .user {
      .unlogin {
        display: flex;
        align-items: center;

        .disqus {
          padding-top: 2px;
          padding-bottom: 2px;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          &:hover {
            .iconfont {
              font-weight: normal;
            }
            font-weight: bold;
            border-bottom-color: $text-secondary;
          }

          .iconfont {
            margin: 0 $sm-gap;
            color: $disqus-primary;
            font-size: $font-size-small;
            &.right {
              margin-right: 0;
            }
          }
        }
      }

      .logined {
        position: relative;
        display: inline-block;
        cursor: pointer;
        &:hover {
          .name {
            background-color: $module-bg-darker-2;
          }
          .user-menu {
            display: block;
          }
        }

        .name {
          padding: 0 $gap;
          height: $topbar-size;
          display: flex;
          align-items: center;
          background-color: $module-bg-darker-1;
          @include radius-box($xs-radius);

          .icon {
            &.disqus {
              font-size: $font-size-h4;
              color: $disqus-primary;
            }
          }

          .text {
            margin-right: $xs-gap;
            margin-left: $sm-gap;
            font-weight: bold;
          }

          .arrow {
            color: $text-divider;
            font-size: $font-size-small;
          }
        }

        .user-menu {
          display: none;
          position: absolute;
          right: 0;
          padding-top: 0.5em;
          z-index: $z-index-normal + 1;
          .menus {
            margin: 0;
            padding: 0;
            list-style: none;
            @include radius-box($xs-radius);

            .item {
              .button {
                white-space: nowrap;
                display: block;
                width: 100%;
                padding: 0 $gap;
                line-height: 2.3em;
                text-align: right;
                font-size: $font-size-small;
                background-color: $module-bg-darker-2;
                &:hover {
                  background-color: $module-bg-darker-3;
                }

                .iconfont {
                  &.disqus {
                    color: $disqus-primary;
                    font-size: 10px;
                    margin: 0 $xs-gap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
