<template>
  <div class="navigation">
<!--    <ul class="content-skeleton" key="skeleton">-->
<!--      <li class="item">-->

<!--      </li>-->
<!--    </ul>-->
    <ul class="content">
      <template v-for="navigation in navigationStore.navigationOfficeList.data" >
        <li class="group" v-if="navigation.navigationList" :key="navigation.categoryId">
          <div class="header">
            {{ navigation.categoryName}}
          </div>
          <div class="list">
            <ulink class="item" v-for="item in navigation.navigationList" :to="item?.url" :href="item.path" @click="count(item.id)">
              <!--            icon: "http://cdn.potatomelon.com/blog/article/20230118/5cdd8a4946d14d52b83c1e8b12f8efe3.jpg"-->
              <!--            id: "1615541592876421121"-->
              <!--            name: "菜鸟教程"-->
              <!--            path: "www.baidu.com"-->
              <!--            <img src="/images/emojis/doge.png" :alt="item.name" class="icon">-->
              <img :src="item.icon" class="icon">
              <span class="name">{{ item.name}}</span>
            </ulink>
          </div>
        </li>
      </template>

    </ul>

  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, reactive, ref, toRaw, toRefs, onMounted} from "vue";
  import { useUniversalFetch } from '/@/universal'
  import { META } from "/@/config/app.config";
  import { LanguageKey } from "/@/language";
  import { useEnhancer } from "/@/app/enhancer";
  import { useNavigationStore } from '/@/stores/scavenge'
  import { webSearchUrl } from './navigationData'

  export default defineComponent({
    name: "ScavengeNavigation",
    setup(){
      const { globalState, meta, i18n  } = useEnhancer()
      const navigationStore = useNavigationStore()

      meta(() => ({
        title: `导航工具 - ${META.title}`,
        description: `${META.description}`,
        keywords: `导航工具,${META.keywords}`
      }))

      onMounted(() => {
        navigationStore.fetchNavigationOfficeList()
      })

      // const importData = () => {
      //   navigationStore.postSubmit(webSearchUrl)
      // }

      const count = (id: string) => {
        navigationStore.postClick({id: id})
      }
      return {
        navigationStore,
        count
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $avatar-size: 10rem;
  .navigation{
    min-height: 800px;
    .content{
      padding: 0;
      margin: 0;
      list-style: none;
      overflow: hidden;
      .group{
        margin-bottom: $lg-gap;
        @include radius-box($lg-radius);
        @include common-bg-module();
        list-style: none;
        .header{
          padding: $gap $lg-gap*3;
          font-size: 15px;
          //padding-left: 46px;
          font-weight: 600;
          flex-shrink: 0;
          border-bottom: .1px solid $text-divider;
        }
        .list{
          padding: $sm-gap $lg-gap*3;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          .item{
            display: flex;
            height: 45px;
            justify-items: center;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover{
              .name{
                color: $surmon;
              }
            }
            .icon{
              width: 20px;
              height: 20px;
              margin-right: 5px;

            }
            .name{
              white-space: nowrap;
            }
          }
        }

      }
      //display: flex;
      //padding: $lg-gap;
      //margin-bottom: $gap;
      //flex-direction: column;
      //justify-content: center;
      //@include radius-box($sm-radius);
      //background-color: $module-bg;
      //&:hover {
      //  background-color: $module-bg-opaque;
      //}
    }

  }
</style>
