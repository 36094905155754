<template>
  <div class="sponsor-page">
    <page-banner :image="sponsor?.background" :position="50" position :blur="false">
      <template #title>
        <webfont>
          <i18n :zh="sponsor?.title" en="Become a sponsor to me" />
        </webfont>
      </template>
      <template #description>
        <i18n>
          <template #zh>{{sponsor?.subTitle}}</template>
          <template #en
            >Your generous financial support is my motivation to keep moving forward</template
          >
        </i18n>
      </template>
    </page-banner>
    <div class="tips">
      <i18n><template #zh>{{sponsor?.statement}}</template></i18n>
    </div>
    <div class="sponsor-wrapper">
      <sponsor class="sponsor" :targets="sponsor?.payList" :init-id="initSponsor" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { Language, LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { firstUpperCase } from '/@/transforms/text'
  import Sponsor from '/@/components/widget/sponsor.vue'
  import PageBanner from '/@/components/common/fullpage/banner.vue'
  import {useConfigStore} from "/@/stores/config";

  export default defineComponent({
    name: 'SponsorPage',
    components: {
      Sponsor,
      PageBanner
    },
    setup() {
      const { route, i18n, meta, isZhLang } = useEnhancer()
      const configStore = useConfigStore()
      const initSponsor = computed(() => route.hash.replace('#', ''))
      const sponsor =  computed(() => configStore.getConfig("sponsor"))
      meta(() => {
        const enTitle = firstUpperCase(i18n.t(LanguageKey.PAGE_SPONSOR, Language.English)!)
        const titles = isZhLang.value ? [i18n.t(LanguageKey.PAGE_SPONSOR), enTitle] : [enTitle]
        return { pageTitle: titles.join(' | ') }
      })

      return { LanguageKey, initSponsor, sponsor }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .sponsor-page {
    width: 100%;
    min-height: $full-page-active-content-height;
    display: flex;
    flex-direction: column;

    .tips {
      width: 100%;
      height: 3em;
      line-height: 3em;
      text-align: center;
      color: $text-disabled;
      background-color: $module-bg-lighter;
      letter-spacing: 1px;
    }

    .sponsor-wrapper {
      flex-grow: 1;
      display: flex;
      background-color: $module-bg;

      .sponsor {
        flex-shrink: 0;
        width: $container-width;
        margin: 2rem auto;
        border-radius: $xs-radius;
        overflow: hidden;
      }
    }
  }
</style>
