/**
 * @file Comment state
 * @module store.comment
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { isClient } from '/@/app/environment'
import { UNDEFINED } from '/@/constants/value'
import { SortType, UniversalKeyValue, CommentParentType } from '/@/constants/state'
import { delayPromise } from '/@/utils/delayer'
import nodepress from '/@/services/nodepress'
import localhost from "/@/services/localhost";
import {User} from "@/stores/user";

export const COMMENT_API_PATH = '/blog/web/comment'
export const COMMENT_EMPTY_PID = CommentParentType.Self

export interface Author {
  name: string
  site?: string
  email?: string
  email_hash?: string
}

export interface IPLocation {
  status: string
  country: string
  countryCode: string
  region: string
  regionName: string
  city: string
  zip: string
  lat: number
  lon: number
  timezone: string
  isp: string
  org: string
  as: string
  query: string
}

export interface Comment {
  resourceId: string
  id: string
  pid: string | typeof COMMENT_EMPTY_PID
  content: string
  agent?: string
  user: User
  author: User
  likeNum: number
  isLike: boolean
  ip?: string
  city?: string
  ip_location?: IPLocation
  commentTime: string
  extends: UniversalKeyValue[]
}
export interface CommentFetchParams {
  page?: number
  per_page?: number
  sort?: SortType
  loadmore?: boolean
  [key: string]: any
}

export interface CommentTreeItem {
  comment: Comment
  children: Array<Comment>
}

export interface CommentData {
  fetching: boolean,
  posting: boolean,
  deleting: boolean,
  comments: Array<Comment>,
  pagination: object
}

export const useCommentStore = defineStore('comment', {
  state: () => ({
    // fetching: false,
    // posting: false,
    // deleting: false,
    // comments: [] as Array<Comment>,
    // pagination: null as null | $TODO,
    // commentMap: new Map<string, CommentData>() ssr build报错
    commentMap: new Map<string, any>()
  }),
  getters: {
    commentTreeList: (state): (postId: string) => { comment: Comment; children: Array<Comment> }[] => (postId: string) =>{
      // only keep 2 level tree
      const ids = state.commentMap.get(postId)?.comments.map((comment) => comment.id)
      const roots = state.commentMap.get(postId)?.comments.filter(
        (comment) => comment.pid === COMMENT_EMPTY_PID || !ids?.includes(comment.pid)
      )
      const children = state.commentMap.get(postId)?.comments.filter(
        (comment) => comment.pid !== COMMENT_EMPTY_PID && ids?.includes(comment.pid)
      )
      const fullMap = new Map<string, Comment>(
        state.commentMap.get(postId)?.comments.map((comment) => [comment.id, comment])
      )
      const treeMap = new Map<string, { comment: Comment; children: Array<Comment> }>(
        roots?.map((comment) => [comment.id, { comment, children: [] }])
      )

      const findRootParentID = (pid: string): string | void => {
        const target = fullMap.get(pid)
        if (!target) {
          return UNDEFINED
        }
        return target.pid === COMMENT_EMPTY_PID ? target.id : findRootParentID(target.pid)
      }

      children?.forEach((comment) => {
        const rootPID = findRootParentID(comment.pid)
        if (rootPID) {
          if (treeMap.has(rootPID)) {
            const target = treeMap.get(rootPID)!
            treeMap.set(rootPID, {
              ...target,
              children: [comment, ...target.children]
            })
          }
        }
      })
      return Array.from(treeMap.values())
    }
  },
  actions: {
    clearList(resourceId: string) {
      this.commentMap.get(resourceId).comments = []
      this.commentMap.get(resourceId).pagination = null
    },

    createCommentData(resourceId: string){
      this.commentMap.set(resourceId,{
        fetching: false,
        posting: false,
        deleting: false,
        comments: [] as Array<Comment>,
        pagination: null as null | $TODO,
      })
    },

    fetchList(params: CommentFetchParams = {}) {
      if(!(this.commentMap.get(params.resourceId))){
        this.createCommentData(params.resourceId)
      }
      params = {
        sort: SortType.Desc,
        loadmore: false,
        ...params
      }

      // clear list when refetch
      if (params.pageNum === 1) {
        this.clearList(params.resourceId)
      }
      this.commentMap.get(params.resourceId).fetching = true
      const fetch = localhost.get(`${COMMENT_API_PATH}/api/list`, { params })
      const promise = isClient ? delayPromise(480, fetch) : fetch
      return promise
        .then((response) => {
          if (params.loadmore) {
            this.commentMap.get(params.resourceId).comments.push(...response.data)
          } else {
            this.commentMap.get(params.resourceId).comments = response.data
          }
          this.commentMap.get(params.resourceId).pagination = response.pagination
          return response.data
        })
        .finally(() => {
          this.commentMap.get(params.resourceId).fetching = false
        })
    },

    postComment(comment: Comment) {
      this.commentMap.get(comment.resourceId).posting = true
      return localhost
        .post<Comment>(`${COMMENT_API_PATH}/save`, comment)
        .then((response) => {
          this.commentMap.get(comment.resourceId).comments.unshift(response.data)
          if (this.commentMap.get(comment.resourceId).pagination) {
            this.commentMap.get(comment.resourceId).pagination.total++
          }
          return response.data
        })
        .finally(() => {
          this.commentMap.get(comment.resourceId).posting = false
        })
    },

    deleteComment(id: string, resourceId: string) {
      this.commentMap.get(resourceId).deleting = true
      return localhost.delete(`${COMMENT_API_PATH}/delete/${resourceId}/${id}`).then((response) => {
          console.log(`${response.data}`)
          for (let k = 0; k < response.data.length; k++) {
            const index = this.commentMap.get(resourceId)?.comments.findIndex((comment) => comment.id === response.data[k])
            if (index > -1) {
              //查找
              this.commentMap.get(resourceId)?.comments.splice(index, 1)
              this.commentMap.get(resourceId).pagination.total--
            }
          }
        })
        .finally(() => {
          this.commentMap.get(resourceId).deleting = false
        })
    },
  }
})
