<template>
  <div class="boiling-item">
    <div class="item-content">
      <div class="item-main">
        <!--   左封面   -->
        <div class="item-avatar">
          <uimage :alt="boiling?.user?.nickName" :src="boiling?.user?.avatar" />
        </div>
        <!--   右内容   -->
        <div class="item-body">
          <div class="item-content">
            <h5 class="user-name">
              <router-link class="link" :to="getArticleDetailRoute(boiling?.user?.id)" :title="boiling?.user?.nickName">
                {{ boiling?.user?.nickName }}
              </router-link>
<!--              <span class="language">{{ getLanguageText(article.lang) }}</span>-->
            </h5>
            <div class="user-other">
              <span class="description" style="-webkit-box-orient: vertical">{{ boiling.user.occupation }}</span>
              <span class="separator">•</span><udate to="ago" :date="boiling?.publishDate" />
            </div>
            <show-more :show-content="boiling.content" :padding-bottom=4 :padding-right=8 />
            <div class="picture">
              <single-swiper :images="boiling?.pictures" />
            </div>
          </div>
        </div>
      </div>
      <!--   底部动作   -->
      <div class="item-action">
        <div class="item-meta">
<!--          <span class="date">-->
<!--            <i class="iconfont icon-clock"></i>-->
<!--            <udate to="ago" :date="article.createTime" />-->
<!--          </span>-->
          <span class="likes">
            <i class="iconfont icon-like" :class="{ liked: boiling?.isLike }"></i>
            <span>{{ boiling.likeNum }}</span>
          </span>
          <span class="comments" @click="openComment">
            <i class="iconfont icon-comment"></i>
            <span>{{ boiling.commentNum }}</span>
          </span>
          <span class="categories">
            <i class="iconfont icon-category"></i>
              <span>{{boiling.boneName}} </span>
          </span>
        </div>
      </div>
      <div class="comment" v-if="isOpenComment">
        <comment :plain="false" :fetching="fetching" :postId="boiling?.id" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType, ref, reactive } from 'vue'
  import { Language, LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { useArticleDetailStore } from '/@/stores/article'
  import { getArticleDetailRoute, getTagFlowRoute, getCategoryFlowRoute } from '/@/transforms/route'
  import { isOriginalType, isHybridType, isReprintType } from '/@/transforms/state'
  import { getArticleListThumbnailURL } from '/@/transforms/thumbnail'
  import { numberSplit } from '/@/transforms/text'
  import SingleSwiper from '/@/components/widget/swiper/single/singleswiper.vue'
  import Comment from '/@/components/comment/index.vue'
  import ShowMore from '/@/components/common/content.vue'
  import { Boiling } from "/@/stores/saunter";

export default defineComponent({
  name: 'UserBoilingListItem',
  components: {
    SingleSwiper,
    Comment,
    ShowMore
  },
  props: {
    boiling: {
      type: Object as PropType<Boiling>,
      required: true
    },
    showComment: {
      type: Boolean,
      default: false
    }
  },
  emits: ['show-comment'],
  setup(props, context) {
    const { globalState } = useEnhancer()

    const articleDetailStore = useArticleDetailStore()
    const fetching = computed(() => articleDetailStore.fetching)

    //是否显示评论
    const isOpenComment = ref<Boolean>(props.showComment.value)
    //显示/隐藏评论
    const openComment = () => {
      isOpenComment.value = !isOpenComment.value
    }

    const getLanguageText = (language: Language) => {
      return language === Language.Chinese ? '中文' : 'EN'
    }

    return {
      LanguageKey,
      numberSplit,
      getLanguageText,
      getArticleDetailRoute,
      getCategoryFlowRoute,
      getTagFlowRoute,
      fetching,
      isOpenComment,
      openComment
    }
  }
})
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.boiling-item {
  position: relative;
  @include radius-box($sm-radius);
  margin-bottom: 1.236rem;
  &:last-child {
    margin: 0;
  }

  > .item-content {
    background-color: $module-bg;

    $padding: $sm-gap;
    > .item-main{
      display: flex;
      padding: $padding $padding * 2;
      overflow: hidden;
      border-bottom: 1px solid $text-divider;
      &:hover {
        background-color: $module-bg-opaque;
      }
      > .item-avatar {
        flex-shrink: 0;
        $width: 50px;
        width: $width;
        height: $width;
        margin-right: $lg-gap;
        position: relative;
        background-color: $module-bg-darker-1;
        @include radius-box(25px);
        img {
          transition: transform 0.5s ease-out;
        }
      }

      > .item-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //height: $content-height;
        padding-right: $xs-gap;

        .user-name {
          display: flex;
          //justify-content: space-between;
          margin-top: 3px;
          margin-bottom: $sm-gap;
          font-weight: bold;

          .link {
            display: block;
            max-width: 26rem;
            margin-left: 0;
            transition: margin $transition-time-normal;
            border-bottom: 1px solid transparent;
            text-decoration: none;
            @include text-overflow();
            color: $text;
            &:hover {
              color: $link-color;
              border-color: initial;
              margin-left: $xs-gap;
            }
          }

          .language {
            opacity: 0.6;
            color: $text-divider;
          }
        }

        .user-other {
          margin: 0;
          line-height: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: $font-size-base;
          color: $text-disabled;
          @include clamp(2);
          .separator {
            margin: 0 5px;
          }
        }
        .content{
          margin-top: 15px;
        }
        .picture{
          margin-top: 10px;
        }

      }
    }
    > .item-action{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /*padding-right: $xs-gap;*/
      padding: $padding $padding * 10;
      border-bottom: 1px solid $text-divider;
      > .item-meta {
        height: 2em;
        line-height: 2em;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        overflow: hidden;
        font-size: $font-size-small;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        color: $text-secondary;

        > .views {
          width: 4em;
        }

        > .likes {
          > .liked {
            color: $red;
          }
        }

        > .likes,
        > .comments {
          width: 3em;
          cursor: pointer;
        }

        > .date,
        > .views,
        > .comments,
        > .likes,
        > .tags,
        > .categories {
          > .iconfont {
            margin-right: $xs-gap;
          }
        }

        > .categories {
          a {
            color: $text-secondary;
            text-transform: capitalize;
            margin-right: $sm-gap;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        > .tags {
          margin-right: 0;
        }
      }

    }
    > .comment{
      padding: 0 10px;
      margin-top: 10px;
    }
  }
}
</style>
