/**
 * @file Proxy constant
 * @module constant.proxy
 * @author potatomelon <https://gitee.com/xiaofango>
 */

export enum ProxyModule {
  Default = 'default',
  Instagram = 'instagram',
  YouTube = 'youtube',
  NetEasyMusic = '163',
  Disqus = 'disqus'
}
