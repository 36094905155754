<template>
  <div class="statement">
    <div class="content">
      <markdown :markdown="siteInfo?.faq" :compact="false" />
      <br />
      <hr />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { emailLink } from '/@/transforms/email'
import { useConfigStore } from '/@/stores/config'
import Markdown from '/@/components/common/markdown.vue'

export default defineComponent({
  name: 'Statement',
  components: { Markdown },
  setup() {
    const configStore = useConfigStore()
    const siteInfo = computed(() => configStore.getConfig("site"))
    console.log(`siteInfo${siteInfo}`)
    return { siteInfo, emailLink }
  }
})
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.statement {
  background-color: $module-bg-opaque !important;
  overflow-y: scroll !important;

  .content {
    padding: 0 2em;
    width: 60rem;
    min-height: 70rem;

    .email {
      .link {
        color: $text-secondary;

        .iconfont {
          margin-right: $xs-gap;
        }
        .text {
          font-weight: bold;
        }

        &:hover {
          color: $link-hover;
        }
      }
    }
  }
}
</style>
