<template>
  <div class="news">
    <div class="news-item baidu">
      <div class="name">百度头条</div>
      <ul class="list">
        <li v-for="(item, index) in baiduData" :key="index" class="item">
          <span class="index small" :data-index="index + 1">{{ index + 1 }}</span>
          <a target="_blank" :href="item.url" class="content">{{ item.title }} </a>
        </li>
      </ul>
    </div>
    <div class="news-item weibo">
      <div class="name">微博热搜</div>
      <ul class="list">
        <li v-for="(item, index) in weiboData" :key="index" class="item">
          <span class="index small" :data-index="index + 1">{{ index + 1 }}</span>
          <a target="_blank" :href="item.url" class="content">{{ item.title }} </a>
        </li>
      </ul>
    </div>
    <div class="news-item zhihu">
      <div class="name">知乎日报</div>
      <ul class="list">
        <li v-for="(item, index) in zhihuData" :key="index" class="item">
          <span class="index small" :data-index="index + 1">{{ index + 1 }}</span>
          <a target="_blank" :href="item.url" class="content">{{ item.title }} </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import {defineComponent, computed, reactive, ref, toRaw, toRefs} from "vue";
  import {LanguageKey} from "/@/language";
  import {useEnhancer} from "/@/app/enhancer";
  import { baidu, weibo, zhihu } from "./data";

  export default defineComponent({
    name: "News",
    props: {
      data:  Object
    },
    setup(props){
      const { globalState, meta, i18n  } = useEnhancer()

      const baiduData = computed(() => { return props.data.baiduNews })
      const weiboData = computed(() => { return props.data.weiboNews })
      const zhihuData = computed(() => { return props.data.zhihuNews })

      return {
        baiduData,
        weiboData,
        zhihuData
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .news{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .news-item{
      display: flex;
      flex-direction: column;
      margin-right: 5px;
      border-radius: 5px;
      min-height: 200px;
      padding: $sm-gap;
      .name{
        text-align: center;
        padding: 5px 20px;
      }
      .list{
        list-style: none;
        padding: 0 0;
        margin-bottom: 0;
        .item{
          display: flex;
          flex-direction: row;
          line-height: 2em;
          align-items: center;
          padding: 0 $sm-gap;
          &:hover{
            background: #f6f6f6;
            opacity: 0.9;
            border-radius: 5px;
          }
          .index{
            $size: $font-size-base;
            width: $size;
            height: $size;
            line-height: $size;
            margin-top: 4px;
            display: inline-block;
            flex-shrink: 0;
            margin-right: $sm-gap;
            text-align: center;
            border-radius: $xs-radius;
            color: $text-disabled;
            font-weight: 700;
            font-size: $font-size-base - 1;
            &.small {
              font-size: $font-size-small;
            }
            &[data-index='1'],
            &[data-index='2'],
            &[data-index='3'] {
              color: $white;
              font-size: $font-size-small * 0.8;
            }
            &[data-index='1'] {
              background-color: $surmon;
            }
            &[data-index='2'] {
              background-color: rgba($accent, 0.7);
            }
            &[data-index='3'] {
              background-color: rgba($red, 0.6);
            }
          }
          .content{
            width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .baidu{
      background: linear-gradient(to bottom, rgba(#ff6d0d, 0.5), transparent);
    }
    .weibo{
      background: linear-gradient(to bottom, rgba(#fc2626, 0.5), transparent);
    }
    .zhihu{
      background: linear-gradient(to bottom, rgba(#1a65ff, 0.5), transparent);
    }
  }
</style>
