<template>
  <div class="background">
    <div class="upload">
      <button type="button" class="preview add" :style="{ 'backgroundImage': `url(${background.cover})`, 'background-repeat': 'no-repeat','background-size': '100% 100%','opacity': '0.9'}" @click="selectImage">
        <div v-if="loading" class="loading">
          <load :loading = "loading" />
        </div>
        <upload
          ref="upload"
          style="display: none"
          type="jpg,jpeg,png"
          :bulk="2"
          :multiple = true
          @data="res"
          v-model:loading="loading"
        />
        <i class="thirdiconfont el-icon-third-share-upload"></i>
      </button>
      <button class="up" :disabled="loading" @click="handleSubmit()">
        设成背景
<!--        <i class="iconfont icon-new-window"></i>-->
      </button>
    </div>
    <page-title class="module-title youtube" :level="5">
      <template #left>
        <span class="brand">系统推荐</span>
      </template>
      <template #right>
        <span class="brand">20</span>
      </template>
    </page-title>
    <div class="default">
      <placeholder :data="userBackgroundStore.defaultList.data" :loading="userBackgroundStore.defaultList.fetching">
        <template #placeholder>
          <empty class="module-empty" key="empty">
            <i18n :k="LanguageKey.EMPTY_PLACEHOLDER" />
          </empty>
        </template>
        <template #loading>
          <grid-skeleton
            :columns="4"
            :rows="2"
            :height="100"
            key="loading"
            class="module-loading"
          />
        </template>
        <template #default>
          <div class="module-content">
            <ul class="images">
              <li
                class="image"
                :key="index"
                v-for="(item, index) in userBackgroundStore.defaultList.data"
                :title="item.cover"
              >
                <div v-if="item.cover === userStore.currentBackground" class="masked">
                  <span class="icon">
                    <i class="iconfont icon-success"></i>
                  </span>
                </div>
                <div v-else class="mask">
                  <span class="icon">
                    <i class="iconfont icon-eye" @click="viewImg(item.cover)"></i>
                    <i class="iconfont icon-setting" @click="setBackground(item)"></i>
                  </span>
                </div>
                <div
                  z-load
                  class="background"
                  :style="{ backgroundImage: `url(${item.cover})`}"
                />
              </li>
            </ul>
          </div>
        </template>
      </placeholder>
    </div>
    <page-title class="module-title youtube" :level="5">
      <template #left>
        <span class="brand">历史设置</span>
      </template>
      <template #right>
        <span class="brand">20</span>
      </template>
    </page-title>
    <div class="history">
      <placeholder :data="userBackgroundStore.historyList.data" :loading="userBackgroundStore.historyList.fetching">
        <template #placeholder>
          <empty class="module-empty" key="empty">
            <i18n :k="LanguageKey.EMPTY_PLACEHOLDER" />
          </empty>
        </template>
        <template #loading>
          <grid-skeleton
            :columns="4"
            :rows="2"
            :height="100"
            key="loading"
            class="module-loading"
          />
        </template>
        <template #default>
          <div class="module-content">
            <ul class="images">
              <li
                class="image"
                :key="index"
                v-for="(item, index) in userBackgroundStore.historyList.data"
                :title="item.cover"
              >
                <div v-if="item.cover === userStore.currentBackground" class="masked">
                  <span class="icon">
                    <i class="iconfont icon-success"></i>
                  </span>
                </div>
                <div v-else class="mask">
                  <span class="icon">
                    <i class="iconfont icon-eye" @click="viewImg(item.cover)"></i>
                    <i class="iconfont icon-setting" @click="setBackground(item)"></i>
                  </span>
                </div>
                <div
                  z-load
                  class="background"
                  :style="{ backgroundImage: `url(${item.cover})`}"
                />
              </li>
            </ul>
          </div>
        </template>
      </placeholder>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, toRaw } from "vue";
  import { useUserBackgroundStore, useUserStore } from "/@/stores/user";
  import { useEnhancer } from "/@/app/enhancer";
  import { META } from "/@/config/app.config";
  import { LanguageKey } from "/@/language";
  import PageTitle from '/@/components/common/fullpage/title.vue'
  import GridSkeleton from '/@/components/common/skeleton/gridskeleton.vue'
  import { useUniversalFetch } from "/@/universal";
  import Upload from "/@/components/common/upload.vue"
  import Load from "/@/components/common/load.vue"

  export default defineComponent({
    name: "Background",
    components: {
      PageTitle,
      GridSkeleton,
      Load,
      Upload
    },
    setup(){
      const userBackgroundStore = useUserBackgroundStore()
      const userStore = useUserStore()
      const { globalState, meta, i18n  } = useEnhancer()
      meta(() => ({
        title: `个人-背景设置 - ${META.title}`,
        description: `${META.description}`,
        keywords: `个人-背景设置,${META.keywords}`
      }))
      const upload = ref(null);
      const loading  = ref(false);
      const background = reactive({
        id: null,
        cover: ""
      })
      const fetchAllData = () => {
        return Promise.all([
          userBackgroundStore.fetchUserBackground()
        ])
      }

      const viewImg = (url: string) => {
        window.$popup.vImage(url)
      }

      const selectImage = () => {
        upload.value.inputClick();
      }
      const handleSubmit = () => {
        try {
          userBackgroundStore.updateUserBackground(toRaw(background)).then(res => {
            userBackgroundStore.fetchUserBackground()
            userStore.setBackground(background.cover)
            background.id = null
            background.cover = ''
          })
        }catch (e) {
          console.log(e)
          alert(e)
        }
      }

      const setBackground = (item: any) => {
        background.id = item.id
        background.cover = item.cover
        handleSubmit()
      }

      const res = (data: object) =>{
        background.cover = data?.data[0].filePath
      }

      //触发接口请求
      useUniversalFetch(() => fetchAllData())

      return {
        LanguageKey,
        userBackgroundStore,
        viewImg,
        loading,
        upload,
        background,
        userStore,
        selectImage,
        res,
        setBackground,
        handleSubmit
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';


  .background{
    padding: $lg-gap;
    @include radius-box($sm-radius);
    background-color: $module-bg;
    /*    background-color: $module-bg;
        &:hover {
          background-color: $module-bg-opaque;
        }*/
    .upload{
      display: flex;
      flex-direction: column;
      .preview{
        position: relative;
        width: 100%;
        height: 100px;
        @include radius-box($sm-radius);
        display: flex;
        justify-content: center;
        align-items: center;
        .loading{
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          top: 0;
          width: 100%;
          overflow-y: auto;
          background-color: $module-bg-lighter;
        }
        &.add{
          border: 1px dotted $text-divider;
        }
      }
      .up{
        $height: 2.4em;
        display: block;
        width: 100%;
        margin-top: $lg-gap;
        line-height: $height;
        border: 1px solid;
        border-color: $primary;
        color: $primary;
        font-size: $font-size-small;
        text-align: center;
        letter-spacing: 1px;
        transition: color $transition-time-fast, background-color $transition-time-fast;
        @include radius-box($xs-radius);

        &:hover {
          color: $text-reversal;
          background-color: $primary;
        }
      }
    }
    .module-title{
      &.youtube{
        --item-primary: #{$youtube-primary};
        background: linear-gradient(to right, $module-bg-opaque, transparent);
      }
    }
    .module-content{
      $size: 100px;
      .images {
        margin: 0;
        padding: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        //grid-template-rows: repeat(3, 1fr);
        grid-gap: $gap * 2;

        .image {
          position: relative;
          display: block;
          height: $size;
          overflow: hidden;
          @include radius-box($sm-radius);
          @include common-bg-module();
          &:hover {
            .background {
              transform: scale(1.15);
            }

            .mask {
              @include visible();
              .icon {
                transform: scale(1);
              }
            }
          }

          .background {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            transform: scale(1.05);
            @include transform-transition($transition-time-normal);
          }

          .length {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: $z-index-normal + 1;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 2rem;
            border-top-left-radius: $xs-radius;
            background-color: $text-divider;
            color: $white;
          }

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: $z-index-normal + 1;
            background-color: rgba(#000, 0.3);
            color: rgba($white, 0.8);
            @include backdrop-blur(2px);
            @include hidden();
            @include visibility-transition();

            .icon {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              //grid-template-rows: repeat(3, 1fr);
              grid-gap: $gap;
              font-size: 2.5em;
              transform: scale(1.2);
              @include transform-transition($transition-time-normal);
              i{
                cursor: pointer;
              }
            }
          }
          .masked {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: $z-index-normal + 1;
            //background-color: rgba(#000, 0.3);
            color: rgba($accent, 0.8);
            @include backdrop-blur(2px);
            @include visible();
            @include visibility-transition();

            .icon {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              //grid-template-rows: repeat(3, 1fr);
              grid-gap: $gap;
              font-size: 2.5em;
              transform: scale(1.2);
              @include transform-transition($transition-time-normal);
            }
          }
        }
      }
    }
  }
</style>
