<template>
  <placeholder :data="pictureStore.pictureList.data" :loading="pictureStore.pictureList.fetching">
    <template #placeholder>
      <slot name="empty"></slot>
    </template>
    <template #loading>
      <slot name="loading"></slot>
    </template>
    <template #default>
      <list-swiper
        :rows="4"
        :columns="5"
        :data="pictureStore.pictureList.data">
        <template #item="{ item }">
          <div class="picture">
            <div class="thumb">
              <!-- 预览按钮 -->
              <div class="mask">
                <div class="button" @click="handleView(item)">
                  <i class="iconfont icon-eye" />
                </div>
              </div>
              <!-- 发布时间 -->
              <span class="tag">
<!--                <i class="iconfont icon-clock"></i>-->
                <span  v-for="(i, index) in item.tagList" class="text">
                  {{ i.name }}
                </span>
              </span>
              <div
                z-load
                class="background"
                :style="{ backgroundImage: `url(${item.pictures[0].filePath + '?x-oss-process=image/resize,p_50'})`}"
              />
            </div>
            <div class="content">
              <div class="foot-info">
                <div class="title">{{ item.title }}</div>
                <div class="author">
                  <uimage :alt="item.nickName" class="avatar" :src="item.avatar" />
                  <div class="nickname"><router-link :to=getUserDetailRoute(item.userId)>{{ item.nickName }}</router-link></div>
                </div>
              </div>
              <div class="foot-action">
                <span class="time"><udate to="YMD" :date="item.publishTime" /></span>
                <div class="action">
                  <button type="button" class="like" :class="{liked: isLike(item.id)}" @click="handleLike(item.id)"><i class="icon thirdiconfont" :class="isLike(item.id) ? 'el-icon-third-like-i' : 'el-icon-third-like-good'"  style="font-size: 18px"></i>({{item.likeNum}})</button>
                  <button type="button" class="collect" :class="{collected: isCollect(item.id)}" @click="handleCollect(item.id)"><i class="icon thirdiconfont" :class="isCollect(item.id) ? 'el-icon-third-star-i' : 'el-icon-third-star'" style="font-size: 18px"></i>({{item.collectNum}})</button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </list-swiper>
    </template>
  </placeholder>
  <!--  预览  -->
  <exhibition
    :image="imageList"
    :visible="isOnImageModal"
    @close="closeImageModal"
  />
</template>

<script lang="ts">

  import {computed, defineComponent, onMounted, ref, reactive} from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { usePictureStore } from '/@/stores/picture'
  import { LanguageKey } from '/@/language'
  import { ProxyModule } from '/@/constants/proxy'
  import { getTargetProxyURL } from '/@/transforms/url'
  import ListSwiper from '/@/components/widget/swiper/swiper.vue'
  import {useUniversalFetch} from "/@/universal";
  import Exhibition from "/@/pages/picture/exhibition.vue";
  import { UNDEFINED } from "/@/constants/value";
  import Toast from "/@/composables/toast";
  import { useUserStore } from '/@/stores/user'
  import { getUserDetailRoute } from '/@/transforms/route'

  export default defineComponent({
    name: "Images",
    components: {
      ListSwiper,
      Exhibition
    },
    setup(props, context) {
      const pictureStore = usePictureStore()
      const userStore = useUserStore()
      // const pictures = ref<Array<any>>([])
      // const pictures = computed(() => {
      //   return pictureStore.pictureList.data.forEach(e => {
      //     console.log(`e:${e}`)
      //     e.cover = e.cover + '?x-oss-process=image/resize,p_50'
      //   })
      // })

      const imageModal = ref<any>()
      const images = reactive<Object>({})
      //关闭/弹出
      const isOnImageModal = computed(() => imageModal.value !== UNDEFINED)
      const isLike = computed(() => (id: string) => userStore.votes.likes.includes(id))
      const isCollect = computed(() => (id: string) => userStore.votes.collects.includes(id))
      const imageList = computed(() => {
        return images
      })
      //弹出
      const handleView = (item: any) => {
        images.value = item
        imageModal.value = item.id
      }
      //关闭
      const closeImageModal = () => {
        imageModal.value = UNDEFINED
      }

      const handleCollect = async (pictureId: string) => {
        if(!userStore.isLogin){
          Toast.error("请登入！")
          return
        }
        try {
          const flag = await pictureStore.postPictureCollect(pictureId)
          userStore.changesVotes(flag, 'collects', pictureId)
        } catch (error) {
          alert(error)
        }
      }

      const handleLike = async (pictureId: string) => {
        if(!userStore.isLogin){
          Toast.error("请登入！")
          return
        }
        try {
          const flag = await pictureStore.postPictureLike(pictureId)
          userStore.changesVotes(flag, 'likes', pictureId)
        } catch (error) {
          alert(error)
        }
      }

      const fetchAllData = () => {
        return Promise.all([
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          pictureStore.fetchPictureList().catch(() => {})
        ])
      }


      //触发接口请求
      //useUniversalFetch(() => fetchAllData())
      onMounted(() => {
        fetchAllData()
      })
      return {
        LanguageKey,
        pictureStore,
        isOnImageModal,
        imageModal,
        closeImageModal,
        handleView,
        handleCollect,
        handleLike,
        getUserDetailRoute,
        images,
        imageList,
        userStore,
        isLike,
        isCollect
      }
    }
  })
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.picture {
  display: block;
  height: 166px;
  //cursor: pointer;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,.5);
  transition: .25s;
  @include radius-box($sm-radius);
  @include common-bg-module();
  &:hover {
    .thumb {
      .background {
        transform: scale(1.1);
      }

      .mask {
        @include visible();
        .button {
          transform: scale(1);
          cursor: pointer;
        }
      }
    }

    .title {
      color: $link-color;
    }
    .content {
      @include visible();
    }
  }

  .thumb {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: $module-bg-darker-3;

    .background {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transform: scale(1);
      @include transform-transition($transition-time-normal);
    }

    .tag {
      position: absolute;
      top: $sm-gap;
      right: 1rem;
      z-index: $z-index-normal + 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      //padding: 0 $sm-gap;
      //margin-left: $sm-gap;
      //border-radius: $sm-radius;
      //background-color: rgba(#000, 0.3);
      //font-size: $font-size-small;
      //color: $white;

      .iconfont {
        margin-right: $xs-gap;
      }
      .text {
        padding: 0 $sm-gap;
        margin-left: $sm-gap;
        border-radius: $sm-radius;
        background-color: rgba(#000, 0.3);
        font-size: $font-size-small;
        color: $white;

        font-weight: bold;

      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: $z-index-normal + 1;
      background-color: rgba(#000, 0.4);
      @include hidden();
      @include visibility-transition();

      .button {
        opacity: 0.88;
        font-size: 3em;
        text-align: center;
        color: rgba($white, 0.8);
        transform: scale(1.2);
        @include transform-transition($transition-time-normal);
      }
    }
  }
  .content{
    width: 100%;
    position: absolute;
    z-index: 99999;
    bottom: 0;
    left: 0;
    background-color: $module-bg;
    @include backdrop-blur(5px);
    @include hidden();
    @include visibility-transition();
    .foot-info,
    .foot-action {
      display: flex;
      align-items: center;
      padding: 0 0.7em;
      justify-content: space-between;
    }

    .foot-info{
      @include text-overflow();
      .title{
        opacity: 0.8;
        max-width: 50%;
      }
      .author{
        display: flex;
        align-items: center;
        max-width: 40%;
        .avatar{
          width: 20px;
          height: 20px;
          border-radius: 10px;
        }
        .nickname{
          margin-left: $sm-gap;

        }
      }
    }
    .foot-action{
      .time{
        opacity: 0.5;
        font-size: $font-size-h6;
      }
      .action{
        display: flex;
        align-items: center;
        .like,
        .collect{
          margin-left: $sm-gap;
          &:hover {
            color: $gitee-primary-hover;
          }
        }
        .liked,
        .collected{
          color: $gitee-primary-hover;
        }
      }
    }
  }


  .title {
    margin-top: $sm-gap;
    margin-bottom: 0.4em;
    font-weight: bold;
    color: $text;
    max-width: 90%;
    @include text-overflow();
  }
  .nickname{
    @include text-overflow();
  }

  .description {
    max-width: 95%;
    font-size: $font-size-h5 - 1;
    color: $text-secondary;
    @include text-overflow();
  }
}
</style>
