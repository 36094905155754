<template>
  <div class="navigation-page">
    <div
      class="banner"
      :class="{ dark: isDarkTheme }"
      style="backgroundImage:url('/images/page-navigation/banner.png');backgroundPositionY: 66%"
    >
      <div class="content">
        <h2 class="title">
          网址大全，不定期更新收入导航内容
        </h2>
        <div class="description" @click="importData">
          开发者导航可移步 →
          <router-link
            class="link"
            to="/scavenge/navigation"
            title="导航工具"
          >
            这里
          </router-link>
        </div>
      </div>
      <div class="banner-tool container" v-if="navigationStore.navigationResource.recommendList.length > 0">
        <ulink class="item" v-for="item in navigationStore.navigationResource.recommendList" :to="item?.url" :href="item.path"  @click="count(item.id)">
          <img :src="item.icon" class="icon">
          <span class="name">{{ item.name}}</span>
        </ulink>
      </div>
    </div>
    <div class="all container-plus">
      <template  v-for="item in navigationStore.navigationResource.groupList">
        <div class="group" v-if="item?.navigationList">
          <div class="title">{{ item.categoryName }}</div>
          <div class="list">
            <ulink class="item" v-for="i in item.navigationList" :to="i?.url" :href="i.path"  @click="count(i.id)">
              <img :src="i.icon" class="icon">
              <span class="name">{{ i.remark}}</span>
            </ulink>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { META, VALUABLE_LINKS } from '/@/config/app.config'
  import { Language, LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { firstUpperCase } from '/@/transforms/text'
  import { emailLink } from '/@/transforms/email'
  import { allNavigationUrl, groupNavigationUrl } from '/@/pages/scavenge/navigationData'
  import PageBanner from '/@/components/common/fullpage/banner.vue'
  import { useNavigationStore } from "/@/stores/scavenge";

  export default defineComponent({
    name: 'NavigationPage',
    components: {
      PageBanner
    },
    setup() {
      const { i18n, meta, isZhLang, isDarkTheme } = useEnhancer()
      const navigationStore = useNavigationStore()

      const navigationTop = computed(() => { return allNavigationUrl.slice(0, 10) })
      const navigationList = computed(() => { return allNavigationUrl })

      const groupList = computed(() => { return groupNavigationUrl })

      meta(() => ({
        title: `导航 - ${META.title}`,
        description: `${META.description}`,
        keywords: `导航,${META.keywords}`
      }))

      // const state: waterfallFlow = reactive({
      //   waterfallFlowHeight: [0, 0, 0, 0, 0, 0]
      // })
      // const waterfallFlowFun = () => {
      //   const dom = document.querySelectorAll('.group')
      //   dom.forEach((item: any) => {
      //     item.style.position = 'absolute'
      //     const minIndex = filterMin()
      //     item.style.top = state.waterfallFlowHeight[minIndex] + 10 + 'px'
      //     item.style.left = minIndex * (100 / 2) + '%'
      //     state.waterfallFlowHeight[minIndex] += item.querySelector('.list').offsetHeight + 10
      //   })
      // }
      // const filterMin = () => {
      //   const min = Math.min.apply(null, state.waterfallFlowHeight)
      //   return state.waterfallFlowHeight.indexOf(min)
      // }
      // const _isMobile = () => {
      //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      //   return flag
      // }
      // onMounted(() => waterfallFlowFun())

      // const importData = () => {
      //   navigationStore.postSubmit(allNavigationUrl)
      // }

      const count = (id: string) => {
        navigationStore.postClick({id: id})
      }

      onMounted(() => {
        navigationStore.fetchNavigationResourceList()
      })

      return {
        isDarkTheme,
        LanguageKey,
        navigationTop,
        navigationList,
        navigationStore,
        count,
        groupList
      }
    }
  })
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

  .navigation-page{
    //max-height: 10000px;
    //position: relative;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    //width: $container-width + 200;
    //margin: 0 auto;
    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      height: $full-column-page-banner-height + 10rem;
      background-color: $module-bg-darker-1;
      background-size: cover;
      //background-position-x: center;
      opacity: 0.8;
      &.dark {
         background-blend-mode: difference;
      }

      .content {
        //height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $white;
        &.blur {
          @include backdrop-blur(3px);
        }

        .title {
          font-size: 3em;
          margin-top: 0;
          margin-bottom: $lg-gap * 2;
        }

        .description {
          font-size: $font-size-h4;
          font-weight: bold;
          .link{

          }
        }
      }

      &.mobile {
        height: $mobile-banner-height;
        margin-top: -$lg-gap;
        margin-left: -$gap;
        margin-right: -$gap;

        .title {
          font-size: 2em;
        }
      }

      .banner-tool{
        position: absolute;
        bottom: 0px;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        background: $module-bg-darker-1;
        //border: 1px solid $text-darker;
        //border-bottom: 1px dotted $text-darker;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        .item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 10px;
          $size: 40px;
          .icon{
            width: $size;
            height: $size;
          }
          .name{
            text-align: center;
            line-height: 30px;
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &:hover{
            .icon{
              opacity: 0.88;
              transform: translateY(-3px);
            }
            .name{
              opacity: 0.88;
              transform: translateY(3px);
              color: $surmon;
            }

          }
        }
      }
    }
    .all {
      width: 85vw;
      min-height: 80vh;
      margin: 20px auto;
      column-count: 4;
      column-gap: 10px;
      .group {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        break-inside: avoid;
        .title{
          line-height: 1rem;
          padding: $sm-gap $xs-gap;
          border-left: 3px solid $surmon;
          color: #f47983;
          background: linear-gradient(to right, rgba(#ff6d0d, 0.2), transparent);
          font-size: 18px;
        }
        .list{
          display: flex;
          flex-direction: column;
          .item{
            display: flex;
            justify-items: center;
            align-items: center;
            height: 36px;
            line-height: 36px;
            cursor: pointer;
            &:hover{
              opacity: 0.88;
              transform: translateX(-3px);
              color: $surmon;
            }
            .icon{
              width: 20px;
              height: 20px;
              margin-right: 5px;

            }
            .name{
              white-space: nowrap;
            }
          }
        }
      }
      //.item img{
      //  width: 100%;
      //  height:100%;
      //}
    }
    .container-plus{

    }
  }

</style>
