/**
 * @file NodePress API service
 * @module service.nodepress
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import axios, { AxiosInstance, Method as AxiosMethod } from 'axios'
import cookies from 'js-cookie'
import {BAD_REQUEST, NOT_FOUND, SUCCESS, INVALID_ERROR, FORBIDDEN} from '/@/constants/error'
import Toast from "/@/composables/toast/index"
import API_CONFIG from '/@/config/api.config'

export type LocalhostResult<T = any> = {
  total: number
  rows: Array<any>
  code: number
  msg: string
  data: T
  pagination: any
}

const localhost = axios.create({
  baseURL: API_CONFIG.NODEPRESS,
  timeout: 800000, // request timeout
  headers: {
    'Accept': 'application/json',
  },
})

localhost.interceptors.request.use(
  (config) => {
  // 是否需要设置 token
    const token = cookies.get('user-token')
    // console.log(`请求url:${config.url}`)
    // console.info(`request headers:${token}`)
    if (token) {
      // console.log(`已携带token`)
      if (config.headers) {
        config.headers['Authorization'] = 'Bearer ' + token
      } // 让每个请求携带自定义token 请根据实际
    }
    return  config;
  }, (error) => {
    // console.log(error); // for debug
    return Promise.reject(error);
  }
)

localhost.interceptors.response.use(
  (response) => {
    // if (response.headers['content-type'].includes('json')) {
    //   if (response.data.status !== LocalhostResponseStatus.Success) {
    //     return Promise.reject(response.data)
    //   }
    // }
    console.log(`response`)
    const res = response.data;
    const code = res.code || 200;
    if (code === 500) {
      console.log("拦截response响应code：" + JSON.stringify(res.msg));
      return Promise.reject(res.msg)//直接将错误消息传入catch，弹窗显示
    }
    return res;
  },
  (error) => {
    // console.log(`response error:${error}`)
    Toast.error(error)
    return Promise.reject(error)
  }
)

type Method = Exclude<Lowercase<AxiosMethod>, 'unlink' | 'purge' | 'link'> | 'request'
const overwrite = (method: Method) => {
  return <T = any>(
    ...args: Parameters<AxiosInstance[typeof method]>
  ): Promise<LocalhostResult<T>> => {
    return (localhost[method] as any)(...args)
  }
}

export default {
  $: localhost,
  request: overwrite('request'),
  head: overwrite('head'),
  get: overwrite('get'),
  post: overwrite('post'),
  put: overwrite('put'),
  patch: overwrite('patch'),
  delete: overwrite('delete'),
  options: overwrite('options')
}
