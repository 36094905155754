<template>
  <div class="account">
    <form>
      <div class="profile-form">
        <div class="info">
          <div class="form-item name">
            <div class="label">手机</div>
            <input
              :value="account.name"
              required
              type="text"
              name="name"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check">绑定</span>
          </div>
          <div class="form-item position">
            <div class="label">邮箱</div>
            <input
              :value="account.position"
              required
              type="text"
              name="position"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check">绑定</span>
          </div>
          <div class="form-item company">
            <div class="label"><span style="color: red">*</span>旧密码</div>
            <input
              :value="account.company"
              required
              type="text"
              name="company"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check"></span>
          </div>
          <div class="form-item password">
            <div class="label"><span style="color: red">*</span>新密码</div>
            <input
              :value="account.password"
              required
              type="password"
              name="company"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check"></span>
          </div>
          <div class="form-item password">
            <div class="label"><span style="color: red">*</span>确认密码</div>
            <input
              :value="account.password2"
              required
              type="password"
              name="password2"
              autocomplete="on"
              maxlength="20"
            />
            <span class="check"></span>
          </div>

        </div>
        <div class="buttons">
          <button class="item submit">
            <i class="iconfont icon-mail-plane" />
            <span class="text">
          提交
        </span>
          </button>
        </div>
      </div>

    </form>

  </div>
</template>

<script lang="ts">
  import {  defineComponent, reactive } from "vue";
  import {  useEnhancer } from "/@/app/enhancer";
  import {  META } from "/@/config/app.config";
  import {  LanguageKey } from "/@/language";

  export default defineComponent({
    name: "Account",
    setup(){
      const account = reactive({
        name: '',
        position: '',
        company: '',
        introduce: ''
      })
      const { globalState, meta, i18n  } = useEnhancer()
      meta(() => ({
        title: `个人-账户设置 - ${META.title}`,
        description: `${META.description}`,
        keywords: `个人-账户设置,${META.keywords}`
      }))

      return {
        account
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $avatar-size: 10rem;
  .account{
    height: 800px;
    padding: $lg-gap;
    @include radius-box($sm-radius);
    background-color: $module-bg;
    /*    background-color: $module-bg;
        &:hover {
          background-color: $module-bg-opaque;
        }*/
    .profile-form {
      /*padding-left: 5.2rem;*/
      display: flex;
      justify-content: center;
      flex-direction: column;

      .info {
        flex: 1;
        display: flex;
        padding: $lg-gap;
        margin-bottom: $gap;
        flex-direction: column;
        justify-content: center;
        @include radius-box($sm-radius);
        background-color: $module-bg;
        &:hover {
          background-color: $module-bg-opaque;
        }
        .form-item{
          display: flex;
          margin-bottom: $lg-gap;
          padding: $sm-gap $lg-gap;
          .label{
            display: flex;
            justify-content: right;
            align-items: center;
            margin-right: $lg-gap;
            width: 80px;
          }
          textarea,
          input {
            /*width: 80%;*/
            flex: 1;
            line-height: 2em;
            text-indent: 3px;
            background-color: $module-bg-darker-1;
            @include radius-box($sm-radius);
            @include background-transition();
            border: 1px solid $text-divider;
            resize:none;
            &:focus,
            &:hover {
              background-color: $module-bg-hover;
              border: 1px solid $primary;
            }
          }
        }
        .name,
        .email,
        .site {

        }
      }
    }
    .buttons {
      display: flex;
      justify-content: right;

      .item {
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $mini-radius;
        &[disabled] {
          opacity: 0.7;
        }
        &:not([disabled]):hover {
          color: $link-hover;
        }

        &.cancel {
          width: 7rem;
          color: $text-secondary;
          &:not([disabled]):hover {
            color: $link-color;
          }
        }

        &.submit {
          width: 12rem;
          color: $text-secondary;
          background-color: $module-bg-darker-2;
          &:not([disabled]):hover {
            background-color: $module-bg-darker-3;
          }
        }

        .iconfont {
          margin-right: 1em;
        }

        .text {
          font-weight: bold;
        }
      }
    }
  }
</style>
