<template>
  <div class="boiling-item">
    <div class="item-content">
      <div class="item-main">
        <!--   左封面   -->
        <div class="item-avatar" v-if="!isMobile">
          <img alt="用户名" :src="boiling.user.avatar" />
        </div>
        <!--   右内容   -->
        <div class="item-body">
          <div class="item-content">
            <div class="user-info">
              <div class="item-avatar" v-if="isMobile">
                <img alt="用户名" :src="boiling.user.avatar" />
              </div>
              <router-link class="link" :to="getUserDetailRoute(boiling.user.id)" :title="boiling.user.nickName">
                {{ boiling.user.nickName }}
              </router-link>
<!--              <span class="language">{{ boiling.boneName }}</span>-->
              <span class="description" v-if="boiling.user.occupation">{{ '@' + boiling.user.occupation }}</span>
            </div>
            <div class="user-nick">

              <udate to="ago" :date="boiling.publishDate" />
              <template v-if="boiling.ip">
                <span class="separator">•</span>
                <span class="description">{{ boiling.city }}</span>
              </template>
            </div>
            <show-more :show-content="boiling.content" :padding-bottom=4 :padding-right=8 />
            <div class="picture">
              <single-swiper :images="boiling.pictures" />
            </div>
            <!--     video       -->
            <div class="picture" v-if="boiling.video?.src">
              <basic-video :src="boiling.video?.src" :poster="boiling.video?.poster" />
            </div>
          </div>
        </div>
      </div>
      <!--   底部动作   -->
      <div class="item-action">
        <div class="item-meta">
<!--          <span class="date">-->
<!--            <i class="iconfont icon-clock"></i>-->
<!--            <udate to="ago" :date="article.createTime" />-->
<!--          </span>-->


          <button :disabled="userStore.votes.fetching" class="likes" @click="handleLike(boiling.id)">
            <i class="iconfont icon-like" :class="{ liked: isLike }"></i>
            <span>{{ boiling.likeNum }}</span>
          </button>
          <span class="comments" @click="openComment">
            <i class="iconfont icon-comment"></i>
            <span>{{ boiling.commentNum }}</span>
          </span>
          <span class="share">
            <i class="iconfont icon-share"></i>
<!--            <span>{{ numberSplit(boiling.viewNum) }}</span>-->
          </span>
          <span class="categories">
            <i class="iconfont icon-category"></i>
            <router-link
              :to="getCategoryFlowRoute(boiling.boneId)"
            >
                  {{boiling.boneName}}
                </router-link>

          </span>
        </div>
      </div>
      <div class="comment" v-if="isOpenComment">
        <comment :module-type="ModuleState.Boiling" :plain="isMobile" :postId="boiling.id" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType, ref, reactive } from 'vue'
  import { Language, LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { ModuleState } from '/@/constants/state'
  import { getUserDetailRoute, getTagFlowRoute, getCategoryFlowRoute } from '/@/transforms/route'
  import { isOriginalType, isHybridType, isReprintType } from '/@/transforms/state'
  import { getArticleListThumbnailURL } from '/@/transforms/thumbnail'
  import { numberSplit } from '/@/transforms/text'
  import SingleSwiper from '/@/components/widget/swiper/single/singleswiper.vue'
  import Comment from '/@/components/comment/index.vue'
  import Markdown from '/@/components/common/markdown.vue'
  import ShowMore from '/@/components/common/content.vue'
  import BasicVideo from '/@/components/common/video.vue'
  import {markdownToHTML} from "/@/transforms/markdown";
  import { useCommentStore } from "/@/stores/comment";
  import { useUserStore } from "/@/stores/user";
  import {useUniversalFetch} from "/@/universal";
  import { getCity } from "/@/server/getters/ip";
  import { Boiling } from "/@/stores/saunter";

export default defineComponent({
  name: 'BoilingListItem',
  components: {
    SingleSwiper,
    Comment,
    Markdown,
    ShowMore,
    BasicVideo
  },
  props: {
    boiling: {
      type: Object as PropType<Boiling>,
      required: true
    },
    showComment: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['show-comment', 'like'],
  setup(props, context) {
    const { globalState } = useEnhancer()
    const commentStore = useCommentStore()
    const userStore = useUserStore()

    // const isLiked = computed(() => universalStore.isLikedPage(props.article.id))
    // const isHybrid = isHybridType(props.article.origin)
    // const isReprint = isReprintType(props.article.origin)
    // const isOriginal = isOriginalType(props.article.origin)
    const isLike = computed(() => userStore.votes.likes.includes(props.boiling.id))

    const getThumbnailURL = (thumbURL: string) => {
      return getArticleListThumbnailURL(thumbURL, globalState.imageExt.value.isWebP)
    }

    const renderMarkdown = (markdown: string) => {
      return markdownToHTML(markdown, {
        sanitize: false,
        relink: false
      })
    }
    //是否显示评论
    const isOpenComment = ref<Boolean>(props.showComment.value)
    //显示/隐藏评论
    const openComment = () => {
      isOpenComment.value = !isOpenComment.value
      if(isOpenComment.value){
        commentStore.fetchList({ resourceId: props.boiling.id,moduleType: ModuleState.Boiling})
      }
    }

    const getLanguageText = (language: Language) => {
      return language === Language.Chinese ? '中文' : 'EN'
    }

    const getCityByIp = async (ip: string) => {
      const city = await getCity(ip)
      props.boiling.city = city
    }



    const handleLike = (id: string) => {
      context.emit('like', id)
    }

    useUniversalFetch(() => getCityByIp(props.boiling.ip))

    return {
      LanguageKey,
      // isLiked,
      // isHybrid,
      // isReprint,
      // isOriginal,
      numberSplit,
      getThumbnailURL,
      getLanguageText,
      getUserDetailRoute,
      getCategoryFlowRoute,
      getTagFlowRoute,
      ModuleState,
      isOpenComment,
      openComment,
      renderMarkdown,
      isLike,
      handleLike,
      userStore
    }
  }
})
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.boiling-item {
  position: relative;
  @include radius-box($sm-radius);

  &:last-child {
    margin: 0;
  }

  > .item-content {
    background-color: $module-bg;

    $padding: $sm-gap;
    > .item-main{
      display: flex;
      padding: $padding;
      overflow: hidden;
      border-bottom: 1px solid $text-divider;
      &:hover {
        background-color: $module-bg-opaque;
      }
      > .item-avatar {
        flex-shrink: 0;
        $width: 50px;
        width: $width;
        height: $width;
        margin-right: $lg-gap;
        position: relative;
        background-color: $module-bg-darker-1;
        @include radius-box(25px);
        img {
          width: 100%;
          height: 100%;
          border-radius: $xs-radius;
          transition: transform 0.5s ease-out;
        }
      }

      > .item-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //height: $content-height;
        padding-right: $xs-gap;
        .item-avatar {
          $width: 30px;
          width: $width;
          height: $width;
          margin-right: $lg-gap;
          position: relative;
          background-color: $module-bg-darker-1;
          @include radius-box(25px);
          img {
            width: 100%;
            height: 100%;
            border-radius: $xs-radius;
            transition: transform 0.5s ease-out;
          }
        }
        .user-info {
          display: flex;
          //justify-content: space-between;
          align-items: center;
          margin-top: 3px;
          margin-bottom: $sm-gap;
          font-weight: bold;

          .link {
            display: block;
            max-width: 26rem;
            margin-left: 0;
            transition: margin $transition-time-normal;
            border-bottom: 1px solid transparent;
            text-decoration: none;
            @include text-overflow();
            color: $text;
            &:hover {
              color: $link-color;
              border-color: initial;
              margin-left: $xs-gap;
            }
          }
          .description{
            font-weight: normal;
            cursor: pointer;
            color: $text-divider;
            font-size: $font-size-root;
            margin-left: $sm-gap;
          }

          .language {
            font-weight: normal;
            cursor: pointer;
            opacity: 0.6;
            color: $text-divider;
            font-size: $font-size-root;
            &:hover{
              color: $surmon;
              opacity: 1;
            }
          }
        }

        .user-nick {
          margin: 0;
          color: $text-disabled;
          user-select: none;
          line-height: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: $font-size-h6;
          @include clamp(2);
          .separator {
            margin: 0 5px;
          }
        }

        //.content{
        //  margin-top: 10px;
        //  display: -webkit-box;
        //  -webkit-line-clamp: 3;
        //  -webkit-box-orient: vertical;
        //  overflow: hidden;
        //}
        .picture{
          margin-top: 10px;
        }

      }
    }
    > .item-action{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: $xs-gap;
      padding: $padding;
      border-bottom: 1px solid $text-divider;
      > .item-meta {
        height: 2em;
        line-height: 2em;
        display: flex;
        padding: 0 $sm-gap;
        justify-content: space-between;
        align-items: baseline;
        overflow: hidden;
        font-size: $font-size-small;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        color: $text-secondary;

        > .share {
          width: 4em;
        }

        > .likes {
          > .liked {
            color: $red;
          }
        }

        > .likes,
        > .share,
        > .comments {
          width: 3em;
          //cursor: pointer;
        }

        > .date,
        > .share,
        > .comments,
        > .likes,
        > .tags,
        > .categories {
          > .iconfont {
            margin-right: $xs-gap;
          }
        }

        > .categories {
          a {
            color: $text-secondary;
            text-transform: capitalize;
            margin-right: $sm-gap;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        > .tags {
          margin-right: 0;
        }
      }

    }
    > .comment{
      padding: 0 10px;
      margin-top: 10px;
    }
  }
}
</style>
