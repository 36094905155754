/**
 * @file emoji
 * @author potatomelon <https://gitee.com/xiaofango>
 */

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

// fork from: https://github.com/danalloway/react-country-flag/blob/main/src/index.tsx
export const countryCodeToEmoji = (countryCode: string): string => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + OFFSET))
}
