<template>
  <div class="friend">
    <div class="header">
      <span class="title">
        <i class="iconfont icon-user" />
        <webfont class="text">
<!--          <i18n :k="LanguageKey.HOT_ARTICLE_LIST_TITLE" />-->
           友链
        </webfont>
      </span>
    </div>
    <div class="friend-links">
      <template v-for="(item, index) in friends || []" :key="index">
<!--        <divider type="vertical" size="lg" v-if="index !== 0" />-->
        <a :href="item.link" class="item" target="_blank" rel="external nofollow noopener">
          {{ item.title }}
        </a>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import {computed, defineComponent, onMounted, onBeforeMount, ref} from 'vue'
  import { useFriendStore } from '/@/stores/friend'
  import { LanguageKey } from '/@/language'
  import { useUniversalFetch } from "/@/universal";
  import { useEnhancer } from "/@/app/enhancer";

  export default defineComponent({
    name: 'DesktopAsideFriend',
    setup() {

      const friendStore = useFriendStore()
      const friends = computed(() => friendStore.friendList.data)
      // prefetch
      useUniversalFetch(() =>
        Promise.all([friendStore.fetchFriendList()])
      )
      return {
        friends
      }
    }
  })
</script>

<style lang="scss" scoped>
  @use 'sass:math';
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .friend {
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      height: 3em;
      line-height: 3em;
      margin: 0;
      padding: 0 $gap;
      border-bottom: 1px dotted $module-bg-darker-1;

      .title {
        .iconfont {
          margin-right: $sm-gap;
        }
      }
    }


    .friend-links {
      list-style: none;
      padding: 0;
      overflow: hidden;
      margin-left: $gap;
      margin-bottom: $gap;

      .item {
        $height: 1.5em;
        display: inline-flex;
        margin-right: $gap;
        margin-top: $gap;
        height: $height;
        line-height: $height;
        font-size: $font-size-h6;
        font-family: $font-family-normal;
        @include radius-box($xs-radius);
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .speciallinks {
      .item {
        color: $text-disabled;
        &:hover {
          color: $text;
        }
      }
    }

  }
</style>
