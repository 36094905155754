<template>
  <div class="us">
    <label class="label-name">{{ labelName }}</label>
    <label class="switch" :for="propName">
      <input type="checkbox"
             :active="active"
             :checked="modelValue == active"
             :value="modelValue"
             :id="propName" @input="changeValue" />
<!--      <input type="checkbox" v-model="modelValue" :id="propName" @input="changeValue"/>-->
      <div class="slider round"></div>
    </label>
  </div>

</template>

<script lang="ts">
import {defineComponent, ref, reactive, computed} from "vue";

  export default defineComponent({
    name: "Uswitch",
    props: {
      propName:String,
      labelName: String,
      modelValue: Number,
      active: Number
    },
    setup(props, context) {
      // const checked = computed(() => props.modelValue == props.active)
      const changeValue = (e) => {
        const value = ref(props.modelValue)
        if(props.active == 0){
          if(e.target.checked){
            value.value = 0
          }else{
            value.value = 1
          }
        }else{
          if(e.target.checked){
            value.value = 1
          }else{
            value.value = 0
          }
        }
        context.emit("update:modelValue", value)
      }
      return {
        changeValue,
        // checked
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $height: 20px;
  $width: 40px;
  .us{
    display: flex;
    align-items: center;
    .label-name{
      width: 80px;
      margin-right: $lg-gap;
    }
    .switch {
      display: inline-block;
      height: $height;
      position: relative;
      width: $width;
    }

    .switch input {
      display:none;
    }

    .slider {
      background-color: #ccc;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: .4s;
    }

    .slider:before {
      background-color: #fff;
      //bottom: 1px;
      content: "";
      height: 20px;
      //left: 1px;
      position: absolute;
      transition: .4s;
      width: 20px;
    }

    input:checked + .slider {
      background-color: #66bb6a;
    }

    input:checked + .slider:before {
      transform: translateX(20px);
    }

    .slider.round {
      border-radius: $height;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

</style>
