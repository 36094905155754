<template>
    <div ref="dropdownRef" class="app-dropdown">
        <div class="data" @click.prevent="toggleOpen">
            <slot name="data" />
<!--            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2674" width="200" height="200"><path d="M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123.392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z" fill="#747474" p-id="2675" /></svg>-->
        </div>
        <ul v-if="isOpen" class="dropdown-menu" :style="{ display: 'block' }" @click="toggleClose">
            <slot />
        </ul>
    </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onMounted, onUnmounted, watch, CSSProperties } from 'vue'
  import { Theme } from "@/composables/theme";
  export default defineComponent({
    name: 'Dropdown',
    setup(props) {
      const isOpen = ref(false)
      const dropdownRef = ref<null | HTMLElement>(null)
      const toggleOpen = () => {
        isOpen.value = !isOpen.value
      }

      const toggleClose = () => {
        isOpen.value = false
      }

      const useClickOutside = (elementRef: Record<string, any>):any => {
        const isClickOutside = ref(false)
        const handler = (e: MouseEvent) => {
          if (elementRef.value) {
            //如果点击的是ref的子节点 则没有点击到外部
            if (elementRef.value.contains(e.target as HTMLElement)) {
              isClickOutside.value = false
            } else {
              isClickOutside.value = true
            }
          }
        }
        onMounted(() => {
          document.addEventListener('click', handler)
        })
        onUnmounted(() => {
          document.removeEventListener('click', handler)
        })
        return isClickOutside
      }
      const isClick = useClickOutside(dropdownRef)
      watch(isClick, () => {
        if (isOpen.value && isClick.value) {
          isOpen.value = false
        }
      })


      return {
        isOpen,
        dropdownRef,
        toggleOpen,
        toggleClose,
      }
    }
  })


</script>
<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';
  .app-dropdown {
    display: inline-block;
    position: relative;
    .data {
      gap: 6px;
      display: flex;
      align-items: center;
      font-size: 13px;
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
      }
    }
    .dropdown-menu {
      min-width: 120px;
      text-align: center;
      position: absolute;
      animation: nav .6s;
      margin-bottom: 0;
      margin-left: -60px;
      transform: translateY(14px);
      transition: all .5s ease;
      @keyframes nav {
        0% {
          opacity: 0;
          -webkit-transform: translateY(-15px);
          transform: translateY(0px);
          -webkit-transition: all 500ms ease;
          transition: all 500ms ease;
        }
      }
    }
  }

</style>
