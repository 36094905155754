<template>
  <div class="rule">
    <ul class="rule-list">
      <li v-for="(item, index) in ruleStore.ruleList.data" :key="index + 1"  class="item">
        <span class="index">
          {{ index + 1 }}
        </span>
        <div class="action">{{ item.actionName }}</div>
        <div class="exp">{{ item.expValue }}</div>
        <div class="desc">{{ item.description }}</div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, onMounted } from 'vue'
  import { useRuleStore } from '/@/stores/exp'

  export default defineComponent({
    name: 'Rule',
    setup(props) {
      const ruleStore = useRuleStore()
      return {
        ruleStore
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .rule {
    display: flex;
    flex-direction: column;
    .rule-list {
      list-style: none;
      padding: $sm-gap 0;
      margin-bottom: 0;

      > .item {
        display: flex;
        padding: 0 $gap;
        margin-bottom: $sm-gap * 2;
        color: $text-darker;
        font-size: $font-size-h5;
        cursor: pointer;
        &:last-child {
          margin: 0;
        }
        &:hover{
          color: $gitee-primary-hover;
        }

        .index {
          $size: $font-size-base;
          width: $size;
          height: $size;
          line-height: $size;
          margin-top: 4px;
          display: inline-block;
          flex-shrink: 0;
          margin-right: $sm-gap;
          text-align: center;
          border-radius: $xs-radius;
          color: $text-disabled;
          font-weight: 700;
          font-size: $font-size-base - 1;
        }
        .action,
        .exp,
        .desc{
          padding: 0 $lg-gap;
          min-width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

      }
    }

  }
</style>
