<template>
  <div class="recommend">
    <div>
      <br />
      正在开发中
      <br />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { useEnhancer } from "/@/app/enhancer";
  import { META } from "/@/config/app.config";
  import { LanguageKey } from "/@/language";

  export default defineComponent({
    name: "ScavengeRecommend",
    setup(){

      const { globalState, meta, i18n  } = useEnhancer()
      meta(() => ({

        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `好物推荐`,
        keywords: `土豆社区-好物推荐`
      }))

      return {
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .recommend{
    min-height: 800px;
    padding: $lg-gap;
    @include radius-box($sm-radius);
    background-color: $module-bg;
    display: flex;
    justify-content: center;
  }
</style>
