<template>
  <div class="scavenge">
    <div class="poetry">
      <h1>拾荒者</h1>
      <h4>巷尾街头只影巡，为那生计咽酸辛。</h4>
      <h4>难能赐得平身坐，羞忍投予斜眼瞋。</h4>
      <h4>四处风尘还上路，半生门户不关身。</h4>
      <h4>天寒酒薄人难醉，嘴里呢喃说脱贫。</h4>
    </div>


  </div>
</template>

<script lang="ts">
  import {defineComponent, computed, reactive, ref, toRaw, toRefs} from "vue";
  import { useUniversalFetch } from '/@/universal'
  import { META } from "/@/config/app.config";
  import {LanguageKey} from "/@/language";
  import {useEnhancer} from "/@/app/enhancer";


  export default defineComponent({
    name: "Scavenge",
    setup(){
      const { globalState, meta, i18n  } = useEnhancer()

      meta(() => ({
        title: `${META.title}`,
        description: `${META.description}`,
        keywords: `${META.keywords}`
      }))

      return {

      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .scavenge{
    height: 800px;
    padding: $lg-gap;
    @include radius-box($sm-radius);
    background-color: $module-bg;
    display: flex;
    justify-content: center;
    .poetry{
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }
  }
</style>
