<template>
  <div class="follow">
    <user-follow-list
      :fetching="userInfoStore.followList.fetching"
      :users="userInfoStore.followList.data"
      :pagination="userInfoStore.followList.pagination"
      @loadmore="loadmoreFollow"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue"
  import { useUserInfoStore } from "/@/stores/user"
  import { useEnhancer } from "/@/app/enhancer"
  import { META } from "/@/config/app.config"
  import { LanguageKey } from "/@/language"
  import { onClient, useUniversalFetch } from "/@/universal"
  import { scrollToNextScreen } from "/@/utils/scroller"
  import UserFollowList from "./list.vue"

  export default defineComponent({
    name: "UserFollow",
    components: {
      UserFollowList
    },
    props: {
      userId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const userInfoStore = useUserInfoStore()
      const { meta, i18n } = useEnhancer()
      meta(() => ({
        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `user follow`,
        keywords: `user follow keys`
      }))
      const { isDarkTheme } = useEnhancer()
      const loadmoreFollow = async () => {
        const targetPage = userInfoStore.followList.pagination?.current + 1
        await userInfoStore.fetchFollowList({ pageNum: targetPage, userId: props.userId})
        if (targetPage > 1) {
          onClient(scrollToNextScreen)
        }
      }
      useUniversalFetch(() => {
        return Promise.all([
          userInfoStore.fetchFollowList({userId: props.userId}),
        ])
      })

      return {
        LanguageKey,
        userInfoStore,
        loadmoreFollow,
        isDarkTheme
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .follow{

  }
</style>
