<template>
  <div class="about-page">
    <page-banner
      :position="70"
      :blur="false"
      :is-mobile="true"
      :image="adminInfo?.background"
    >
      <template #title>
        <i18n :k="LanguageKey.PAGE_ABOUT" />
      </template>
    </page-banner>
    <div class="profile">
      <div class="avatar">
        <uimage class="image" :src="getAdminAvatar(adminInfo?.avatar)" />
      </div>
      <h2 class="name">{{ adminInfo?.nickName || '-' }}</h2>
<!--      <h5 class="slogan">{{ adminInfo?.nickName || '-' }}</h5>-->
      <divider dashed />
      <h4 class="bio">
        <webfont bolder>
          <span>{{ adminInfo?.hitokoto }}</span>
        </webfont>
      </h4>
    </div>
    <!--  相关板块链接  -->
<!--    <div class="links">-->
<!--      <div class="list col-2">-->
<!--        <router-link class="item text-only" :to="getPageRoute(RouteName.Archive)">-->
<!--          <i class="iconfont icon-quill" />-->
<!--          <i18n v-bind="i18ns.myArchive" />-->
<!--        </router-link>-->
<!--        <router-link class="item text-only" :to="getPageRoute(RouteName.Lens)">-->
<!--          <i class="iconfont icon-lens" />-->
<!--          <i18n v-bind="i18ns.myVlogs" />-->
<!--        </router-link>-->
<!--        <router-link class="item text-only" :to="getPageRoute(RouteName.Merch)">-->
<!--          <i class="iconfont icon-rubik" />-->
<!--          <i18n v-bind="i18ns.merchBar" />-->
<!--        </router-link>-->
<!--        <router-link class="item text-only" :to="getPageRoute(RouteName.Freelancer)">-->
<!--          <i class="iconfont icon-coin-s" />-->
<!--          <i18n v-bind="i18ns.hireMe" />-->
<!--        </router-link>-->
<!--      </div>-->
<!--      <divider dashed />-->
<!--      <div class="list col-2">-->
<!--        <ulink class="item discord" :href="VALUABLE_LINKS.DISCORD_GROUP">-->
<!--          <i class="iconfont icon-discord" />-->
<!--          <i18n v-bind="i18ns.DiscordGroup" />-->
<!--        </ulink>-->
<!--        <ulink class="item telegram" :href="VALUABLE_LINKS.TELEGRAM_GROUP">-->
<!--          <i class="iconfont icon-telegram" />-->
<!--          <i18n v-bind="i18ns.TelegramGroup" />-->
<!--        </ulink>-->
<!--      </div>-->
<!--    </div>-->
    <!--  相关信息链接  -->
    <div class="links">
      <div class="list col-3">
        <ulink v-for="(item, index) in adminInfo?.socialList"
               :class="[`item center`, item.icon]"
               :href="item.url">
          <i :class="[`iconfont`, item.icon]" />
          <span class="text">{{ item.name }}</span>
        </ulink>
      </div>
<!--      <divider dashed />-->
<!--      <div class="list col-5">-->
<!--        <ulink class="item telegram icon-only" :href="VALUABLE_LINKS.TELEGRAM">-->
<!--          <i class="iconfont icon-telegram" />-->
<!--        </ulink>-->
<!--        <ulink class="item linkedin icon-only" :href="VALUABLE_LINKS.LINKEDIN">-->
<!--          <i class="iconfont icon-linkedin" />-->
<!--        </ulink>-->
<!--        <ulink class="item youtube icon-only" :href="VALUABLE_LINKS.YOUTUBE_CHANNEL">-->
<!--          <i class="iconfont icon-youtube" />-->
<!--        </ulink>-->
<!--        <ulink class="item douban icon-only" :href="VALUABLE_LINKS.DOUBAN">-->
<!--          <i class="iconfont icon-douban" />-->
<!--        </ulink>-->
<!--        <ulink class="item zhihu icon-only" :href="VALUABLE_LINKS.ZHIHU">-->
<!--          <i class="iconfont icon-zhihu" />-->
<!--        </ulink>-->
<!--      </div>-->
    </div>
    <div class="links">
      <div class="list col-2">
        <div :key="index" v-for="(item, index) in adminInfo?.channelList">
          <uimage class="image-item" :src="item.url" />
          <ulink class="link" :href="item.link">{{ item.name }}</ulink>
        </div>


      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { VALUABLE_LINKS } from '/@/config/app.config'
  import { LanguageKey } from '/@/language'
  import { RouteName } from '/@/app/router'
  import { useUniversalFetch } from '/@/universal'
  import { getPageRoute } from '/@/transforms/route'
  import PageBanner from '/@/components/common/fullpage/banner.vue'
  import { useAboutPageMeta, getAdminAvatar, i18ns } from './helper'
  import { useConfigStore } from "/@/stores/config"

  export default defineComponent({
    name: 'MobileAboutPage',
    components: {
      PageBanner
    },
    setup() {
      const configStore = useConfigStore()
      const adminInfo = computed(() => configStore.getConfig("blogger"))

      useAboutPageMeta()
      // useUniversalFetch(() => metaStore.fetchAdminInfo())

      return {
        i18ns,
        adminInfo,
        RouteName,
        getAdminAvatar,
        getPageRoute,
        LanguageKey,
        VALUABLE_LINKS
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .about-page {
    .profile {
      position: relative;
      padding: $lg-gap;
      padding-top: $gap * 6;
      border-bottom-left-radius: $lg-radius;
      border-bottom-right-radius: $lg-radius;
      background-color: $module-bg-opaque;
      &::before {
        content: '';
        position: absolute;
        display: block;
        height: 1rem;
        bottom: -0.5rem;
        left: 0;
        right: 0;
        background-image: radial-gradient(circle, transparent 70%, $module-bg-opaque 70%);
        background-size: 0.7em 1em;
        background-position: 0 -0.5em;
      }

      .avatar {
        width: 100%;
        position: absolute;
        top: -$gap * 3;
        left: 0;
        text-align: center;

        .image {
          $size: 7rem;
          width: $size;
          height: $size;
          z-index: $z-index-normal + 2;
          border-radius: 100%;
          border: 4px solid $module-bg-opaque;
          box-sizing: content-box;
        }
      }

      .name,
      .slogan {
        text-align: center;
        margin-top: 0;
        margin-bottom: $gap;
      }

      .bio {
        margin: 0;
        line-height: $line-height-base * 1.4;
        text-align: center;
      }
    }

    .links {
      margin-top: $lg-gap;
      padding: $gap;
      border-radius: $lg-radius;
      background-color: $module-bg-opaque;
      overflow: hidden;

      .list {
        display: grid;
        grid-gap: $lg-gap;
        &.col-2 {
          grid-template-columns: repeat(2, 1fr);
        }
        &.col-3 {
          grid-template-columns: repeat(3, 1fr);
        }
        &.col-5 {
          grid-template-columns: repeat(5, 1fr);
        }

        .image-item {
          max-width: 100%;
        }

        .item {
          display: inline-flex;
          align-items: center;
          height: 3rem;
          padding: 0 $gap;
          border-radius: $sm-radius;
          color: $white;
          &.center {
            justify-content: center;
          }

          &.text-only {
            background-color: $surmon;
          }
          &.icon-only {
            justify-content: center;
            .iconfont {
              font-size: $font-size-h3;
              margin: 0;
            }
          }

          .iconfont {
            margin-right: $sm-gap;
          }

          .text {
            font-weight: bold;
          }

          &.github {
            background-color: $github-primary;
          }
          &.twitter {
            background-color: $twitter-primary;
          }
          &.instagram {
            background: $instagram-primary;
            background: $instagram-gradient;
            @include visibility-transition();
          }
          &.discord {
            background-color: $discord-primary;
          }
          &.telegram {
            background-color: $telegram-primary;
          }
          &.douban {
            background-color: $douban-primary;
          }
          &.zhi {
            background-color: $zhihu-primary;
          }
          &.gitee {
            background-color: $gitee-primary-hover;
          }
          &.jue {
            background-color: $linkedin-primary;
          }
        }

        .link{
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-bottom: $sm-gap;
          padding-bottom: 2px;
          border-bottom: 2px solid;
          font-weight: bold;
          color: var(--item-primary);
          opacity: 0.8;
          transition: opacity $transition-time-fast;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
</style>
