import { VALUABLE_LINKS } from '/@/config/app.config'
import { LanguageKey } from '/@/language'
import { RouteName, CategorySlug } from '/@/app/router'
import { getPageRoute, getCategoryFlowRoute } from '/@/transforms/route'
import { getTargetCDNURL } from '/@/transforms/url'

export interface NavItem {
  id: string
  route: string
  i18nKey: string
}

export const navs: Array<NavItem> = [
  {
    id: '1',
    route: 'article',
    i18nKey: LanguageKey.PAGE_USER_NAV_ARTICLE
  },
  {
    id: '2',
    route: 'boiling',
    i18nKey: LanguageKey.PAGE_USER_NAV_BOILING
  },
  {
    id: '3',
    route: 'follow',
    i18nKey: LanguageKey.PAGE_USER_NAV_FOLLOW
  },
  // {
  //   id: '4',
  //   route: 'comment',
  //   i18nKey: LanguageKey.PAGE_USER_NAV_COMMENT
  // },
  // {
  //   id: '5',
  //   route: 'picture',
  //   i18nKey: LanguageKey.PAGE_USER_NAV_PICTURE
  // }
]

