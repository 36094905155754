<template>
  <transition name="toast">
    <ul class="message-list">
      <li
        :class="`message-item ${setClass(item.type)}`"
        v-for="(item, index) in messageList"
        :key="index"
      >
        {{ item.title }}
      </li>
    </ul>
  </transition>
</template>

<script lang="ts">
import {defineComponent, PropType, reactive} from 'vue';
  interface MessageList {
    type: string;
    title: string;
  }
  export default defineComponent({
    props: {
      messageList: {
        type: Array,
        default: [] as Array<MessageList>
      },
    },
    setup(props) {
      const setClass = (type: string) => {
        if (type === 'info') {
          return 'message-info-item';
        } else if (type === 'warning') {
          return 'message-warning-item';
        } else if (type === 'success') {
          return 'message-success-item';
        } else if (type === 'error') {
          return 'message-error-item';
        }
      }
      return {
        setClass
      }
    }
  })
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';
.message-list {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 9999999;
  max-width: 200px;
  margin-bottom: 0;
  text-align: right;
  .message-item {
    display: inline-block;
    padding: 6px 24px;
    border: 1px solid #b3d0cf;
    margin-left: 8px;
    margin-bottom: 12px;
    background-color: #e6f3ff;
    font-size: 14px;
    color: #007bff;
    text-align: left;
    box-shadow: 0 1px 1px 0 hsla(0, 0%, 80.4%, 0.5);
    @include radius-box($sm-radius);
    cursor: default;
  }
  .message-info-item {
    border: 1px solid #b3d0cf;
    background-color: #e6f3ff;
    color: #007bff;
  }
  .message-error-item {
    border: 1px solid #e99;
    background-color: #f6e3e3;
    color: #e33;
  }
}
</style>
