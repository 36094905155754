<template>
  <calendar @sign="doSign" class="calendar">
    <template #day="data">
<!--      <router-link class="date-link" v-if="articlesIn(humanDate) > 0" :to="getDateRoute(humanDate)">-->
<!--        {{ humanDate.day }}-->
<!--      </router-link>-->
<!--      <span class="date-link" v-if="articlesIn(humanDate) > 0"  @click="sign(humanDate)">-->
<!--        {{ humanDate.day }}-->
<!--      </span>-->
      <span class="date-span">
        {{ data.item.day }}
        <i v-if="signIn(data.item)" class="iconfont icon-success" :class="{
            signed: signIn(data.item)
          }"></i>
      </span>
    </template>
  </calendar>
</template>

<script lang="ts">
  import { defineComponent, onMounted } from 'vue'
  import { useSignStore } from '/@/stores/user'
  import { humanDateToYMD, HumanDate,dateToHuman, humanToDate, isSameHumanDay, } from '/@/transforms/moment'
  import { getDateFlowRoute } from '/@/transforms/route'
  import Calendar from '/@/components/widget/calendar.vue'
  import { useUniversalFetch } from "/@/universal";

  export default defineComponent({
    name: 'DesktopAsideCalendar',
    components: { Calendar },
    setup() {
      const signStore = useSignStore()
      const signIn = (targetDate: HumanDate) => {
        const ymd = humanDateToYMD(targetDate)
        return signStore.signList.find((item) => item === ymd)
      }

      const getDateRoute = (humanDate: HumanDate) => {
        return getDateFlowRoute(humanDateToYMD(humanDate))
      }

      const sign = async (day: HumanDate, isMonth: Boolean) => {
        //签到
        if(isMonth){
          await signStore.postSign(humanDateToYMD(day))
        }
      }
      const doSign = async (day: HumanDate) => {
        //签到
        await signStore.postSign(humanDateToYMD(day))
      }


      onMounted(() => signStore.fetchSignList())
      // useUniversalFetch(() => signStore.fetchSignList())

      return {
        signIn,
        getDateRoute,
        humanDateToYMD,
        sign,
        doSign,
      }
    }
  })
</script>

<style lang="scss" scoped>
  @use 'sass:math';
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .calendar {
    padding: $gap;

    .date-link {
      position: relative;
      display: block;
      border-radius: 100%;
      font-weight: bold;
      color: $primary;
      &::before {
        content: '';
        $size: 4px;
        position: absolute;
        top: 0.3em;
        right: 50%;
        width: $size;
        height: $size;
        margin-right: -#{math.div($size, 2)};
        border-radius: 100%;
        background-color: $primary;
      }

      &:hover,
      &.link-active {
        background-color: $primary;
        color: $text-reversal;
        &::before {
          background-color: $text-reversal;
        }
      }
    }
    .date-span{
      position: relative;

      //display: flex;
      //align-items: center;
      //justify-content: center;
      .signed{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 888;
        color: $surmon;
        //font-size: 1em;
        //line-height: 100%;
        opacity: 0.5;
      }
    }
  }
</style>
