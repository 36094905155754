<template>
    <li class="dropdown-option" :class="{ 'is-disabled': disabled }">
        <slot />
    </li>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'DropdownItem',
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {

    }

  })
</script>
<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

    .dropdown-option {
      color: #747474;
      font-size: 13px;
      position: relative;
      cursor: pointer;
      padding: 6px 10px;
      list-style-type: none;
      background-color: $module-bg-opaque;
      transition: 0.5s;
      box-shadow: 0 1px 2px rgba(0,0,0,0.1);
      &:hover {
        background-color: #f5f5f5;
      }
      &.is-disabled {
        color: #6c757d;
        cursor: not-allowed;
        background-color: #f3f3f3;
      }
      a {
        color: #747474;
        transition: 0.5s;
        &:hover {
          color: #1890ff;
        }
      }
    }

</style>
