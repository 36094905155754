<template>
  <div class="boiling">
    <div class="condition">
      <div class="left">
        <span class="hot">我的</span>
        <divider type="vertical" />
        <span class="new">点赞</span>
      </div>
    </div>
    <user-boiling-list
      :fetching="boilingStore.userBoilingList.fetching"
      :boilings="boilingStore.userBoilingList.data"
      :pagination="boilingStore.userBoilingList.pagination"
      @loadmore="loadmoreBoiling"
    />

  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive } from "vue"
  import { LanguageKey } from '/@/language'
  import { useBoilingStore } from "/@/stores/saunter"
  import { useEnhancer } from "/@/app/enhancer"
  import { META } from "/@/config/app.config"
  import { onClient, useUniversalFetch } from "/@/universal"
  import UserBoilingList from './list.vue'
  import { scrollToNextScreen } from "/@/utils/scroller"

  export default defineComponent({
    name: "UserBoiling",
    components: {
      UserBoilingList
    },
    props: {
      userId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      console.log(`center user boiling props:${props.userId}`)
      const boilingStore = useBoilingStore()
      const { meta, i18n, isDarkTheme } = useEnhancer()
      meta(() => ({
        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `saunter boiling`,
        keywords: `saunter boiling keys`
      }))
      const params  = reactive({
        userId: props.userId,
        choose: 0,
        sort: 1,
        pageNum: 1
      })
      const loadmoreBoiling = async () => {
        const targetPage = boilingStore.userBoilingList.pagination?.current + 1
        params.pageNum = targetPage
        await boilingStore.fetchUserBoilingList(params)
        if (targetPage > 1) {
          onClient(scrollToNextScreen)
        }
      }

      useUniversalFetch(() => {
        return Promise.all([
          boilingStore.fetchUserBoilingList(params),
        ])
      })

      return {
        LanguageKey,
        boilingStore,
        loadmoreBoiling,
        isDarkTheme
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .boiling{
    .condition{
      display: flex;
      justify-content: space-between;
      align-items: center;
      $conditionbar-size: 3rem;
      height: $conditionbar-size;
      line-height: $conditionbar-size;
      padding: 0 $lg-gap;
      .left{
        .me,
        .collect {
          margin-right: 2rem;
          background-color: $module-bg-darker-1;
          @include radius-box($xs-radius);
          cursor: pointer;
          padding: $xs-gap $xs-gap * 2;
          /*font-weight: bold;*/
          &:hover {
            background-color: $module-bg-darker-2;
          }
        }
      }
      .right{
        .hot,
        .new{
          cursor: pointer;
        }
      }
    }
  }
</style>
