<template>
  <div class="picture-page">
    <div class="tags">
      <span class="tag-item" :style="{'font-size': randomSize()}" v-for="(item, i) in pictureStore.pictureTagList" @click="tagRefresh(item.id)">{{ item.name }}</span>
    </div>
    <div class="picture-container">
      <image-list>
        <template #empty>
          <empty class="module-empty" key="empty">
            <i18n :k="LanguageKey.EMPTY_PLACEHOLDER" />
          </empty>
        </template>
        <template #loading>
          <picture-skeleton
            :columns="1"
            :rows="2"
            :height="166"
            key="loading"
            class="module-loading"
          />
        </template>
      </image-list>
    </div>

  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { Language, LanguageKey } from "/@/language"
  import { META } from "/@/config/app.config"
  import { useEnhancer } from "/@/app/enhancer";
  import { getLayoutByRouteMeta } from "/@/transforms/layout";
  import { useUniversalFetch } from '/@/universal'
  import ImageList from './images.vue'
  import PictureSkeleton from '../skeleton.vue'
  import { usePictureStore } from '/@/stores/picture'

  export default defineComponent({
    name: 'MobilePicturePage',
    components: {
      ImageList,
      PictureSkeleton
    },
    setup() {
      const { globalState,router, meta, i18n  } = useEnhancer()
      const pictureStore = usePictureStore()
      const tagRefresh = (tagId: string) => {
        pictureStore.fetchPictureList({ tagId: tagId })
      }
      const handlePageTransitionDone = () => {
        globalState.setLayoutColumn(getLayoutByRouteMeta(route.meta))
      }

      meta(() => ({
        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `picture-list`,
        keywords: `picture image`
      }))
      const fetchTagCategoryData = () => {
        return Promise.all([
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          pictureStore.fetchCategoryTag().catch(() => {})
        ])
      }

      const randomSize = () => {
        return Math.floor((Math.random() * 6 + 10)) + 'px'
      }

      //触发接口请求
      useUniversalFetch(() => fetchTagCategoryData())
      return {
        layoutColumn: globalState.layoutColumn,
        LanguageKey,
        tagRefresh,
        pictureStore,
        randomSize
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .picture-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //width: $container-width + 200;
    width: 100vm;
    margin: 0 auto;
    //padding-top: 2.1rem;
    //padding-bottom: 3.1rem;
    &.full-page {
      width: 100%;
    }
    .tags{
      width: 100%;
      /*max-width: 100%;*/
      //height: 50px;
      //max-height: 100px;
      /*margin-top: 5px;*/
      //max-width: 80%;
      margin: 5px auto;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 40%);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      .tag-item{
        filter: saturate(100%);
        margin: 2px 8px;
        color: $tag-text!important;
        text-shadow: 1px 1px 3px rgba(0,0,0,.4);
        cursor: pointer;
        &:before{
          color: $tag-text;
          opacity: 0.5;
          content: '#';
        }
        &:hover{
          opacity: 0.5;
        }
      }
    }
    .wordCloud{
      width: 100%;
      max-width: 100%;
      height: 100px;
      max-height: 100px;
      margin-top: 5px;
    }
    /*.category{
      $c-size:50px;
      $b-size:80px;
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 99999;
      .navbar{
        width: $c-size;
        height: $c-size;
        line-height: $c-size;
        border-radius: 50%;
        background: #fff;
        margin: 70px auto;
        position: relative;
        cursor: pointer;
        text-align: center;
        font-size: 1em;
        font-weight: bold;
        color: #99b977;
        transition: 0.24s 0.2s;
      }
      .navbar:hover{
        background: rgba(255, 255, 255, 0.75);
      }
      .navbar .menu{
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: -$c-size / 2;
        left: -$c-size / 2;
        border: $c-size solid transparent;
        cursor: default;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 1.4s 0.07s;
        z-index: -1;
      }
      .navbar:hover .menu{
        transition: transform 0.4s 0.08s, z-index   0s  0.5s;
        transform: scale(1);
        z-index: 1;
      }
      .navbar .menu li{
        position: absolute;
        top: -$b-size;
        left: -$b-size;
        transform-origin: $b-size  $b-size;
        transition: all 0.5s 0.1s;
      }
      .navbar:hover .menu li{
        transition: all 0.6s;
      }
      .navbar .menu li a{
        width: 45px;
        height: 45px;
        line-height: 45px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        font-size: 60%;
        color: #99b977;
        transition: 0.6s;
      }
      .navbar:hover .menu li:nth-child(1){
        transition-delay: 0.02s;
        transform: rotate(85deg);
      }
      .navbar:hover .menu li:nth-child(1) a{
        transition-delay: 0.04s;
        transform: rotate(635deg);
      }
      .navbar:hover .menu li:nth-child(2){
        transition-delay: 0.04s;
        transform: rotate(125deg);
      }
      .navbar:hover .menu li:nth-child(2) a{
        transition-delay: 0.08s;
        transform: rotate(595deg);
      }
      .navbar:hover .menu li:nth-child(3){
        transition-delay: 0.06s;
        transform: rotate(165deg);
      }
      .navbar:hover .menu li:nth-child(3) a{
        transition-delay: 0.12s;
        transform: rotate(555deg);
      }
      .navbar:hover .menu li:nth-child(4){
        transition-delay: 0.08s;
        transform: rotate(205deg);
      }
      .navbar:hover .menu li:nth-child(4) a{
        transition-delay: 0.16s;
        transform: rotate(515deg);
      }
      .navbar:hover .menu li:nth-child(5){
        transition-delay: 0.1s;
        transform: rotate(245deg);
      }
      .navbar:hover .menu li:nth-child(5) a{
        transition-delay: 0.2s;
        transform: rotate(475deg);
      }
      .navbar:hover .menu li:nth-child(6){
        transition-delay: 0.12s;
        transform: rotate(285deg);
      }
      .navbar:hover .menu li:nth-child(6) a{
        transition-delay: 0.24s;
        transform: rotate(435deg);
      }
      .navbar:hover .menu li:nth-child(7){
        transition-delay: 0.14s;
        transform: rotate(325deg);
      }
      .navbar:hover .menu li:nth-child(7) a{
        transition-delay: 0.28s;
        transform: rotate(395deg);
      }
      .navbar:hover .menu li:nth-child(8){
        transition-delay: 0.16s;
        transform: rotate(365deg);
      }
      .navbar:hover .menu li:nth-child(8) a{
        transition-delay: 0.32s;
        transform: rotate(355deg);
      }
      .navbar:hover .menu li:nth-child(9){
        transition-delay: 0.18s;
        transform: rotate(405deg);
      }
      .navbar:hover .menu li:nth-child(9) a{
        transition-delay: 0.36s;
        transform: rotate(315deg);
      }
    }*/
    .picture-container{
      flex: 1;
      min-height: 80vh;
      margin-top: 10px;
      .module-empty {
        min-height: 12rem;
        margin-bottom: $gap * 2;
        font-weight: bold;
        font-size: $font-size-h3;
        @include radius-box($sm-radius);
        @include common-bg-module();
      }

      .module-loading {
        margin-bottom: $gap * 2;
      }
      .discussion {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: $gap * 2;
        width: 100%;
        margin-bottom: 2rem;
        margin-top: $lg-gap;
        .item {
          height: 5rem;
          display: flex;
          justify-content: start;
          align-items: center;
          @include common-bg-module($transition-time-fast);
          @include radius-box($lg-radius);



          .divider {
            opacity: 0.6;
            border-color: initial;
          }

          .text {
            //font-size: $font-size-h4;
            font-weight: bold;
          }
        }


          .bridge {
            width: $lg-gap;
            height: 2rem;
            background: linear-gradient(
                to right,
                $module-bg-darker-1,
                $module-bg,
                $module-bg-darker-1
            );
          }

          .mini {
            .iconfont {
              font-size: 3rem;
            }
            &.sponsor {
              --item-primary: #{$surmon};
            }
            &.feedback,
            &.statement {
              --item-primary: #{$surmon};
            }

            flex: 1;
            height: 100%;
            color: var(--item-primary);
            //font-size: $font-size-h5;
            @include common-bg-module($transition-time-fast);
            @include radius-box($lg-radius);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
              font-size: $font-size-h5;
            }
            &:hover {
              color: $white;
              background-color: var(--item-primary);
            }
          }

      }
      .discussion {
        .item {
          color: var(--item-primary);
          &:hover {
            color: $white;
            background-color: var(--item-primary);
          }

          &.discord {
            --item-primary: #{$discord-primary};
          }
          &.telegram {
            --item-primary: #{$telegram-primary};
          }
          &.spotify {
            --item-primary: #{$spotify-primary};
          }
          &.music-163 {
            --item-primary: #{$music163-primary};
          }
        }
      }
    }
  }
</style>
