<template>
  <div class="list-swiper">
    <swiper
      class="vertical-swiper"
      direction="vertical"
      :slides-per-view="3"
      :space-between="10"
      :mousewheel="true"
      @swiper="setVSwiperRef"
      @slide-change="updateVSwiperIndex"
    >
      <swiper-slide class="slide-item" :key="index" v-for="(item, index) in data">
        <slot name="item" v-bind="{ item, index }"></slot>
      </swiper-slide>
      <swiper-slide>
        <button class="article-load" disabled>
          <div class="background">
            <span class="left"></span>
            <span class="right"></span>
          </div>
          <div class="content">
        <span class="left">

        </span>
            <span class="right">
          <webfont bolder uppercase>
            <span>春尽江南</span>
          </webfont>
          <i class="iconfont icon-loadmore"></i>
        </span>
          </div>
        </button>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, PropType } from 'vue'
  import SwiperClass, { Swiper, SwiperSlide } from '/@/effects/swiper'

  export default defineComponent({
    name: 'MListSwiper',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      data: {
        type: Array as PropType<Array<any>>,
        required: true
      },
    },
    setup() {
      // vertical swiper
      let vSwiperRef: SwiperClass | null = null
      const setVSwiperRef = (swiper: SwiperClass) => {
        vSwiperRef = swiper
      }
      const vSwiperIndex = ref<number>()
      const updateVSwiperIndex = () => {
        vSwiperIndex.value = vSwiperRef?.activeIndex
      }


      return {
        setVSwiperRef,
        vSwiperIndex,
        updateVSwiperIndex
      }
    }
  })
</script>

<style lang="scss" scoped>
  @use 'sass:math';
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .list-swiper {
    position: relative;

    .vertical-swiper {
      height: 80vh;
      position: relative;
    }

    .slide {
      font-size: $font-size-h6;

      .link {
        text-decoration: none;
        margin-right: $xs-gap;
        color: $module-bg;
        &:hover {
          color: $link-color;
        }

        & + .iconfont {
          margin-top: 0.5em;
          font-size: $font-size-small;
        }
      }

      .click {
        margin-top: 1.4rem;
        padding: 0.5em 1em;
        border: 1px dashed $text-divider;
        border-radius: $sm-radius;
        //color: $text-color;
        background-color: $module-bg;
        opacity: 0.8;
        cursor: pointer;
        &:hover {
          color: $link-color;
          opacity: 1;
        }
      }
    }

    .nav-button-next,
    .nav-button-prev {
      position: absolute;
      z-index: 9;
      top: 50%;
      margin: 0;
      padding: 0;
      border: none;
      $size: 2.4rem;
      width: $size;
      height: $size;
      margin-top: math.div(-$size, 2);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      opacity: 0.5;
      color: rgba(white, 0.6);
      background-color: var(--swiper-theme-color);
      transition: opacity 0.5, visibility 0.5;
      &[disabled] {
        cursor: no-drop;
        opacity: 0.3;
      }
      &:not([disabled]) {
        cursor: pointer;
        &:hover {
          opacity: 1;
          color: white;
        }
      }

      .iconfont {
        transform: rotate(180deg);
      }
    }

    .nav-button-next {
      right: $lg-gap * 2;
    }

    .nav-button-prev {
      left: $lg-gap * 2;
    }
    .swiper {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }
  .no-more{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .article-load {
    width: 100%;
    height: $button-block-height;
    position: relative;
    display: block;
    @include radius-box($sm-radius);
    &[disabled] {
      opacity: 0.6;
    }
    &:not([disabled]):hover {
      .background {
        .left {
          background: $module-bg-opaque;
        }
        .right {
          background: $surmon;
        }
      }
      .content {
        .left {
          color: $primary;
        }
        .right {
          color: $white;
        }
      }
    }

    .content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left,
      .right {
        @include color-transition();
      }

      .left {
        font-weight: bold;
        color: $text-disabled;
        margin-left: 2em;
      }

      .right {
        width: 10rem;
        color: $text-secondary;
        .iconfont {
          margin-left: $gap;
        }
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-index-normal - 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      $skew: -20deg;
      $skew-offset: $gap;

      .left,
      .right {
        height: 100%;
        border-radius: $xs-radius;
        @include background-transition();
      }

      .left {
        flex: 1;
        margin-left: -$skew-offset;
        margin-right: $skew-offset;
        background: $module-bg;
        transform: skew($skew);
      }

      .right {
        width: 12rem;
        margin-right: -$skew-offset;
        background: $module-bg-opaque;
        transform: skew($skew);
      }
    }
  }
</style>
