<template>
  <div class="user-page">
    <responsive>
      <template #desktop>
<!--        <div class="user-head" style="backgroundImage: url('/images/page-job/banner-2.jpg');background-repeat: no-repeat;background-size: 100% 100%;opacity: 0.1;">-->
        <placeholder :data="userStore.user" :loading="userStore.fetching">
          <template #loading>
            <div class="skeleton-info-main">
              <div class="skeleton-avatar">
                <skeleton-base />
              </div>
              <div class="skeleton-info">
                <div class="skeleton-info-item">
                  <skeleton-line />
                </div>
                <div class="skeleton-info-item">
                  <skeleton-line />
                </div>
                <div class="skeleton-info-item">
                  <skeleton-line />
                </div>
                <div class="skeleton-info-item">
                  <skeleton-line />
                </div>
              </div>
              <div class="skeleton-action">
                <skeleton-line />
              </div>
            </div>
          </template>
          <template #placeholder>
            <empty class="empty" :i18n-key="LanguageKey.ARTICLE_PLACEHOLDER" />
          </template>
          <template #default>
            <div class="user-info">
              <div class="info-main">
                <div class="exp" @click="handleRule">经验值：{{ userStore.user?.expValue }}</div>
                <div class="avatar">
                  <uimage class="image" :src="userStore.user?.avatar" />
                </div>
                <div class="info">
                  <div class="nick"><div class="name">{{ userStore.user?.nickName }}</div><level :level="userStore.user?.level" /></div>
                  <div class="position">职位：{{ userStore.user?.occupation }}</div>
                  <div class="time">注册时间：{{ userStore.user?.registerTime }}</div>
                  <div class="description">{{ userStore.user?.summary }}</div>
                </div>
                <div class="action">
                  <div class="btn" v-if="currUserStore.isCurrentUser(userId)">
                    <router-link to="/setting/profile">编辑资料</router-link>
                  </div>
                  <div class="btn" v-else>
                    <button type="button" :disabled="currUserStore.votes.fetching" @click="handUserFollow(userStore.user?.id)">{{ isFollow(userStore.user?.id) ? '已关注' : '关  注' }}</button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </placeholder>

        <div class="user-center">
          <div class="center-nav">
            <nav class="nav-list">
              <template v-for="(nav, index) in navs" :key="nav.id">
                <span v-if="index > 0" class="divider"></span>
                <ulink
                  class="item"
                  :class="[nav.id, { hot: nav.hot, new: nav.newWindow }]"
                  :to="getUserCenterRoute(userId, nav.route)"
                >
                  <webfont class="text" bolder uppercase>
                    <i18n :k="nav.i18nKey" />
                  </webfont>
                  <span v-if="nav.hot" class="superscript">
              <i class="iconfont icon-hot-fill"></i>
            </span>
                  <sup v-if="nav.newWindow" class="newscript">
                    <i class="iconfont icon-new-window-s"></i>
                  </sup>
                </ulink>
              </template>
            </nav>
          </div>

          <!-- unuse suspense -> async route component -> can't extract style to css file -->
          <router-view v-slot="{ Component, route }">
            <div class="router-view">
              <transition name="subpage" mode="out-in"  @before-enter="handlePageTransitionDone">

                <suspense>
                  <component :is="Component" :key="route.name"/>
                </suspense>

              </transition>
            </div>
          </router-view>

        </div>
      </template>
      <template #mobile>

      </template>
    </responsive>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, reactive } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUserInfoStore, useUserStore } from '/@/stores/user'
  import { LanguageKey } from '/@/language'
  import { META } from '/@/config/app.config'
  import { getLayoutByRouteMeta } from '/@/transforms/layout'
  import { getUserCenterRoute } from '/@/transforms/route'
  import { navs } from "./nav";
  import { useUniversalFetch } from "/@/universal"
  import Level from '/@/components/widget/level.vue'
  import Toast from "/@/composables/toast/index"
  export default defineComponent({
    name: 'UserPage',
    components: {
      Level
    },
    props: {
      userId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      console.log(`user center props:userId => ${props.userId}`)
      const { meta, i18n } = useEnhancer()
      const userStore = useUserInfoStore()
      const currUserStore = useUserStore()
      const { router, route, globalState } = useEnhancer()

      meta(() => ({
        title: `${i18n.t(LanguageKey.PAGE_USER)} - ${META.title}`,
        description: `${META.description}`,
        keywords: `${i18n.t(LanguageKey.PAGE_USER)},${META.keywords}`
      }))

      // const user = computed(() => userStore.user)
      const handlePageTransitionDone = () => {
        globalState.setLayoutColumn(getLayoutByRouteMeta(route.meta))
      }

      const fetchUserDetail = (userId: string) => {
        return Promise.all([
          userStore.fetchUserById(userId)
        ])
      }

      const handleRule = () => {
        globalState.toggleSwitcher('rule', true)
      }

      const isFollow = (userId: string) => {
        return currUserStore.votes.follows.includes(userId)
      }

      const handUserFollow = async (userId: string) => {
        if(!currUserStore.isLogin){
          Toast.error("请登入！")
          return
        }
        const isFollow = await currUserStore.postUserFollow({byUserId: userId});
        console.log(`isFollow:${isFollow}`)
        Toast.success(`${isFollow ? "关注成功！" : "取消关注！"}`)
      }

      useUniversalFetch(() => fetchUserDetail(props.userId))
      return {
        navs,
        handlePageTransitionDone,
        getUserCenterRoute,
        handleRule,
        userStore,
        currUserStore,
        handUserFollow,
        isFollow
      }
    }
  })
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.user-page {

  $size: 8rem;
  .skeleton-info-main{
    $carrousel-height: 150px;
    position: relative;
    //height: $carrousel-height;
    margin-bottom: $lg-gap;
    @include radius-box($sm-radius);
    @include common-bg-module();
    display: flex;
    align-items: center;
    padding: $gap $gap;
    .skeleton-avatar{
      display: flex;
      align-items: center;
      margin-right: $lg-gap;
      height: $size;
      width: $size;
    }
    .skeleton-info{
      display: flex;
      flex-direction: column;
      align-content: space-around;
      flex: 1;
      margin-right: $lg-gap;
      .skeleton-info-item{
        height: 2rem;
        margin-bottom: 5px;
        width: 80%;
      }
    }
    .skeleton-action {
      display: flex;
      justify-items: center;
      align-items: center;
      height: 3rem;
      width: 8rem;
    }
  }


  .user-info {
    //background-color: $module-bg;
    $carrousel-height: 150px;
    position: relative;
    //height: $carrousel-height;
    margin-bottom: $lg-gap;
    @include radius-box($sm-radius);
    @include common-bg-module();
    display: flex;
    align-items: center;
    .exp{
      position: absolute;
      top: $sm-gap;
      right: $sm-gap;
      cursor: pointer;
    }
    .info-main{
      display: flex;
      align-items: center;
      padding: 0 $gap;
      flex: 1;
      .avatar{
        display: flex;
        align-items: center;
        margin-right: $lg-gap;
        > .image{

          width: $size;
          height: $size;
          border-radius: 100%;
          border: 5px solid $module-bg;
          box-sizing: content-box;
          transition: transform $transition-time-slow;
          &:hover {
            transform: rotate(360deg);
          }
        }
      }
      .info{
        display: flex;
        flex-direction: column;
        align-content: space-around;
        flex: 1;
        margin-right: $lg-gap;
        .nick{
          display: flex;
          align-items: center;
          .name {
            color: $text;
            font-size: 1.75rem;
            font-weight: 700;
            /*align-items: center;*/
            max-width: 80%;
            @include text-overflow();
          }
        }

        .nick,
        .position,
        .time,
        .description {
          margin: $sm-gap;
        }
      }
      .action {
        display: flex;
        justify-items: center;
        align-items: center;
        .btn {
          background-color: #54a5dd;
          padding: 0 $gap;
          margin-right: $gap;
          height: 3rem;
          display: inline-flex;
          align-items: center;
          border-radius: $sm-radius;
          color: $white;
          transition: all $transition-time-fast;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

  }
  .user-center{
    margin-bottom: $lg-gap;
    @include radius-box($sm-radius);
    //@include common-bg-module();
    background-color: $module-bg;
    .center-nav {
      width: 100%;
      height: 4rem;

      .nav-list {
        $nav-space: $lg-gap;
        height: 100%;
        display: flex;
        justify-content: stretch;
        align-items: center;
        border-bottom: 1px solid $module-bg-darker-1;
        padding: 0 0 0 $nav-space;
        .divider {
          height: 3px;
          width: 1px;
          background-color: $module-bg-translucent;
        }

        .item {
          position: relative;
          display: inline-flex;
          align-items: center;
          color: $text-secondary;
          opacity: 0.7;
          margin-right: $nav-space * 2;
          @include visibility-transition();
          &:hover {
            opacity: 1;
          }
          &.link-active {
            .text {
              padding-top: 4px;
              padding-bottom: 2px;
              border-bottom: 2px solid $primary;

            }
          }

          > .iconfont {
            margin-right: $sm-gap;
          }

          .image-icon {
            width: 1em;
            height: 1em;
            margin-right: $sm-gap;
            border-radius: $xs-radius;
          }

          .superscript {
            margin-left: $xs-gap;
          }

          &.new {
            margin-right: $xs-gap;
          }

          .newscript {
            position: absolute;
            right: -1.2em;
            top: -0.1em;
            font-size: $font-size-small - 3;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
