/**
 * @file Swiper for vue
 * @module effect.swiper
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Grid,
  Lazy,
  Mousewheel,
  Autoplay,
  A11y,
  EffectFade,
  Manipulation
} from 'swiper'

SwiperClass.use([Grid, Pagination, Navigation, Mousewheel, Autoplay, A11y, Lazy, EffectFade, Manipulation])

export default SwiperClass
export { Swiper, SwiperSlide } from 'swiper/vue'
