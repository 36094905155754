<template>
  <div class="index-page">
    <carrousel
      class="carrousel"
      :articles="articleListStore.list.data"
      :fetching="articleListStore.list.fetching"
    />
<!--    <twitter-->
<!--      class="twitter"-->
<!--      :userinfo="twitterStore.userinfo.data || void 0"-->
<!--      :tweets="twitterStore.tweets.data || void 0"-->
<!--      :fetching="-->
<!--        twitterStore.userinfo.fetching ||-->
<!--        twitterStore.tweets.fetching ||-->
<!--        articleListStore.list.fetching-->
<!--      "-->
<!--    />-->
    <article-list
      :mammon="false"
      :fetching="articleListStore.list.fetching"
      :articles="articleListStore.list.data"
      :pagination="articleListStore.list.pagination"
      @loadmore="loadmoreArticles"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useUniversalFetch, onClient } from '/@/universal'
  import { useArticleListStore } from '/@/stores/article'
  import { scrollToNextScreen } from '/@/utils/scroller'
  import { LanguageKey } from '/@/language'
  import { META } from '/@/config/app.config'
  import ArticleList from '/@/components/flow/desktop/list.vue'
  import Carrousel from './carrousel.vue'

  export default defineComponent({
    name: 'IndexPage',
    components: {
      Carrousel,
      ArticleList
    },
    setup() {
      const { meta, i18n } = useEnhancer()
      const articleListStore = useArticleListStore()

      meta(() => ({
        title: `${META.title} - ${i18n.t(LanguageKey.APP_SLOGAN)}`,
        description: `${META.description}`,
        keywords: `${META.keywords}`
      }))

      const loadmoreArticles = async () => {
        const targetPage = articleListStore.list.pagination?.current + 1
        await articleListStore.fetchList({ pageNum: targetPage })
        if (targetPage > 1) {
          onClient(scrollToNextScreen)
        }
      }

      useUniversalFetch(() => {
        return Promise.all([
          articleListStore.fetchList(),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          // twitterStore.fetchUserinfo().catch(() => {}),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          // twitterStore.fetchTweets().catch(() => {})
        ])
      })

      return {
        articleListStore,
        loadmoreArticles
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .index-page {
    .carrousel,
    .twitter {
      margin-bottom: $lg-gap;
    }
  }
</style>
