<template>
  <ul class="aggregate-calendar">
    <li class="month" v-for="(month, index) in months" :key="index">
      <calendar-day
        v-for="(day, i) in month"
        :key="i"
        :date="day"
        :articles="getDayArticles(day)"
        :boilings="getDayBoilings(day)"
        :comments="getDayComments(day)"
        :pictures="getDayPictures(day)"
      />
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted } from 'vue'
  import { useStatisticStore } from '/@/stores/statistic'
  import { dateToHuman, HumanDate, humanDateToYMD } from '/@/transforms/moment'
  import CalendarDay from './day.vue'

  export default defineComponent({
    name: 'StatisticsCalendar',
    components: {
      CalendarDay
    },
    setup() {
      const useStatistic = useStatisticStore()
      // const githubContributionsMap = computed(
      //   () => new Map(githubCalendar.days.map((day) => [day.date, day]))
      // )

      const getDayArticles = (date: string) => {
        return useStatistic.statisticCalendar.articleCalendar.find((i) => i.date === date)?.count || 0
      }
      const getDayBoilings = (date: string) => {
        return useStatistic.statisticCalendar.boilingCalendar.find((i) => i.date === date)?.count || 0
      }
      const getDayComments = (date: string) => {
        return useStatistic.statisticCalendar.commentCalendar.find((i) => i.date === date)?.count || 0
      }
      const getDayPictures = (date: string) => {
        return useStatistic.statisticCalendar.pictureCalendar.find((i) => i.date === date)?.count || 0
      }



      // current month | day
      const today = new Date('2022-12.31')
      const todayHuman = dateToHuman(today)
      const currentMonthDays = Array.from({ length: todayHuman.day }).map((_, i) => {
        return humanDateToYMD({ ...todayHuman, day: i + 1 })
      })
      const getPrevMonthFullDays = (date: HumanDate, prevMonths: number) => {
        const targetMonth = new Date(date.year, date.month - prevMonths, 0)
        const daysCount = targetMonth.getDate()
        return Array.from({ length: daysCount }).map((d, i) => {
          return humanDateToYMD({ ...dateToHuman(targetMonth), day: i + 1 })
        })
      }

      const months = [
        // getPrevMonthFullDays(todayHuman, 12),
        getPrevMonthFullDays(todayHuman, 11),
        getPrevMonthFullDays(todayHuman, 10),
        getPrevMonthFullDays(todayHuman, 9),
        getPrevMonthFullDays(todayHuman, 8),
        getPrevMonthFullDays(todayHuman, 7),
        getPrevMonthFullDays(todayHuman, 6),
        getPrevMonthFullDays(todayHuman, 5),
        getPrevMonthFullDays(todayHuman, 4),
        getPrevMonthFullDays(todayHuman, 3),
        getPrevMonthFullDays(todayHuman, 2),
        getPrevMonthFullDays(todayHuman, 1),
        currentMonthDays
      ]

      onMounted(() => {
        useStatistic.fetchStatisticCalendar()
      })

      return {
        months,
        getDayArticles,
        getDayBoilings,
        getDayComments,
        getDayPictures
      }
    }
  })
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.aggregate-calendar {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  .month {
    width: auto;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: 4px;
    @include scroll-snap-item();
  }
}
</style>
