<template>
  <div :id="ANCHOR.COMMENT_ELEMENT_ID" class="comment-box">
    <comment-topbar
      :login="isLogin"
      :total="Number(commentStore.commentMap.get(postId)?.pagination?.total)"
      :loaded="Number(commentStore.commentMap.get(postId)?.comments?.length)"
      :post-id="postId"
      :fetching="fetching"
      :loading="isLoading"
      :plain="plain"
      :sort="commentState.sort"
      @sort="fetchSortComments"
    />
    <divider class="divider" size="lg" />
    <comment-publisher-main :fetching="fetching">
      <comment-publisher
        :id="ANCHOR.COMMENT_PUBLISHER_ELEMENT_ID"
        :disabled="isLoading || isRootPosting"
        :total="Number(commentStore.commentMap.get(postId)?.pagination?.total)"
        :default-blossomed="plain ? true : false"
        :hidden-avatar="plain"
        :login="isLogin"
      >
        <template #pen>
          <comment-pen
            v-model="rootPenState.content"
            v-model:previewed="rootPenState.previewed"
            :auto-focus="plain ? false : true"
            :hidden-stationery="plain"
            :disabled="isRootPosting || isLoading"
            :posting="isRootPosting"
            @submit="handleRootSubmit"
          />
        </template>
      </comment-publisher>
    </comment-publisher-main>
    <divider class="divider" size="lg" />
    <comment-main
      :fetching="isLoading"
      :skeleton-count="plain ? 1 : 1"
      :has-data="Boolean(commentStore.commentTreeList(postId).length)"
    >
      <template #list>
        <comment-list
          :comments="commentStore.commentTreeList(postId)"
          :reply-pid="commentState.replyPID"
          :hidden-avatar="plain"
          :hidden-ua="plain"
          :plain-vote="plain"
          @delete="handleDeleteComment"
          @reply="replyComment"
          @cancel-reply="cancelCommentReply"
        >
          <template #reply="payload">
            <comment-publisher
              v-model:profile="guestProfile"
              :id="ANCHOR.COMMENT_REPLY_PUBLISHER_ELEMENT_ID"
              :disabled="false"
              :bordered="true"
              :default-blossomed="true"
              :hidden-avatar="plain"
              :fixed-avatar="payload.isChild"
            >
              <template #pen>
                <comment-pen
                  :posting="isReplyPosting"
                  :bordered="true"
                  :auto-focus="true"
                  :hidden-stationery="plain"
                  @submit="handleReplySubmit"
                />
              </template>
            </comment-publisher>
          </template>
        </comment-list>
      </template>
      <template #pagination>
        <comment-loadmore
          :fetching="isFetching"
          :pagination="commentStore.commentMap.get(postId)?.pagination"
          :remain="commentStore.commentMap.get(postId)?.pagination?.total - commentStore.commentMap.get(postId)?.comments.length"
          @page="fetchPageComments"
        />
      </template>
    </comment-main>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    ref,
    reactive,
    computed,
    watch,
    toRaw,
    onBeforeUnmount,
    onUnmounted,
    nextTick
  } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useCommentStore, CommentFetchParams, Author } from '/@/stores/comment'
  import * as ANCHOR from '/@/constants/anchor'
  import { UNDEFINED } from '/@/constants/value'
  import { SortType } from '/@/constants/state'
  import { LanguageKey } from '/@/language'
  import { scrollToAnchor } from '/@/utils/scroller'
  import { luanchEmojiRain } from './helper'
  import CommentTopbar from './topbar.vue'
  import CommentMain from './list/main.vue'
  import CommentList from './list/list.vue'
  import CommentLoadmore from './loadmore.vue'
  import CommentPublisherMain from './publisher/main.vue'
  import CommentPublisher from './publisher/publisher.vue'
  import CommentPen from './publisher/pen.vue'
  import {useUniversalFetch} from "/@/universal";
  import { useUserStore } from "/@/stores/user";

  export default defineComponent({
    name: 'Dsiqus',
    components: {
      CommentTopbar,
      CommentMain,
      CommentList,
      CommentLoadmore,
      CommentPublisherMain,
      CommentPublisher,
      CommentPen
    },
    props: {
      postId: {
        type: String,
        required: true
      },
      fetching: {
        type: Boolean,
        default: false
      },
      plain: {
        type: Boolean,
        default: false
      },
      moduleType: {
        type: Number,
        default: 1
      }
    },
    setup(props) {
      const { i18n, globalState } = useEnhancer()
      const commentStore = useCommentStore()
      if(!(commentStore.commentMap.get(props.postId))){
        commentStore.createCommentData(props.postId)
      }
      const userStore = useUserStore()
      const isLogin = computed(() => userStore.isLogin)
      const isPosting = computed(() => commentStore.commentMap.get(props.postId)?.posting || false)
      const isFetching = computed(() => commentStore.commentMap.get(props.postId).fetching)
      const isLoading = computed(() => {
        return props.fetching || (!commentStore.commentMap.get(props.postId)?.comments.length && commentStore.commentMap.get(props.postId)?.fetching)
      })

      enum PostKey {
        Root = 'root',
        Reply = 'reply'
      }
      const postingKey = ref<PostKey>()
      const isRootPosting = computed(() => isPosting.value && postingKey.value === PostKey.Root)
      const isReplyPosting = computed(() => isPosting.value && postingKey.value === PostKey.Reply)

      const commentState = reactive({
        sort: SortType.Desc,
        replyPID: '0'
      })

      // const guestProfile = ref<any>({...userStore.user})

      const rootPenState = reactive({
        content: '',
        previewed: false
      })

      const clearRootPenContent = () => {
        rootPenState.content = ''
      }
      const closeRootPenPreview = () => {
        rootPenState.previewed = false
      }

      const cancelCommentReply = () => {
        commentState.replyPID = '0'
      }
      const replyComment = (commentID: number) => {
        commentState.replyPID = commentID
      }
      const fetchCommentList = (params: CommentFetchParams = {}) => {
        return commentStore.fetchList({
          ...params,
          sort: commentState.sort,
          resourceId: props.postId,
          moduleType: props.moduleType
        })
      }

      const fetchSortComments = (sort: SortType) => {
        if (commentState.sort !== sort) {
          commentState.sort = sort
          fetchCommentList({pageNum: 1})
          scrollToAnchor(ANCHOR.COMMENT_ELEMENT_ID)
        }
      }

      const fetchPageComments = (pageNum: number) => {
        const comments = commentStore.commentMap.get(props.postId).comments
        const lastCommentID = ANCHOR.getCommentItemElementID(comments[comments.length - 2].id)
        fetchCommentList({ pageNum, loadmore: true }).then(() => {
          nextTick().then(() => {
            scrollToAnchor(lastCommentID)
          })
        })
      }

      const handleDeleteComment = (commentId: string, resourceId: string) => {
        console.log(`del`)
        commentStore.deleteComment(commentId, resourceId).catch((error) => {
          console.warn('delete comment failed', error)
          alert(error.message)
        })
      }

      const createComment = async (payload: { content: string; pid: number }) => {

        // content length
        if (!payload.content || !payload.content.trim()) {
          throw `${i18n.t(LanguageKey.COMMENT_POST_CONTENT)} ?`
        }
        if (payload.content.length > 3000) {
          throw `${i18n.t(LanguageKey.COMMENT_POST_ERROR_CONTENT)} ?`
        }


        try {
          const newComment = await commentStore.postComment({
            pid: payload.pid,
            resourceId: props.postId,
            moduleType: props.moduleType,
            content: payload.content,
            agent: globalState.userAgent.original
          })

          // random emoji rain
          luanchEmojiRain(payload.content)
        } catch (error: any) {
          console.warn('submit comment failed:', error)
          throw error.message
        }
      }

      const validateFormByID = (formID: string) => {
        const formElement = document.getElementById(formID)! as HTMLFormElement
        const check_status = formElement.checkValidity()
        formElement.reportValidity()
        return check_status ? Promise.resolve() : Promise.reject()
      }

      const handleRootSubmit = async (content: string) => {
        await validateFormByID(ANCHOR.COMMENT_PUBLISHER_ELEMENT_ID)
        postingKey.value = PostKey.Root
        try {
          await createComment({ content, pid: 0 })
          closeRootPenPreview()
          clearRootPenContent()

        } catch (error: any) {
          console.log(`${error}`)
          alert(error)
        } finally {
          postingKey.value = UNDEFINED
        }
      }

      const handleReplySubmit = async (content: string) => {
        await validateFormByID(ANCHOR.COMMENT_REPLY_PUBLISHER_ELEMENT_ID)
        postingKey.value = PostKey.Reply
        try {
          await createComment({ content, pid: commentState.replyPID })
          cancelCommentReply()
        } catch (error: any) {
          alert(error)
        } finally {
          postingKey.value = UNDEFINED
        }
      }

      watch(isLoading, (isFetching) => {
        if (isFetching) {
          cancelCommentReply()
        }
      })

      onBeforeUnmount(() => {
        cancelCommentReply()
      })
      onUnmounted(() => {
        commentStore.clearList(props.postId)
      })

      //触发接口请求
      // useUniversalFetch(() => fetchCommentList({pageNum: 1}))

      return {
        ANCHOR,
        isFetching,
        isLoading,
        isRootPosting,
        isReplyPosting,
        commentStore,
        commentState,
        rootPenState,
        // guestProfile,
        isLogin,
        replyComment,
        handleDeleteComment,
        handleRootSubmit,
        handleReplySubmit,
        cancelCommentReply,
        fetchSortComments,
        fetchPageComments
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .comment-box {
    padding: $gap;
    @include common-bg-module();
    @include radius-box($lg-radius);

    .divider {
      border-color: $module-bg-darker-1 !important;
    }
  }
</style>
