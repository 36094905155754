<template>
  <i class="thirdiconfont" :class="[`el-icon-third-lv${level}`, size]"></i>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Level',
    props: {
      level: {
        type: Number,
        default: 1,
      },
      size: {
        type: String,
        default: 'mini',
      },
    },
    setup(props) {
      return {

      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .thirdiconfont{
    margin-left: 5px;
  }
  .mini{
    font-size: $font-size-h2;
  }
  .el-icon-third-lv1{
    color: #7dc5eb;
  }
  .el-icon-third-lv2{
    color: #f4ea2a;
  }
  .el-icon-third-lv3{
    color: #1296db;
  }
  .el-icon-third-lv4{
    color: #36ab60;
  }
  .el-icon-third-lv5{
    color: #ff6c00;
  }
  .el-icon-third-lv-1{
    color: #7dc5eb;
  }
  .el-icon-third-lv-2{
    color: #f4ea2a;
  }
  .el-icon-third-lv-3{
    color: #1296db;
  }
  .el-icon-third-lv-4{
    color: #36ab60;
  }
  .el-icon-third-lv-5{
    color: #ff6c00;
  }
</style>
