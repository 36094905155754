<template>
  <div class="images-grid">
    <ul class="images">
      <li
        class="image"
        :key="index"
        v-for="(item, index) in images"
        :title="item.fileName"
        @click="handleViewOpen(index)"
      >
        <div class="mask">
          <span class="icon">
            <i class="iconfont icon-magnifier"></i>
          </span>
        </div>
        <div
          z-load
          class="background"
          :style="{ backgroundImage: `url(${item.filePath})`}"
        />
      </li>
    </ul>
    <client-only>
      <swiper-view
        :images="images"
        :index="viewActiveIndex"
        :visible="isOnView"
        @close="handleViewClose"
      />
    </client-only>
  </div>
</template>

<script lang="ts">
  import {computed, defineComponent, PropType, ref} from "vue";
  import SwiperView from "./singleview.vue";
  import {UNDEFINED} from "/@/constants/value";

  export default defineComponent({
    name: "BoilingSingleSwiper",
    components: {
      SwiperView
    },
    props: {
      images: {
        type: Array,
        required: true
      }
    },
    setup() {
      const viewActiveIndex = ref<number>()
      const isOnView = computed(() => viewActiveIndex.value !== UNDEFINED)
      const handleViewClose = () => {
        viewActiveIndex.value = UNDEFINED
      }
      const handleViewOpen = (index: number) => {
        viewActiveIndex.value = index
      }

      return {
        isOnView,
        viewActiveIndex,
        handleViewOpen,
        handleViewClose
      }
    }
  })

</script>


<style lang="scss" scoped>
  @import 'src/styles/variables';
  @import 'src/styles/mixins';
  $size: 155px;

  .images {
    margin: 0;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //grid-template-rows: repeat(3, 1fr);
    grid-gap: $gap * 2;

    .image {
      position: relative;
      display: block;
      height: $size;
      overflow: hidden;
      cursor: pointer;
      @include radius-box($sm-radius);
      @include common-bg-module();
      &:hover {
        .background {
          transform: scale(1.15);
        }

        .mask {
          @include visible();
          .icon {
            transform: scale(1);
          }
        }
      }

      .background {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transform: scale(1.05);
        @include transform-transition($transition-time-normal);
      }

      .length {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: $z-index-normal + 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 2rem;
        border-top-left-radius: $xs-radius;
        background-color: $text-divider;
        color: $white;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: $z-index-normal + 1;
        background-color: rgba(#000, 0.3);
        color: rgba($white, 0.8);
        @include backdrop-blur(2px);
        @include hidden();
        @include visibility-transition();

        .icon {
          font-size: 3em;
          transform: scale(1.2);
          @include transform-transition($transition-time-normal);
        }
      }
    }
  }
</style>
