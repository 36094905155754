<template>
  <div :id="NAV_ELEMENT_ID" class="desktop-nav">
    <nav class="nav-list">

      <!--   圈文类型   -->
      <template v-if="RouteName.Saunter === route.name" v-for="menu in boneStore.fullBoneCategoryList" :key="menu.id">
        <span v-if="menu.divider" class="divider"></span>
        <ulink class="item" :class="[menu.id]" :to="getSaunterBoneFlowRoute(menu.id)">
<!--          <uimage v-if="menu.icon" class="image-icon" :src="menu.icon" />-->

          <webfont class="text" bolder uppercase>
            {{ menu.name }}
          </webfont>
          <span class="newscript" v-if="menu.micon">
            <i class="iconfont" :class="menu.micon"></i>
          </span>
        </ulink>
      </template>
      <!-- 个人设置 -->
      <template v-if="RouteName.Setting_Profile === route.name || RouteName.Setting_Account === route.name || RouteName.Setting_Background === route.name"
                v-for="(item, index) in navStore.getNavList(2, 2)" :key="index">
        <ulink class="item" :href="item.url" :to="item.path">
          <i class="iconfont" :class="item.icon"></i>
          <webfont class="ad-text" bolder uppercase>
            {{item.name}}
          </webfont>
        </ulink>
      </template>
      <!-- 拾荒 -->
      <template v-if="RouteName.ScavengeNavigation === route.name || RouteName.Scavenge === route.name || RouteName.ScavengeNews === route.name || RouteName.ScavengeResource === route.name || RouteName.ScavengeRecommend === route.name"
                v-for="(item, index) in navStore.getNavList(3, 2)" :key="index">
        <ulink class="item" :href="item.url" :to="item.path">
          <i class="iconfont" :class="item.icon"></i>
          <webfont class="ad-text" bolder uppercase>
            {{item.name}}
          </webfont>
        </ulink>
      </template>
    </nav>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { NAV_ELEMENT_ID } from '/@/constants/anchor'
  import { useEnhancer } from '/@/app/enhancer'
  import { RouteName } from '/@/app/router'
  import { useUniversalFetch } from "/@/universal";
  import { useBoneStore } from "/@/stores/saunter";
  import { getSaunterBoneFlowRoute } from '/@/transforms/route'
  import { useNavStore } from "/@/stores/nav";


  export default defineComponent({
    name: 'DesktopNav',
    setup() {
      const { route } = useEnhancer()
      const boneStore = useBoneStore()
      const navStore = useNavStore()

      useUniversalFetch(() => {
        return Promise.all([
          boneStore.fetchBoneCategoryList()
        ])
      })
      return {
        NAV_ELEMENT_ID,
        RouteName,
        route,
        boneStore,
        navStore,
        getSaunterBoneFlowRoute
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .desktop-nav {
    width: $navbar-width;
    height: auto;

    .nav-list {
      width: $navbar-width;
      padding: 0;
      margin: 0;
      position: fixed;

      .item {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 3em;
        margin-bottom: $sm-gap;
        padding: 0 $lg-gap;
        border-radius: $mini-radius;
        text-decoration: none;
        letter-spacing: 0.5px;
        color: $text-secondary;
        will-change: background-color;
        transition: background-color $transition-time-fast * 0.6;
        @include radius-box($sm-radius);
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: $primary-lighter;
          background-color: $module-bg-translucent;
        }
        &.link-active {
          color: $primary;
          background-color: $module-bg-lighter;
          &.guestbook {
            background: linear-gradient(to bottom, $module-bg-lighter, transparent);
          }
        }

        &.hot {
          color: $red;
        }
        &.app {
          color: $surmon;
        }
        &.opensea {
          color: $opensea-primary;
        }

 /*       > .iconfont {
          &:first-child {
            width: 1em;
            margin-right: $gap;
            display: inline-block;
          }
        }*/

        .newscript,
        .superscript {
          margin-left: $sm-gap;
        }

        .newscript {
          .iconfont {
            font-size: $font-size-small;
          }
        }

        .superscript {
          color: $red;
        }

        .image-icon {
          width: 1em;
          height: 1em;
          margin-right: $gap;
          border-radius: $xs-radius;
        }

        .ad-text {
          font-weight: bold;
        }
      }

      .divider {
        display: block;
        height: 0;
        background-color: transparent;
        border-top: 1px dotted $module-bg-darker-2;
        margin-bottom: $sm-gap;
      }
    }
  }
</style>
