<template>
  <form
    key="publisher"
    class="publisher"
    name="comment"
    :id="id"
    :class="{
      'hidden-avatar': hiddenAvatar,
      'fixed-avatar': fixedAvatar,
      blossomed,
      bordered
    }"
  >
    <div class="postbox">
      <div class="avatar" v-if="!hiddenAvatar">
        <uimage :alt="user.nickName ? user.nickName : '请登入'" :src="user.avatar ? user.avatar : '/images/default-avatar.svg'" />
      </div>
      <transition name="module-slow">
        <div class="editor" v-if="blossomed" key="editor">
          <slot name="pen"></slot>
        </div>
        <button type="button" class="placeholder" v-else key="placeholder" :disabled="!login" @click="handleBlossom">
          <i18n zh="在下有一拙见，不知..." :en="`${total ? 'Join' : 'Start'} the discussion...`" />
        </button>
      </transition>
    </div>
  </form>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, PropType } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { Author } from '/@/stores/comment'
  import { useUserStore } from '/@/stores/user'
  import { LanguageKey } from '/@/language'
  import {
    getGravatarByHash,
    getDefaultAvatar,
    getDisqusAvatarByUsername
  } from '/@/transforms/avatar'
  import { firstUpperCase } from '/@/transforms/text'
  import { CommentEvents } from '../helper'

  export enum PublisherEvents {
    UpdateProfile = 'update:profile'
  }

  export default defineComponent({
    name: 'CommentPublisher',
    props: {
      id: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // profile: {
      //   type: Object as PropType<Author>,
      //   required: true
      // },
      total: {
        type: Number,
        default: 0,
        required: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      defaultBlossomed: {
        type: Boolean,
        default: true
      },
      hiddenAvatar: {
        type: Boolean,
        required: false
      },
      fixedAvatar: {
        type: Boolean,
        required: false
      },
      login: {
        type: Boolean,
        required: false
      }
    },
    emits: [CommentEvents.Blossom, PublisherEvents.UpdateProfile],
    setup(props, context) {
      const { i18n } = useEnhancer()
      const userStore = useUserStore()
      const user = computed(() => userStore.user)
      const blossomed = ref(props.defaultBlossomed)
      const handleBlossom = () => {
        blossomed.value = true
        context.emit(CommentEvents.Blossom)
      }

      return {
        user,
        blossomed,
        LanguageKey,
        t: i18n.t,
        firstUpperCase,
        handleBlossom
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $avatar-size: 4rem;

  .publisher-skeleton {
    display: flex;
    height: $avatar-size;

    .avatar {
      width: $avatar-size;
      margin-right: $gap;
    }

    .content {
      flex-grow: 1;
    }
  }

  .publisher {
    display: block;
    padding-left: 5.2rem;
    &.bordered {
      input {
        border: 1px solid $module-bg-darker-3;
      }
    }

    .profile {
      width: 100%;
      height: 2em;
      line-height: 2em;
      display: flex;
      margin-bottom: $gap;

      .name,
      .email,
      .site {
        flex-grow: 1;

        input {
          width: 100%;
          height: 2em;
          line-height: 2em;
          text-indent: 3px;
          background-color: $module-bg-darker-1;
          @include radius-box($mini-radius);
          @include background-transition();

          &:focus,
          &:hover {
            background-color: $module-bg-hover;
          }
        }
      }

      .name,
      .email {
        margin-right: $gap;
      }
    }

    .postbox {
      width: 100%;
      height: $avatar-size;
      display: flex;
      position: relative;

      .avatar {
        position: absolute;
        top: 0;
        left: -($avatar-size + $gap);
        display: block;
        width: $avatar-size;
        height: $avatar-size;
        background-color: $module-bg-darker-1;
        @include radius-box($xs-radius);

        img {
          width: 100%;
          height: 100%;
          transition: transform 0.5s ease-out;
        }
      }

      .editor {
        flex-grow: 1;
        overflow: hidden;
      }

      .placeholder {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0 1em;
        display: flex;
        align-items: center;
        font-size: $font-size-h4;
        //cursor: text;
        @include radius-box($xs-radius);
        background-color: $module-bg-darker-1;
        &:hover {
          background-color: $module-bg-darker-3;
        }
      }
    }

    &.blossomed {
      .postbox {
        height: auto;
        min-height: 8rem;
      }
    }

    &.hidden-avatar {
      padding-left: 0;

      .profile {
        padding: 0;
        margin: 0;
        height: auto;
        flex-direction: column;

        .name,
        .email,
        .site {
          width: 80%;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: $gap;
        }
      }
    }

    &.fixed-avatar {
      padding-left: 0;
    }
  }
</style>
