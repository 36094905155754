/**
 * @file Universal state
 * @module store.universal
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import cookies from 'js-cookie'
import localhost from '/@/services/localhost'
import date from "@/pages/date.vue";
import {Article} from "@/stores/article";
import Toast from "/@/composables/toast/index"

export interface User {
  id: string
  source: string
  gender: Gender
  avatar: string
  level: number
  summary: string
  nickName: string
  occupation: string
  company: string
  userType: UserType
  expValue: number
  birthday: string
  registerTime: string
}

export interface Author {
  base: User,
  articleNum: number,
  boilingNum: number,
  followNum: number,
  collectNum: number,
  byViewNum: number,
}

export enum UserType {
  Null = 0,
  VIP = 1
}

export enum UploadType {
  YES = 0,
  NO = 1
}

export enum Gender {
  UNKNOW = 0,
  MALE = 1,
  FEMALE = 2
}

export interface Background {
  id: string
  cover: string
}

export interface Sign {
  userId: string
  sign: string
}

export interface UserVotes {
  likes: string[]
  collects: string[]
  signs: string[]
  follows: string[]
}

//toke key
const USER_TOKEN = 'user-token'

export const USER_API_PATH = '/blog/web/user'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: null,
    // user
    user: {
      userId: '',
      nickName: '',
      avatar: '/images/default-avatar.svg',
      userType: UserType.Null,
      isLogin: false as boolean,
      background: 'http://cdn.potatomelon.com/blog/user/20230116/75d635f86dec4ecea2f982c7e7098e49.png',
      isUpload: UploadType.NO
    },
    // vote history
    votes: {
      fetching: false,
      likes: [] as string[],
      collects: [] as string[],
      signs: [] as string[],
      follows: [] as string[]
    }
  }),
  getters: {
    getUserId:(state) => { return state.user.userId },
    isLogin: (state) => state.user.isLogin === true,
    isCurrentUser: (state) => (id: string) => state.user.userId === id,
    currentBackground: (state) => {
      return state.user.background
    },
    getCurrentUser: (state) => state.user,
    isUploadAuth:(state) => { return state.user.isUpload == UploadType.YES },
  },
  actions: {
    getToken() {
      // console.log(`获取cookie的token：${cookies.get(USER_TOKEN)}`)
      try {
        this.token = cookies.get(USER_TOKEN) || this.token
      } catch (error) {
        // cookies.remove(USER_TOKEN)
      }
    },
    resetUser(){
      this.token = null
      this.user.userId = ''
      this.user.nickName = ''
      this.user.avatar = '/images/default-avatar.svg'
      this.user.userType = UserType.Null
      this.user.isLogin = false
      this.user.background = 'http://cdn.potatomelon.com/blog/user/20230116/75d635f86dec4ecea2f982c7e7098e49.png'
      this.user.isUpload = UploadType.NO
      this.votes.fetching = false
      this.votes.likes = []
      this.votes.collects = []
      this.votes.signs = []
      this.votes.follows = []

      cookies.remove(USER_TOKEN)
    },

    setUser(user: any){
      this.user.userId = user.id
      this.user.nickName = user.nickName
      this.user.avatar = user.avatar
      this.user.userType = user.userType
      this.user.isLogin = true
      this.user.background = user.background
      this.user.isUpload = user.isUpload
    },
    setVotes(votes: any){
      this.votes = votes
    },
    setBackground(background: any){
      this.user.background = background
    },
    initOnClient() {
      // 1. 获取token
      this.getToken()
      // 2. init and reset user info
      if (this.$state.token) {
        Promise.all([
          this.fetchUserInfo().catch(() => {
            this.resetUser();
          }),
          this.fetchVotes()
        ])
      } else {
        this.resetUser();
      }
      // console.log(`finish initOnClient`)
    },

    fetchUserLogout() {
      // return localhost.get(`${USER_API_PATH}/logout`).then(() => {
      //   this.resetUser();
      // })
      this.resetUser();
    },

    fetchUserLogin(source: string) {
      return localhost.get(`/auth/render/${source}`)
    },
    fetchUserInfo() {
      return localhost.get<any>(`${USER_API_PATH}/api/info`).then((response) => {
        this.setUser(response.data);
      })
    },

    //IM签名
    fetchUserSign() {
      return localhost.get<any>(`${USER_API_PATH}/getUserSig`)
    },

    //IM签名
    fetchUserSignTem(userId: string) {
      return localhost.get<any>(`${USER_API_PATH}/api/getUserSigTem/${userId}`)
    },

    fetchVotes(){
      return localhost.get<any>(`/blog/web/statistics/votes`).then((response) => {
        this.setVotes(response.data);
      })
    },
    changesVotes(flag: boolean, key: string, id: string){
      if(flag){
        this.votes[key].push(id)
      }else{
        this.votes[key] = this.votes[key].filter(item => item != id)
      }
    },

    fetchUserById(userId: string){
      return localhost.get<any>(`${USER_API_PATH}/api/${userId}`).then((response) => {

      })
    },

    postUserFollow(params: Partial<any>){
      this.votes.fetching = true
      return localhost.post<any>(`${USER_API_PATH}/follow`,params).then((response) => {
        this.changesVotes(response.data, 'follows', params.byUserId)
        return response.data
      }).finally(() => {
        this.votes.fetching = false
      })
    },
  }
})


export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    // 个人中心
    fetching: false,
    user: null as null | User,
    userSign: '',

    // 最新加入
    joinList: {
      fetching: false,
      data: [] as Array<User>,
    },

    // 关注列表
    followList: {
      fetching: false,
      data: [] as Array<User>,
      pagination: null as null | $TODO
    },

    // 用户基本信息
    userBase: {
      fetching: false,
      data: null as null | User,
    }
  }),
  actions: {
    fetchUserById(userId: string){
      this.fetching = true
      return localhost.get<any>(`${USER_API_PATH}/api/${userId}`).then((response) => {
        this.user = response.data
        this.fetching = false
      }).finally(() => {
        this.fetching = false
      })
    },
    //最新加入（20）
    fetchJoinList(){
      this.joinList.fetching = true
      return localhost.get<any>(`${USER_API_PATH}/api/join`).then((response) => {
        this.joinList.data = response.data
        this.joinList.fetching = false
      }).finally(() => {
        this.joinList.fetching = false
      })
    },
    //关注列表
    fetchFollowList(params: any = {}){
      const isRestart = !params.pageNum || params.pageNum === 1

      // 清空已有数据
      if (isRestart) {
        this.followList.data = []
        this.followList.pagination = null
      }

      this.followList.fetching = true
      return localhost.get<any>(`${USER_API_PATH}/api/follow/${params.userId}`, { params }).then((response) => {
        this.followList.data.push(...response.data)
        this.followList.pagination = response.pagination
      }).finally(() => {
        this.followList.fetching = false
      })
    },
    //获取基本信息
    fetchUserBaseInfo(){
      this.userBase.fetching = true
      return localhost.get<any>(`${USER_API_PATH}/base`).then((response) => {
        this.userBase.data = response.data
      }).finally(() => {
        this.userBase.fetching = false
      })
    },
    //更新基本信息
    updateUserBaseInfo(base: Partial<any>){
      console.log(`updateUserBaseInfo param base: ${base}`)
      return localhost.put<any>(`${USER_API_PATH}/update/base`, base).then((response) => {
        // this.userBase.data = response.data
        Toast.success('更新成功！')
      }).catch((error) => {
        Toast.error(error)
      })
    },
    //更新账户信息
    updateUserAccount(base: Partial<any>){
      return localhost.put<any>(`${USER_API_PATH}/update/base`, base).then((response) => {

      })
    },
    fetchUserSign(userId: string){
      return localhost.get<any>(`${USER_API_PATH}/api/getUserSig/${userId}`)
    },

  }
})


export const useUserBackgroundStore = defineStore('userBackground', {
  state: () => ({

    // 系统背景
    defaultList: {
      fetching: false,
      data: [] as Array<Background>,
    },

    // 历史背景
    historyList: {
      fetching: false,
      data: [] as Array<Background>,
    },

  }),
  actions: {
    fetchUserBackground(){
      this.defaultList.fetching = true
      this.historyList.fetching = true
      return localhost.get<any>(`${USER_API_PATH}/background`).then((response) => {
        this.defaultList.data = response.data.defaultList
        this.historyList.data = response.data.historyList
        this.defaultList.fetching = false
        this.historyList.fetching = false
      }).finally(() => {
        this.defaultList.fetching = false
        this.historyList.fetching = false
      })
    },

    updateUserBackground(background: Partial<any>){
      return localhost.put<any>(`${USER_API_PATH}/update/background`, background)
    }
  }
})

export const useSignStore = defineStore('userSign', {
  state: () => ({
    //已签到日期
    signList: [] as Array<string>
  }),
  actions: {
    fetchSignList(){
      return localhost.get<any>(`${USER_API_PATH}/api/sign/list`).then((response) => {
        this.signList = response.data
        console.log(JSON.stringify(this.signList))
      }).finally(() => {

      })
    },
    postSign(sign: string){
      if(this.signList.includes(sign)){
        console.log(`已签到`)
        return
      }
      const now = new Date();
      const signDate = new Date(sign)
      if(now.getDate() < signDate.getDate()){
        console.log(`签到不能大于今日`)
        Toast.error("签到不能大于今日")
      }else{
        return localhost.post<any>(`${USER_API_PATH}/sign`, {sign: sign}).then((response) => {
          this.signList.push(sign)
          Toast.success("签到成功！")
        }).catch((error) => {
          Toast.error(error)
        })
      }
    },
  }
})
