<template>
  <div class="mammon">
    <placeholder :data="advStore.advList.data" :loading="advStore.advList.fetching">
      <template #placeholder>
        <empty class="module-empty" key="empty">
          广而告之
        </empty>
      </template>
      <template #loading>
        <skeleton-base />
      </template>
      <template #default>
        <swiper
            class="swiper"
            direction="vertical"
            :initial-slide="index"
            :loop="true"
            :simulate-touch="false"
            :autoplay="{ delay: 2800, disableOnInteraction: false }"
            :pagination="{ clickable: true }"
            :set-wrapper-size="true"
            :auto-height="true"
            :mousewheel="true"
            :observe-parents="true"
            :preload-images="false"
            :lazy="true"
            @swiper="updateSwiper"
            @slide-change-transition-end="handleSlideChange"
        >
          <swiper-slide
              class="swiper-slide"
              :key="_index"
              v-for="(ad, _index) in advStore.advList.data"
          >
            <ulink class="content" v-if="ad.type == 0" :href="ad.link">
<!--              <uimage :src="ad.cover" :alt="ad.name" />-->
              <div
                z-load
                class="ad"
                :style="{ backgroundImage: `url(${ad.cover})`}"
              />
              <i class="iconfont icon-ad"></i>
            </ulink>

            <ulink class="content" v-if="ad.type == 1" :to="ad.link">
              <div
                z-load
                class="ad"
                :style="{ backgroundImage: `url(${ad.cover})`}"
              />
              <i class="iconfont icon-hotfill"></i>
            </ulink>

            <div class="content" v-if="ad.type == 2" @click="viewImg(ad.cover)">
              <div
                z-load
                class="ad"
                :style="{ backgroundImage: `url(${ad.cover})`}"
              />
              <i class="iconfont icon-ad"></i>
            </div>


          </swiper-slide>
        </swiper>
      </template>
    </placeholder>

  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { useAdvStore } from '/@/stores/adv'
  import SwiperClass, { Swiper, SwiperSlide } from '/@/effects/swiper'
  import { useUniversalFetch } from "/@/universal";

  export enum AsideMammonEvent {
    Ready = 'ready',
    UpdateIndex = 'update:index',
    IndexChange = 'index-change'
  }
  export default defineComponent({
    name: 'DesktopAsideMammon',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      ready: {
        type: Object
      }
    },
    emits: [AsideMammonEvent.Ready, AsideMammonEvent.UpdateIndex, AsideMammonEvent.IndexChange],
    setup(props, context) {
      const advStore = useAdvStore()
      const swiper = ref<SwiperClass>()
      const updateSwiper = (_swiper: SwiperClass) => {
        swiper.value = _swiper
        context.emit(AsideMammonEvent.Ready, swiper.value)
      }

      const handleSlideChange = () => {
        const realIndex = swiper.value?.realIndex
        context.emit(AsideMammonEvent.UpdateIndex, realIndex)
        context.emit(AsideMammonEvent.IndexChange, realIndex)
      }

      // const fetchAllData = () => {
      //   return Promise.all([
      //     advStore.fetchAdvList()
      //   ])
      // }

      const viewImg = (url: string) => {
        window.$popup.vImage(url)
      }

      //触发接口请求
      // useUniversalFetch(() => fetchAllData())

      return {
        advStore,
        updateSwiper,
        viewImg,
        handleSlideChange
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .mammon {
    width: 100%;
    overflow: hidden;

    .swiper {
      height: 88px;

      .swiper-wrapper {
        &[style*='300ms'] {
          .swiper-slide-active {
            @include motion-blur-filter('vertical-small');
          }
        }

        .swiper-slide {
          .content {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            img {
              width: 100%;
              //height: 100%;
            }
            .ad{
              width: 100%;
              height: 100%;
              background-size: cover;
              background-position: center;
              transform: scale(1);
              @include transform-transition($transition-time-normal);
            }

            .iconfont {
              position: absolute;
              bottom: $sm-gap;
              left: $gap;
              color: $white;
            }
          }
        }
      }
    }
  }
</style>
