<template>
  <popup :visible="visible" @close="handleGalleryClose">
    <div class="instagram-gallery">
      <div class="topbar">
        <div class="left">
          <span class="timestamp">
            <i class="iconfont icon-clock"></i>
            <udate to="YMDm" :date="imageObject.publishTime" separator="/" />
          </span>
        </div>
        <div class="center">
          <span class="pagination">{{ galleryActiveIndex + 1 }} / {{ imageObject.pictures.length }}</span>
        </div>
        <div class="right">
          <span class="caption">
            <i class="iconfont icon-camera"></i>
            <template>{{ imageObject.nickName }}</template>
          </span>
        </div>
      </div>
<!--      <div class="postlink">-->
<!--        <i class="iconfont icon-star"></i>-->
<!--      </div>-->
      <button
        class="navigation prev"
        :disabled="galleryActiveIndex === 0"
        @click="galleryPrevSlide"
      >
        <i class="iconfont icon-prev" />
      </button>
      <button
        class="navigation next"
        :disabled="galleryActiveIndex === imageObject.pictures.length - 1"
        @click="galleryNextSlide"
      >
        <i class="iconfont icon-next" />
      </button>
      <swiper
        effect="fade"
        :fade-effect="{ crossFade: true }"
        :initial-slide="0"
        :mousewheel="true"
        :observe-parents="true"
        :grab-cursor="false"
        :preload-images="false"
        :lazy="true"
        :simulate-touch="false"
        @swiper="handleGallerySwiperReady"
        @transition-start="handleGallerySwiperTransitionStart"
      >
        <swiper-slide v-for="(item, i) in imageObject.pictures" :key="i">
          <div
            class="image swiper-lazy"
            :style="{ backgroundImage: `url(${item.filePath})`}"
          />
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </swiper-slide>
      </swiper>
    </div>
  </popup>
</template>

<script lang="ts">
  import { defineComponent, ref, computed } from 'vue'
  import type { InstagramMediaItem } from '/@/server/getters/instagram'
  import SwiperClass, { Swiper, SwiperSlide } from '/@/effects/swiper'
  import { getInstagramImage } from '/@/transforms/media'
  import {UNDEFINED} from "/@/constants/value";

  enum GalleryEvents {
    Close = 'close'
  }

  export default defineComponent({
    name: 'Exhibition',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      image: {
        type: Object,
        required: true
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    emits: [GalleryEvents.Close],
    setup(props, context) {
      const galleryActiveIndex = ref<number>(0)
      const gallerySwiper = ref<SwiperClass>()
      console.log(`props images:${props.image}`)
      const galleryPrevSlide = () => gallerySwiper.value?.slidePrev()
      const galleryNextSlide = () => gallerySwiper.value?.slideNext()
      const handleGallerySwiperReady = (_swiper: SwiperClass) => {
        gallerySwiper.value = _swiper
      }

      const handleGalleryClose = () => {
        context.emit(GalleryEvents.Close)
      }
      const imageObject = computed(() => props.image.value)
      const handleGallerySwiperTransitionStart = () => {
        galleryActiveIndex.value = gallerySwiper.value?.activeIndex || 0
      }
      return {
        handleGalleryClose,
        handleGallerySwiperReady,
        handleGallerySwiperTransitionStart,
        galleryPrevSlide,
        galleryNextSlide,
        galleryActiveIndex,
        imageObject
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .instagram-gallery {
    width: 88vw;
    height: 76vh;
    position: relative;
    background: $black !important;

    .swiper {
      height: 100%;
      .image {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
      }
    }

    .topbar {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0 1em;
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-h4;
      background-color: rgba($black, 0.4);
      color: $white;
      z-index: $z-index-normal + 2;

      .left,
      .right,
      .center {
        display: inline-flex;
        align-items: center;
      }
      .left {
        //width: 40%;
        justify-content: start;
      }
      .center {
        //width: 20%;
        justify-content: center;
      }
      .right {
        //width: 40%;
        justify-content: end;
      }

      .pagination {
        font-weight: bold;
      }

      .timestamp,
      .caption {
        .iconfont {
          margin-right: $sm-gap;
        }
      }
    }

    .postlink {
      $size: 4rem;
      position: absolute;
      z-index: $z-index-normal + 2;
      bottom: 6rem;
      right: 6rem;
      width: $size;
      height: $size;
      line-height: $size;
      border-radius: 50%;
      text-align: center;
      font-size: $font-size-h4;
      background: $instagram-gradient;
      color: $white;
      opacity: 0.5;
      transition: opacity $transition-time-fast;
      &:hover {
        opacity: 1;
      }
    }

    .navigation {
      display: inline-block;
      position: absolute;
      top: 33%;
      height: 33%;
      width: 4rem;
      z-index: $z-index-normal + 2;
      font-size: $font-size-h1 * 2;
      color: $module-bg;
      &[disabled] {
        opacity: 0.4;
      }
      &:not([disabled]):hover {
        color: $module-bg-lighter;
      }

      &.prev {
        left: $gap;
      }
      &.next {
        right: $gap;
      }
    }
  }
</style>
