<template>
  <div class="figure-wrapper" v-if="loading" >
    <figure class="image caption" data-status="loading">
      <div class="placeholder loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </figure>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'Loading',
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },
    setup() {

      return {

      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .figure-wrapper {
    width: 100%;
    /*margin-bottom: 1em;*/
    display: flex;
    justify-content: center;
    /*overflow: hidden;*/
    text-align: center;
    min-height: 100%;
    > figure {
      display: block;
    }
  }

  figure.image {
    position: relative;
    border-radius: $xs-radius;
    border: 0px solid $module-bg-darker-1;
    text-indent: 0;
    text-align: center;

    .placeholder {
      visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }


    figcaption {
      display: block;
      border-top: 0px dashed $module-bg-darker-1;
      text-align: center;
      line-height: $gap * 3;
      font-size: $font-size-small;
      font-weight: bold;
      /* font-style: italic; */
      user-select: none;
      pointer-events: none;
    }

    &[data-status='loaded'] {
      width: auto;
      img {
        min-width: auto;
        min-height: auto;
      }
    }

    &[data-status='loading'],
    &[data-status='error'] {
      width: 100%;

      /* image size */
      img {
        min-height: 6rem;
      }

      /* placeholder size */
      &.caption {
        .placeholder {
          //padding-bottom: $gap * 3;
        }
      }
    }

    /* error icon */
    &[data-status='error'] {
      .placeholder.error {
        visibility: visible;
        font-size: $font-size-h1 * 1.8;
        color: $text-divider;
      }
    }

    /* loading animation */
    &[data-status='loading'] {
      .placeholder.loading {
        visibility: visible;

        @keyframes loading {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }

        div {
          width: 2rem;
          height: 1.2rem;
          margin: 0 $sm-gap;
          border-radius: $mini-radius;
          animation: loading 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          &:nth-child(1) {
            background: $module-bg-darker-2;
            animation-delay: -0.6s;
          }
          &:nth-child(2) {
            background: $module-bg-darker-3;
            animation-delay: -0.4s;
          }
          &:nth-child(3) {
            background: $module-bg-darker-2;
            animation-delay: -0.2s;
          }
          &:nth-child(4) {
            background: $module-bg-darker-3;
            animation-delay: -1s;
          }
        }
      }
    }
  }
</style>
