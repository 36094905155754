<template>
  <popup :visible="visible" @close="handleViewClose">
    <div class="instagram-gallery">
      <div class="topbar">
        <div class="left">
          <span class="timestamp">
<!--            <i class="iconfont icon-clock"></i>-->
<!--            <udate to="YMDm" :date="viewActiveImage.timestamp" separator="/" />-->
          </span>
        </div>
        <div class="center">
          <span class="pagination">{{ viewActiveIndex + 1 }} / {{ images.length }}</span>
        </div>
        <div class="right">
          <span class="caption">
<!--            <i class="iconfont icon-camera"></i>-->
<!--            <template v-if="viewActiveImage.fileName">{{ viewActiveImage.fileName }}</template>-->
          </span>
        </div>
      </div>
<!--      <ulink class="postlink" :href="viewActiveImage.permalink">-->
<!--        <i class="iconfont icon-new-window-s"></i>-->
<!--      </ulink>-->
      <button
        class="navigation prev"
        :disabled="viewActiveIndex === 0"
        @click="viewPrevSlide"
      >
        <i class="iconfont icon-prev" />
      </button>
      <button
        class="navigation next"
        :disabled="viewActiveIndex === images.length - 1"
        @click="viewNextSlide"
      >
        <i class="iconfont icon-next" />
      </button>
      <swiper
        effect="fade"
        :fade-effect="{ crossFade: true }"
        :initial-slide="index"
        :mousewheel="true"
        :observe-parents="true"
        :grab-cursor="false"
        :preload-images="false"
        :lazy="true"
        :simulate-touch="false"
        @swiper="handleViewSwiperReady"
        @transition-start="handleViewSwiperTransitionStart"
      >
        <swiper-slide v-for="(item, i) in images" :key="i">
          <div
            class="image swiper-lazy"
            :style="{ backgroundImage: `url(${item.filePath})`}"
          />
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </swiper-slide>
      </swiper>
    </div>
  </popup>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, PropType } from 'vue'
  import type { InstagramMediaItem } from '/@/server/getters/instagram'
  import SwiperClass, { Swiper, SwiperSlide } from '/@/effects/swiper'
  import { getInstagramImage } from '/@/transforms/media'

  enum ViewEvents {
    Close = 'close'
  }

  export default defineComponent({
    name: 'BoilingSingleSwiperView',
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      images: {
        type: Array,
        required: true
      },
      index: {
        type: Number,
        default: 0
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    emits: [ViewEvents.Close],
    setup(props, context) {
      const viewActiveIndex = ref<number>(props.index)
      const viewSwiper = ref<SwiperClass>()
      const viewActiveImage = computed(() => props.images[viewActiveIndex.value])
      const viewPrevSlide = () => viewSwiper.value?.slidePrev()
      const viewNextSlide = () => viewSwiper.value?.slideNext()
      const handleViewSwiperReady = (_swiper: SwiperClass) => {
        viewSwiper.value = _swiper
      }
      const handleViewSwiperTransitionStart = () => {
        viewActiveIndex.value = viewSwiper.value?.activeIndex || 0
      }
      const handleViewClose = () => {
        context.emit(ViewEvents.Close)
      }

      return {
        viewActiveIndex,
        viewActiveImage,
        handleViewClose,
        handleViewSwiperReady,
        handleViewSwiperTransitionStart,
        viewPrevSlide,
        viewNextSlide
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables';
  @import 'src/styles/mixins';

  .instagram-gallery {
    width: 88vw;
    height: 76vh;
    position: relative;
    background: $black !important;

    .swiper {
      height: 100%;
      .image {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
      }
    }

    .topbar {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0 1em;
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $font-size-h4;
      background-color: rgba($black, 0.4);
      color: $white;
      z-index: $z-index-normal + 2;

      .left,
      .right,
      .center {
        display: inline-flex;
        align-items: center;
      }
      .left {
        width: 40%;
        justify-content: start;
      }
      .center {
        width: 20%;
        justify-content: center;
      }
      .right {
        width: 40%;
        justify-content: end;
      }

      .pagination {
        font-weight: bold;
      }

      .timestamp,
      .caption {
        .iconfont {
          margin-right: $sm-gap;
        }
      }
    }

    .postlink {
      $size: 4rem;
      position: absolute;
      z-index: $z-index-normal + 2;
      bottom: 6rem;
      right: 6rem;
      width: $size;
      height: $size;
      line-height: $size;
      border-radius: 50%;
      text-align: center;
      font-size: $font-size-h4;
      background: $instagram-gradient;
      color: $white;
      opacity: 0.5;
      transition: opacity $transition-time-fast;
      &:hover {
        opacity: 1;
      }
    }

    .navigation {
      display: inline-block;
      position: absolute;
      top: 33%;
      height: 33%;
      width: 4rem;
      z-index: $z-index-normal + 2;
      font-size: $font-size-h1 * 2;
      color: $module-bg;
      &[disabled] {
        opacity: 0.4;
      }
      &:not([disabled]):hover {
        color: $module-bg-lighter;
      }

      &.prev {
        left: $gap;
      }
      &.next {
        right: $gap;
      }
    }
  }
</style>
