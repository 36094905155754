/**
 * @file Article state
 * @module store.article
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { Language } from '/@/language'
import { LONG_ARTICLE_THRESHOLD } from '/@/config/app.config'
import { OriginState, UniversalKeyValue } from '/@/constants/state'
import { getArticleContentHeadingElementID } from '/@/constants/anchor'
import { markdownToHTML } from '/@/transforms/markdown'
import { delayPromise } from '/@/utils/delayer'
import { isClient } from '/@/app/environment'
import localhost from '/@/services/localhost'
import Toast from "/@/composables/toast";
import article from "@/components/layout/desktop/aside/article.vue";
import {firstUpperCase} from "/@/transforms/text";

export const ARTICLE_API_PATH = '/blog/web/article'

export interface Article {
  id: string
  userId: string
  title: string
  description: string
  content: string
  cover: string
  original: OriginState
  articleSource: string
  tag: Tag[]
  categoryName: string
  isComment: boolean
  viewNum: number
  collectNum: number
  likeNum: number
  ip: string
  IPLocation: string
  createTime: string
  isLike: boolean
  isCollect: boolean
}

export type CategoryMap = Map<string, Category>
export interface Category {
  id: string
  name: string
  num: number
}

export interface Tag {
  id: string
  name: string
}

export const useArticleListStore = defineStore('article', {
  state: () => ({
    hotList: {
      fetched: false,
      fetching: false,
      data: [] as Array<Article>
    },
    list: {
      fetching: false,
      data: [] as Array<Article>,
      pagination: null as null | $TODO
    },
    userArticleList: {
      fetched: false,
      fetching: false,
      data: [] as Array<Article>,
      pagination: null as null | $TODO
    }
  }),
  actions: {
    // 获取文章列表
    fetchList(params: any = {}) {
      const isRestart = !params.pageNum || params.pageNum === 1
      // const isLoadMore = !isRestart && params.page > 1

      // 清空已有数据
      if (isRestart) {
        this.list.data = []
        this.list.pagination = null
      }

      this.list.fetching = true
      const fetch = localhost.get<any>(`${ARTICLE_API_PATH}/api/list`, { params })
      const promise = isClient ? delayPromise(520, fetch) : fetch
      return promise.then((response) => {
            this.list.data.push(...response.data)
            this.list.pagination = response.pagination
        })
        .finally(() => {
          this.list.fetching = false
        })
    },

    // 获取最热文章列表
    fetchHottestList() {
      if (this.hotList.fetched) {
        return Promise.resolve()
      }

      this.hotList.fetching = true
      return localhost
        .get(`${ARTICLE_API_PATH}/api/hot/list`)
        .then((response) => {
          this.hotList.data = response.data
          this.hotList.fetched = true
        })
        .finally(() => {
          this.hotList.fetching = false
        })
    },

    // 获取用户文章列表
    fetchUserArticleList(params: any = {}){
      const isRestart = !params.pageNum || params.pageNum === 1
      // 清空已有数据
      if (isRestart) {
        this.userArticleList.data = []
        this.userArticleList.pagination = null
      }
      this.userArticleList.fetching = true
      const fetch = localhost.get(`${ARTICLE_API_PATH}/api/center/list`, { params })
      const promise = isClient ? delayPromise(520, fetch) : fetch
      return promise
        .then((response) => {
          this.userArticleList.data.push(...response.data)
          this.userArticleList.pagination = response.pagination
        })
        .finally(() => {
          this.userArticleList.fetching = false
        })
    }
  }
})

export interface ArticleHeading {
  text: string
  level: number
  id: string
}
const renderArticleMarkdown = (markdown: string): { html: string; headings: ArticleHeading[] } => {
  const headings: Array<ArticleHeading> = []
  const html = markdownToHTML(markdown, {
    sanitize: false,
    relink: false,
    headingIDRenderer: (html, level, raw) => {
      const id = getArticleContentHeadingElementID(
        level,
        raw.toLowerCase().replace(/[^a-zA-Z0-9\u4E00-\u9FA5]+/g, '-')
      )
      headings.push({ level, id, text: raw })
      return id
    }
  })

  return { html, headings }
}

export const useArticleDetailStore = defineStore('articleDetail', {
  state: () => ({
    fetching: false,
    article: null as null | Article,
    author: null as null,
    prevArticle: null as null | Article,
    nextArticle: null as null | Article,
    relatedArticles: [] as Article[],
    renderedFullContent: true
  }),
  getters: {
    contentLength(): number {
      return this.article?.content.length || 0
    },
    readMinutes(): number {
      const minutes = Math.round(this.contentLength / 400)
      return minutes < 1 ? 1 : minutes
    },
    isLongContent(): boolean {
      return Boolean(this.article && this.contentLength >= LONG_ARTICLE_THRESHOLD)
    },
    splitIndex(): number | null {
      if (!this.article || !this.isLongContent) {
        return null
      }

      const splitLength = Math.min(LONG_ARTICLE_THRESHOLD, Math.floor(this.contentLength / 2))
      const shortContent = this.article.content.substring(0, splitLength)
      // 坐标优先级：H5 > H4 > H3 > \n\n\n
      const lastH5Index = shortContent.lastIndexOf('\n##### ')
      const lastH4Index = shortContent.lastIndexOf('\n#### ')
      const lastH3Index = shortContent.lastIndexOf('\n### ')
      const lastLineIndex = shortContent.lastIndexOf('\n\n\n')
      const splitIndex = Math.max(lastH5Index, lastH4Index, lastH3Index, lastLineIndex)
      // console.log('-----content length', this.contentLength, index, splitIndex)
      return splitIndex
    },
    defaultContent(): null | { markdown: string; html: string; headings: ArticleHeading[] } {
      if (!this.article) {
        return null
      }
      const markdown = this.isLongContent
        ? this.article.content.substring(0, this.splitIndex!)
        : this.article.content
      const { html, headings } = renderArticleMarkdown(markdown)
      return {
        markdown,
        html,
        headings
      }
    },
    moreContent(): null | { markdown: string; html: string; headings: ArticleHeading[] } {
      if (this.article && this.isLongContent) {
        const markdown = this.article.content.substring(this.splitIndex!)
        const { html, headings } = renderArticleMarkdown(markdown)
        return {
          markdown,
          html,
          headings
        }
      }
      return null
    }
  },
  actions: {
    renderFullContent() {
      this.renderedFullContent = true
    },

    fetchArticleDetail(articleId: string) {
      this.article = null
      const fetch = localhost.get(`${ARTICLE_API_PATH}/api/detail/${articleId}`)
      const promise = isClient ? delayPromise(580, fetch) : fetch
      return promise.then((response) => {
        this.article = response.data
        this.fetchArticleAuthor(response.data.userId)
        this.renderedFullContent = !this.isLongContent
      })
    },

    fetchArticleContext(articleId: string) {
      this.prevArticle = null
      this.nextArticle = null
      this.relatedArticles = []
      return localhost.get(`${ARTICLE_API_PATH}/api/context/${articleId}`).then((response) => {
        this.prevArticle = response.data.prevArticle
        this.nextArticle = response.data.nextArticle
        this.relatedArticles = response.data.relateArticleList
      })
    },

    fetchCompleteArticle(params: { articleId: string }) {
      console.log(`articleId: ${params.articleId}`)
      this.fetching = true
      return Promise.all([
        this.fetchArticleDetail(params.articleId),
        this.fetchArticleContext(params.articleId)
      ]).finally(() => {
        this.fetching = false
      })
    },

    fetchArticleAuthor(userId: string|null) {
      this.author = null
      return localhost.get(`/blog/web/statistics/api/author/${userId}`).then((response) => {
        this.author = response.data
      })
    },

    getArticleDetail(articleId: string){
      return localhost.get(`${ARTICLE_API_PATH}/edit/${articleId}`)
    },

    submitArticle(article: Partial<any>) {
      return localhost.post<any>(`${ARTICLE_API_PATH}/submit`, article).then((response) => {
        // this.userBase.data = response.data
        Toast.success('保存成功！')
        return response.data
      }).catch((error) => {
        Toast.error(error)
      })
    },

    postArticleLike(articleId: string) {
      return localhost
        .post(`${ARTICLE_API_PATH}/like`, { resourceId: articleId })
        .then((response) => {
          if (this.article) {
            // console.log(response.data)
            this.article.isLike = response.data
            this.article.likeNum = response.data ? this.article.likeNum + 1 : this.article.likeNum - 1
            return response.data
          }
        })
    },

    postArticleCollect(articleId: string) {
      return localhost
        .post(`${ARTICLE_API_PATH}/collect`, { resourceId: articleId })
        .then((response) => {
          if (this.article) {
            // console.log(response.data)
            this.article.isCollect = response.data
            this.article.collectNum = response.data ? this.article.collectNum + 1 : this.article.collectNum - 1
            return response.data
          }
        })
    }
  }
})

export const useArticleCategoryStore = defineStore('articleCategory', {
  state: () => ({
    fetched: false,
    fetching: false,
    categoryList: [] as Array<Category>
  }),
  getters: {
    // sort by count
    sorted: (state) => {
      const categoryList = [...state.categoryList]
      categoryList.sort((a, b) => b.num - a.num)
      return categoryList
    },
    // 全量标签列表（本身、全小写、全大写、首字母大写）
    fullNameTags: (state): CategoryMap => {
      const categoryList = state.categoryList
      const categoryMap: CategoryMap = new Map()
      categoryList.forEach((category) => {
        categoryMap.set(category.name, category)
        categoryMap.set(category.name.toLowerCase(), category)
        categoryMap.set(category.name.toUpperCase(), category)
        categoryMap.set(firstUpperCase(category.name), category)
      })
      return categoryMap
    }
  },
  actions: {
    fetchAll() {
      if (this.fetched) {
        return Promise.resolve()
      }

      this.fetching = true
      return localhost
        .get(`${ARTICLE_API_PATH}/api/category/list`)
        .then((response) => {
          this.categoryList = response.data
          this.fetched = true
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }
})

export const useArticleTagStore = defineStore('articleTag', {
  state: () => ({
    fetched: false,
    fetching: false,
    tagList: [] as Array<Tag>
  }),
  getters: {
    //渲染标签样式
    tagData: (state) => {
      const tagList = [...state.tagList]
      tagList.map((val) => {
        return {
          ...val,
          value: val.id,
          textStyle:{
            color: 'rgb(' + [
              Math.round(Math.random() * 160),
              Math.round(Math.random() * 160),
              Math.round(Math.random() * 160)
            ].join(',') + ')',
            lineHeight: 24,
            borderRadius: 10,
            width: 100,
          },
          // 鼠标放上的效果
          emphasis: {
            textStyle: {
              color: 'black',
            },
          }
        }
      })
      return tagList
    },
  },

  actions: {
    fetchAll() {
      if (this.fetched) {
        return Promise.resolve()
      }

      this.fetching = true
      return localhost.get(`${ARTICLE_API_PATH}/api/tag/list`).then( res => {
        this.tagList = res.data
        this.fetching = false
      }).finally(() => {
        this.fetching = false
      })
    },
    fetchTag() {
      return localhost.get(`${ARTICLE_API_PATH}/api/tag/list`)
    }
  }
})
