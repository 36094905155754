/**
 * @file App exporter
 * @module effect.exporter
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { App } from 'vue'

declare global {
  interface Window {
    $app: App
  }
}

export const exportAppToGlobal = (app: App) => {
  window.$app = app
}
