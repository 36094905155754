<template>
  <video-player
    class="video-player vjs-big-play-centered"
    :src="src"
    :poster="poster"
    crossorigin="anonymous"
    playsinline
    controls
    :volume="0.6"
    :height="180"
    :playback-rates="[0.7, 1.0, 1.5, 2.0]"
    :children="[
      // custom Video.js children component
      // https://videojs.com/guides/options/#children
      'mediaLoader',
      'posterImage',
      'bigPlayButton',
      'loadingSpinner',
      'controlBar',
      'textTrackDisplay'
    ]"
    @mounted="handleMounted"
    @ready="handleEvent($event)"
    @play="handleEvent($event)"
    @pause="handleEvent($event)"
    @ended="handleEvent($event)"
    @loadeddata="handleEvent($event)"
    @waiting="handleEvent($event)"
    @playing="handleEvent($event)"
    @canplay="handleEvent($event)"
    @canplaythrough="handleEvent($event)"
    @timeupdate="handleEvent(player?.currentTime())"
  >
<!--    <template v-slot="{ player, state }">-->
<!--      <div class="custom-player-controls">-->
<!--        <button @click="state.playing ? player.pause() : player.play()">-->
<!--          {{ state.playing ? 'Pause' : 'Play' }}-->
<!--        </button>-->
<!--        <button @click="player.muted(!state.muted)">-->
<!--          {{ state.muted ? 'UnMute' : 'Mute' }}-->
<!--        </button>-->
<!--        &lt;!&ndash; more custom controls elements ... &ndash;&gt;-->
<!--      </div>-->
<!--    </template>-->
  </video-player>
</template>

<script lang="ts">
  import { defineComponent, shallowRef } from 'vue'
  import { VideoJsPlayer } from 'video.js'
  import { VideoPlayer } from '@videojs-player/vue'
  import 'video.js/dist/video-js.css'

  export default defineComponent({
    name: 'BasicVideo',
    props: {
      src: {
        // 资源
        type: String,
        default: '',
      },
      poster: {
        // 封面
        type: String,
        default: '',
      }
    },
    components: {
      VideoPlayer
    },
    setup() {
      const player = shallowRef<VideoJsPlayer>()
      const handleMounted = (payload: any) => {
        player.value = payload.player
        console.log('Basic player mounted', payload)
      }

      const handleEvent = (log: any) => {
        console.log('Basic player event', log)
      }

      return { player, handleMounted, handleEvent }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .video-player {
    background-color: $black;
    width: 100%;
  }

  ::v-deep .video-js .vjs-tech {
    object-fit: fill;
  }
  ::v-deep .vjs-poster {
    background-size: cover;
  }

  ::v-deep .vjs-big-play-button{
    font-size: 2em;
    line-height: 2.3em;
    height: 2.5em;
    width: 2.5em;
    -webkit-border-radius: 2.5em;
    -moz-border-radius: 2.5em;
    border-radius: 2.5em;
    background-color: #73859f;
    background-color: rgba(115,133,159,.5);
    margin-top: -1.25em;
    margin-left: -1.75em;
  }
  /* 中间的播放箭头 */
  ::v-deep .vjs-big-play-button .vjs-icon-placeholder {
    font-size: 1.63em;
  }
  /* 加载圆圈 */
  ::v-deep .vjs-loading-spinner {
    font-size: 2.5em;
    height: 2em;
    border-radius: 1em;
    margin-top: -1em;
    margin-left: -1.5em;
  }
</style>
