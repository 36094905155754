<template>
  <div class="write-page">
    <form ref="formElement" class="write-form">
      <div class="col-left">
        <div class="module">
          <div class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>标题</span>
              <input
                id="title"
                class="item-input"
                required
                type="text"
                name="search"
                maxlength="50"
                v-model.trim="form.title"
                placeholder="标题"
              />
            </div>
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>是否原创</span>
              <select class="select" :value="form.original" @change="handleOriginState($event.target)">
                <option :value="OriginState.Original">原创</option>
                <option :value="OriginState.Reprint">转载</option>
                <option :value="OriginState.Hybrid">衍生</option>
              </select>
            </div>

          </div>
          <div v-if="form.original != OriginState.Original" class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>文章出处</span>
              <input
                id="articleSource"
                class="item-input"
                required
                type="text"
                v-model.trim="form.articleSource"
                placeholder="来源"
              />
            </div>
          </div>
          <div class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>文章摘要</span>
              <textarea
                class="textarea"
                name="description"
                id="description"
                rows="2"
                v-model.trim="form.description"
                placeholder="文章摘要"
              ></textarea>
            </div>
          </div>
          <div class="line">
            <div class="form-item">
              <span class="label"><span style="color: red">*</span>标签</span>
              <div class="tags">
                <div v-for="(item, index) in articleTagStore.tagList" class="tag" :class="form.tags?.includes(item.id) ? 'selected' : ''" @click="selectTag(item)">
                  <i class="thirdiconfont el-icon-third-tag-i"></i>
                  {{ item.name}}
                </div>
              </div>

            </div>
          </div>
          <MdEditor
            v-model:modelValue="form.content"
            @onUploadImg="onUploadImg"
            :theme="isDarkTheme ? 'dark' : 'light'"
            class="md"
          ></MdEditor>
        </div>
      </div>
      <div class="col-right">
        <div class="module">
          <div class="header">
            <span class="title">文章分类</span>
          </div>
          <div class="body">
            <div class="categories">
              <div class="category" v-for="(item, index) in articleCategoryStore.categoryList"><label><input name="category" v-model="form.categoryId" :value="item.id" type="radio" />{{item.name}}</label></div>
            </div>
          </div>
        </div>
        <div class="module">
          <div class="header">
            <upload
              ref="upload"
              style="display: none"
              type="jpg,jpeg,png"
              :bulk="2"
              :multiple = true
              @data="res"
              :module-type="1"
              v-model:loading="loading"
            />
            <span class="title">封面上传</span>
          </div>
          <div class="body">
            <button type="button" :disabled="loading" :style="{ 'backgroundImage': `url(${form.cover})`, 'background-repeat': 'no-repeat','background-size': '100% 100%','opacity': '0.9'}" class="upload" @click="selectImage">
              <div v-if="loading" class="loading">
                <load :loading = "loading" />
              </div>
              <i class="thirdiconfont el-icon-third-share-upload"></i>
              <span>上传</span>
            </button>
            <div class="url">
              <button class="search-btn">
                <i class="iconfont icon-link" />
              </button>
              <input
                class="search-input"
                type="search"
                name="cover"
                :value="form.cover"
                placeholder="可直接输入地址"
              />
            </div>
          </div>
        </div>
        <div class="module">
          <div class="header">
            <span class="title">发布选项</span>
          </div>
          <div class="body">
<!--            <div class="line">-->
<!--              <div class="form-item">-->
<!--                <span class="label"><span style="color: red">*</span>发布状态</span>-->
<!--                <select :value="form.publish" @change="handlePublishState($event.target)" class="select">-->
<!--                  <option :value="PublishState.Publish">发布</option>-->
<!--                  <option :value="PublishState.Draft">存稿</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
            <div class="line">
              <div class="form-item">
                <span class="label"><span style="color: red">*</span>开启评论</span>
                <input class="item-input"
                       required
                       type="radio"
                       name="isComment"
                       :value="CommentState.Open"
                       v-model.number="form.isComment"
                /><label>允许</label>
                <input class="item-input"
                       required
                       type="radio"
                       name="isComment"
                       :value="CommentState.Close"
                       v-model.trim="form.isComment"
                /><label>禁止</label>
              </div>
            </div>
            <div class="line">
              <div class="form-item">
                <button type="submit" class="submit" @click="handleSubmit">
                  <i class="iconfont icon-success"></i>
                  提交
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>


  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, reactive, ref, toRaw } from 'vue'
  import { useEnhancer } from "/@/app/enhancer"
  import {useArticleDetailStore, useArticleCategoryStore, ARTICLE_API_PATH} from "/@/stores/article"
  import { useArticleTagStore } from "/@/stores/article"
  import { META } from "/@/config/app.config"
  import { LanguageKey } from "/@/language"
  import { OriginState, CommentState, PublishState } from '/@/constants/state'
  import MdEditor from 'md-editor-v3';
  import 'md-editor-v3/lib/style.css';
  import Upload from "/@/components/common/upload.vue"
  import Load from "/@/components/common/load.vue"
  import localhost from "/@/services/localhost";

  export default defineComponent({
    name: 'WritePage',
    components: {
      Load,
      Upload,
      MdEditor,
    },
    props: {
      articleId: {
        type: String,
        required: false
      }
    },
    setup(props) {
      const { meta, i18n, isDarkTheme, router, globalState } = useEnhancer()
      const articleDetailStore = useArticleDetailStore()
      const articleTagStore = useArticleTagStore()
      const articleCategoryStore = useArticleCategoryStore()
      const form = reactive({
        id: null,
        title: '',
        cover:'',
        content: '',
        tags:[],
        categoryId: null,
        original: OriginState.Original,
        articleSource: '',
        source: '',
        description: '',
        isComment: CommentState.Open,
        publish: PublishState.Publish,
        agent: globalState.userAgent.original
      })
      const formElement = ref<HTMLFormElement>()
      const td = ref([])
      const loading  = ref(false);
      const defaultOriginal = ref(OriginState.Original)

      meta(() => ({
        title: `${i18n.t(LanguageKey.PAGE_WRITE)} - ${META.title}`,
        description: `${META.description}`,
        keywords: `${i18n.t(LanguageKey.PAGE_WRITE)},${META.keywords}`
      }))

      const selectTag = (item: any) => {
        if(form.tags.includes(item.id)){
          form.tags = form.tags.filter(e => e !== item.id);
        }else{
          form.tags.push(item.id)
        }
        console.log(`tags:${form.tags}`)
      }
      const handleOriginState = (target: any) => {
        const value = Number(target?.value)
        form.original = value
      }
      const handlePublishState = (target: any) => {
        const value = Number(target?.value)
        form.publish = value
      }
      const handleSubmit = async (event) => {
        event.preventDefault()
        const check_status = formElement.value?.checkValidity()
        if (!check_status) {
          formElement.value?.reportValidity()
          return
        }
        try {
          const res = await articleDetailStore.submitArticle(toRaw(form))
          if(res){
            Object.assign(form, res)
            // cleanForm()
            // router.push({
            //   path: '/'
            // })
          }else{

          }

        }catch (error: any) {
          console.warn('submit article failed:', error)
          throw error.message
        }

      }
      const cleanForm = () => {
        form.title = '',
        form.cover = '',
        form.content = '',
        form.tags = [],
        form.categoryId = null,
        form.original = OriginState.Original,
        form.articleSource = '',
        form.source = '',
        form.description = '',
        form.isComment = CommentState.Open,
        form.publish = PublishState.Publish,
        form.agent = globalState.userAgent.original
      }
      // const defaultValue = ref('');
      const upload = ref(null);
      const text = ref('')
      const selectImage = () => {
        upload.value.inputClick();
      }

      const res = (data: object) =>{
        form.cover = data?.data[0].filePath
      }

      const onUploadImg = async (files: FileList, callback: (urls: string[]) => void) => {
        let images = []
        const res = await Promise.all(
          Array.from(files).map((file) => {
            console.log(file)
            return new Promise((rev, rej) => {
              const data = new FormData();
              data.append('files', file);
              data.append("source", false);
              data.append("moduleType", '0');
              localhost.post<any>(`/blog/file/uploadBatch`, data).then((response) => {
                // this.userBase.data = response.data
                rev(response.data[0])
              }).catch((error) => {

              })

            });
          })
        )
        //上传图片事件，弹窗会等待上传结果，务必将上传后的 urls 作为 callback 入参回传
        callback(res.map((item: any) => item.filePath));
      }

      //编辑获取详情
      onMounted(() => {
        if(props.articleId){
          articleDetailStore.getArticleDetail(props.articleId).then( res => {
            Object.assign(form, res.data)
          })
        }
      })

      // useUniversalFetch(() => articleTagStore.fe(props.articleId))
      return {
        // defaultValue
        formElement,
        text,
        form,
        selectTag,
        td,
        OriginState,
        CommentState,
        PublishState,
        defaultOriginal,
        articleTagStore,
        articleCategoryStore,
        handleOriginState,
        handlePublishState,
        loading,
        upload,
        onUploadImg,
        selectImage,
        res,
        handleSubmit,
        isDarkTheme
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  $page-width: $container-width + 200;
  .write-page {
    position: relative;

    width: $page-width;
    margin: 0 auto;
    padding-top: 2.1rem;
    padding-bottom: 3.1rem;
    .write-form{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .col-left{
        flex: 1;
        width:  $page-width - $aside-width - 15px;
        .module{
          padding: $lg-gap;
          @include radius-box($sm-radius);
          @include common-bg-module();


        }
      }
      .col-right{
        width: $aside-width;
        margin-left: $lg-gap;
        .module{
          @include radius-box($sm-radius);
          @include common-bg-module();
          margin-bottom: $lg-gap;
          .header{
            display: flex;
            justify-content: space-between;
            height: 3em;
            line-height: 3em;
            margin: 0;
            padding: 0 $gap;
            border-bottom: 1px solid $text-divider;
          }
          .body{
            display: flex;
            //justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: $xs-gap;
            position: relative;
            .categories{
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              //grid-gap: $xs-gap / 2;
              .category{
                display: flex;
                justify-content: start;
                align-items: center;
                padding: $xs-gap $xs-gap;
                border-radius: $sm-radius;
                &:hover{
                  background-color: $module-bg-hover;
                }
                label{
                  cursor: pointer;
                }
                input{
                  margin-right: $sm-gap;

                }
              }
            }
            .loading{
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              top: 0;
              width: 100%;
              overflow-y: auto;
              background-color: $module-bg-lighter;
            }
            .upload{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              /*cursor: pointer;*/
              width: 100%;
              padding: $lg-gap;
              margin-top: $lg-gap;
              @include radius-box($sm-radius);
              @include background-transition();
              border: 1px dotted $text-divider;
              &:focus,
              &:hover {
                border: 1px dotted $primary;
              }
            }
            .url{
              //padding: $sm-gap;
              width: 100%;
              height: 3em;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              .search-input,
              .search-btn{
                height: 2em;
                line-height: 2em;
                background-color: $module-bg-darker-1;
                @include background-transition();
                &:hover {
                  background-color: $module-bg-hover;
                }

              }
              .search-input {
                flex: 1;
                margin-right: 0;
                padding: 0;
                text-indent: 0.5em;
                &::-webkit-calendar-picker-indicator {
                  display: none;
                }
              }

              .search-btn {
                width: 3em;
                background-color: $module-bg-hover;
                @include background-transition();
                &:hover {
                  background-color: $module-bg-darker-3;
                }
              }
            }
          }
        }
      }
    }

  }

  //markdown style
  //::v-deep{
  //  .md{
  //    width: 1000px!important;
  //  }
  //}
  .line{
    display: flex;
    //justify-content: space-between;
    align-items: center;
    width: 100%;
    .form-item{
      display: flex;
      align-items: center;
      flex: 1;
      margin-bottom: $lg-gap;
      padding: 0 $lg-gap;
      /*justify-content: space-between;*/
      .label{
        display: flex;
        justify-content: right;
        align-items: center;
        margin-right: $lg-gap;
        width: 80px;
      }
      label{
        display: flex;
        align-items: center;
        margin: 0 10px 0px 5px;
        cursor: pointer;
      }
      textarea,
      input[type='text'] {
        /*width: 80%;*/
        flex: 1;
        //height: 2em;
        line-height: 2em;
        background-color: $module-bg-darker-1;
        @include radius-box($sm-radius);
        @include background-transition();
        border: 1px solid $text-divider;
        resize:none;
        width: 11em;
        margin-right: 0;
        padding: 0;
        text-indent: 0.5em;
        &::-webkit-calendar-picker-indicator {
          display: none;
        }
        &:focus,
        &:hover {
          background-color: $module-bg-hover;
          border: 1px solid $primary;
        }
      }
      .select{
        flex: 1;
        background-color: $module-bg-darker-1;
        @include radius-box($sm-radius);
        @include background-transition();
        border: 1px solid $text-divider;
      }
      .item-input{
        height: 2em;
        line-height: 2em;
        background-color: $module-bg-darker-1;
        @include background-transition();
        &:hover {
          background-color: $module-bg-hover;
        }
      }
      .tags{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 10px 5px;
        flex: 1;
        .tag{
          display: flex;
          align-items: center;
          padding: 0 $xs-gap;
          border: 1px solid $text-divider;
          cursor: pointer;
          margin-right: $lg-gap;
          &:hover {
            border-color: $primary;
            color: $primary;
          }
          &.selected{
            border-color: $primary;
            color: $primary;
          }
          @include radius-box($xs-radius);
          .thirdiconfont,
          .iconfont {
            font-size: 1.5rem;
          }
        }
      }
      .submit{
        $height: 2.4em;
        display: block;
        width: 100%;
        margin-top: $lg-gap;
        line-height: $height;
        border: 1px solid;
        border-color: $primary;
        font-size: $font-size-small;
        color: $primary;
        text-align: center;
        letter-spacing: 1px;
        transition: color $transition-time-fast, background-color $transition-time-fast;
        @include radius-box($xs-radius);

        &:hover {
          color: $text-reversal;
          background-color: $primary;
        }
      }

    }
  }
</style>
