<template>
  <div class="issue bordered" v-if="isLogin" >
    <div class="markdown">
      <!--   内容框   -->
      <div
        ref="inputElement"
        class="markdown-input"
        :contenteditable="!disabled"
        :placeholder="t(LanguageKey.COMMENT_POST_PLACEHOLDER)"
        @focus="disableCopyrighter"
        @blur="enableCopyrighter"
      />

      <!--   图片及骨头   -->
      <div class="markdown-other">
        <div class="other-pic">
          <div class="pic" v-for="(item,index) in pictures" :key="index">
            <div class="picBox">
              <uimage class="img" :src="item.filePath" alt="" @click="viewImg(item.filePath)"  />
              <span class="img-del" @click="deleteItem(index)"></span>
            </div>
          </div>
          <div class="pic">
            <div class="videoBox" v-if="video.src">
              <i class="iconfont icon-video video" />
              <span class="video-del" @click="deleteVideo()"></span>
            </div>
          </div>

        </div>

        <div class="other-show">
          <div class="show-bone"><span v-if="boneId"><i class="thirdiconfont el-icon-third-yu-3"></i> {{boneStore.getBoneById(boneId).name}}</span></div>
          <div class="show-count">{{2000 - content.length}}</div>
        </div>
      </div>
      <!--   预览   -->
      <transition name="list-fade">
        <div class="preview-content" v-if="isPreviewed">
          <markdown :markdown="content" :sanitize="true" :compact="true" />
        </div>
      </transition>
    </div>
    <div class="issue-box">
      <div class="stationery">
<!--        <ulink class="markdown" title="markdown" :href="VALUABLE_LINKS.MARKDOWN">-->
<!--          <i class="iconfont icon-markdown" />-->
<!--        </ulink>-->
        <template v-if="!hiddenStationery">
          <button class="bone" title="bone" :disabled="disabled || isPreviewed">
            <i class="thirdiconfont el-icon-third-yu"></i>
            <div class="bone-box">
              <div class="panel-center">
                <div class="panel-center-up">
                  <template v-for="(item, index) in boneStore.boneMap" :key="index">
                    <button class="item" :class="[item.categoryId, { active: activeCid === index }]" :title="item.categoryName" @click="handleSwitch(index, item)">
                      <span class="title">{{ item.categoryName }}</span>
                    </button>
                  </template>
                </div>
                <div class="panel-center-down">
                  <template v-for="(item, index) in boneStore.boneMap[activeCid]?.boneList" :key="index">
                    <div class="item" @click="selectBone(item)">
                      <uimage class="icon" :src="item.icon" />
                      <div class="info">
                        <span>{{item.name}}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </button>


          <button
            class="link"
            title="link"
            :disabled="disabled || isPreviewed"
            @click.prevent="insertLink"
          >
            <i class="iconfont icon-link" />
          </button>
<!--          <button-->
<!--            class="code"-->
<!--            title="code"-->
<!--            :disabled="disabled || isPreviewed"-->
<!--            @click.prevent="insertCode"-->
<!--          >-->
<!--            <i class="iconfont icon-code" />-->
<!--          </button>-->
          <upload
            ref="upload"
            style="display: none"
            type="jpg,jpeg,png"
            :bulk="20"
            :multiple = true
            :module-type="2"
            @data="res"
            v-model:loading="loading"
          />
          <button
            class="image"
            title="image"
            :disabled="disabled || isPreviewed || loading || pictures.length >= 9 || video.src"
            @click.prevent="insertImage"
          >
            <i class="iconfont icon-image" />

          </button>
          <div v-if="loading" class="image-box">
            <load :loading="loading" />
          </div>
          <upload
            ref="uploadVideo"
            style="display: none"
            acceptType="video/*"
            :bulk="30"
            :multiple = false
            :module-type="2"
            @data="resVideo"
            v-model:loading="loading"
          />
          <button
            class="video"
            title="video"
            :disabled="disabled || isPreviewed || loading || pictures.length > 0"
            @click.prevent="insertVideo"
          >
            <i class="iconfont icon-video-play" />

          </button>
          <button class="emoji" title="emoji" type="button" :disabled="disabled || isPreviewed">
            <i class="iconfont icon-emoji" />
            <div class="emoji-box">
              <ul class="emoji-list">
                <li
                  v-for="(emoji, index) in EMOJIS"
                  v-once
                  :key="index"
                  class="item"
                  @click="insertEmoji(emoji)"
                >
                  {{ emoji }}
                </li>
              </ul>
            </div>
          </button>
<!--          <button-->
<!--            class="preview"-->
<!--            title="preview"-->
<!--            :class="{ actived: isPreviewed }"-->
<!--            :disabled="disabled"-->
<!--            @click.prevent="handleTogglePreview"-->
<!--          >-->
<!--            <i class="iconfont" :class="isPreviewed ? 'icon-eye-close' : 'icon-eye'" />-->
<!--          </button>-->
        </template>
      </div>
      <button type="submit" class="submit" :disabled="disabled || loading" @click="handleSubmit">
        <i18n zh="发布中..." en="Posting..." v-if="posting" />
        <i18n zh="发布" en="Publish" v-else />
        <i class="iconfont icon-mail-plane"></i>
      </button>
    </div>
  </div>
  <div class="login-prompt" v-else>
    登入才可以发布圈文哦<button v-if="!isMobile" class="loginBtn" type="button" @click="handleLogin">登入</button>
  </div>
</template>

<script lang="ts">
  import {defineComponent, ref, watch, onMounted, onBeforeUnmount, reactive, nextTick, computed, toRefs} from 'vue'
  import { VALUABLE_LINKS } from '/@/config/app.config'
  import { LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { enableCopyrighter, disableCopyrighter } from '/@/effects/copyright'
  import { focusPosition } from '/@/utils/editable'
  import { insertContent } from '/@/utils/editable'
  import { CommentEvents, EMOJIS } from '/@/components/comment/helper'
  import Markdown from '/@/components/common/markdown.vue'
  import { useBoneStore } from "/@/stores/saunter";
  import {useUniversalFetch} from "/@/universal";
  import Upload from "/@/components/common/upload.vue"
  import  Load from  '/@/components/common/load.vue'

export enum PenEvents {
  Update = 'update:content',
  UpdatePreviewed = 'update:previewed',
  UpdatePictures = 'update:pictures',
  UpdateVideo = 'update:video',
  UpdateBoneId = 'update:boneId'
}

export default defineComponent({
  name: 'CommentIssue',
  components: {
    Markdown,
    Upload,
    Load
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      required: false
    },
    boneId: {
      type: String,
      required: false
    },
    topicId: {
      type: String,
      required: false
    },
    pictures: {
      type: Array,
      required: false
    },
    video: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    previewed: {
      type: Boolean,
      default: false
    },
    posting: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      required: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    hiddenStationery: {
      type: Boolean,
      default: false
    }
  },
  emits: [PenEvents.Update, PenEvents.UpdatePreviewed, PenEvents.UpdatePictures, PenEvents.UpdateBoneId, CommentEvents.Submit],
  setup(props, context) {
    const { i18n, globalState } = useEnhancer()
    const boneStore = useBoneStore()
    const content = ref(props.content)
    const pictures = ref(props.pictures)
    const video = ref(props.video)
    const topicId = ref(props.topicId)
    const boneId = ref(props.boneId)
    const isPreviewed = ref(props.previewed || false)
    const inputElement = ref<HTMLElement>()
    console.log(`${JSON.stringify(inputElement)}`)
    let inputElementObserver: MutationObserver | null = null
    const activeCid = ref(0)
    const loading = ref(false)
    // const bone  = reactive({
    //   id: null,
    //   name: null
    // })
    const upload = ref(null);
    const uploadVideo = ref(null);

    const handleSwitch = (id: number, item: any) => {
      activeCid.value = id
      // boneStore.boneMap[id].boneList
    }
    const selectBone = (item: any) => {
      boneId.value = item.id
      context.emit(PenEvents.UpdateBoneId, item.id)
    }
    const handleTogglePreview = () => {
      isPreviewed.value = !isPreviewed.value
      context.emit(PenEvents.UpdatePreviewed, isPreviewed.value)
    }

    const handleInputChange = () => {
      const text = inputElement.value?.innerText as string
      if (text !== content.value) {
        content.value = text
        context.emit(PenEvents.Update, text)
      }
    }

    const handleSubmit = (event) => {
      event.preventDefault()
      const data = {
        pictures: pictures.value,
        video: video.value,
        boneId: boneId.value,
        content: content.value,
        topicId: topicId.value,
        agent: globalState.userAgent.original
      }
      context.emit(CommentEvents.Submit, data)
    }

    const insertContentToInput = (before: string, after = '') => {
      insertContent({
        element: inputElement.value,
        content: [before, after]
      })
      handleInputChange()
    }

    const insertEmoji = (emoji: any) => {
      insertContentToInput(` ${emoji} `)
    }
    const insertImage = () => {
      console.log("upload")
      upload.value.inputClick();
    }

    const insertVideo = () => {
      console.log("uploadVideo")
      uploadVideo.value.inputClick();
    }
    const insertLink = () => {
      insertContentToInput(` [`, `](https://) `)
    }
    const insertCode = () => {
      insertContentToInput('\n```javascript\n', '\n```\n')
    }
    const viewImg = (url: string) => {
      window.$popup.vImage(url)
    }

    watch(
      () => props.content,
      (value = '') => {
        if (value !== content.value) {
          content.value = value
          if (inputElement.value) {
            inputElement.value.innerText = value
          }
        }
      }
    )

    watch(
      () => props.previewed,
      (_preview) => {
        if (_preview !== isPreviewed.value) {
          isPreviewed.value = _preview
        }
      }
    )

    watch(
      () => props.pictures,
      (_pictures) => {
        if (_pictures !== pictures.value) {
          pictures.value = _pictures
        }
      }
    )

    watch(
      () => props.video,
      (_video) => {
        if (_video !== video.value) {
          video.value = _video
        }
      }
    )

    watch(
      () => props.boneId,
      (_boneId) => {
        if (_boneId !== boneId.value) {
          boneId.value = _boneId
        }
      }
    )


    onMounted(() => {
      if(props.isLogin){
        // auto focus
        if (props.autoFocus) {
          nextTick().then(() => {
            if (inputElement.value) {
              inputElement.value.innerText = content.value
              //修改方便回显及光标定位
              focusPosition(inputElement.value, inputElement.value.innerText.length)
              console.log(`inputElement.value:${inputElement.value.innerText}`)
            }
          })
        }
        // watch element
        inputElementObserver = new MutationObserver(() => handleInputChange())
        inputElementObserver.observe(inputElement.value!, {
          attributes: true,
          characterData: true,
          childList: true,
          subtree: true
        })
      }

    })
    onBeforeUnmount(() => {
      inputElementObserver?.disconnect()
    })
    const res = (data: any) =>{
      pictures.value.push(...data.data)
      pictures.value = pictures.value.slice(0, pictures.value.length >= 9 ? 9 : pictures.value.length)
      context.emit(PenEvents.UpdatePictures, pictures.value)
      // console.log(`${files}`)
    }

    const resVideo = (data: any) =>{
      console.log(`data:${data}`)
      video.value.src = data.data[0]?.filePath
      video.value.poster = data.data[0]?.filePath + '?x-oss-process=video/snapshot,t_7000,f_jpg,w_400,h_200,m_fast'
      video.value.source = 0
      context.emit(PenEvents.UpdateVideo, video)
      // console.log(`${files}`)
    }

    const deleteItem = (i) => {
      pictures.value.splice(i,1)
      context.emit(PenEvents.UpdatePictures, pictures.value)
    }

    const deleteVideo = (i) => {
      video.value.src = null
      video.value.poster = null
      context.emit(PenEvents.UpdateVideo, video.value)
    }

    const handleLogin = () => {
      globalState.toggleSwitcher('login', true)
    }

    useUniversalFetch(() => {
      return Promise.all([
        boneStore.fetchBoneList()
      ])
    })

    return {
      t: i18n.t,
      EMOJIS,
      VALUABLE_LINKS,
      LanguageKey,
      inputElement,
      content,
      pictures,
      video,
      topicId,
      boneId,
      isPreviewed,
      insertEmoji,
      insertImage,
      insertVideo,
      insertLink,
      insertCode,
      handleInputChange,
      handleTogglePreview,
      handleSubmit,
      enableCopyrighter,
      disableCopyrighter,
      boneStore,
      handleSwitch,
      selectBone,
      // boneList,
      activeCid,
      upload,
      uploadVideo,
      res,
      resVideo,
      loading,
      deleteItem,
      deleteVideo,
      viewImg,
      handleLogin
    }
  }
})
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.login-prompt{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $module-bg-darker-3;
  margin-bottom: $lg-gap;
  padding: $lg-gap;
  font-size: 14px;
  opacity: .8;
  text-align: center;
  .loginBtn{
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin-left: $sm-gap;
    color: #919191;
    &:hover {
      font-weight: bold;
      border-bottom-color: $text-secondary;
    }
  }
}
.markdown-input{

}
.issue {
  position: relative;
  @include radius-box($xs-radius);
  &.bordered {
    border: 1px solid $module-bg-darker-3;
    .issue-box {
      border-top: 1px solid $module-bg-darker-3;
    }
  }



  .markdown {
    position: relative;
    overflow: hidden;

    .markdown-input {
      min-height: 6em;
      max-height: 36em;
      overflow: auto;
      outline: none;
      padding: 0.5em;
      cursor: auto;
      font-size: $font-size-h6;
      line-height: 1.8em;
      background-color: $module-bg-darker-1;
      @include background-transition();

      &:empty:before {
        content: attr(placeholder);
        color: $text-disabled;
      }
      &:focus {
        content: none;
      }
      &:hover{
        background-color: $module-bg-hover;
        //background-color: $module-bg-darker-1;
        +.markdown-other{
          background-color: $module-bg-hover;
        }
      }
    }

    .preview-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      margin: 0;
      padding: 0.5em;
      background-color: $module-bg-darker-1;
    }

    .markdown-other{
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: $module-bg-darker-1;
      //@include background-transition();
      .other-pic{
        $img-h-w: 60px;
        display: flex;
        margin-top: 10px;
        padding: 0 $sm-gap;
        flex-flow: wrap;
        .pic {
          display: flex;
          align-items: center;
          margin-right: 2px;
          .videoBox,
          .picBox {
            width: $img-h-w;
            height: $img-h-w;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-top: 2px;
            i {
              font-size: 3em;
            }
          }
          .video,
          .img {
            max-height: $img-h-w;
            max-width: $img-h-w;
            border-radius: 2px;
            width: $img-h-w;
            height: $img-h-w;

            backgroundPosition: '50% 40%';
            backgroundSize: cover;
            //background-repeat: no-repeat;
            //background-size: cover;
            //flex: 0 0 auto;
            position: relative;
            cursor: pointer;
          }
          .video-del,
          .img-del{
            cursor: pointer;
            width: 18px;
            height: 18px;
            position: absolute;
            top: .98px;
            right: 2px;
            border-radius: 50%;
            border: 1px solid #c5c5c5;
            background: rgba(0,0,0,.4);
            transform: rotate(
                45deg
            );
            &:before{
              content: "";
              width: .8rem;
              height: 1px;
              display: block;
              position: absolute;
              background: #fff;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
            }
            &:after{
              content: "";
              width: .8rem;
              height: 1px;
              display: block;
              position: absolute;
              background: #fff;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%) rotate(90deg);
            }
          }
        }
      }
      .other-show{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em;
        .show-bone{
          i{
            font-size: $font-size-root;
          }
          span{
            padding: $xs-gap;
            font-size: $font-size-root;
            background-color: $text-secondary;
            border-radius: 50px;
            border-bottom-left-radius: 0;
            opacity: 0.6;
          }
        }
        .show-count{

        }
      }
    }
  }

  .issue-box {
    $size: 30px;
    height: $size;
    line-height: $size;
    display: flex;
    justify-content: space-between;
    background-color: $module-bg-darker-2;

    .stationery {
      display: flex;

      .bone,
      .emoji,
      .image,
      .link,
      .code,
      .video,
      .preview {
        width: $size;
        height: $size;
        text-align: center;
        /*display: block;*/
        @include background-transition();

        &[disabled] {
          opacity: 0.7;
        }

        &:not([disabled]) {
          &.actived,
          &:hover {
            background-color: $module-bg-darker-4;
          }
        }
      }
      .bone{
        i{
          font-size: $font-size-h2;
        }
        &:not([disabled]):hover {
          .bone-box {
            display: block;
          }
        }

        .bone-box {
          display: none;
          position: absolute;
          bottom: $size;
          left: 0;
          top: 0;
          width: 100%;
          overflow-y: auto;
          background-color: $module-bg-lighter;

          .panel-center{
            display: flex;
            flex-direction: column;
            align-items: center;
            .panel-center-up{
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              line-height: 1em;
              //text-align: center;
              background-color: $module-bg-lighter;
              border-bottom: 1px dashed $module-bg-darker-1;
              .item {
                height: 2rem;
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                .title {
                  font-weight: bold;
                  margin: 0 $gap;
                  padding: 3px 0;
                  color: $text-secondary;
                  transition: color $transition-time-fast;
                  border-bottom: 2px solid transparent;
                }
                &.active,
                &:hover {
                  .title {
                    color: $link-color;
                    border-color: $link-color;
                  }
                }
              }
            }
            .panel-center-down{
              display: grid;
              grid-gap: $xs-gap;
              grid-template-columns: repeat(6, 1fr);
              padding: $sm-gap;
              width: 100%;
              .item{
                $img-size: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: $xs-gap $xs-gap;
                border-radius: $sm-radius;
                @include background-transition();
                &:hover {
                  background-color: $module-bg-hover;
                }
                .icon{
                  width: $img-size;
                  height: $img-size;
                  border-radius: 5px;
                  margin-right: 5px;
                }
              }
            }

          }
          .panel-footer{

          }
        }
      }
      .image{

      }

      .markdown {
        width: 4rem;
        text-align: center;
        background-color: $module-bg-darker-3;
      }
      .image-box {
        display: block;
        position: absolute;
        bottom: $size;
        left: 0;
        top: 0;
        width: 100%;
        overflow-y: auto;
        background-color: $module-bg-lighter;
      }

      .emoji {
        &:not([disabled]):hover {
          .emoji-box {
            display: block;
          }
        }

        .emoji-box {
          display: none;
          position: absolute;
          bottom: $size;
          left: 0;
          top: 0;
          width: 100%;
          overflow-y: auto;
          background-color: $module-bg;

          .emoji-list {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: $font-size-h3;
            display: grid;
            grid-template-columns: repeat(auto-fill, 45px);

            .item {
              cursor: pointer;
              padding: $xs-gap 0;
              font-size: $font-size-h2;
              border-radius: $sm-radius;
              @include background-transition();
              &:hover {
                background-color: $module-bg-hover;
              }
            }
          }
        }
      }
    }

    .submit {
      min-width: 8rem;
      height: $size;
      padding: 0 $gap;
      font-weight: bold;
      font-size: $font-size-small;
      color: $text-disabled;
      background-color: $module-bg-darker-3;
      @include background-transition();
      &:hover {
        color: $text-secondary;
        background-color: $module-bg-darker-4;
      }

      .iconfont {
        margin-left: $sm-gap;
      }
    }
  }
}
</style>
