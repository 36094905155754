/**
 * @file Tags state
 * @module store.tag
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { firstUpperCase } from '/@/transforms/text'
import { UniversalKeyValue } from '/@/constants/state'
import nodepress from '/@/services/nodepress'

export type TagMap = Map<string, Tag>
export interface Tag {
  id: string
  name: string
  articlesCount: number
}

export const tagEnName = (tag: Tag) => {
  // english name
  if (!/.*[\u4e00-\u9fa5]+.*$/.test(tag.name)) {
    return tag.name
  }
  // english words
  // if (tag.slug.includes('-')) {
  //   return tag.slug
  // }
  // english word
  return firstUpperCase(tag.name)
}

export const useTagStore = defineStore('tag', {
  state: () => ({
    fetched: false,
    fetching: false,
    tags: [] as Array<Tag>
  }),
  getters: {
    // sort by count
    sorted: (state) => {
      const tags = [...state.tags]
      tags.sort((a, b) => b.articlesCount - a.articlesCount)
      return tags
    },
    // 全量标签列表（本身、全小写、全大写、首字母大写）
    fullNameTags: (state): TagMap => {
      const tags = state.tags
      const tagMap: TagMap = new Map()
      tags.forEach((tag) => {
        tagMap.set(tag.name, tag)
        tagMap.set(tag.name.toLowerCase(), tag)
        tagMap.set(tag.name.toUpperCase(), tag)
        tagMap.set(firstUpperCase(tag.name), tag)
      })
      return tagMap
    }
  },
  actions: {
    fetchAll() {
      if (this.fetched) {
        return Promise.resolve()
      }

      this.fetching = true
      return nodepress
        .get('/tag/all')
        .then((response) => {
          this.tags = response.result
          this.fetched = true
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }
})
