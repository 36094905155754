/**
 * @file Lens state
 * @module store.lens
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import { defineStore } from 'pinia'
import { isClient } from '/@/app/environment'
import { TunnelModule } from '/@/constants/tunnel'
import type { InstagramMediaItem } from '/@/server/getters/instagram'
import { delayPromise } from '/@/utils/delayer'
import tunnel from '/@/services/tunnel'
import {OriginState, UniversalKeyValue} from "@/constants/state";
import {Tag} from "@/stores/tag";
import {Category} from "@/stores/category";
import {Language} from "@/language";
import localhost from '/@/services/localhost'
import {ARTICLE_API_PATH} from "@/stores/article";
import nodepress from "@/services/nodepress";
import {COMMENT_EMPTY_PID, CommentTreeItem} from "@/stores/comment";

export interface Rule {
  id: string
  action: string
  actionName: string
  expValue: number
  description: number
}

export const useRuleStore = defineStore('rule', {
  state: () => ({
    ruleList: {
      fetching: false,
      data: [] as Array<Rule>
    },
  }),

  //获取各个导航
  getters: {

  },
  actions: {
    fetchRuleList() {
      this.ruleList.fetching = true
      const fetch = localhost.get<any>('/blog/web/exp/api/rule/list').then((response) => {
          this.ruleList.data = response.data
        })
        .finally(() => {
          this.ruleList.fetching = false
        })
    },
  }
})
