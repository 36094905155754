/**
 * @file Language keys
 * @module language.key
 * @author potatomelon <https://gitee.com/xiaofango>
 */

export enum LanguageKey {
  APP_SLOGAN = 'app-slogan',
  CATEGORY_INSIGHT = 'insight',
  CATEGORY_CODE = 'code',
  PAGE_HOME = 'home',
  PAGE_GITHUB = 'github',
  PAGE_OPENSEA = 'opensea',
  PAGE_LENS = 'lens',
  PAGE_JOB = 'job',
  PAGE_MERCH = 'merch',
  PAGE_ABOUT = 'about',
  PAGE_SPONSOR = 'sponsor',
  PAGE_FREELANCER = 'freelancer',
  PAGE_NAVIGATION = 'navigation',
  PAGE_GUESTBOOK = 'guestbook',
  PAGE_APP = 'app',
  PAGE_ARCHIVE = 'archive',
  PAGE_SAUNTER = 'saunter',
  PAGE_USER = 'user',
  PAGE_USER_NAV_ARTICLE = 'user-article',
  PAGE_USER_NAV_BOILING = 'user-boiling',
  PAGE_USER_NAV_FOLLOW = 'user-follow',
  PAGE_USER_NAV_COMMENT = 'user-comment',
  PAGE_USER_NAV_PICTURE = 'user-picture',
  PAGE_USER_NAV_SETTING = 'user-setting',

  Setting_PROFILE  = 'setting-profile',
  Setting_ACCOUNT  = 'setting-account',
  Setting_BACKGROUND  = 'setting-background',

  PAGE_PICTURE = 'picture',
  PAGE_WRITE = 'write',
  COMMON_ALL = 'all',
  COMMON_ME = 'me',
  COMMON_LIKE = 'like',
  COMMON_VIEW = 'view',
  COMMON_COLLECT = 'collect',
  COMMON_ASC = 'asc',
  COMMON_DESC = 'desc',

  STATISTIC_ARTICLES = 'statistic-articles',//全站文章
  STATISTIC_COMMENTS = 'statistic-comments',//全站评论
  STATISTIC_BOILINGS = 'statistic-boilings',//全站圈文
  STATISTIC_USERS = 'statistic-users',//全站用户

  STATISTIC_TAGS = 'statistic-tags',
  STATISTIC_TODAY_VIEWS = 'statistic-today-views',
  STATISTIC_TOTAL_VIEWS = 'statistic-total-views',
  STATISTIC_TOTAL_UPVOTES = 'statistic-total-upvotes',
  STATISTIC_AVERAGE_EMOTION = 'statistic-average-emotion',
  EMPTY_PLACEHOLDER = 'common-empty-placeholder',
  NOT_FOUND = 'not-found',
  BACK_TO_HOME_PAGE = 'back-to-home-page',
  GUESTBOOK_SLOGAN = 'guestbook-banner-slogan',
  PAGE_FREELANCER_SLOGAN = 'freelancer-page-banner-slogan',
  PAGE_FREELANCER_EMAIL_ME = 'freelancer-email-me',
  SPONSOR_TEXT = 'sponsor-text',
  AD = 'ad',
  BARRAGE = 'barrage',
  FEEDBACK = 'feedback',
  TO_TOP = 'to-page-top',
  TO_BOTTOM = 'to-page-bottom',
  ORIGIN_ORIGINAL = 'original',
  ORIGIN_REPRINT = 'reprint',
  ORIGIN_HYBRID = 'hybrid',
  ACTION_ON = 'on',
  ACTION_OFF = 'off',
  MUSIC_PLACEHOLDER = 'music-placeholder',
  SEARCH_PLACEHOLDER = 'search-input-placeholder',
  HOT_ARTICLE_LIST_TITLE = 'hot-article-list-title',
  JOIN_USER_LIST_TITLE = 'join-user-top-title',
  ANNOUNCEMENT_PLACEHOLDER = 'announcement-empty-placeholder',
  CATEGORY_PLACEHOLDER = 'category-empty-placeholder',
  TAG_PLACEHOLDER = 'tag-placeholder',
  ARTICLE_TITLE = 'article-title',
  ARTICLE_VIEWS = 'article-views',
  ARTICLE_PLACEHOLDER = 'article-empty-placeholder',
  ARTICLE_READ_ALL = 'read-all-article-content',
  ARTICLE_RENDERING = 'rendering',
  ARTICLE_LIST_LOADMORE = 'loadmore',
  ARTICLE_LIST_LOADING = 'loading',
  ARTICLE_LIST_NO_MORE = 'no-more',
  COMMENT_LIST_EMPTY = 'comment-list-placeholder',
  COMMENT_UPVOTE = 'comment-likes',
  COMMENT_DOWNVOTE = 'comment-dislikes',
  COMMENT_REPLY = 'reply-comment',
  COMMENT_DELETE = 'delete-comment',
  COMMENT_DELETE_CONFIRM = 'delete-comment-confirm',
  COMMENT_REPLY_CANCEL = 'cancel-reply-comment',
  COMMENT_MODERATOR = 'comment-moderator',
  COMMENT_SORT_OLD = 'oldest-comments',
  COMMENT_SORT_NEW = 'newest-comments',
  COMMENT_SORT_HOT = 'hottest-comments',
  COMMENT_POST_NAME = 'comment-author-name',
  COMMENT_POST_EMAIL = 'comment-author-email',
  COMMENT_POST_SITE = 'comment-author-site-url',
  COMMENT_POST_CONTENT = 'comment-content',
  COMMENT_POST_PLACEHOLDER = 'comment-box-placeholder',
  COMMENT_POST_ERROR_CONTENT = 'comment-submit-failed-of-content-error',
  QUERY_PARAMS_ERROR = 'query-params-error',
  POST_ACTION_ERROR = 'post-requiest-failed-of-other-error',
  SUBMIT = 'submit-data',
  SUBMITTING = 'submitting',
  MOMENT_AM = 'moment-am',
  MOMENT_PM = 'moment-pm',
  MOMENT_JUST_NOW = 'moment-just-now',
  MOMENT_MINUTES = 'moment-minutes',
  MOMENT_HOURS = 'moment-hours',
  MOMENT_WEEKS = 'moment-weeks',
  MOMENT_DAYS = 'moment-days',
  MOMENT_MONTHS = 'moment-months',
  MOMENT_YEAR = 'moment-year',
  MOMENT_AGO = 'moment-ago'
}
