/**
 * @file ip city getter
 * @module server.getter.ip
 * @author potatomelon <https://gitee.com/xiaofango>
 */

import localhost from "/@/services/localhost";

// 太平洋
export const getCity = (ip: string) => {
  return localhost.get<any>(`/blog/web/common/api/ip/${ip}`).then((response) => {
    return response.msg
  }).catch(() => {
    return "未知"
  }).finally(() => {
    console.log("get city error!")
  })
}



